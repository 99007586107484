import cash_operations from './cashboxes/cash-operations'
import general from './cashboxes/general'
import subpages from './cashboxes/subpages'

export default [].concat(

    // Child entities
    cash_operations,

    // Subpages not bound to specific entities
    subpages,

    // General route set
    general,
)