// import CashboxesShowDetailsPage from '../../../components/Pages/cashboxes/show/details'
import auth from '../../auth'

export default [
    {
        path: '/cashboxes/:uuid/details',
        name: 'cashboxes.show.details',
//        component: CashboxesShowDetailsPage,
        redirect: 'cashboxes/:uuid',
        beforeEnter: auth.guard.user,
    },
]