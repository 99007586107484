<template>
    <v-input
            dense
            hide-details
    >
        <slot v-bind:filter="filter"></slot>
        <template slot="append">
            <div class="complex-filter_reset">
                <v-icon @click="resetFilter" class="complex-filter_reset_icon">
                    mdi-close
                </v-icon>
            </div>
        </template>
    </v-input>
</template>

<script>
    import DisableChromeAutocompleteMixin from '../../../mixins/Hacks/DisableChromeAutocompleteMixin'

    const defaultFilter = {}

    export default {
        name: "ComplexFilter",
        mixins: [
            DisableChromeAutocompleteMixin,
        ],
        props: {
            value: {
                required: false,
                type: Object,
                default: () => ({}),
            },
        },
        data: vm => ({
            filter: vm.getInitialFilterValue()
        }),
        methods: {
            clone(value) {
                return _.isArray(value) || _.isObject(value)
                    ? JSON.parse(JSON.stringify(value))
                    : value
            },
            itemsEqual(item1, item2) {
                const type = typeof item1
                switch (true) {
                    case type !== (typeof item2):
                        return false
                    case type === 'object':
                        return !JSON.stringify(item1).localeCompare(JSON.stringify(item2))
                    default:
                        return item1 === item2
                }
            },
            getInitialFilterValue() {
                return this.getValue()
            },
            getValue() {
                return this.clone(_.isObject(this.value) ? this.value : defaultFilter)
            },
            resetFilter() {
/*
                this.filter = _.map(this.clone(this.filter), function (value) {
                    switch (true) {
                        case _.isObject(value):
                            return {}
                        case _.isArray(value):
                            return []
                        case _.isString(value):
                            return ''
                        default:
                            return null
                    }
                })
*/
                this.filter = this.clone(defaultFilter)
            },
        },
        watch: {
            value: {
                deep: true,
                immediate: true,
                handler(value) {
                    if (!_.isObject(value) || _.isArray(value)) {
                        this.filter = ''
                    } else if (!this.itemsEqual(value, this.filter)) {
                        this.filter = this.clone(value)
                    }
                }
            },
            filter: {
                deep: true,
                immediate: true,
                handler(filter) {
                    this.$emit(
                        'input',
                        this.clone((!_.isObject(filter) || _.isArray(filter)) ? defaultFilter : filter)
                    )
                }
            },
        },
    }
</script>
