<template>
    <item-create-view v-slot:default="_">
        <cash-operation-edit-form
                :disabled="_.itemDisabled"
                v-model="_.item"
        />
    </item-create-view>
</template>

<script>
    import ItemCreateView from '../../Item/Views/ItemCreateView'
    import CashOperationEditForm from './Shared/CashOperationEditForm'

    export default {
        name: 'CashOperationsCreatePage',
        components: {
            ItemCreateView,
            CashOperationEditForm,
        },
    }
</script>
