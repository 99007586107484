<template>
    <item-show-view v-slot:default="{ item: position }">
        <v-row>
            <v-col>
                <subcard>
                    <v-simple-table>
                        <tbody>
                        <tr>
                            <th>{{ l('pages.positions.properties.name') }}</th>
                            <td>{{ position.name }}</td>
                        </tr>
                        <tr>
                            <th>{{ l('pages.positions.properties.unit') }}</th>
                            <td>{{ position.unit.name || '' }}</td>
                        </tr>
                        <tr>
                            <th>{{ l('pages.positions.properties.location') }}</th>
                            <td>{{ position.location.name || '' }}</td>
                        </tr>
                        </tbody>
                    </v-simple-table>
                </subcard>
            </v-col>
        </v-row>
    </item-show-view>
</template>

<script>
    import ItemShowView from '../../Item/Views/ItemShowView'
    import Subcard from '../../Shared/Cards/Subcard'

    export default {
        name: 'PositionsShowPage',
        components: {
            ItemShowView,
            Subcard,
        },
    }
</script>

<style scoped>

</style>