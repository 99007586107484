<template>
    <item-list-view
            :headers="headers"
            create-button
    >

        <template v-slot:extraItemActions="_">
            <span>
                <tooltip-icon
                        v-if="_.item && undefined !== _.item.user_id && !!_.item.user_id"
                        class="ml-1"
                        :tooltip="l('pages.cash_operations.actions.show_slip')"
                        @click="showSlip(_.item)"
                        value="mdi-file-document-box-check-outline"
                        color="grey"
                />
            </span>
        </template>

        <template v-slot:item.user="_">
            <router-link v-if="_.item.user_id" :to="{name: 'employees.show', params: {uuid: _.value.uuid}}">
                {{ _.value | listName }}
            </router-link>
        </template>

        <template v-slot:item.type="_">
            <v-chip
                    small
                    :color="_.value === 'withdrawal' ? 'deep-orange lighten-5' : 'light-green lighten-4'"
                    :class="'font-weight-bold text-uppercase text--darken-4 ' + (_.value === 'withdrawal' ? 'deep-orange--text' : 'light-green--text')"
            >
                {{ l('pages.cash_operations.values.type.' + _.value) }}
            </v-chip>
        </template>

        <template v-slot:filter="_">
            <v-row dense>

                <v-col cols="12" lg="5" class="py-0">
                    <v-row dense>
                        <v-col cols="12" sm="4">
                            <api-select
                                    api="cash-operations/types"
                                    dense
                                    hide-details
                                    chips
                                    deletable-chips
                                    multiple
                                    v-model="_.filter.type"
                                    :label="l('pages.cash_operations.index.filters.type')"
                            />
                        </v-col>
                        <v-col cols="12" sm="8">
                            <trim-text-field
                                    class="mt-3"
                                    dense
                                    hide-details
                                    v-model="_.filter.text"
                                    :label="l('pages.cash_operations.index.filters.text')"
                            />
                        </v-col>
                    </v-row>
                </v-col>

                <v-col cols="12" lg="7" class="py-0">
                    <v-row dense>
                        <v-col cols="12" sm="6" lg="3">
                            <term-select
                                    clearable
                                    class="mt-3"
                                    dense
                                    :disabled="!termFilterOptions || termFilterOptions.length === 0"
                                    hide-details
                                    :items="termFilterOptions"
                                    v-model="_.filter.from_term"
                                    :label="l('pages.cash_operations.index.filters.from_term')"
                            />
                        </v-col>
                        <v-col cols="12" sm="6" lg="3">
                            <term-select
                                    clearable
                                    class="mt-3"
                                    dense
                                    :disabled="!termFilterOptions || termFilterOptions.length === 0"
                                    hide-details
                                    :items="termFilterOptions"
                                    v-model="_.filter.to_term"
                                    :label="l('pages.cash_operations.index.filters.to_term')"
                            />
                        </v-col>
                        <v-col cols="12" sm="6" lg="3">
                            <date-field
                                    clearable
                                    text-field-class="mt-3"
                                    dense
                                    hide-details
                                    v-model="_.filter.from_date"
                                    :label="l('pages.cash_operations.index.filters.from_date')"
                            />
                        </v-col>
                        <v-col cols="12" sm="6" lg="3">
                            <date-field
                                    clearable
                                    text-field-class="mt-3"
                                    dense
                                    hide-details
                                    v-model="_.filter.to_date"
                                    :label="l('pages.cash_operations.index.filters.to_date')"
                            />
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </template>

    </item-list-view>
</template>

<script>
    import TablePageMixin from '../../../mixins/Cumulative/TablePageMixin'
    import TrimTextField from '../../Shared/Forms/TextFields/TrimTextField'
    import DateField from '../../Shared/Forms/TextFields/DateField'
    import ApiSelect from "../../Shared/Forms/ApiSelect";
    import TooltipIcon from "../../Shared/Icons/TooltipIcon";
    import TermSelect from "../../Shared/Forms/Selects/TermSelect";

    export default {
        name: "CashOperationsIndexPage",
        components: {
            TermSelect,
            TooltipIcon,
            ApiSelect,
            DateField,
            TrimTextField,
        },
        mixins: [
            TablePageMixin,
        ],
        data: vm => ({
            termFilterOptions: [],
            headers: [
                {
                    value: 'id',
                    align: 'end',
                    sortable: true,
                    filterable: false,
                    width: '5%',
                },
                {
                    value: 'type',
                    align: 'center',
                    sortable: false,
                    filterable: false,
                },
                {
                    value: 'user',
                    align: 'start',
                    sortable: false,
                    filterable: false,
                },
                {
                    value: 'cashbox',
                    align: 'start',
                    sortable: false,
                    filterable: false,
                },
                {
                    value: 'note',
                    align: 'start',
                    sortable: false,
                    filterable: false,
                },
                {
                    value: 'term',
                    align: 'end',
                    sortable: true,
                    filterable: false,
                },
                {
                    value: 'amount',
                    align: 'end',
                    sortable: true,
                    filterable: false,
                },
                {
                    value: 'created_at',
                    align: 'end',
                    sortable: true,
                    filterable: false,
                },
            ],
            customAppTitle: 'pages.cash_operations.index.title',
        }),
        methods: {
            showSlip(item) {
                if (undefined !== item.uuid) {
                    window.open('/cash-operations/' + item.uuid + '/slip?stripped=1')
                }
            }
        },
        created() {
            this.$api.get('transactions/terms/list/desc')
                .then(function(response) {
                    this.termFilterOptions = response.data
                }.bind(this))
                .catch(error => error)
        },
    }
</script>

<style scoped>
</style>