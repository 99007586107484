import LocationsShowDetailsPage from '../../../components/Pages/locations/show/details'
import auth from '../../auth'

export default [
    {
        path: '/locations/:uuid/details',
        name: 'locations.show.details',
        component: LocationsShowDetailsPage,
        beforeEnter: auth.guard.user,
    },
]