<template>
    <item-show-view
            :tabs="tabs"
            v-bind="$attrs"
            v-on="$listeners"
    >
        <template v-slot:default="_">
            <slot
                    v-bind:item-disabled="_.itemDisabled"
                    v-bind:item="_.item"
            />
        </template>
    </item-show-view>
</template>

<script>
    import ItemShowView from '../../Item/Views/ItemShowView'

    export default {
        name: 'EmployeesShowPage',
        inheritAttrs: false,
        components: {
            ItemShowView,
        },
        data: vm => ({
            baseTabs: [
                'details',
                'documents',
                'assignments',
                'shifts',
                'adjustments',
                'fund-locks',
                'transactions',
            ],
        }),
        computed: {
            tabs() {
                let tabs = this.baseTabs
                if (this.$store.getters.userWorksWithCashboxes) {
                    tabs.push('cash-operations')
                }
                return tabs
            },
        },
    }
</script>

<style scoped>

</style>