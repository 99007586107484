import auth from '../auth'
import ShiftsIndexPage from '../../components/Pages/shifts/index'
import ShiftsShowPage from '../../components/Pages/shifts/show'
import ShiftsEditPage from '../../components/Pages/shifts/edit'
import ShiftsCreatePage from '../../components/Pages/shifts/create'

export default [
    {
        path: '/shifts/create',
        name: 'shifts.create',
        component: ShiftsCreatePage,
        beforeEnter: auth.guard.user,
    },
    {
        path: '/shifts/:uuid/edit',
        name: 'shifts.edit',
        component: ShiftsEditPage,
        beforeEnter: auth.guard.user
    },
    {
        path: '/shifts/:uuid',
        name: 'shifts.show',
        component: ShiftsShowPage,
        beforeEnter: auth.guard.user,
    },
    {
        path: '/shifts',
        name: 'shifts.index',
        component: ShiftsIndexPage,
        beforeEnter: auth.guard.user,
    },
]