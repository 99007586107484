<template>
    <input-list
            v-bind="$attrs"
            :dense="dense"
            :disabled="disabled"
            :hide-details="hideDetails"
            :model-template="modelTemplate"
            v-on="$listeners"
            :value="value"
            @input="handleInput"
    >
        <template slot="default" slot-scope="_">
            <contract-field
                    autocomplete="off"
                    :dense="dense"
                    :disabled="disabled"
                    :hide-details="hideDetails"
                    v-model="_.item"
            >
            </contract-field>
        </template>
    </input-list>
</template>

<script>
    import ContractField from './ContractField'
    import InputList from '../../../../Shared/Forms/InputList'

    export default {
        name: "ContractList",
        inheritAttrs: false,
        components: {
            InputList,
            ContractField,
        },
        props: {
            dense: {
                type: Boolean,
                required: false,
                default: false,
            },
            disabled: {
                type: Boolean,
                required: false,
                default: false,
            },
            hideDetails: {
                type: Boolean,
                required: false,
                default: false,
            },
            value: {
                type: Array,
                required: false,
                default: () => [],
            },
        },
        data: () => ({
            modelTemplate: {
                type_id: '',
                from_date: '',
                to_date: '',
                note: '',
            },
        }),
        methods: {
            handleInput(input) {
                this.$emit('input', input)
            },
        },
    }
</script>
