<template>
    <v-form>
        <v-row>
            <v-col cols="12">
                <subcard>
                    <v-row dense>
                        <v-col cols="12" :md="cashboxAmountIsVisible ? 10 : 12">
                            <api-select
                                    class="mt-0"
                                    api="cashboxes/list/available"
                                    first
                                    :disabled="disabled"
                                    hide-details
                                    :label="l('pages.cash_operation.properties.cashbox')"
                                    v-model="cashboxId"
                                    :readonly="!!cashOperation.id"
                                    :rules="[rules.required]"
                                    required
                            />
                        </v-col>
                        <v-col cols="12" md="2" v-if="cashboxAmountIsVisible">
                            <v-sheet
                                    :color="cashbox.amount > 0 ? 'green lighten-5' : 'deep-orange lighten-4'"
                                    class="px-1 py-2 body-2 font-weight-bold mt-2"
                                    align="right"
                            >
                                {{ cashbox.amount + ' ' + l('global.currency') }}
                            </v-sheet>
                        </v-col>
                    </v-row>
                    <v-row dense>
                        <v-col cols="12" md="3">
                            <v-radio-group
                                    :disabled="disabled"
                                    hide-details
                                    row
                                    v-model="type"
                            >
                                <v-radio
                                        v-for="cashOperationType of types"
                                        :key="cashOperationType"
                                        :label="l('pages.cash_operations.values.type.' + cashOperationType)"
                                        :value="cashOperationType"
                                />
                            </v-radio-group>
                        </v-col>
                        <v-col cols="12" md="5">
                            <v-text-field
                                    class="mt-3"
                                    dense
                                    :disabled="disabled"
                                    hide-details
                                    :label="l('pages.cash_operations.properties.note')"
                                    v-model="cashOperation.note"
                            />
                        </v-col>
                        <v-col cols="12" md="2">
                            <term-select
                                    auto-select-first
                                    class="mt-3"
                                    dense
                                    :disabled="disabled"
                                    hide-details
                                    :label="l('pages.cash_operations.properties.term')"
                                    v-model="cashOperation.term"
                                    :rules="[rules.required]"
                                    required
                            />
                        </v-col>
                        <v-col cols="12" md="2">
                            <number-field
                                    class="mt-3"
                                    dense
                                    :disabled="amountDisabled"
                                    hide-details
                                    :label="l('pages.cash_operations.properties.amount')"
                                    v-model="amount"
                                    :rules="[rules.required]"
                                    required
                            />
                        </v-col>
                    </v-row>
                    <v-row v-if="showAvailableAmount">
                        <v-col align="end">
                            <v-tooltip bottom v-if="!!availableAmount">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-chip
                                            class="font-weight-bold text-uppercase"
                                            :color="availableAmount === null ? 'grey lighten-3' : (availableAmount <= 0 ? 'deep-orange lighten-4' : 'light-green lighten-3')"
                                            @click="handleAvailableAmountClick"
                                            v-bind="attrs"
                                            v-on="on"
                                    >
                                        {{ l('global.max') }}
                                        <span class="ml-1">
                                            <span v-if="null !== availableAmount">
                                                {{ availableAmount | amount }} {{ l('global.currency') }}
                                            </span>
                                            <span v-else>
                                                ---
                                            </span>
                                        </span>
                                    </v-chip>
                                </template>
                                <span>{{ l('pages.employees.actions.set_max_cash_amount') }}</span>
                            </v-tooltip>
                            <v-chip
                                    class="font-weight-bold text-uppercase"
                                    :color="availableAmount === null ? 'grey lighten-3' : (availableAmount <= 0 ? 'deep-orange lighten-4' : 'light-green lighten-3')"
                                    v-else
                            >
                                {{ l('pages.employees.properties.max_cash_amount') }}:
                                <span class="ml-1">
                                    <span v-if="null !== availableAmount">
                                        {{ availableAmount | amount }} {{ l('global.currency') }}
                                    </span>
                                    <span v-else>
                                        ---
                                    </span>
                                </span>
                            </v-chip>
                        </v-col>
                    </v-row>
                </subcard>
            </v-col>
        </v-row>
    </v-form>
</template>

<script>
    import t from 'typy'
    import _ from 'lodash'
    import Subcard from '../../../../../Shared/Cards/Subcard'
    import NumberField from '../../../../../Shared/Forms/TextFields/NumberField'
    import ApiSelect from "../../../../../Shared/Forms/ApiSelect";
    import TermSelect from "../../../../../Shared/Forms/Selects/TermSelect";

    const TYPE_DEPOSIT = 'deposit'
    const TYPE_WITHDRAWAL = 'withdrawal'

    const multipliers = {}
    multipliers[TYPE_WITHDRAWAL] = -1
    multipliers[TYPE_DEPOSIT] = 1

    export default {
        name: "CashOperationEditForm",
        inheritAttrs: false,
        components: {
            TermSelect,
            ApiSelect,
            NumberField,
            Subcard,
        },
        props: {
            value: {
                required: false,
                type: Object,
                default: () => ({}),
            },
            employee: {
                required: false,
                type: Object,
                default: () => ({}),
            },
            disabled: {
                required: false,
                type: Boolean,
                default: false,
            }
        },
        data: vm => ({
            t: t,
            amount: null,
            availableAmount: null,
            cashOperation: {},
            cashboxId: null,
            cashbox: {},
            rules: {
                required: value => undefined !== value && '' !== value || vm.l('pages.cash_operations.validation.required')
            },
            cashboxAmountIsAvailable: false,
            cashboxAmountIsVisible: false,
            type: TYPE_WITHDRAWAL,
            types: [
                TYPE_WITHDRAWAL,
                TYPE_DEPOSIT,
            ],
        }),
        watch: {
            value: {
                deep: true,
                immediate: true,
                handler(value) {
                    this.cashOperation = value
                    this.cashboxId = _.get(value, 'cashbox_id', null)
                    if (undefined !== value.amount) {
                        this.type = value.amount < 0 ? TYPE_WITHDRAWAL : TYPE_DEPOSIT
                        this.amount = Math.abs(value.amount)
                    }
                },
            },
            amount(amount) {
                this.cashOperation.amount = Math.abs(amount) * multipliers[this.type]
            },
            availableAmount(amount) {
                if (null !== amount && this.amount && this.amount > amount) {
                    this.amount = amount
                }
            },
            'cashOperation.amount': function (amount) {
                this.amount = !!amount ? Math.abs(amount) : null
            },
            'cashOperation.term': {
                immediate: true,
                handler() {
                    this.refreshAvailableAmount()
                }
            },
            type(type) {
                if (this.amount) {
                    this.cashOperation.amount = Math.abs(this.amount) * multipliers[type]
                }
            },
            cashOperation: {
                deep: true,
                immediate: true,
                handler(value) {
                    this.$emit('input', value)
                },
            },
            cashbox: {
                deep: true,
                handler(cashbox) {
                    const amountIsAvailable = t(cashbox, 'amount').isDefined
                    this.cashboxAmountIsAvailable = amountIsAvailable
                    this.cashboxAmountIsVisible = amountIsAvailable && !t(this.cashOperation, 'id').isDefined
                },
                immediate: true
            },
            cashboxId: function (cashboxId) {
                this.cashOperation.cashbox_id = cashboxId
                if (cashboxId) {
                    const currentCashboxId = _.get(this.cashbox, 'id', null)
                    if (currentCashboxId !== cashboxId) {
                        this.$api.get('cashboxes', {params: {filter: {only_id: cashboxId}}})
                            .then(function (response) {
                                this.cashbox = (response.data.items.length !== 0) ? response.data.items[0] : {}
                            }.bind(this))
                            .catch(function () {
                                this.cashbox = {}
                            }.bind(this))
                    }
                } else {
                    this.cashbox = {}
                }
            },

        },
        computed: {
            amountDisabled() {
                return this.disabled || null === this.availableAmount && this.showAvailableAmount
            },
            showAvailableAmount() {
                return this.type === TYPE_WITHDRAWAL
            }
        },
        methods: {
            handleAvailableAmountClick() {
                if (null !== this.availableAmount) {
                    this.amount = this.availableAmount
                }
            },
            refreshAvailableAmount() {
                this.availableAmount = null
                const term = this.cashOperation.term;
                if (!!term) {
                    this.$api.get('employees/' + this.employee.uuid + '/amount/' + term)
                        .then(function (response) {
                            this.availableAmount = response.data.amount
                        }.bind(this))
                        .catch(error => error)
                }
            },
        },
    }
</script>

