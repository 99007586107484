import adjustments from './employees/adjustments'
import assignments from './employees/assignments'
import cash_operations from './employees/cash-operations'
import contracts from './employees/contracts'
import fund_locks from './employees/fund-locks'
import general from './employees/general'
import residences from './employees/residences'
import shifts from './employees/shifts'
import subpages from './employees/subpages'
import transactions from './employees/transactions'

export default [].concat(

    // Child entities
    adjustments,
    assignments,
    cash_operations,
    contracts,
    fund_locks,
    residences,
    shifts,
    transactions,

    // Subpages not bound to specific entities
    subpages,

    // General route set
    general,
)