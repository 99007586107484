<template>
    <span>
        <v-tooltip
                v-if="!!tooltipText"
                :bottom="tooltipPosition === 'bottom'"
                :left="tooltipPosition === 'left'"
                :right="tooltipPosition === 'right'"
                :top="tooltipPosition === 'top' || tooltipPosition === 'bottom' && tooltipPosition === 'left' && tooltipPosition === 'right'"
        >
            <template v-slot:activator="{ on }">
                <span
                        v-on="on"
                >
                    <v-btn
                            :class="buttonClassName"
                            :min-width="buttonMinWidth"
                            v-bind="$attrs"
                            v-on="$listeners"
                            :large="large"
                            :small="small"
                            :x-large="xLarge"
                            :x-small="xSmall"
                    >
                        <v-icon
                                :class="iconClass"
                                :size="iconSize"
                        >
                            {{ icon }}
                        </v-icon>
                    </v-btn>
                </span>
            </template>
            <span>
                {{ tooltipText }}
            </span>
        </v-tooltip>
    </span>
</template>

<script>

    const defaultIcon = 'mdi-file-question-outline',
        forcedClassName = 'px-0'

    export default {
        name: "SquareButton",
        inheritAttrs: false,
        props: {
            buttonClass: {
                default: '',
                required: false,
                type: String,
            },
            defaultIconSize: {
                default: 24,
                required: false,
            },
            icon: {
                default: defaultIcon,
                required: false,
                type: String,
            },
            iconClass: {
                default: '',
                required: false,
                type: String,
            },
            tooltipPosition: {
                default: 'top',
                required: false,
                type: String,
            },
            tooltipText: {
                default: null,
                required: false,
                type: String,
            },
            minWidth: {
                default: 0,
                required: false,
            },
            large: {
                default: false,
                required: false,
                type: Boolean,
            },
            largeIconSize: {
                default: 44,
                required: false,
            },
            small: {
                default: false,
                required: false,
                type: Boolean,
            },
            smallIconSize: {
                default: 24,
                required: false,
            },
            xLarge: {
                default: false,
                required: false,
                type: Boolean,
            },
            xLargeIconSize: {
                default: 52,
                required: false,
            },
            xSmall: {
                default: false,
                required: false,
                type: Boolean,
            },
            xSmallIconSize: {
                default: 18,
                required: false,
            },
        },
        computed: {
            buttonClassName() {
                return forcedClassName + ' ' + this.buttonClass
            },
            buttonMinWidth() {
                switch (true) {
                    case (this.minWidth !== 0):
                        return this.minWidth
                    case this.xLarge:
                        return 52
                    case this.large:
                        return 44
                    case this.small:
                        return 28
                    case this.xSmall:
                        return 20
                    default:
                        return 36
                }
            },
            iconSize() {
                switch (true) {
                    case this.xLarge:
                        return this.xLargeIconSize
                    case this.large:
                        return this.largeIconSize
                    case this.small:
                        return this.smallIconSize
                    case this.xSmall:
                        return this.xSmallIconSize
                    default:
                        return this.defaultIconSize
                }
            },
        },
    }
</script>

<style scoped>

</style>