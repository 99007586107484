<template>
    <v-form>
        <v-row>
            <v-col>
                <subcard>
                    <v-row dense>
                        <v-col cols="12" md="3">
                            <v-text-field
                                    :disabled="disabled"
                                    hide-details
                                    v-model="position.name"
                                    :label="l('pages.positions.properties.name')"
                                    :rules="[rules.required]"
                                    required
                            />
                        </v-col>
                        <v-col cols="12" md="2">
                            <api-select
                                    api="units/list"
                                    :disabled="disabled"
                                    hide-details
                                    :label="l('pages.positions.properties.unit')"
                                    v-model="position.unit_id"
                            />
                        </v-col>
                        <v-col cols="12" md="4">
                            <api-select
                                    api="locations/list"
                                    :disabled="disabled"
                                    hide-details
                                    :label="l('pages.positions.properties.location')"
                                    v-model="position.location_id"
                            />
                        </v-col>
                        <v-col cols="12" md="3">
                            <rates-field
                                    v-model="position.rates"
                            />
                        </v-col>
                    </v-row>
                </subcard>
            </v-col>
        </v-row>
    </v-form>
</template>

<script>
    import Subcard from '../../../Shared/Cards/Subcard'
    import RatesField from '../../../Shared/ModelSpecific/Position/RatesField'
    import ApiSelect from '../../../Shared/Forms/ApiSelect'

    const defaultPositionModel = {
        name: null,
        unit_id: null,
        location_id: null,
        rates: [],
    }

    export default {
        name: "PositionEditForm",
        inheritAttrs: false,
        components: {
            ApiSelect,
            RatesField,
            Subcard,
        },
        props: {
            value: {
                required: false,
                type: Object,
                default: () => defaultPositionModel,
            },
            disabled: {
                required: false,
                type: Boolean,
                default: false,
            }
        },
        data: vm => ({
            position: {},
            rules: {
                required: value => undefined !== value && '' !== value || vm.l('pages.positions.validation.required')
            },
        }),
        watch: {
            value: {
                deep: true,
                immediate: true,
                handler(value) {
                    this.position = value
                },
            },
            position: {
                deep: true,
                handler(value) {
                    this.$emit('input', value)
                },
            },
        },
    }
</script>

