<template>
    <v-text-field
            :autocomplete="autocomplete"
            v-bind="attrs"
            v-on="listeners"
            v-model="model"
            placeholder="HH:MM"
            :rules="[rules.required, rules.invalid]"
            :required="required"
            class="time-field"
    >
        <template slot="prepend-inner">
            <v-icon
                    color="blue-grey"
            >
                mdi-clock-outline
            </v-icon>
        </template>
    </v-text-field>
</template>

<script>
    import _ from 'lodash'
    import DisableChromeAutocompleteMixin from '../../../../mixins/Hacks/DisableChromeAutocompleteMixin'

    const defaultModel = '00:00'

    export default {
        name: "TimeField",
        inheritAttrs: false,
        mixins: [
            DisableChromeAutocompleteMixin,
        ],
        props: {
            autocomplete: {
                required: false,
                default: 'autocomplete',
            },
            value: {
                required: false,
                default: '',
            },
            required: {
                type: Boolean,
                required: false,
                default: false,
            },
        },
        data: vm => ({
            model: vm.required ? defaultModel : '',
            tail: '',
            rules: {
                'invalid': value => !!(value || '').trim().match(/^(?:(?:[01]?\d|2[0-3]):(?:[0-5]\d).*)?$/) || vm.l('forms.timeField.validation.invalid'),
                'required': value => (!vm.required || !!(value || '').trim().length) || vm.l('forms.timeField.validation.required'),
            },
        }),
        watch: {
            model(value) {
                this.handleModelUpdate(value)
            },
            required() {
                this.handleValueUpdate(this.value)
            },
            value: {
                immediate: true,
                handler(value) {
                    this.handleValueUpdate(value)
                },
            },
        },
        methods: {

            handleModelUpdate(value) {
                try {
                    if (!value && (!!this.value && this.value !== defaultModel)) {
                        if (this.required) {
                            this.$nextTick(function() {
                                this.model = defaultModel
                            }.bind(this))
                        } else {
                            this.$emit('input', null)
                        }
                    } else {
                        const time = value.trim().match(/^((?:[01]?\d|2[0-3]):(?:[0-5]\d)).*$/)

                        if (time) {

                            value = time[1] + this.tail
                            if (value.length === 4) {
                                value = '0' + value
                            }

                            if (this.value !== value) {
                                this.$emit('input', value)
                            }
                        }
                    }
                } catch (e) {
                    //
                }
            },
            handleValueUpdate(value) {
                try {
                    const time = value.trim().match(/^((?:[01]?\d|2[0-3]):(?:[0-5]\d))(.*)$/)
                    if (time) {
                        if (this.model !== time[1]) {
                            this.model = time[1]
                        }
                        this.tail = time[2]
                        return
                    }
                } catch (e) {
                    //
                }
                this.model = this.required ? defaultModel : ''
            },


        },
        computed: {
            attrs() {
                return _.omit(this.$attrs, ['value', 'required'])
            },
            listeners() {
                return _.omit(this.$listeners, 'input')
            },
        },
    }
</script>

