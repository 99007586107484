<template><span
  :class="colorClass"
  v-bind="$attrs"
  v-on="$listeners"
  class="font-weight-bold"
>{{ text }}</span></template>

<script>
    import _ from 'lodash'

    const classes = {
        'minus': 'deep-orange--text text--darken-3',
        'plus': 'light-green--text text--darken-3',
        'zero': 'grey--text text--darken-3',
    }

    export default {
        name: "Amount",
        props: {
            currency: {
                default: false,
                required: false,
                type: Boolean,
            },
            noColor: {
                default: false,
                required: false,
                type: Boolean,
            },
            value: {
                default: '',
                required: false,
            },
        },
        data: vm => ({
            amount: null,
            amountString: '',
            colorClass: '',
            currencyString: '',
            sign: 'zero',
            slotText: '',
            text: '',
        }),
        computed: {
            source() {
                return this.value || this.slotText
            },
        },
        watch: {
            '$slots.default.0.text': function(slotText) {
                this.slotText = slotText
            },
            amount(amount) {
                this.sign = (amount === 0) ? 'zero' : (amount < 0 ? 'minus' : 'plus')
                this.amountString = this.$options.filters.amount(amount)
            },
            amountString(currencyString) {
                this.text = this.composeText()
            },
            currency: {
                immediate: true,
                handler(currency) {
                    this.currencyString = currency ? (' ' + this.l('global.currency')) : ''
                },
            },
            currencyString(currencyString) {
                this.text = this.composeText()
            },
            noColor() {
                this.refreshColorClass()
            },
            sign() {
                this.refreshColorClass()
            },
            source: {
                immediate: true,
                handler(source) {
                    this.amount = this.extractAmount(source)
                },
            },
        },
        methods: {
            composeText() {
                return this.amountString + this.currencyString
            },
            extractAmount(text) {
                try {
                    return parseFloat(text.toString().trim().replace(/^(-?(?:\d+|\d*)(?:\.\d+)?)([^\d].*)?/, '$1'))
                } catch (e) {
                    return 0
                }
            },
            refreshColorClass() {
                this.colorClass = this.noColor ? '' : classes[this.sign]
            },
        },
        updated() {
            this.slotText = _.get(this.$slots, 'default.0.text', '')
        },
    }
</script>

