<template>
    <v-form>
        <v-row>
            <v-col cols="12">
                <subcard>
                    <v-row dense>
                        <v-col cols="12" md="6">
                            <v-text-field
                                    :disabled="disabled"
                                    hide-details
                                    v-model="location.name"
                                    :label="l('pages.locations.properties.name')"
                                    :rules="[rules.required]"
                                    required
                            />
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-text-field
                                    :disabled="disabled"
                                    hide-details
                                    v-model="location.address"
                                    :label="l('pages.locations.properties.address')"
                                    required
                            />
                        </v-col>
                    </v-row>
                </subcard>
            </v-col>
        </v-row>
    </v-form>
</template>

<script>
    import Subcard from '../../../Shared/Cards/Subcard'

    const defaultLocationModel = {
        name: '',
        address: '',
    }

    export default {
        name: "LocationEditForm",
        inheritAttrs: false,
        components: {
            Subcard,
       },
        props: {
            value: {
                required: false,
                type: Object,
                default: () => defaultLocationModel,
            },
            disabled: {
                required: false,
                type: Boolean,
                default: false,
            }
        },
        data: vm => ({
            location: {},
            rules: {
                required: value => undefined !== value && '' !== value || vm.l('pages.locations.validation.required')
            },
        }),
        watch: {
            value: {
                deep: true,
                immediate: true,
                handler(value) {
                    this.location = value
                },
            },
            location: {
                deep: true,
                handler(value) {
                    this.$emit('input', value)
                },
            },
        },
    }
</script>

