<template>
  <locations-show-page
      parent
      content-color="white"
      v-slot:default="{item: location}"
  >
    <tunnel :value="location" @input="handleLocationTunnel"/>
    <v-row>
      <v-col cols="12" md="12" class="pb-0">
        <sub-item-list
            :create-button="createButtonEnabled"
            :headers="headers"
            :hidden="listHidden"
        >

          <template v-slot:item.user="_">
            <router-link
                :to="{name: 'employees.show', params: {uuid: _.value.uuid}}">
              {{ _.value | listName }}
            </router-link>
          </template>

          <template v-slot:filter="_">
            <tunnel :value="_.filter" @input="handleFilterTunnel"/>
            <v-row dense>
              <v-col cols="12" md="8" class="py-0">
                <v-row dense>
                  <v-col cols="12" sm="5">
                    <trim-text-field
                        class="mt-3 py-2"
                        dense
                        hide-details
                        v-model="_.filter.text"
                        :label="l('pages.employees.index.filters.text')"
                    />
                  </v-col>
                  <v-col cols="12" sm="3">
                    <v-select
                        class="mt-3 py-2"
                        dense
                        :disabled="!filterUnitOptions"
                        hide-details
                        chips
                        deletable-chips
                        multiple
                        :items="filterUnitOptions"
                        v-model="filter.unit_id"
                        :label="l('pages.assignments.index.filters.unit_id')"
                    />
                  </v-col>
                  <v-col cols="12" sm="4">
                    <v-select
                        class="mt-3 py-2"
                        dense
                        :disabled="!filterPositionOptions"
                        hide-details
                        chips
                        deletable-chips
                        :items="filterPositionOptions"
                        multiple
                        v-model="filter.position_id"
                        :label="l('pages.assignments.index.filters.position_id')"
                    />
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" md="4" class="py-0">
                <v-row dense>
                  <v-col cols="12" sm="6">
                    <date-field
                        clearable
                        text-field-class="mt-3 py-2"
                        dense
                        hide-details
                        v-model="filter.from_date"
                        :label="l('pages.assignments.index.filters.from_date')"
                    />
                  </v-col>
                  <v-col cols="12" sm="6">
                    <date-field
                        clearable
                        text-field-class="mt-3 py-2"
                        dense
                        hide-details
                        v-model="filter.to_date"
                        :label="l('pages.assignments.index.filters.to_date')"
                    />
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </template>

        </sub-item-list>
      </v-col>
    </v-row>
  </locations-show-page>
</template>

<script>
import CheckMark from '../../../../Shared/Icons/CheckMark'
import Subcard from '../../../../Shared/Cards/Subcard'
import LocationsShowPage from '../../show'
import SubItemList from '../../../../Item/SubItemList'
import DateField from '../../../../Shared/Forms/TextFields/DateField'
import TrimTextField from '../../../../Shared/Forms/TextFields/TrimTextField'
import Tunnel from '../../../../Shared/Tunnel'
import ServiceFunctionsMixin from '../../../../../mixins/ServiceFunctionsMixin'
import ApiSelect from '../../../../Shared/Forms/ApiSelect'

export default {
  name: 'LocationsShowShiftsIndexPage',
  components: {
    ApiSelect,
    Tunnel,
    CheckMark,
    DateField,
    LocationsShowPage,
    SubItemList,
    Subcard,
    TrimTextField,
  },
  mixins: [
    ServiceFunctionsMixin,
  ],
  data: vm => ({
    createButtonEnabled: false,
    headers: [
      {
        value: 'id',
        align: 'end',
        sortable: true,
        filterable: false,
      },
      {
        value: 'user',
        align: 'start',
        sortable: false,
        filterable: false,
      },
      {
        value: 'position',
        align: 'start',
        sortable: false,
        filterable: false,
      },
      {
        value: 'date',
        align: 'end',
        sortable: true,
        filterable: false,
      },
      /*
                      {
                          value: 'time',
                          align: 'end',
                          sortable: true,
                          filterable: false,
                      },
      */
      {
        value: 'hours',
        align: 'end',
        sortable: true,
        filterable: false,
      },
      {
        value: 'rate',
        align: 'end',
        sortable: true,
        filterable: false,
      },
      {
        value: 'is_payable',
        align: 'center',
        sortable: true,
        filterable: false,
      },
      {
        value: 'amount',
        align: 'end',
        sortable: true,
        filterable: false,
      },
      {
        value: 'created_by',
        align: 'start',
        sortable: false,
        filterable: false,
      },
    ],
    filterLocationId: null,
    filter: {},
    filterPositionOptions: [],
    filterPositionScope: {},
    filterUnitId: null,
    filterUnitOptions: [],
    filterUnitScope: {},
    location: null,
    listHidden: false,
  }),
  watch: {
    filter: {
      deep: true,
      immediate: true,
      handler(filter) {
        const filterUnitId = this.clone(_.get(filter, 'unit_id', null)),
            oldFilterUnitId = this.clone(this.filterUnitId)
        const unitSetAsArray = _.isArray(filterUnitId)
        if (
            unitSetAsArray && !this.arraysEqual(oldFilterUnitId, filterUnitId)
            || !unitSetAsArray && oldFilterUnitId !== filterUnitId
        ) {
          this.filterUnitId = filterUnitId
        }
      },
    },
    filterLocationId: {
      immediate: true,
      handler(filterLocationId) {
        this.filterUnitScope = {
          location_id: filterLocationId,
        }
        this.filterPositionScope = {
          location_id: filterLocationId,
          unit_id: this.filterUnitId,
        }
      },
    },
    filterPositionScope: {
      immediate: true,
      deep: true,
      handler(filterPositionScope) {
        this.filterPositionOptions = []
        if (_.get(filterPositionScope, 'location_id', null)) {
          this.$api.get('positions/list', {params: filterPositionScope})
              .then(function (response) {
                this.filterPositionOptions = response.data
                const validValues = _.map(response.data, item => item.value)
                const currentPositionFilterValue = this.clone(_.get(this.filter, 'position_id', []))
                if (currentPositionFilterValue.length > 0) {
                  this.filter.position_id = _.intersection(validValues, currentPositionFilterValue)
                }
              }.bind(this))
              .catch(error => error)
        }
      },
    },
    filterUnitId: {
      immediate: false,
      handler(filterUnitId) {
        this.filterPositionScope = {
          location_id: this.filterLocationId,
          unit_id: filterUnitId,
        }
      },
    },
    filterUnitScope: {
      immediate: true,
      deep: true,
      handler(filterUnitScope) {
        this.filterUnitOptions = []
        if (_.get(filterUnitScope, 'location_id', null)) {
          this.$api.get('units/list', {params: this.clone(filterUnitScope)})
              .then(function (response) {
                this.filterUnitOptions = response.data
                const validValues = _.map(response.data, item => item.value)
                const currentUnitFilterValue = this.clone(_.get(this.filter, 'unit_id', []))
                if (currentUnitFilterValue.length > 0) {
                  this.filter.unit_id = _.intersection(validValues, currentUnitFilterValue)
                }
              }.bind(this))
              .catch(error => error)
        }
      },
    },
    location: {
      immediate: true,
      handler(location) {

        const filterLocationId = _.get(location, 'id', null)
        if (this.filterLocationId !== filterLocationId) {
          this.filterLocationId = filterLocationId
        } else {
          this.listHidden = true
          setTimeout(function () {
            this.listHidden = false
          }.bind(this), 300)
        }

        const openTerms = _.get(location, 'open_terms', [])

        let createButtonEnabled = false

        if (openTerms.length > 0 && _.get(location, 'positions', []).length > 0) {
          const currentTerm = this.$moment().format('YYYYMM')
          const previousTerm = this.$moment().startOf('month').subtract(1, 'months').format('YYYYMM')
          createButtonEnabled = (openTerms.indexOf(currentTerm) > -1 || openTerms.indexOf(previousTerm) > -1)
        }

        this.createButtonEnabled = createButtonEnabled
      },
    },
  },
  methods: {
    handleFilterTunnel(filter) {
      this.filter = filter
    },
    handleLocationTunnel(location) {
      this.location = location
    },
  },
}
</script>

<style scoped>

</style>