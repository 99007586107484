<template>
    <v-combobox
            v-if="items && items.length"
            :autocomplete="autocomplete"
            v-bind="attrs"
            v-on="listeners"
            :items="items"
            v-model="displayModel"
            class="number-field"
    />
    <v-text-field
            v-else
            :autocomplete="autocomplete"
            v-bind="attrs"
            v-on="listeners"
            v-model="displayModel"
            class="number-field"
    />
</template>

<script>
    import DisableChromeAutocompleteMixin from '../../../../mixins/Hacks/DisableChromeAutocompleteMixin'
    import _ from 'lodash'

    export default {
        name: "NumberField",
        inheritAttrs: false,
        mixins: [
            DisableChromeAutocompleteMixin,
        ],
        props: {
            abs: {
                type: Boolean,
                required: false,
                default: false,
            },
            autocomplete: {
                required: false,
                default: 'autocomplete',
            },
            integer: {
                type: Boolean,
                required: false,
                default: false,
            },
            items: {
                required: false,
                type: Array,
                default: () => [],
            },
            value: {
                required: false,
                default: '',
            },
        },
        data: vm => ({
            displayModel: '',
            dataModel: '',
        }),
        watch: {
            abs() {
                this.handleParamChange()
            },
            dataModel: {
                handler(value) {
                    this.handleDataModelUpdate(value)
                },
            },
            displayModel: {
                handler(value) {
                    const properValue = this.formatForDisplay(value)
                    if (value === properValue) {
                        this.handleDisplayModelUpdate(value)
                    } else if (properValue.length === 0 && !!value && value.toString().trim().length !== 0) {
                        this.$nextTick(function() {
                            this.displayModel = this.formatForDisplay(this.value)
                        })
                    } else {
                        this.$nextTick(function() {
                            this.displayModel = properValue
                        })
                    }
                },
            },
            integer() {
                this.handleParamChange()
            },
            value: {
                immediate: true,
                handler(value) {
                    this.handleValueUpdate(value)
                },
            },
        },
        methods: {

            getIntegerValue: value => parseInt(value.toString().replace(/^(-?\d+).*$/, '$1')),
            getAbsValue: value => Math.abs(parseFloat(value)),

            formatForDisplay(value) {

                const isAbs = this.abs
                const isInteger = this.integer

                try {

                    value = value.toString().trim()

                    if (value.length > 0) {

                        const sign = isAbs ? '' : (_.startsWith(value, '-') ? '-' : '')
                        value = value.replace(/^-/, '').replace(/^(\d*)([.,]\d*)?.*$/, isInteger ? '$1' : '$1$2')

                        let parsed = value.replace(',', '.')
                        let formatted

                        if (!isInteger && parsed.match(/^\d*\.$/)) {
                            formatted = parsed.replace('.', ',')
                        } else {
                            parsed = parseFloat(parsed)
                            const number = isInteger ? this.getIntegerValue(parsed) : parsed
                            formatted = ((number === parsed) ? value : (!isNaN(number) ? number : '')).toString().replace('.', ',')
                        }

                        return sign + formatted

                    }

                    return ''

                } catch (e) {
                    return ''
                }
            },
            formatForData(value) {

                const isAbs = this.abs
                const isInteger = this.integer

                try {
                    value = value.toString().trim().replace(/\./g, ',')
                    value = _.trimEnd(value.replace(/\s\t/, '').replace(/^(-?\d*)(?:[,.](\d+))?.*$/, isInteger ? '$1' : '$1.$2'), '.')
                    value = isInteger ? this.getIntegerValue(value) : parseFloat(value)

                    return isNaN(value) ? null : (isAbs ? this.getAbsValue(value) : value)
                } catch (e) {
                    return null
                }
            },

            handleDataModelUpdate(value) {
                if (this.value !== value) {
                    this.$emit('input', value)
                }
            },
            handleDisplayModelUpdate(value) {
                this.updateDataModel(value)
            },
            handleParamChange() {
                this.updateDisplayModel(this.displayModel)
            },
            handleValueUpdate(value) {
                if (this.integer || this.formatForData(this.displayModel) !== value) {
                    this.updateDisplayModel(value)
                }
            },

            updateDataModel(value) {
                const updatedValue = this.formatForData(value)
                if (this.dataModel !== updatedValue) {
                    this.dataModel = updatedValue
                }
            },
            updateDisplayModel(value) {
                this.displayModel = this.formatForDisplay(value)
            },

        },
        computed: {
            attrs() {
                return _.omit(this.$attrs, ['items', 'value'])
            },
            listeners() {
                return _.omit(this.$listeners, 'input')
            },
        },
    }
</script>

<style>
/*
    .v-text-field.number-field * {
        text-align: right;
    }
*/
</style>

