import auth from '../../auth'
import EmployeesShowResidencesIndexPage from '../../../components/Pages/employees/show/residences/index'
import EmployeesShowResidencesCreatePage from '../../../components/Pages/employees/show/residences/create'
import EmployeesShowResidencesEditPage from '../../../components/Pages/employees/show/residences/edit'
import EmployeesShowResidencesShowPage from '../../../components/Pages/employees/show/residences/show'

export default [
    {
        path: '/employees/:uuid/residences/create',
        name: 'employees.show.residences.create',
        component: EmployeesShowResidencesCreatePage,
        beforeEnter: auth.guard.user,
    },
    {
        path: '/employees/:uuid/residences/:childUuid',
        name: 'employees.show.residences.show',
        component: EmployeesShowResidencesShowPage,
        beforeEnter: auth.guard.user,
    },
    {
        path: '/employees/:uuid/residences/:childUuid/edit',
        name: 'employees.show.residences.edit',
        component: EmployeesShowResidencesEditPage,
        beforeEnter: auth.guard.user,
    },
    {
        path: '/employees/:uuid/residences',
        name: 'employees.show.residences',
        component: EmployeesShowResidencesIndexPage,
        beforeEnter: auth.guard.user,
    },

]
