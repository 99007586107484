import t from "typy";
<template>
    <v-form>
        <v-row>
            <v-col cols="12">
                <subcard>
                    <v-row dense>
                        <v-col cols="12" md="12">
                            <user-picker
                                    employee
                                    :disabled="disabled"
                                    v-model="item.user_id"
                                    :readonly="!!item.id"
                                    :rules="[rules.required]"
                                    required
                            />
                        </v-col>
                    </v-row>
                    <v-row dense>
                        <v-col cols="12" md="3">
                            <v-radio-group
                                    :disabled="disabled"
                                    hide-details
                                    row
                                    v-model="action"
                            >
                                <v-radio
                                        v-for="action of actions"
                                        :key="action"
                                        :label="l('pages.transactions.actions.' + action)"
                                        :value="action"
                                />
                            </v-radio-group>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-text-field
                                    class="mt-4"
                                    dense
                                    :disabled="disabled"
                                    hide-details
                                    :label="l('pages.transactions.properties.note')"
                                    v-model="item.note"
                            />
                        </v-col>
                        <v-col cols="12" md="3">
                            <v-row>
                                <v-col cols="12" sm="6" class="py-0">
                                    <term-select
                                            auto-select-first
                                            v-if="termIsEditable"
                                            :disabled="disabled"
                                            hide-details
                                            v-model="item.term"
                                    />
                                    <v-text-field
                                            v-else
                                            readonly
                                            hide-details
                                            :label="l('pages.transactions.properties.term')"
                                            :value="item.term ? $options.filters.term(item.term) : ''"
                                    />
                                </v-col>
                                <v-col cols="12" sm="6" class="py-0">
                                    <number-field
                                            abs
                                            class="mt-4"
                                            dense
                                            :disabled="disabled"
                                            hide-details
                                            :label="l('pages.transactions.properties.amount')"
                                            v-model="amount"
                                            :rules="[rules.required]"
                                            required
                                    />
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </subcard>
            </v-col>
        </v-row>
    </v-form>
</template>

<script>
    import t from 'typy'

    import Subcard from '../../../Shared/Cards/Subcard'
    import NumberField from '../../../Shared/Forms/TextFields/NumberField'
    import UserPicker from '../../../Shared/Forms/UserPicker'
    import TermSelect from "../../../Shared/Forms/Selects/TermSelect";

    const ACTION_DEPOSIT = 'deposit'
    const ACTION_WITHDRAW = 'withdraw'

    const multipliers = {}
    multipliers[ACTION_WITHDRAW] = -1
    multipliers[ACTION_DEPOSIT] = 1

    export default {
        name: "TransactionEditForm",
        inheritAttrs: false,
        components: {
            TermSelect,
            NumberField,
            Subcard,
            UserPicker,
        },
        props: {
            value: {
                required: false,
                type: Object,
                default: () => ({}),
            },
            disabled: {
                required: false,
                type: Boolean,
                default: false,
            }
        },
        data: vm => ({
            amount: null,
            availableTerms: [],
            item: {},
            rules: {
                required: value => undefined !== value && '' !== value || vm.l('pages.transactions.validation.required')
            },
            action: ACTION_WITHDRAW,
            actions: [
                ACTION_WITHDRAW,
                ACTION_DEPOSIT,
            ],
        }),
        computed: {
            termIsEditable() {
                return !t(this.item, 'id').safeNumber || ~this.availableTerms.indexOf(t(this.item, 'term').safeString)
            },
        },
        watch: {
            value: {
                deep: true,
                immediate: true,
                handler(value) {
                    this.item = value
                    if (undefined !== value.amount) {
                        this.action = value.amount < 0 ? ACTION_WITHDRAW : ACTION_DEPOSIT
                        this.amount = Math.abs(value.amount)
                    }
                },
            },
            amount(amount) {
                this.item.amount = Math.abs(amount) * multipliers[this.action]
            },
            'item.amount': function (amount) {
                this.amount = !!amount ? Math.abs(amount) : null
            },
            action(action) {
                if (this.amount) {
                    this.item.amount = Math.abs(this.amount) * multipliers[action]
                }
            },
            item: {
                deep: true,
                immediate: true,
                handler(value) {
                    this.$emit('input', value)
                },
            },

        },
        created() {
            this.$api.get('available/terms')
                .then(function (response) {
                    this.availableTerms = response.data
                }.bind(this))
                .catch(function (err) {
                    this.availableTerms = []
                }.bind(this))
        }
    }
</script>

