import auth from '../auth'
import AssignmentsIndexPage from '../../components/Pages/assignments/index'
import AssignmentsShowPage from '../../components/Pages/assignments/show'
import AssignmentsEditPage from '../../components/Pages/assignments/edit'
import AssignmentsCreatePage from '../../components/Pages/assignments/create'

export default [
    {
        path: '/assignments/create',
        name: 'assignments.create',
        component: AssignmentsCreatePage,
        beforeEnter: auth.guard.user,
    },
    {
        path: '/assignments/:uuid/edit',
        name: 'assignments.edit',
        component: AssignmentsEditPage,
        beforeEnter: auth.guard.user
    },
    {
        path: '/assignments/:uuid',
        name: 'assignments.show',
        component: AssignmentsShowPage,
        beforeEnter: auth.guard.user,
    },
    {
        path: '/assignments',
        name: 'assignments.index',
        component: AssignmentsIndexPage,
        beforeEnter: auth.guard.user,
    },
]