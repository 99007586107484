import {
    APP_REFRESH_CONFIG,
    APP_REQUEST_CONFIG,
    APP_LOADING,
    // APP_SET_LANG,
    APP_START_LOADING,
    APP_STOP_LOADING,
} from '../actions/app'

import {
    APP_SAVE_CONFIG,
    APP_SET_LOCALE,
    APP_SET_OPTION,
    APP_SET_ROUTE_OPTION,
    APP_SET_TARGET_URL,
    APP_SET_TITLE,
    APP_WIPE_TARGET_URL,
} from '../mutations/app'

import _ from 'lodash'
import t from 'typy'
import api from '../../internals/api'
import {version} from '../../../package.json'
import Vue from 'vue'

const defaultAppLocale = 'cs'
const defaultAppName = 'ASMIS'
const defaultAppOptions = {}
const defaultAppTitle = ''

function prepareTargetUrl(url) {
    return (false === url)
        ? url
        : (t(url).safeString.replace(/^https?:\/\/[^\/]+/, '') || '/')
}

function saveAppOptions(options) {
    if (t(options).isObject) {
        state.appOptions = options
        localStorage.setItem('options', JSON.stringify(options));
    }
}

function saveAppOption(key, value) {
    const options = _.clone(state.appOptions)
    _.set(options, key, value)
    saveAppOptions(options)
}

function saveAppRouteOption(route, key, value) {
    route = _.trim(route, '.')
    if (!!route.length) {
        saveAppOption(['route', route, key], value)
    }
}

const state = {
    appConfig: {},
    appLoading: false,
    appLocale: t(localStorage.getItem('locale')).safeString || defaultAppLocale,
    appName: defaultAppName,
    appOptions: t(JSON.parse(localStorage.getItem('options'))).safeObjectOrEmpty || defaultAppOptions,
    appTargetUrl: false,
    appTitle: defaultAppTitle,
    appVersion: version || '0'
    // appLang: {},
}

const getters = {
    appConfig: state => state.appConfig,
    appConfigValue: (state, getters) => (key) => _.get(getters.appConfig, key, null),
    appLoading: state => !!state.appLoading,
    appLocale: state => state.appLocale,
    appName: state => state.appName,
    appRouteOption: (state, getters) => (route, key) => _.get(getters.appRouteOptions, [route, key], null),
    appRouteOptions: (state, getters) => t(getters.appOption('route')).safeObjectOrEmpty,
    appOption: (state, getters) => (key) => _.get(getters.appOptions, key, null),
    appOptions: state => state.appOptions,
    appTargetUrl: state => state.appTargetUrl,
    appTitle: state => state.appTitle,
    appVersion: state => {
        return state.appVersion
    }
    /*
    appLang: state => state.appLang,
    appLangLoaded: state => !!state.appLang,
    l: (state, getters) => (key) => ((t(key).isString && key.length) ? (getters.appLangLoaded ? (t(state.appLang, key).isDefined ? t(state.appLang, key).safeString : key) : key) : ''),
    */
}

const actions = {
    /*
    [APP_SET_LANG]: ({commit}) => {
        return api.get('lang')
            .then(resp => commit(APP_SET_LANG, resp.data))
    },
    */
    [APP_REFRESH_CONFIG]: ({commit, dispatch}) => dispatch(APP_REQUEST_CONFIG)
        .then(response => commit(APP_SAVE_CONFIG, response.data))
        .catch(() => commit(APP_SAVE_CONFIG, {})),
    [APP_REQUEST_CONFIG]: () => api.get('config'),
}

const mutations = {
    [APP_SAVE_CONFIG]: (state, config) => Vue.set(state, 'appConfig', t(config).safeObjectOrEmpty),
    // [APP_SET_LANG]: (state, lang) => Vue.set(state, 'appLang', t(lang).safeObjectOrEmpty),
    [APP_SET_LOCALE]: (state, locale) => Vue.set(state, 'appLocale', t(locale).safeString),
    [APP_SET_OPTION]: (state, {key, value}) => saveAppOption(key, value),
    [APP_SET_ROUTE_OPTION]: (state, {route, key, value}) => saveAppRouteOption(route, key, value),
    [APP_SET_TARGET_URL]: (state, url) => Vue.set(state, 'appTargetUrl', prepareTargetUrl(url)),
    [APP_SET_TITLE]: (state, lang) => Vue.set(state, 'appTitle', t(lang).safeString),
    [APP_WIPE_TARGET_URL]: (state) => Vue.set(state, 'appTargetUrl', false),
}


export default {
    state,
    getters,
    actions,
    mutations,
}
