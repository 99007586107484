<template>
    <employees-show-page
            v-slot:default="{ item: employee }"
            content-class="pt-3"
    >
        <v-row>
            <v-col cols="12" md="6" xl="4" class="pb-0">
                <documents-residence-list :employee="employee" />
            </v-col>
            <v-col cols="12" md="6" xl="4" class="pb-0">
                <documents-contract-list :employee="employee" />
            </v-col>
            <!--
            <v-col cols="12" md="6" xl="4" class="pb-0">
                <documents-food-cert-list :employee="employee" />
            </v-col>
            -->
        </v-row>
    </employees-show-page>
</template>

<script>
    import Subcard from '../../../Shared/Cards/Subcard'
    import EmployeesShowPage from '../show'
    import ItemList from "../../../Item/ItemList";
    import DocumentsResidenceList from "./documents/DocumentsResidenceList";
    import DocumentsContractList from "./documents/DocumentsContractList";
    import DocumentsFoodCertList from "./documents/DocumentsFoodCertList";

    export default {
        name: 'EmployeesShowDocumentsPage',
        components: {
            DocumentsFoodCertList,
            DocumentsContractList,
            DocumentsResidenceList,
            ItemList,
            Subcard,
            EmployeesShowPage,
        },
    }
</script>

<style scoped>

</style>