<template>
    <item-show-view v-slot:default="_">
        <v-row v-if="wrapped">
            <v-col>
                <subcard>
                    <slot name="default" v-bind="_"/>
                </subcard>
            </v-col>
        </v-row>
        <div v-else>
            <slot name="default" v-bind="_"/>
        </div>
    </item-show-view>
</template>

<script>
    import ItemShowView from '../../Item/Views/ItemShowView'
    import Subcard from '../../Shared/Cards/Subcard'

    export default {
        name: 'CashOperationsShowPage',
        components: {
            ItemShowView,
            Subcard,
        },
    }
</script>

<style scoped>

</style>