<template>
  <locations-show-page
      parent
      content-color="white"
      v-slot:default="{ item: location, itemDisabled }"
  >
    <v-row>
      <v-col cols="12" md="12" class="pb-0">
        <sub-item-list create-button :headers="headers">
          <template v-slot:filter="_">
            <v-row dense>
              <v-col cols="12" sm="7">
                <trim-text-field
                    class="py-2 mt-3"
                    dense
                    hide-details
                    v-model="_.filter.text"
                    :label="l('pages.positions.index.filters.text')"
                />
              </v-col>
              <v-col cols="12" sm="5">
                <api-select
                    class="py-2 mt-3"
                    api="units/list"
                    dense
                    hide-details
                    chips
                    deletable-chips
                    multiple
                    v-model="_.filter.unit_id"
                    :label="l('pages.positions.index.filters.unit_id')"
                />
              </v-col>
            </v-row>
          </template>

          <template v-slot:item.rates="_">
                        <span
                            v-for="(rate, index) in _.value"
                            :key="index"
                        >{{ index !== 0 ? ', ' : '' }}{{ rate | euroNumber }}</span>
          </template>

        </sub-item-list>
      </v-col>
    </v-row>
  </locations-show-page>
</template>

<script>
import LocationsShowPage from '../../show'
import SubItemList from '../../../../Item/SubItemList'
import TrimTextField from '../../../../Shared/Forms/TextFields/TrimTextField'
import ApiSelect from '../../../../Shared/Forms/ApiSelect'

export default {
  name: 'LocationsShowPositionsIndexPage',
  components: {
    ApiSelect,
    LocationsShowPage,
    SubItemList,
    TrimTextField,
  },
  data: vm => ({
    headers: [
      {
        value: 'id',
        align: 'end',
        sortable: true,
        filterable: false,
      },
      {
        value: 'name',
        align: 'start',
        sortable: true,
        filterable: false,
      },
      {
        value: 'unit',
        align: 'start',
        sortable: false,
        filterable: false,
      },
      {
        value: 'rates',
        align: 'start',
        sortable: false,
        filterable: false,
      },
      {
        value: 'assignment_count',
        align: 'end',
        sortable: true,
        filterable: false,
      },
      {
        value: 'shift_count',
        align: 'end',
        sortable: true,
        filterable: false,
      },
      {
        value: 'hours',
        align: 'end',
        sortable: true,
        filterable: false,
      },
      {
        value: 'rate',
        align: 'end',
        sortable: true,
        filterable: false,
      },
      {
        value: 'amount',
        align: 'end',
        sortable: true,
        filterable: false,
      },
    ],
  }),
}
</script>

<style scoped>

</style>