<template>
    <v-form>
        <subcard>
            <v-row>
                <v-col cols="12" sm="8" md="9">
                    <trim-text-field
                            :disabled="disabled"
                            hide-details
                            :label="l('pages.fund_locks.properties.note')"
                            v-model="item.note"
                            :rules="[rules.required]"
                            required
                    />
                </v-col>
                <v-col cols="12" sm="4" md="3">
                    <number-field
                            abs
                            :disabled="disabled"
                            hide-details
                            :label="l('pages.fund_locks.properties.amount')"
                            v-model="amount"
                            :rules="[rules.required]"
                            required
                    />
                </v-col>
            </v-row>
        </subcard>
    </v-form>
</template>

<script>
    import t from 'typy'
    import Subcard from '../../../../../Shared/Cards/Subcard'
    import NumberField from "../../../../../Shared/Forms/TextFields/NumberField";
    import TrimTextField from "../../../../../Shared/Forms/TextFields/TrimTextField";

    export default {
        name: "EmployeeFundLockEditForm",
        inheritAttrs: false,
        components: {
            TrimTextField,
            NumberField,
            Subcard,
        },
        props: {
            value: {
                required: false,
                type: Object,
                default: () => {},
            },
            disabled: {
                required: false,
                type: Boolean,
                default: false,
            }
        },
        data: vm => ({
            amount: null,
            item: {},
            rules: {
                required: value => undefined !== value && '' !== value || vm.l('pages.fund_locks.validation.required')
            },
        }),
        watch: {
            value: {
                deep: true,
                immediate: true,
                handler(value) {
                    this.item = value
                    this.amount = (undefined === value.amount || !value.amount) ? null : Math.abs(t(value, 'amount').safeNumber)
                },
            },
            item: {
                deep: true,
                handler(item) {
                    this.$emit('input', item)
                },
            },
            amount(amount) {
                this.item.amount = !amount ? null : -Math.abs(t(amount).safeNumber)
            },
        },
    }
</script>

