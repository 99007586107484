<template>
    <div>
        {{ appTitle }}
    </div>
</template>

<script>

    import PageMixin from '../../../mixins/Cumulative/PageMixin'

    export default {
        name: "Error404Page",
        mixins: [
            PageMixin,
        ],
        data: () => ({
            customAppTitle: 'global.errors.not_found',
        }),
    }
</script>

<style scoped>

</style>