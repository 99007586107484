<template>
    <management-page>

        <template slot="title">
            <v-flex>
                <div class="d-flex">
                    <v-flex class="d-none d-sm-inline-flex">
                        <span>{{ appTitle }}</span>
                    </v-flex>
                    <v-flex class="text-right">
                    </v-flex>
                </div>
            </v-flex>
        </template>

        <div>
            <v-row>
                <v-col>
                    {{ l('pages.management.cashboxes.title') }}
                </v-col>
            </v-row>
        </div>

    </management-page>
</template>
<script>

    import PageMixin from '../../../mixins/Cumulative/PageMixin'
    import ManagementPage from './Shared/ManagementPage'

    export default {
        name: "ManagementCashboxesPage",
        components: {
            ManagementPage,
        },
        mixins: [
            PageMixin,
        ],
        data: vm => ({
            customAppTitle: 'pages.management.cashboxes.title',
        }),
    }
</script>
<style scoped>
</style>