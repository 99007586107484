/* eslint-disable promise/param-names */
import {
    USER_REQUEST_TOKEN,
    USER_REQUEST_LOGIN,
    USER_REQUEST_RESTORE,
    USER_LOGGED_IN,
    USER_REQUEST_PROFILE,
    USER_REQUEST_LOGOUT,
    USER_LOGGED_OUT,
} from '../actions/user'
import {
    USER_SAVE_TOKEN,
    USER_SAVE_PROFILE,
    USER_SET_OPTION,
    USER_SET_ROUTE_OPTION,
    USER_WIPE
} from '../mutations/user'
import _ from 'lodash'
import t from 'typy'
import api from '../../internals/api'
import Vue from 'vue'
import router from '../../router'

const state = {
    userToken: localStorage.getItem('user-token') || '',
    userAuthenticated: false,
    userProfile: {},
    userProfileLoaded: false,
}

const getters = {
    userAuthenticated: state => !!state.userAuthenticated,
    userFlags: state => t(state.userProfile, 'flags').safeArray,
    userHasFlag: (state, getters) => (flag) => (getters.userFlags.indexOf(flag) !== -1),
    userHasAnyFlag: (state, getters) => (flags) => _.filter(t(flags).safeArray, flag => (getters.userFlags.indexOf(flag) !== -1)).length > 0,
    userHasAllFlags: (state, getters) => (flags) => _.filter(t(flags).safeArray, flag => (getters.userFlags.indexOf(flag) !== -1)).length === flags.length,
    userIsGodLike: (state, getters) => getters.userHasFlag('is_company_manager') || getters.userHasFlag('is_admin'),
    userProfile: state => state.userProfile,
    userProfileValue: state => (key) => _.get(state.userProfile, key, null),
    userId: (state, getters) => getters.userProfileValue('id'),
    userProfileLoaded: state => !!state.userProfileLoaded,
    userToken: state => state.userToken,
    userTokenStored: state => !!state.userToken,
    userViewsLocations: (state, getters) => getters.userWorksWithLocations || getters.userHasAnyFlag(['is_accountant', 'is_hr']),
    userWorksWithCashboxes: (state, getters) => getters.userIsGodLike || getters.userHasAnyFlag(['is_cashier', 'is_chief_cashier']),
    userWorksWithLocations: (state, getters) => getters.userIsGodLike || getters.userHasFlag('is_location_manager'),
}

const actions = {
    [USER_REQUEST_TOKEN]: ({dispatch}, data) => {

        data = t(data).isString ? {token: data} : t(data).safeObjectOrEmpty
        const uri = 'token' + (t(data, 'token').isDefined ? '/check' : '')

        return new Promise((resolve, reject) => {
            api.post(uri, data)
                .then(resp => {
                    dispatch(USER_LOGGED_IN, resp.data.token)
                        .then(() => resolve())
                        .catch(err => {
                            dispatch(USER_REQUEST_LOGOUT, {offline: true})
                                .finally(() => reject(err))
                        })
                })
                .catch(err => {
                    dispatch(USER_REQUEST_LOGOUT, {offline: true})
                        .finally(() => reject(err))
                })
        })
    },
    [USER_REQUEST_LOGIN]: ({dispatch}, user) => {
        return dispatch(USER_REQUEST_TOKEN, user)
    },
    [USER_REQUEST_RESTORE]: ({state, dispatch}) => {
        return dispatch(USER_REQUEST_TOKEN, state.userToken)
    },
    [USER_LOGGED_IN]: ({commit, dispatch}, token) => {
        return new Promise((resolve, reject) => {
            localStorage.setItem('user-token', token)
            commit(USER_SAVE_TOKEN, token)
            dispatch(USER_REQUEST_PROFILE)
                .then(function (resp) {
                    commit(USER_SAVE_PROFILE, resp.data)
                    resolve()
                })
                .catch(err => reject(err))
        })
    },
    [USER_REQUEST_PROFILE]: () => api.get('my/profile'),
    [USER_REQUEST_LOGOUT]: ({commit, dispatch}, params = {}) => {

        const all = t(params, 'all').isTruthy;
        const offline = t(params, 'offline').isTruthy;

        return new Promise((resolve, reject) => {
            if (offline) {
                dispatch(USER_LOGGED_OUT).finally(() => resolve())
            } else {
                api.delete('token' + (all ? '/all' : ''))
                    .then(() => dispatch(USER_LOGGED_OUT).finally(() => resolve()))
                    .catch(err => reject(err))
            }
        })
    },
    [USER_LOGGED_OUT]: ({commit}) => {
        return new Promise((resolve) => {
            localStorage.removeItem('user-token')
            commit(USER_WIPE)
            if (router.currentRoute.name !== 'login') {
                router.push({name: 'login'}).finally(() => resolve())
            }
        })
    },
}

const mutations = {
    [USER_SAVE_PROFILE]: (state, profile) => {
        Vue.set(state, 'userProfile', t(profile).safeObjectOrEmpty)
        Vue.set(state, 'userProfileLoaded', true)
    },
    [USER_SAVE_TOKEN]: (state, token) => {
        Vue.set(state, 'userToken', t(token).safeString)
        Vue.set(state, 'userAuthenticated', true)
    },
    [USER_WIPE]: state => {
        Vue.set(state, 'userProfile', {})
        Vue.set(state, 'userProfileLoaded', false)
        Vue.set(state, 'userToken', '')
        Vue.set(state, 'userAuthenticated', false)
    },
}

export default {
    state,
    getters,
    actions,
    mutations,
}