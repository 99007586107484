import Vue from 'vue'
import _ from 'lodash'
import t from 'typy'
import i18n from './i18n'

Vue.filter('amount', value => t(parseFloat(value)).safeNumber.toFixed(2).replace('.', ','))
Vue.filter('capitalize', value => (t(value).safeString.charAt(0).toUpperCase() + value.slice(1).toLowerCase()))
Vue.filter('emails', value => t(value).safeArray.map(({ address }) => address).join(', '))
Vue.filter('euroDate', value => t(value).safeString.substr(0, 10).replace(/^(\d{4})-(\d{2})-(\d{2})$/, '$3.$2.$1'))
Vue.filter('euroDateShortTime', value => t(value).safeString.replace(/^(\d{4})-(\d{2})-(\d{2})[\sT](\d{2}:\d{2})(?::\d{2}(?:\.[^.:]+)?)$/, '$3.$2.$1, $4'))
Vue.filter('euroDateTime', value => t(value).safeString.replace(/^(\d{4})-(\d{2})-(\d{2})[\sT](\d{2}:\d{2}:\d{2})(?:\.[^.:]+)?$/, '$3.$2.$1, $4'))
Vue.filter('euroNumber', value => t(parseFloat(value)).safeNumber.toString().replace('.', ','))
Vue.filter('gender', value => i18n.t('global.gender.' + value))
Vue.filter('lastName', value => t(value).isObject ? (t(value, 'last_name').safeString || t(value, 'name').safeString) : t(value).safeString)
Vue.filter('listName', value => t(value).isObject ? (t(value, 'list_name').safeString || t(value, 'name').safeString) : t(value).safeString)
Vue.filter('name', value => t(value).isObject ? t(value, 'name').safeString : t(value).safeString)
Vue.filter('names', values => t(values).isArray ? _.map(values, value => t(value, 'name').safeString).join(', ') : JSON.stringify(values))
Vue.filter('phoneNumber', value => _.startsWith(value.number, '420') ? value.number.slice(3).match(/.{3}/g).join(' ') : ('+' + value.number))
Vue.filter('phones', value => t(value).safeArray.map(({ number }) => _.startsWith(number, '420') ? number.slice(3).match(/.{3}/g).join(' ') : ('+' + number)).join(', '))
Vue.filter('shortTime', value => t(value).safeString.replace(/^(?:\d{4}-\d{2}-\d{2}[\sT])?(\d{2}:\d{2})(?::\d{2}(?:\.[^.:]+)?)$/, '$1'))
Vue.filter('term', value => _.map(_.pick(t(t(value).safeString.match(/^(?<year>\d{4})(?<month>(?:0[1-9]|1[0-2]))/), 'groups').safeObjectOrEmpty, ['month', 'year']), (value, key) => key === 'month' ? i18n.t('global.months.' + value) : value).join(' ') || i18n.t('global.errors.invalid_value'))
Vue.filter('time', value => t(value).safeString.replace(/^(\d{6})?.*$/, '$1'))
Vue.filter('yesno', value => i18n.t('global.' + (!!value ? 'yes' : 'no')))

