import auth from '../../auth'
import EmployeesIndexPage from '../../../components/Pages/employees/index'
import EmployeesEditPage from '../../../components/Pages/employees/edit'
import EmployeesCreatePage from '../../../components/Pages/employees/create'

export default [
    {
        path: '/employees/create',
        name: 'employees.create',
        component: EmployeesCreatePage,
        beforeEnter: auth.guard.user,
    },
    {
        path: '/employees/:uuid/edit',
        name: 'employees.edit',
        component: EmployeesEditPage,
        beforeEnter: auth.guard.user
    },
    {
        path: '/employees/:uuid',
        name: 'employees.show',
        redirect: 'employees/:uuid/details',
        beforeEnter: auth.guard.user,
    },
    {
        path: '/employees',
        name: 'employees.index',
        component: EmployeesIndexPage,
        beforeEnter: auth.guard.user,
    },
]