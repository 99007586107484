import t from 'typy'

const DateTimeMixin = {
    methods: {
        isDateString(date) {
            date = t(date).safeString
            return !!date.match(/^\d{4}-\d{2}-\d{2}/) && this.$moment(date, 'YYYY-MM-DD').isValid()
        },
        isFormattedDateString(date) {
            date = t(date).safeString
            return !!date.match(/^\d{1,2}[.\/]\d{1,2}[.\/]\d{4}/) && this.$moment(date.replace('/', '.'), 'DD.MM.YYYY').isValid()
        },
        formatDate: date => t(date).safeString.replace(/^(\d{4})-(\d{2})-(\d{2}).*?$/, '$3.$2.$1'),
        formatDateTime: date => t(date).safeString.replace(/^(\d{4})-(\d{2})-(\d{2})[T ](\d{2}:\d{2}:\d{2}).*$/, '$3.$2.$1, $4'),
        convertFormattedDateToNormal: date => (t(date).safeString.match(/^(\d{1,2})[.\/](\d{1,2})[.\/](\d{4}),?( \d{2}:\d{2}:\d{2})?$/) || [])
            .filter((value, index) => (index > 0 && undefined !== value))
            .map(value => (value.length === 1 ? ('0' + value) : value))
            .reverse()
            .join('-') || date,
        parseDate(date) {
            return this.isFormattedDateString(date) ? this.convertFormattedDateToNormal(date) : date
        },
        parseDateTime: date => (t(date).safeString.match(/^(\d{1,2})[.\/](\d{1,2})[.\/](\d{4}),?(?:( \d{2}:\d{2}:\d{2}).*)?$/) || [])
            .filter((value, index) => (index > 0 && undefined !== value))
            .map(value => (value.length === 1 ? ('0' + value) : value))
            .join('-')
            .replace(/^(\d{2})-(\d{2})-(\d{4})(?:- (.*))?$/, '$3-$2-$1 $4')
            .trim() || date,
    }
}

export default DateTimeMixin
