<template>
    <item-create-view v-slot:default="_">
        <employee-edit-form :disabled="_.itemDisabled" v-model="_.item" />
    </item-create-view>
</template>

<script>
    import ItemCreateView from '../../Item/Views/ItemCreateView'
    import EmployeeEditForm from './Shared/EmployeeEditForm'

    export default {
        name: 'EmployeesCreatePage',
        components: {
            ItemCreateView,
            EmployeeEditForm,
        },
    }
</script>
