import auth from '../../auth'
import EmployeesShowFundLocksIndexPage from '../../../components/Pages/employees/show/fund_locks/index'
import EmployeesShowFundLocksCreatePage from '../../../components/Pages/employees/show/fund_locks/create'
import EmployeesShowFundLocksEditPage from '../../../components/Pages/employees/show/fund_locks/edit'
import EmployeesShowFundLocksShowPage from '../../../components/Pages/employees/show/fund_locks/show'

export default [
    {
        path: '/employees/:uuid/fund-locks/create',
        name: 'employees.show.fund_locks.create',
        component: EmployeesShowFundLocksCreatePage,
        beforeEnter: auth.guard.user,
    },
    {
        path: '/employees/:uuid/fund-locks/:childUuid',
        name: 'employees.show.fund_locks.show',
        component: EmployeesShowFundLocksShowPage,
        beforeEnter: auth.guard.user,
    },
    {
        path: '/employees/:uuid/fund-locks/:childUuid/edit',
        name: 'employees.show.fund_locks.edit',
        component: EmployeesShowFundLocksEditPage,
        beforeEnter: auth.guard.user,
    },
    {
        path: '/employees/:uuid/fund-locks',
        name: 'employees.show.fund_locks',
        component: EmployeesShowFundLocksIndexPage,
        beforeEnter: auth.guard.user,
    },

]
