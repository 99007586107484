<template>
    <item-show-view v-slot:default="{ item: transaction }">
        <v-row>
            <v-col>
                <subcard>
                    <v-simple-table>
                        <tbody>
                        <tr>
                            <th>{{ l('pages.transactions.properties.user') }}</th>
                            <td>{{ transaction.user | name }}</td>
                        </tr>
                        <tr>
                            <th>{{ l('pages.transactions.properties.note') }}</th>
                            <td>{{ transaction.note }}</td>
                        </tr>
                        <tr v-if="transaction.type">
                            <th>{{ l('pages.transactions.properties.type') }}</th>
                            <td>
                                <transaction-type :item="transaction"/>
                            </td>
                        </tr>
                        <tr>
                            <th>{{ l('pages.transactions.properties.amount') }}</th>
                            <td>
                                <amount currency>{{ transaction.amount }}</amount>
                            </td>
                        </tr>
                        <tr>
                            <th>
                                {{ l('pages.transactions.properties.created_at') }}
                            </th>
                            <td>
                                {{ transaction.created_at | euroDateShortTime }}, {{ lodash.get(transaction, 'created_by.list_name', '') }}
                            </td>
                        </tr>
                        <tr v-if="transaction.created_at !== transaction.updated_at">
                            <th>
                                {{ l('pages.transactions.properties.updated_at') }}
                            </th>
                            <td>
                                {{ transaction.updated_at | euroDateShortTime }}, {{ lodash.get(transaction, 'updated_by.list_name', '') }}
                            </td>
                        </tr>
                        </tbody>
                    </v-simple-table>
                </subcard>
            </v-col>
        </v-row>
    </item-show-view>
</template>

<script>
    import ItemShowView from '../../Item/Views/ItemShowView'
    import Amount from "../../Shared/Text/Amount";
    import TransactionType from "../../Shared/ModelSpecific/Transaction/TransactionType";
    import Subcard from "../../Shared/Cards/Subcard";
    import _ from 'lodash'

    export default {
        name: 'TransactionsShowPage',
        components: {
            Subcard,
            TransactionType,
            Amount,
            ItemShowView,
        },
        data: vm => ({
            lodash: _,
        }),
    }
</script>

<style scoped>

</style>