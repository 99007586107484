<template>
    <item-edit-view v-slot:default="_">
        <shift-edit-form
                :disabled="_.itemDisabled"
                v-model="_.item"
        />
    </item-edit-view>
</template>

<script>
    import ItemEditView from '../../Item/Views/ItemEditView'
    import ShiftEditForm from './Shared/ShiftEditForm'

    export default {
        name: 'ShiftsEditPage',
        components: {
            ItemEditView,
            ShiftEditForm,
        },
    }
</script>

<style scoped>

</style>