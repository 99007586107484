<template>
    <item-list-view
            create-button
            excel-button
            :headers="headers"
    >

        <template v-slot:item.user="_">
            <router-link
                    :to="{name: 'employees.show', params: {uuid: _.value.uuid}}">
                {{ _.value | listName }}
            </router-link>
        </template>

        <template v-slot:filter="_">
            <v-row dense>
                <v-col cols="12" md="8" class="py-0">
                    <v-row dense>
                        <v-col cols="12" sm="5">
                            <trim-text-field
                                    class="mt-3"
                                    dense
                                    hide-details
                                    v-model="_.filter.text"
                                    :label="l('pages.shifts.index.filters.employee')"
                            />
                        </v-col>
                        <v-col cols="12" sm="5">
                            <v-select
                                    dense
                                    hide-details
                                    chips
                                    deletable-chips
                                    multiple
                                    v-model="_.filter.location_id"
                                    :items="locationList"
                                    :label="l('pages.shifts.index.filters.location_id')"
                            />
                        </v-col>
                        <v-col cols="12" sm="2">
                            <v-select
                                    dense
                                    hide-details
                                    chips
                                    deletable-chips
                                    multiple
                                    v-model="_.filter.unit_id"
                                    :items="unitList"
                                    :label="l('pages.shifts.index.filters.unit_id')"
                            />
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="12" md="4" class="py-0">
                    <v-row dense>
                        <v-col cols="12" sm="6">
                            <date-field
                                    clearable
                                    text-field-class="mt-3"
                                    dense
                                    hide-details
                                    v-model="_.filter.from_date"
                                    :label="l('pages.shifts.index.filters.from_date')"
                            />
                        </v-col>
                        <v-col cols="12" sm="6">
                            <date-field
                                    clearable
                                    text-field-class="mt-3"
                                    dense
                                    hide-details
                                    v-model="_.filter.to_date"
                                    :label="l('pages.shifts.index.filters.to_date')"
                            />
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </template>

    </item-list-view>
</template>

<script>
    import TablePageMixin from '../../../mixins/Cumulative/TablePageMixin'
    import TrimTextField from '../../Shared/Forms/TextFields/TrimTextField'
    import DateField from '../../Shared/Forms/TextFields/DateField'

    export default {
        name: "ShiftsIndexPage",
        components: {
            DateField,
            TrimTextField,
        },
        mixins: [
            TablePageMixin,
        ],
        data: vm => ({
            locationList: [],
            unitList: [],
            headers: [
                {
                    value: 'id',
                    align: 'end',
                    sortable: true,
                    filterable: false,
                },
                {
                    value: 'user',
                    align: 'start',
                    sortable: false,
                    filterable: false,
                },
                {
                    value: 'location',
                    align: 'start',
                    sortable: false,
                    filterable: false,
                },
                {
                    value: 'position',
                    align: 'start',
                    sortable: false,
                    filterable: false,
                },
                {
                    value: 'date',
                    align: 'end',
                    sortable: true,
                    filterable: false,
                },
                {
                    value: 'time',
                    align: 'end',
                    sortable: true,
                    filterable: false,
                },
                {
                    value: 'hours',
                    align: 'end',
                    sortable: true,
                    filterable: false,
                },
                {
                    value: 'rate',
                    align: 'end',
                    sortable: true,
                    filterable: false,
                },
                {
                    value: 'is_payable',
                    align: 'center',
                    sortable: true,
                    filterable: false,
                },
                {
                    value: 'amount',
                    align: 'end',
                    sortable: true,
                    filterable: false,
                },
            ],
            customAppTitle: 'pages.shifts.index.title',
        }),
        created() {

            this.$api.get('locations/list')
                .then(function(response) {
                    this.locationList = response.data
                }.bind(this))
                .catch(error => error)

            this.$api.get('units/list')
                .then(function(response) {
                    this.unitList = response.data
                }.bind(this))
                .catch(error => error)

        },
    }
</script>

