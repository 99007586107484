import _ from 'lodash'

const LangMixin = {
    methods: {
        l(key, params = {}, values = {}) {
            switch (true) {

                // Try key as is
                case (this.$te(key)):
                    return !!params && !isNaN(params) ? this.$tc(key, params, values) : this.$t(key, params)

                // Try fallback key if it is page-related
                case (_.startsWith(key, 'pages.') && !_.startsWith(key, 'pages.all.')):
                    return this.l(key.replace(/^pages\.[^.]+/, 'pages.all'), params, values)

                // Give up
                default:
                    return key
            }
        },
    }
}

export default LangMixin
