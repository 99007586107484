import store from "../store";
import {APP_SET_OPTION, APP_SET_ROUTE_OPTION} from '../store/mutations/app'

const AppOptionsMixin = {
    methods: {
        getAppOption: (key) => store.getters.appOption(key),
        getAppRouteOption(key) {
            const route = this.$route.name
            return store.getters.appRouteOption(route, key)
        },
        setAppOption: (key, value) => store.commit(APP_SET_OPTION, {key: key, value: value}),
        setAppRouteOption(key, value) {
            store.commit(APP_SET_ROUTE_OPTION, {route: this.$route.name, key: key, value: value})
        },
    },
    computed: {
        appOptions: () => store.getters.appOptions,
    },
}

export default AppOptionsMixin
