<template>
    <v-chip
            :color="backgroundColor"
            :text-color="textColor"
            :small="!dense"
            :x-small="dense"
            class="text-uppercase font-weight-bold"
            :to="route"
    >
        {{ l('pages.transactions.values.type.' + type) }}
    </v-chip>
</template>

<script>
    import t from 'typy'
    import _ from 'lodash'

    _.mixin(require('lodash-inflection'))

    const defaultType = 'regular'

    const typeClasses = {
        bonus: {
            background: 'teal lighten-5',
            text: 'teal darken-4',
        },
        cash_operation: {
            background: 'light-green darken-2',
            text: 'white',
        },
        envelope: {
            background: 'deep-purple lighten-4',
            text: 'deep-purple darken-4',
        },
        fund_lock: {
            background: 'grey darken-1',
            text: 'white',
        },
        penalty: {
            background: 'deep-orange lighten-5',
            text: 'deep-orange darken-4',
        },
        regular: {
            background: 'transparent',
            text: 'blue-grey darken-4',
        },
        shift: {
            background: 'light-green lighten-5',
            text: 'light-green darken-4',
        },
    }

    export default {
        name: "TransactionType",
        props: {
            dense: {
                default: false,
                required: false,
                type: Boolean,
            },
            item: {
                default: () => ({}),
                required: true,
                type: Object,
            },
        },
        data: vm => ({
            type: defaultType,
            backgroundColor: typeClasses[defaultType].background,
            route: null,
            textColor: typeClasses[defaultType].text
        }),
        watch: {
            item: {
                deep: true,
                handler() {
                    this.type = this.getType()
                },
                immediate: true,
            },
            type: {
                handler() {
                    this.backgroundColor = this.getBackgroundColor()
                    this.textColor = this.getTextColor()
                    this.route = this.getRoute()
                },
                immediate: true,
            },
        },
        methods: {
            convertTypeToKey(type) {
                return ~['bonus', 'penalty'].indexOf(type) ? 'adjustment' : type
            },
            getBackgroundColor() {
                return typeClasses[this.type] ? t(typeClasses[this.type], 'background').safeString : typeClasses[defaultType].background
            },
            getContextOptions() {
                return _.keys(this.item)
                    .filter(key => ((key !== 'user_id') && _.endsWith(key, '_id') && !(_.endsWith(key, '_by_id') || (key.indexOf('transaction') !== -1))))
                    .map(key => key.slice(0, -3))
            },
            getRoute() {
                const context = this.convertTypeToKey(this.type)
                return (context === defaultType)
                    ? null
                    : {
                        name: _(context).pluralize() + '.show',
                        params: {
                            uuid: this.item[context].uuid
                        },
                    }
            },
            getTextColor() {
                return typeClasses[this.type] ? t(typeClasses[this.type], 'text').safeString : typeClasses[defaultType].text
            },
            getType() {
                const options = this.getContextOptions()
                for (let index = 0; index < options.length; index++) {
                    const key = options[index]
                    const value = t(this.item, key).safeObjectOrEmpty
                    if (!!value && t(value, 'uuid').isDefined) {
                        return (key === 'adjustment') ? (_.get(value, 'amount', 0) < 0 ? 'penalty' : 'bonus') : key
                    }
                }
                return defaultType
            },
        },
    }
</script>

<style scoped>

</style>