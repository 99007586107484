<template>
    <employees-show-page
            parent
            content-color="white"
            v-slot:default="{ item: employee, itemDisabled }"
    >
        <v-row>
            <v-col cols="12" md="12" class="pb-0">
                <sub-item-list create-button :headers="headers">

                    <template v-slot:item.type="_">
                        {{ _.value | listName }}
                    </template>

                    <template v-slot:filter="_">
                        <v-row dense>
                            <v-col cols="12" md="6">
                                <v-select
                                        dense
                                        hide-details
                                        chips
                                        deletable-chips
                                        multiple
                                        v-model="_.filter.type_id"
                                        :items="typeList"
                                        :label="l('pages.residences.index.filters.type_id')"
                                />
                            </v-col>
                            <v-col cols="12" md="3">
                                <date-field
                                        clearable
                                        text-field-class="mt-3"
                                        dense
                                        hide-details
                                        v-model="_.filter.from_date"
                                        :label="l('pages.residences.index.filters.from_date')"
                                />
                            </v-col>
                            <v-col cols="12" md="3">
                                <date-field
                                        clearable
                                        text-field-class="mt-3"
                                        dense
                                        hide-details
                                        v-model="_.filter.to_date"
                                        :label="l('pages.residences.index.filters.to_date')"
                                />
                            </v-col>
                        </v-row>
                    </template>

                </sub-item-list>
            </v-col>
        </v-row>
    </employees-show-page>
</template>

<script>
    import CheckMark from '../../../../Shared/Icons/CheckMark'
    import Subcard from '../../../../Shared/Cards/Subcard'
    import EmployeesShowPage from '../../show'
    import SubItemList from '../../../../Item/SubItemList'
    import DateField from '../../../../Shared/Forms/TextFields/DateField'
    import TrimTextField from '../../../../Shared/Forms/TextFields/TrimTextField'

    export default {
        name: 'EmployeesShowResidencesIndexPage',
        components: {
            CheckMark,
            DateField,
            EmployeesShowPage,
            SubItemList,
            Subcard,
            TrimTextField,
        },
        data: vm => ({
            locationList: [],
            typeList: [],
            unitList: [],
            headers: [
                {
                    value: 'id',
                    align: 'end',
                    sortable: true,
                    filterable: false,
                },
                {
                    value: 'type',
                    align: 'start',
                    sortable: false,
                    filterable: false,
                },
                {
                    value: 'from_date',
                    align: 'end',
                    sortable: true,
                    filterable: false,
                },
                {
                    value: 'to_date',
                    align: 'end',
                    sortable: true,
                    filterable: false,
                },
                {
                    value: 'note',
                    align: 'start',
                    sortable: false,
                    filterable: false,
                },
                {
                    value: 'created_at',
                    align: 'end',
                    sortable: true,
                    filterable: false,
                },
                {
                    value: 'updated_at',
                    align: 'end',
                    sortable: true,
                    filterable: false,
                },

            ],
        }),
        created() {

            this.$api.get('residence-types/list')
                .then(function (response) {
                    this.typeList = response.data
                }.bind(this))
                .catch(error => error)

        },
    }
</script>

<style scoped>

</style>