import auth from '../../auth'
import EmployeesShowTransactionsIndexPage from '../../../components/Pages/employees/show/transactions/index'
import EmployeesShowTransactionsCreatePage from '../../../components/Pages/employees/show/transactions/create'
import EmployeesShowTransactionsEditPage from '../../../components/Pages/employees/show/transactions/edit'
import EmployeesShowTransactionsShowPage from '../../../components/Pages/employees/show/transactions/show'

export default [
    {
        path: '/employees/:uuid/transactions/create',
        name: 'employees.show.transactions.create',
        component: EmployeesShowTransactionsCreatePage,
        beforeEnter: auth.guard.user,
    },
    {
        path: '/employees/:uuid/transactions/:childUuid',
        name: 'employees.show.transactions.show',
        component: EmployeesShowTransactionsShowPage,
        beforeEnter: auth.guard.user,
    },
    {
        path: '/employees/:uuid/transactions/:childUuid/edit',
        name: 'employees.show.transactions.edit',
        component: EmployeesShowTransactionsEditPage,
        beforeEnter: auth.guard.user,
    },
    {
        path: '/employees/:uuid/transactions',
        name: 'employees.show.transactions',
        component: EmployeesShowTransactionsIndexPage,
        beforeEnter: auth.guard.user,
    },

]
