<template>
  <v-row>
    <v-col cols="12" md="6" class="py-0 py-sm-1">
      <my-dashboard-payments-card v-if="showPaymentsCard"/>
      <my-dashboard-money-card v-if="showMoneyCard"/>
    </v-col>
    <v-col cols="12" md="6" class="py-0 py-sm-1">
      <my-dashboard-balances-card v-if="showBalancesCard"/>
    </v-col>
  </v-row>
</template>
<script>
import PageMixin from '../../../mixins/Cumulative/PageMixin'
import MyDashboardBalancesCard from './dashboard/cards/balances'
import MyDashboardMoneyCard from './dashboard/cards/money'
import MyDashboardPaymentsCard from './dashboard/cards/payments'

export default {
  name: "MyDashboardPage",
  components: {
    MyDashboardBalancesCard,
    MyDashboardMoneyCard,
    MyDashboardPaymentsCard,
  },
  mixins: [
    PageMixin,
  ],
  data: () => ({
    customAppTitle: 'pages.my.dashboard.title',
    showBalancesCard: false,
    showMoneyCard: false,
    showPaymentsCard: false,
  }),
  created() {
    const godOrChiefCashier = this.userIsGodLike || this.userHasFlag('is_chief_cashier')
    this.showBalancesCard = godOrChiefCashier
    this.showMoneyCard = godOrChiefCashier
    this.showPaymentsCard = godOrChiefCashier
  },
}
</script>
