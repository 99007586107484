<template>
  <employees-show-page
      v-slot:default="{ item: employee, itemDisabled }"
      content-class="pt-3"
  >
    <v-row class="pt-2">
      <v-col
          cols="12"
          lg="4"
          xl="3"
          class="pb-md-0 pb-lg-3"
      >
        <v-row>
          <v-col
              cols="12"
              md="6"
              lg="12"
              class="pb-0"
          >
            <subcard>
              <v-simple-table dense>
                <tbody>
                <tr>
                  <th>
                    {{ l('pages.employees.properties.birth_date') }}
                  </th>
                  <td class="font-weight-bold">
                    {{ employee.birth_date | euroDate }}
                  </td>
                </tr>
                <tr>
                  <th>
                    {{ l('pages.employees.properties.gender') }}
                  </th>
                  <td class="font-weight-bold">
                    {{ employee.gender | gender }}
                  </td>
                </tr>
                <tr>
                  <th>
                    {{ l('pages.employees.properties.country') }}
                  </th>
                  <td class="font-weight-bold">
                    {{ employee.country | name }}
                  </td>
                </tr>
                <tr v-if="employee.phones && employee.phones.length">
                  <th>
                    {{ l('pages.employees.properties.phones') }}
                  </th>
                  <td class="font-weight-bold"><span
                      v-for="(phone, index) in employee.phones"
                      :key="index"
                      class="text-no-wrap"
                  >{{ index === 0 ? '' : ', ' }}{{ phone | phoneNumber }}</span></td>
                </tr>
                <tr v-if="employee.emails && employee.emails.length">
                  <th>
                    {{ l('pages.employees.properties.emails') }}
                  </th>
                  <td class="font-weight-bold"><span
                      v-for="(email, index) in employee.emails"
                      :key="index"
                      class="text-no-wrap"
                  >{{ index === 0 ? '' : ', ' }}<a :href="'mailto:' + email.address">{{ email.address }}</a></span></td>
                </tr>
                <tr
                    v-if="(!employee.emails || !employee.emails.length) && (!employee.phones || !employee.phones.length)"
                >
                  <td
                      colspan="2"
                      class="px-5 py-9 text-center"
                  >
                    {{ l('pages.employees.show.cards.contacts.not_found') }}
                  </td>
                </tr>
                </tbody>
              </v-simple-table>
            </subcard>
          </v-col>
          <v-col
              cols="12"
              md="6"
              lg="12"
              class="pb-0 pt-0 pt-md-3 pt-lg-0"
          >
            <subcard>
              <v-chip
                  v-if="employee.is_temporary"
                  :to="(userIsGodLike || userHasFlag('is_hr')) ? '/employees/' + employee.uuid + '/edit' : null"
                  color="indigo"
                  dark
                  small
                  class="font-weight-bold text-uppercase mr-1 mt-1"
              >
                {{ l('pages.employees.properties.is_temporary') }}
              </v-chip>
              <div v-else>
                <v-chip
                    v-for="paper of ['stay_permit', 'work_permit', 'valid_contract'/*, 'food_cert'*/]"
                    :key="paper"
                    :color="employee[paper] ? 'green' : 'red'"
                    dark
                    small
                    class="font-weight-bold text-uppercase mr-1 mt-1"
                >
                  {{ l('pages.employees.properties.' + paper) }}
                </v-chip>
              </div>
            </subcard>
          </v-col>
        </v-row>
      </v-col>
      <v-col
          cols="12"
          lg="8"
          xl="9"
      >
        <v-row>
          <v-col
              cols="12"
              xl="4"
              class="pb-0 pt-0 pt-md-3"
          >
            <subcard>

              <v-simple-table dense>
                <tbody>
                <tr>
                  <th>
                    {{ l('pages.employees.properties.amount') }}
                  </th>
                  <td class="text-right">
                    <amount currency>
                      {{ employee.amount }}
                    </amount>
                  </td>
                </tr>
                <tr>
                  <th>
                    {{ l('pages.employees.properties.locked_amount') }}
                  </th>
                  <td class="text-right">
                    <amount currency>
                      {{ employee.locked_amount }}
                    </amount>
                  </td>
                </tr>
                <tr>
                  <th>
                    {{ l('pages.employees.properties.available_amount') }}
                  </th>
                  <td class="text-right">
                    <amount currency>
                      {{ employee.available_amount }}
                    </amount>
                  </td>
                </tr>
                </tbody>
              </v-simple-table>
            </subcard>
          </v-col>
          <v-col
              cols="12"
              xl="8"
              class="pb-0 pt-0 pt-xl-3"
          >
            <employees-payslip-card v-if="employee && employee.uuid" :employee="employee.uuid" />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </employees-show-page>
</template>

<script>
import CheckMark from '../../../../Shared/Icons/CheckMark'
import Subcard from '../../../../Shared/Cards/Subcard'
import EmployeesShowPage from '../../show'
import Amount from '../../../../Shared/Text/Amount'
import EmployeesPayslipCard from '@/components/Pages/employees/show/details/payslip/EmployeesPayslipCard'
import UserMixin from "@/mixins/UserMixin";

export default {
  name: 'EmployeesShowDetailsPage',
  mixins: [
      UserMixin,
  ],
  components: {
    Amount,
    CheckMark,
    Subcard,
    EmployeesPayslipCard,
    EmployeesShowPage,
  },
}
</script>

<style scoped>

</style>
