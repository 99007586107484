<template>
    <employees-show-page parent v-slot:default="_parent">
        <sub-item-show-view v-slot:default="_">
            <employee-fund-lock-edit-form :disabled="_.itemDisabled" v-model="_.item" />
        </sub-item-show-view>
    </employees-show-page>
</template>

<script>
    import EmployeesShowPage from '../../show'
    import SubItemShowView from '../../../../Item/Views/SubItemShowView'
    import EmployeeFundLockEditForm from './Shared/EmployeeFundLockEditForm'

    export default {
        name: 'EmployeesShowFundLockCreatePage',
        inheritAttrs: false,
        components: {
            EmployeeFundLockEditForm,
            EmployeesShowPage,
            SubItemShowView,
        },
    }
</script>

<style scoped>

</style>