<template>
  <v-form>
    <v-row>
      <v-col cols="12" md="8" class="pb-0">
        <subcard content-class="pt-0">
          <template slot="title">
            {{ l('pages.shifts.edit.cards.general.title') }}
          </template>

          <v-row dense>
            <v-col cols="12">
              <user-picker
                  class="mt-1"
                  employee
                  hide-details
                  :disabled="disabled || editabilityIsLimited"
                  v-model="userId"
              />
            </v-col>
          </v-row>

          <v-row dense>
            <v-col cols="12" md="3">
              <v-select
                  v-if="!assignmentId || isAuto"
                  :disabled="disabled || !unitOptions.length"
                  hide-details
                  :label="l('pages.shifts.properties.unit')"
                  :items="unitOptions"
                  v-model="positionScope.unit_id"
              />
              <v-text-field
                  v-else
                  :disabled="disabled"
                  hide-details
                  :label="l('pages.shifts.properties.unit')"
                  readonly
                  :value="shift.unit.name"
              />
            </v-col>
            <v-col cols="12" md="9">
              <v-select
                  v-if="!assignmentId || isAuto"
                  :disabled="disabled || !positionOptions.length"
                  hide-details
                  :label="l('pages.shifts.properties.position')"
                  :items="positionOptions"
                  v-model="positionId"
              />
              <v-text-field
                  v-else
                  :disabled="disabled"
                  hide-details
                  :label="l('pages.shifts.properties.position')"
                  readonly
                  :value="shift.position.name"
              />
            </v-col>
          </v-row>
          <v-row v-if="editabilityIsLimited">
            <v-col>
              <v-card flat color="amber lighten-4">
                <v-card-text class="font-weight-bold pa-2">
                  {{ l('pages.shifts.strings.position_is_not_modifiable') }}.
                  {{ l('pages.shifts.strings.based_on_assignment') }}:
                  <router-link
                      v-if="undefined !== assignment.uuid"
                      :to="{name: undefined === parentModel.uuid ? 'assignments.edit' : 'locations.show.assignments.edit', params: {uuid: parentModel.uuid, childUuid: assignment.uuid}}"
                  >
                    {{ assignment.name }}
                  </router-link>
                  <span v-else>
                                        {{ l('pages.shifts.properties.assignment') }} {{ assignmentId }}
                                    </span>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>

        </subcard>
      </v-col>

      <v-col cols="12" md="4" class="pb-0">
        <subcard content-class="pt-0">

          <template slot="title">
            {{ l('pages.shifts.edit.cards.params.title') }}
          </template>

          <v-row dense>
            <v-col cols="12" sm="7">
              <date-field
                  autocomplete="off"
                  text-field-class="mt-4"
                  :disabled="disabled"
                  dense
                  hide-details
                  :max="maxDate"
                  :min="minDate"
                  required
                  :label="l('pages.shifts.properties.date')"
                  v-model="date"
              />
            </v-col>
            <v-col cols="12" sm="5">
              <time-field
                  autocomplete="off"
                  class="mt-4"
                  :disabled="disabled"
                  clearable
                  dense
                  hide-details
                  :label="l('pages.shifts.properties.time')"
                  v-model="time"
              />
            </v-col>
          </v-row>


          <v-row dense>
            <v-col cols="12" sm="3">
              <hours-field
                  autocomplete="off"
                  class="mt-4"
                  :disabled="disabled"
                  dense
                  hide-details
                  placeholder=""
                  v-model="hours"
              />
            </v-col>
            <v-col cols="12" sm="4">
              <rate-field
                  autocomplete="off"
                  class="mt-4"
                  :disabled="disabled"
                  dense
                  hide-details
                  :items="rates"
                  v-model="rate"
                  maxlength="6"
              />
            </v-col>
            <v-col cols="12" sm="5">
              <v-checkbox
                  dense
                  :disabled="disabled"
                  hide-details
                  :label="l('pages.shifts.properties.is_payable')"
                  v-model="isPayable"
              />
            </v-col>
          </v-row>
        </subcard>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import _ from 'lodash'
import t from 'typy'
import Subcard from '../../../../../Shared/Cards/Subcard'
import DateField from '../../../../../Shared/Forms/TextFields/DateField'
import NumberField from '../../../../../Shared/Forms/TextFields/NumberField'
import TimeField from '../../../../../Shared/Forms/TextFields/TimeField'
import HoursField from '../../../../../Shared/Forms/TextFields/HoursField'
import RateField from '../../../../../Shared/Forms/TextFields/RateField'
import ApiSelect from '../../../../../Shared/Forms/ApiSelect'
import ServiceFunctionsMixin from '../../../../../../mixins/ServiceFunctionsMixin'
import UserPicker from "../../../../../Shared/Forms/UserPicker";

export default {
  name: "LocationShiftEditForm",
  inheritAttrs: false,
  components: {
    UserPicker,
    ApiSelect,
    DateField,
    HoursField,
    NumberField,
    RateField,
    Subcard,
    TimeField,
  },
  mixins: [
    ServiceFunctionsMixin,
  ],
  props: {
    parentModel: {
      required: false,
      type: Object,
      default: () => ({}),
    },
    value: {
      required: false,
      type: Object,
      default: () => ({}),
    },
    disabled: {
      required: false,
      type: Boolean,
      default: false,
    }
  },
  data: vm => ({
    assignment: {},
    assignmentId: null,
    isAuto: true,
    date: null,
    hours: null,
    isPayable: false,
    editabilityIsLimited: false,
    maxDate: vm.$moment().format('YYYY-MM-DD'),
    minDate: '',
    position: {},
    positionId: null,
    positionOptions: [],
    positionScope: {
      location_id: '',
      unit_id: '',
    },
    rate: null,
    rates: [],
    rules: {
      required: value => undefined !== value && '' !== value || vm.l('pages.shifts.validation.required')
    },
    shift: {},
    time: '',
    unitOptions: [],
    userId: null,
  }),
  watch: {
    assignmentId: {
      immediate: true,
      handler(id) {
        this.shift.assignment_id = id
        if (id && (!_.isObject(this.assignment) || _.get(this.assignment, 'id', null) !== id)) {
          this.$api.get('assignments', {params: {only_id: id}})
              .then(function (response) {
                this.assignment = (response.data.total === 1) ? this.clone(response.data.items[0]) : null
              }.bind(this))
        }
      },
    },
    assignment: {
      deep: true,
      immediate: true,
      handler(assignment) {
        if (_.isObject(assignment) && _.keys(assignment).length !== 0) {

          const isAuto = t(assignment, 'is_auto').safeBoolean
          this.editabilityIsLimited = !isAuto
          if (this.isAuto !== isAuto) {
            this.isAuto = isAuto
          }

          const userId = t(assignment, 'user_id').safeNumber
          if (userId && this.userId !== userId) {
            this.userId = userId
          }

          const positionId = t(assignment, 'position_id').safeNumber
          if (positionId && this.positionId !== positionId) {
            this.positionId = positionId
          }

          if (t(assignment, 'is_auto').safeBoolean) {
            this.minDate = null
            this.maxDate = this.$moment().format('YYYY-MM-DD')
          } else {
            this.minDate = assignment.from_date || null
            this.maxDate = assignment.to_date || this.$moment().format('YYYY-MM-DD')
          }
        }
      },
    },
    date: {
      immediate: true,
      handler(value) {
        this.shift.date = value
      },
    },
    hours: {
      immediate: true,
      handler(value) {
        this.shift.hours = value
      },
    },
    isPayable: {
      immediate: true,
      handler(value) {
        this.shift.is_payable = value
      },
    },
    maxDate(maxDate) {
      if (this.$moment(this.date).isAfter(maxDate)) {
        this.date = maxDate
      }
    },
    minDate(minDate) {
      if (this.$moment(this.date).isBefore(minDate)) {
        this.date = minDate
      }
    },
    parentModel: {
      deep: true,
      immediate: true,
      handler(location) {

        const locationId = _.get(location, 'id', null)
        this.positionScope.location_id = locationId

        if (locationId) {

          const openTerms = _.get(location, 'open_terms', [])

          const currentTerm = this.$moment().format('YYYYMM')
          const currentTermMinDate = this.$moment().startOf('month')
          const currentTermMaxDate = this.$moment().endOf('month')

          const previousTerm = this.$moment().startOf('month').subtract(1, 'months').format('YYYYMM')
          const previousTermMinDate = this.$moment().startOf('month').subtract(1, 'months')
          const previousTermMaxDate = this.$moment().startOf('month').subtract(1, 'months').endOf('month')

          const currentTermAvailable = openTerms.indexOf(currentTerm) > -1
          const previousTermAvailable = openTerms.indexOf(previousTerm) > -1

          const assignmentMinDateString = _.get(this.shift, ['assignment', 'from_date'], null)
          const assignmentMinDate = (null !== assignmentMinDateString) ? this.$moment(assignmentMinDateString) : null
          const assignmentMaxDateString = _.get(this.shift, ['assignment', 'to_date'], null)
          const assignmentMaxDate = (null !== assignmentMaxDateString) ? this.$moment(assignmentMaxDateString) : null

          let minDate, maxDate

          switch (true) {
            case (
                null !== assignmentMaxDate && null !== assignmentMinDate
                && !previousTermAvailable && !currentTermAvailable
                && assignmentMinDate.isBetween(previousTermMinDate, currentTermMaxDate)
                && assignmentMaxDate.isBetween(previousTermMinDate, currentTermMaxDate)
            ):
            case (
                null !== assignmentMaxDate && null !== assignmentMinDate
                && !previousTermAvailable && currentTermAvailable
                && assignmentMinDate.isBetween(previousTermMinDate, previousTermMaxDate)
                && assignmentMaxDate.isBetween(previousTermMinDate, previousTermMaxDate)
            ):
            case (
                null !== assignmentMaxDate && null !== assignmentMinDate
                && previousTermAvailable && !currentTermAvailable
                && assignmentMinDate.isBetween(currentTermMinDate, currentTermMaxDate)
                && assignmentMaxDate.isBetween(currentTermMinDate, currentTermMaxDate)
            ):
            default:
              this.$router.push(this.$route.path.replace(/\/edit$/, '').replace(/\/[^\/]+$/, ''))
              return
            case previousTermAvailable && currentTermAvailable:
              minDate = previousTermMinDate
              maxDate = currentTermMaxDate
              break
            case !previousTermAvailable && currentTermAvailable:
              minDate = currentTermMinDate
              maxDate = currentTermMaxDate
              break
            case previousTermAvailable && !currentTermAvailable:
              minDate = previousTermMinDate
              maxDate = previousTermMaxDate
              break
          }

          if (null !== assignmentMinDate && minDate.isBefore(assignmentMinDate)) {
            minDate = this.$moment(assignmentMinDate)
          }

          if (null !== assignmentMaxDate && maxDate.isAfter(assignmentMaxDate)) {
            maxDate = this.$moment(assignmentMaxDate)
          }

          const today = this.$moment()

          this.maxDate = (maxDate.isAfter(today) ? today : maxDate).format('YYYY-MM-DD')
          this.minDate = minDate.format('YYYY-MM-DD')

        }
      },
    },
    positionId: {
      immediate: true,
      handler(id) {
        this.shift.position_id = id
        if (id && (t(this.position, 'id').safeNumber !== id)) {
          this.$api.get('positions', {params: {only_id: id}})
              .then(function (response) {
                this.position = (response.data.total === 1) ? this.clone(response.data.items[0]) : null
              }.bind(this))
        }
      },
    },
    position: {
      deep: true,
      immediate: true,
      handler(position) {
        this.positionScope.unit_id = _.get(position, 'unit_id', null)
        this.rates = _.get(position, 'rates', [])
      },
    },
    positionOptions: {
      deep: true,
      handler(value) {
        if (value.length && !this.isOptionValid(this.positionId, value)) {
          this.positionId = _.first(value).value
        }
      },
    },
    positionScope: {
      deep: true,
      immediate: true,
      handler() {
        this.updateUnitOptions()
            .then(function () {
              this.updatePositionOptions()
            }.bind(this))
      },
    },
    rate: {
      immediate: true,
      handler(value) {
        this.shift.rate = value
      },
    },
    shift: {
      deep: true,
      immediate: true,
      handler(value) {
        this.emitInput(value)
      },
    },
    time: {
      immediate: true,
      handler(value) {
        this.shift.time = value
      },
    },
    unitOptions: {
      deep: true,
      handler(value) {
        if (value.length && !this.isOptionValid(this.positionScope.unit_id, value)) {
          this.positionScope.unit_id = _.first(value).value
        }
      },
    },
    userId: {
      immediate: true,
      handler(id) {
        this.shift.user_id = id
      },
    },
    value: {
      deep: true,
      immediate: true,
      handler(value) {
        this.shift = value
        this.assignmentId = _.get(value, 'assignment_id', null)
        this.isAuto = _.get(value, ['assignment', 'is_auto'], !this.assignmentId)
        this.minDate = _.get(value, ['assignment', 'from_date'], null)
        this.maxDate = _.get(value, ['assignment', 'to_date'], this.$moment().format('YYYY-MM-DD 00:00:00'))
        this.positionId = _.get(value, 'position_id', _.get(value, ['assignment', 'position_id'], null))
        this.userId = _.get(value, 'user_id', null)

        this.date = (undefined !== value.date)
            ? this.$moment(value.date).format('YYYY-MM-DD 00:00:00')
            : (
                (!this.assignmentId && !this.date)
                    ? this.$moment().format('YYYY-MM-DD 00:00:00')
                    : ''
            )

        this.hours = _.get(value, 'hours', null)
        this.isPayable = _.get(value, 'is_payable', true)
        this.rate = _.get(value, 'rate', null)
        this.time = _.get(value, 'time', null)

        this.editabilityIsLimited = false
      },
    },
  },
  methods: {
    isOptionValid(option, options = [], key = 'value') {
      return !!_.filter(options, value => value && value[key] && value[key] === option).length
    },


    updatePositionOptions() {
      return new Promise(function (resolve, reject) {
        if (this.positionScope.location_id) {
          this.$api.get('positions/list', {params: this.positionScope})
              .then(function (response) {
                this.positionOptions = response.data
                resolve()
              }.bind(this))
              .catch(error => reject(error))
        } else {
          this.positionOptions = []
          resolve()
        }
      }.bind(this))
    },
    updateUnitOptions() {
      return new Promise(function (resolve, reject) {
        const locationId = this.positionScope.location_id
        if (locationId) {
          this.$api.get('units/list', {params: {location_id: locationId}})
              .then(function (response) {
                this.unitOptions = response.data
                resolve()
              }.bind(this))
              .catch(error => reject(error))
        } else {
          this.unitOptions = []
          resolve()
        }
      }.bind(this))
    },
    emitInput(value = null) {
      this.$emit('input', value || this.shift)
    },
  },
}
</script>

