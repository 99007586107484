import LangMixin from '../LangMixin'
import DateTimeMixin from '../DateTimeMixin'
// import PropsMixin from "../PropsMixin";

const GlobalMixin = {
    mixins: [
        LangMixin,
        DateTimeMixin
//        PropsMixin,
    ],
    data: vm => ({
        wrapped: true
    }),
    watch: {
        '$route.query.stripped': {
            handler(stripped) {
                this.wrapped = !stripped
            },
            immediate: true,
        },
    },
}

export default GlobalMixin
