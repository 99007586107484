import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import i18n from '../internals/i18n'

// import store from './../store'
// import cs from 'vuetify/es5/locale/cs'

Vue.use(Vuetify);

export default new Vuetify({
  lang: {
/*
    locales: { cs },
    current: store.getters.appLocale,
*/
    t: (key, ...params) => i18n.t(key, params),
  },
  icons: {
    iconfont: 'mdi',
  },
});
