<template>
  <v-form>
    <v-row>
      <v-col cols="12" md="9" class="pb-0">
        <subcard>
          <template slot="title">
            {{ l('pages.employees.show.cards.general.title') }}
          </template>
          <v-row dense>
            <v-col cols="12" md="6">
              <v-text-field
                  :disabled="disabled"
                  hide-details
                  v-model="employee.first_name"
                  :label="l('pages.employees.properties.first_name')"
                  :rules="[rules.required]"
                  required
              />
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                  :disabled="disabled"
                  hide-details
                  v-model="employee.last_name"
                  :label="l('pages.employees.properties.last_name')"
                  :rules="[rules.required]"
                  required
              />
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" md="6">
              <v-select
                  :disabled="disabled"
                  hide-details
                  :label="l('pages.employees.properties.gender')"
                  :items="genderSelect"
                  v-model="employee.gender"
              />
            </v-col>
            <v-col cols="12" md="6">
              <date-field
                  clearable
                  :disabled="disabled"
                  hide-details
                  :label="l('pages.employees.properties.birth_date')"
                  v-model="employee.birth_date"
              />
            </v-col>
          </v-row>
        </subcard>
        <subcard>
          <template slot="title">
            {{ l('pages.employees.show.cards.papers.title') }}
          </template>
          <v-row dense>
            <v-col cols="2">
              <v-checkbox
                  v-model="employee.is_temporary"
                  hide-details
                  :label="l('pages.employees.properties.is_temporary')"
              />
            </v-col>
            <v-col cols="10">
              <api-select
                  api="countries/list"
                  :params="{filter: {favorite: true}}"
                  :disabled="disabled"
                  hide-details
                  :label="l('pages.employees.properties.country')"
                  v-model="employee.country_id"
              />
            </v-col>
          </v-row>
        </subcard>
      </v-col>
      <v-col cols="12" md="3" class="pb-0">
        <subcard>
          <template slot="title">
            <div>
              {{ l('forms.phoneList.title') }}
            </div>
          </template>
          <phone-list
              dense
              :disabled="disabled"
              hide-details
              v-model="employee.phones"
          />
        </subcard>
        <subcard>
          <template slot="title">
            <div>
              {{ l('forms.emailList.title') }}
            </div>
          </template>
          <email-list
              dense
              :disabled="disabled"
              hide-details
              v-model="employee.emails"
          />
        </subcard>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import PasswordField from '../../../Shared/Forms/TextFields/PasswordField'
import ContractList from './Contract/ContractList'
import ResidenceList from './Residence/ResidenceList'
import DateField from '../../../Shared/Forms/TextFields/DateField'
import PhoneList from '../../../Shared/Forms/UserRelated/PhoneList'
import EmailList from '../../../Shared/Forms/UserRelated/EmailList'
import Subcard from '../../../Shared/Cards/Subcard'
import GenderMixin from '../../../../mixins/GenderMixin'
import ApiSelect from "../../../Shared/Forms/ApiSelect";
import TrimTextField from "../../../Shared/Forms/TextFields/TrimTextField";

const defaultEmployeeModel = {
  first_name: '',
  last_name: '',
  gender: 'unknown',
  birth_date: '',
  password: '',
  passport: '',
  phones: [],
  emails: [],
  country_id: null,
  is_temporary: false,
}


export default {
  name: "EmployeeEditForm",
  inheritAttrs: false,
  mixins: [
    GenderMixin,
  ],
  components: {
    TrimTextField,
    ApiSelect,
    Subcard,
    DateField,
    PasswordField,
    PhoneList,
    EmailList,
    ContractList,
    ResidenceList,
  },
  props: {
    value: {
      required: false,
      type: Object,
      default: () => defaultEmployeeModel,
    },
    disabled: {
      required: false,
      type: Boolean,
      default: false,
    }
  },
  data: vm => ({
    employee: {},
    rules: {
      required: value => undefined !== value && '' !== value || vm.l('pages.employees.validation.required')
    },
  }),
  watch: {
    value: {
      deep: true,
      immediate: true,
      handler(value) {
        this.employee = value
      },
    },
    employee: {
      deep: true,
      handler(value) {
        this.$emit('input', value)
      },
    },
  },
}
</script>

