<template>
    <v-layout class="envelope-face-container title text-uppercase">
        <div class="envelope-face-text">
            {{ lodash.get(envelope, 'user.list_name', '') }}
        </div>
    </v-layout>
</template>

<script>
    import _ from 'lodash'

    export default {
        name: "EnvelopeFace",
        props: {
            envelope: {
                required: true,
                type: Object,
                default: () => ({}),
            },
        },
        data: vm => ({
            lodash: _,
        }),

    }
</script>

<style>

    @media print {
        .v-application {
            background-color: transparent !important;
        }
    }

    body{
        color-adjust: exact;
        -webkit-print-color-adjust: exact;
    }

    .envelope-face-container {
        margin: 0;
        padding: 0;
    }

    .envelope-face-text {
        margin: 12cm 0 0 4.5cm;
        padding: 0;
        text-align: right;
        width: 10cm;
        height: auto;
        -webkit-transform: rotate(90deg);
        -moz-transform: rotate(90deg);
        -o-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        transform: rotate(90deg);
    }

    @media print {
        .envelope-face-container { page-break-after: always; }
    }

</style>
