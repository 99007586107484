<template>
    <item-show-view v-slot:default="{ item: user }">
        <v-row>
            <v-col cols="12" md="6">
                <subcard>
                    <template v-slot:title>
                        {{ l('pages.users.show.cards.contacts.title') }}
                    </template>
                    <v-simple-table v-if="user.emails && user.emails.length || user.phones && user.phones.length">
                        <thead>
                        <tr>
                            <th>
                                {{ l('pages.users.properties.phones') }}
                            </th>
                            <th>
                                {{ l('pages.users.properties.emails') }}
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td class="font-weight-bold">
                                <div
                                    v-for="(phone, index) in user.phones"
                                    :key="index"
                                    class="mb-2 text-no-wrap"
                                >
                                    {{ phone | phoneNumber}}
                                </div>
                            </td>
                            <td class="font-weight-bold">
                                <div
                                    v-for="(email, index) in user.emails"
                                    :key="index"
                                    class="mb-2"
                                >
                                    {{ email.address }}
                                </div>
                            </td>
                        </tr>
                        </tbody>
                    </v-simple-table>
                    <div v-else class="px-5 py-9 text-center">
                        {{ l('pages.users.show.cards.contacts.not_found') }}
                    </div>
                </subcard>
            </v-col>
            <v-col cols="12" md="6">
                <subcard v-if="undefined !== user.flags && user.flags.length">

                    <template v-slot:title>
                        {{ l('pages.users.properties.flags') }}
                    </template>

                    <v-chip
                        v-for="flag in user.flags"
                        :key="flag"
                        class="mr-2"
                    >
                        {{ l('pages.users.values.flags.' + flag) }}
                    </v-chip>

                </subcard>
            </v-col>
        </v-row>
    </item-show-view>
</template>

<script>
    import ItemShowView from '../../Item/Views/ItemShowView'
    import Subcard from '../../Shared/Cards/Subcard'

    export default {
        name: 'UsersShowPage',
        components: {
            ItemShowView,
            Subcard,
        },
    }
</script>

<style scoped>

</style>