import cs from 'vuetify/es5/locale/cs'

export default {
    "$vuetify": cs,
    "auth": {
        "actions": {
            "login": "Přihlásit se",
            "logout": "Odhlásit se",
        },
        "form": {
            "heading": "Přihláste se",
            "fields": {
                "username": "E-mail nebo telefonní číslo",
                "password": "Heslo",
            },
        },
        "user": {
            "guest": "Host",
        },
    },
    "dialogs": {
        "delete": {
            "confirm": "Odstranit",
            "reject": "Zpět",
            "text": "Potvrdte odstranění záznamu",
            "title": "Odstranění záznamu",
        },
    },
    "forms": {
        "confirmableTextField": {
            "label": "Potvrdit",
            "tooltip": {
                "confirmed": "Potvrzeno",
                "unconfirmed": "Ne potvrzeno",
            },
        },
        "contracts": {
            "title": "Smlouvy",
        },
        "datePicker": {
            "label": "Datum",
        },
        "emailList": {
            "title": "E-maily",
        },
        "emailTextField": {
            "placeholder": "E-mail",
        },
        "hoursField": {
            "label": "Hodiny",
            "placeholder": "Max. 24",
            "validation": {
                "invalid": "Zadejte čislo v rozmezí 0 až 24",
                "required": "Zadejte počet hodin",
            },
        },
        "passwordField": {
            "label": "Heslo",
        },
        "phoneList": {
            "title": "Telefony",
        },
        "phoneTextField": {
            "placeholder": "Telefon",
        },
        "rateField": {
            "label": "Sazba",
            "validation": {
                "required": "Zadejte sazbu",
            },
        },
        "residences": {
            "title": "Povolení k pobytu",
        },
        "timeField": {
            "label": "Čas",
            "validation": {
                "invalid": "Špatný čas",
                "required": "Zadejte čas",
            },
        },
        "userPicker": {
            "label": "Zaměstnanec",
            "validation": {
                "required": "Zvolte zaměstnance",
            },
            "properties": {
                "amount": "Zústatek",
                "available_amount": "Disp. zústatek",
                "list_name": "Jméno",
                "birth_date": "Narozen/á",
                "phones": "Telefon",
                "stay_permit": "Pobyt",
                "work_permit": "Práce",
                "valid_contract": "Smlouva",
                "food_cert": "Potr. průkaz",
            },
            "tooltips": {
                "stay_permit": {
                    "yes": "Pobyt v ČR povolen",
                    "no": "Chybí povolení k pobytu",
                },
                "work_permit": {
                    "yes": "Práce v ČR povolena",
                    "no": "Chybí povolení k zaměstnání",
                },
                "valid_contract": {
                    "yes": "Má podepsanou smlouvu",
                    "no": "Musí podepsat smlouvu",
                },
                "food_cert": {
                    "yes": "Má potravinářský průkaz",
                    "no": "Nemá potravinářský průkaz",
                },
            },
        },
    },
    "global": {
        "all": "Všechny",
        "allPersons": "Všichni",
        "any": "Libovolné",
        "anyPerson": "Kdokoli",
        "currency": "Kč",
        "day": "den",
        "errors": {
            "not_found": "Nenalezeno",
            "invalid_value": "Nevalidní hodnota",
        },
        "gender": {
            "undefined": "Neznámé",
            "unknown": "Neznámé",
            "male": "Muž",
            "female": "Žena",
        },
        "hours": "{count} hodin | {count} hodina | {count} hodiny | {count} hodin | {count} hodiny",
        "hrs": "hod.",
        "h": "h.",
        "max": "Max.",
        "months": {
            "01": "Leden",
            "02": "Únor",
            "03": "Březen",
            "04": "Duben",
            "05": "Květen",
            "06": "Červen",
            "07": "Červenec",
            "08": "Srpen",
            "09": "Září",
            "1": "Leden",
            "10": "Říjen",
            "11": "Listopad",
            "12": "Prosinec",
            "2": "Únor",
            "3": "Březen",
            "4": "Duben",
            "5": "Květen",
            "6": "Červen",
            "7": "Červenec",
            "8": "Srpen",
            "9": "Září",
        },
        "no": "Ne",
        "print": "Tisk",
        "signature": "Podpis",
        "to": "až",
        "total": "Celkem",
        "undefined": "Neuvedeno",
        "wait": "Čekejte",
        "yes": "Ano",
    },
    "pages": {
        "all": {
            "actions": {
                "back": "Zpět",
                "back_to_index": "Zpět do seznamu",
                "block": "Blokovat",
                "cancel": "Zrušit",
                "change": "Změnit",
                "clone": "Klonovat",
                "confirm": "Potvrdit",
                "create": "Vytvořit",
                "delete": "Odstranit",
                "edit": "Editovat",
                "erase": "Smazat",
                "excel": "Exportovat do Excelu",
                "lock": "Zamknout",
                "reject": "Odmitnout",
                "save": "Uložit",
                "save_and_index": "Uložit a zpět do seznamu",
                "show": "Ukázat",
                "unblock": "Odblokovat",
                "unlock": "Odemknout",
                "update": "Aktualizovat",
            },
            "create": {
                "title": "Nový záznam",
            },
            "errors": {
                "not_editable": "Záznam není editovatelný",
            },
            "index": {
                "filters": {
                    "created_by": "Vytvořil/a",
                    "deleted_by": "Odstranil/a",
                    "employee": "Jméno/příjmení/telefon",
                    "from_date": "Ode dne",
                    "from_term": "Od období",
                    "location_id": "Objekt",
                    "note": "Poznámka",
                    "position_id": "Pozice",
                    "to_date": "Do dne",
                    "to_term": "Do období",
                    "type": "Typ",
                    "type_id": "Typ",
                    "unit_id": "Středisko",
                    "updated_by": "Aktualizovál/a",
                },
            },
            "indicators": {
                "modified": "Neuložené změny",
            },
            "properties": {
                "actions": "",
                "address": "Adresa",
                "age": "Věk",
                "amount": "Částka",
                "assignment": "Přidělení",
                "auto": "Auto",
                "birth_date": "Datum narození",
                "cash_operation": "Operace s hotovostí",
                "cashbox": "Pokladna",
                "confirmed_at": "Potvrzeno",
                "confirmed_by": "Potvrdil/a",
                "context": "Kontext",
                "created_at": "Vytvořeno",
                "created_by": "Vytvořil/a",
                "createdBy": "Vytvořil/a",
                "date": "Datum",
                "date_from": "Ode dne",
                "date_to": "Do dne",
                "deleted_at": "Odstraněno",
                "deleted_by": "Odstranil/a",
                "deletedBy": "Odstranil/a",
                "emails": "Email",
                "employee": "Zaměstnanec",
                "employee_name": "Zaměstnanec",
                "first_name": "Křestní jméno",
                "flags": "Označení",
                "from_date": "Ode dne",
                "gender": "Pohlaví",
                "hours": "Hodiny",
                "id": "ID",
                "last_name": "Příjmení",
                "location": "Objekt",
                "location_name": "Objekt",
                "locations": "Objekty",
                "locked_amount": "Zablokováno",
                "name": "Název",
                "note": "Poznámka",
                "is_not_payable": "Není hrazeno",
                "is_payable": "Hrazeno",
                "phones": "Telefon",
                "position": "Pozice",
                "rate": "Sazba",
                "term": "Období",
                "time": "Čas",
                "type": "Typ",
                "to_date": "Do dne",
                "unit": "Středisko",
                "updated_at": "Aktualizováno",
                "updated_by": "Aktualizovál/a",
                "updatedBy": "Aktualizovál/a",
                "units": "Střediska",
                "user": "Zaměstnanec",
            },
            "validation": {
                "required": "Musíte uvést"
            },
        },
        "management": {
            "index": {
                "title": "Přehled",
            },
            "cashboxes": {
                "title": "Pokladny",
            },
            "title": "Vedení",
        },
        "my": {
            "dashboard": {
                "title": "Přehled",
                "cards": {
                    "balances": {
                        "title": "Zůstatky",
                        "debt": {
                            "title": "Bezdlužnost",
                            "options": {
                                "hide": "Jenom bez dluhů",
                                "only": "Jenom s dluhy",
                                "show": "Všichni zaměstnanci",
                            },
                        },
                        "headers": {
                            "list_name": "Zaměstnanec",
                            "locked": "Zablokováno",
                            "account": "Stav účtu",
                            "total": "Celkem",
                        },
                    },
                    "money": {
                        "title": "Peníze",
                        "items": {
                            "start": "P1 - Zůstatek na začátku měsíce: Suma všech transakcí ve všech předchozích zúčtovacích obdobích",
                            "startFrom": "P1 - Zůstatek na začátku zúčtovacího období {from}: Suma všech transakcí ve všech předchozích zúčtovacích obdobích",
                            "now": "P2 - Platební operace tento měsíc: Suma pokladních operací se zaměstnanci a transakcí bez kontextu v současném zúčtovacím období",
                            "nowFromTo": "P2 - Platební operace {from} až {to}: Suma pokladních operací se zaměstnanci a transakcí bez kontextu v daných zúčtovacích obdobích",
                            "total": "Konečný zůstatek: P1 + P2",
                        },
                        "loading": "Data se stahují"
                    },
                    "payments": {
                        "title": "Výplaty",
                        "buttons": {
                            "collapse": {
                                "all": "Sklapnout vše",
                                "one": "Sklapnout",
                            },
                            "expand": {
                                "all": "Ukázat vše",
                                "one": "Ukázat",
                            },
                        },
                    },
                },
            },
            "profile": {
                "title": "Můj profil",
            },
        },
        "adjustments": {
            "create": {
                "title": "Nová odměna/pokuta",
            },
            "show": {
                "title": "Korekce",
            },
            "index": {
                "title": "Odměny a pokuty",
            },
            "properties": {},
            "types": {
                "bonus": "Odměna",
                "penalty": "Pokuta",
            },
        },
        "assignments": {
            "actions": {
                "cover": "Potvrdit docházky",
            },
            "create": {
                "title": "Nové přidělení",
            },
            "edit": {
                "cards": {
                    "general": {
                        "title": "Pozice",
                    },
                    "params": {
                        "title": "Parametry",
                    },
                },
                "form": {
                    "from_date": {
                        "single": "Jednodenní přidělení",
                    },
                },
            },
            "show": {
                "title": "Přidělení",
            },
            "index": {
                "title": "Přidělení",
            },
            "properties": {
                "days": "Dnů celkem",
                "days_covered": "Dnů pokryto",
                "is_auto": "Vytvořeno automaticky",
            },
            "values": {
                "to_date": {
                    "longterm": "Trvalé",
                    "multiple": "Vícedenní",
                    "single": "Jednodenní",
                }
            },
        },
        "cash_operations": {
            "actions": {
                "create": "Nová operace",
                "deposit": "Příjmout",
                "withdrawal": "Vydat",
                "show_slip": "Výplatní páska",
            },
            "create": {
                "title": "Nová operace",
                "mode": {
                    "employee": "Zaměstnanec",
                    "simple": "Pokladna",
                },
                "no_employee_warning": "Pozor! Provádite pokladní operaci bez uvedení zaměstnance!",
            },
            "index": {
                "title": "Operace s hotovostí",
                "filters": {
                    "text": "Zaměstnanec nebo text poznámky",
                },
            },
            "properties": {
                "created_at": "Provedena",
                "created_by": "Provedl/a",
                "createdBy": "Provedl/a",
                "updated_at": "Aktualizována",
                'signature': "Podpis",
                "type": "Operace",
            },
            "show": {
                "title": "Operace s hotovostí",
            },
            "values": {
                "type": {
                    "deposit": "Příjem",
                    "transfer": "Převod",
                    "withdrawal": "Výdej",
                }
            },
        },
        "cashboxes": {
            "create": {
                "title": "Nová pokladna",
            },
            "show": {
                "title": "Pokladna",
                "tabs": {
                    "details": "Přehled",
                    "cash_operations": "Operace",
                },
            },
            "index": {
                "title": "Pokladny",
            },
            "properties": {
                "amount": "Zůstatek v pokladně",
                "packs_envelopes": "Balí obálky",
            },
            "values": {
                "type": {
                    "deposit": "Příjem",
                    "withdrawal": "Výdej",
                },
            },
        },
        "contracts": {
            "actions": {
                "create": "Přidat smlouvu",
            },
            "create": {
                "title": "Nová smlouva",
            },
            "index": {
                "title": "Smlouvy",
            },
            "show": {
                "title": "Smlouva",
            },
        },
        "employees": {
            "all": "Všichni",
            "any": "Kdokoli",
            "actions": {
                "set_max_cash_amount": "Zadat maximální částku",
            },
            "create": {
                "title": "Nový zaměstnanec",
            },
            "show": {
                "title": "Zaměstnanec",
                "tabs": {
                    "details": "Přehled",
                    "documents": "Doklady",
                    "adjustments": "Odměny/Pokuty",
                    "assignments": "Přidělení",
                    "contracts": "Smlouvy",
                    "envelopes": "Obálky",
                    "fund_locks": "Blokace",
                    "residences": "Pobyt",
                    "shifts": "Docházky",
                    "transactions": "Transakce",
                    "cash_operations": "Hotovost",
                },
                "cards": {
                    "assignments": {
                        "title": "Přidělení",
                    },
                    "contacts": {
                        "title": "Kontakty",
                        "not_found": "Žádné kontaktní údaje",
                    },
                    "documents": {
                        "title": "Doklady",
                    },
                    "finance": {
                        "title": "Výplatní páska",
                        "envelope": "Obálka",
                        "envelope_packed": "zabalena",
                        "after_envelopes": "Koneční zůstatek"
                    },
                    "general": {
                        "title": "Osobní údaje",
                    },
                    "papers": {
                        "title": "Doklady",
                    },
                },
            },
            "index": {
                "title": "Zaměstnanci",
                "filters": {
                    "from_date": "Registrace od",
                    "to_date": "Registrace do",
                    "no_shift_after": "Docházka nejpozději",
                    "stay_permit": {
                        "label": "Pobyt",
                        "options": {
                            "hide": "Ne",
                            "only": "Ano",
                            "show": "Všichni",
                        },
                    },
                    "text": "Jméno/příjmení/telefon",
                    "valid_contract": {
                        "label": "Smlouva",
                        "options": {
                            "hide": "Ne",
                            "only": "Ano",
                            "show": "Všichni",
                        },
                    },
                    "work_permit": {
                        "label": "Práce",
                        "options": {
                            "hide": "Ne",
                            "only": "Ano",
                            "show": "Všichni",
                        },
                    },
                },
            },
            "properties": {
                "_food_cert": "Potravinářský průkaz",
                "_stay_permit": "Povolení k pobytu",
                "_valid_contract": "Smlouva",
                "_work_permit": "Povolení k práci",
                "amount": "Zůstatek na účtu",
                "available_amount": "Disponibilní zůstatek",
                "country": "Občanství",
                "created_at": "Registrace",
                "documents": "Doklady",
                "food_cert": "Potravinák",
                "is_temporary": "Brigádník",
                "list_name": "Plné jméno",
                "last_shift_date": "Poslední docházka",
                "locked_amount": "Zablokováno",
                "max_cash_amount": "Max. částka",
                "name": "Jméno",
                "passport": "Číslo pasu",
                "stay_permit": "Pobyt",
                "valid_contract": "Smlouva",
                "work_permit": "Práce",
            },
            "values": {
                "max_cash_amount": {
                    "undefined": "Není známá",
                },
                "documents": {
                    "all": "Má všechno"
                },
            },
        },
        "envelopes": {
            "actions": {
                "pack": "Zabalit",
                "show_face": "Přední strana obálky",
                "show_faces": "Obálky",
                "show_protocols": "Předávací protokoly",
                "show_slip": "Výplatní páska",
                "show_slips": "Výplatní pásky",
                "unpack": "Rozbalit",
            },
            "index": {
                "title": "Obálky",
                "filters": {
                    "packed": {
                        "title": "Obálky",
                        "values": {
                            "show": "Všechny",
                            "hide": "Nezabalené",
                            "only": "Zabalené",
                        },
                    },
                    "text": "Zaměstnanec",
                },
                "messages": {
                    "pack": "Příprava obálek proběhne {date}",
                },
            },
            "properties": {
                "created_at": "Vytvořena",
                "created_by": "Vytvořil/a",
                "createdBy": "Vytvořil/a",
                "deleted_at": "Odstraněna",
                "deleted_by": "Odstranil/a",
                "deletedBy": "Odstranil/a",
                "packed_at": "Zabalena",
                "packedAt": "Zabalena",
                "start": "Zůstatek na začátku měsíce",
                "updated_at": "Aktualizována",
                "updated_by": "Aktualizovál/a",
                "updatedBy": "Aktualizovál/a",
            },
            "protocol": {
                "title": "Předávací protokol",
                "columns": {
                    "envelope": "Obálka",
                    "received": "Předána",
                },
                "notes": "Poznámky",
                "signature": "Datum a podpis odpovědné osoby",
            },
        },
        "food_certs": {
            "actions": {
                "create": "Přidat průkaz",
            },
            "create": {
                "title": "Nový průkaz",
            },
            "index": {
                "title": "Potravinářské průkazy",
            },
            "show": {
                "title": "Průkaz",
            },
        },
        "fund_locks": {
            "actions": {
                "process": "Provest blokaci jako transakci",
                "revert": "Zrušit provedení blokace",
            },
            "create": {
                "title": "Nová blokace",
            },
            "index": {
                "title": "Blokace",
                "filters": {
                    "processed": {
                        "title": "Blokace",
                        "values": {
                            "show": "Všechny",
                            "hide": "Neprovedené",
                            "only": "Provedené",
                        },
                    },
                    "text": "Zaměstnanec",
                },
            },
            "show": {
                "title": "Blokace",
            },
            "properties": {
                "created_at": "Vytvořena",
                "deleted_at": "Odstraněna",
                "is_processed": "Provedena",
                "processed_at": "Provedena",
                "updated_at": "Aktualizována",
            },
        },
        "locations": {
            "actions": {
                "close": "Zavřít",
                "open": "Otevřít",
            },
            "create": {
                "title": "Nový objekt",
            },
            "details": {
                "cards": {
                    "invoice": {
                        "title": "Přehled podkladů k fakturaci",
                        "actions": {
                            "excel": "Exportovat do Excelu",
                        },
                        "rows": {
                            "grand_total": "Celkem na objektu",
                            "position_total": "Celkem na pozici",
                        },
                    },
                },
            },
            "index": {
                "title": "Objekty",
                "filters": {
                    "text": "Název nebo adresa objektu",
                },
            },
            "properties": {},
            "show": {
                "messages": {
                    "close": "{month} bude zavřen {date}",
                },
                "tabs": {
                    "details": "Přehled",
                    "assignments": "Přidělení",
                    "positions": "Pozice",
                    "shifts": "Docházky",
                },
                "title": "Objekt",
            },
            "values": {
                "last_month_state": {
                    "auto": "Auto",
                    "closed": "Zavřen",
                    "open": "Otevřen",
                },
                "is_open": {
                    "false": "Zavřen",
                    "true": "Otevřen",
                },
            },
        },
        "positions": {
            "create": {
                "title": "Nová pozice",
            },
            "show": {
                "title": "Pozice",
            },
            "index": {
                "title": "Pozice",
                "filters": {
                    "text": "Název pozice",
                },
            },
            "properties": {
                "amount": "Uhrazeno",
                "assignment_count": "Přidělení",
                "hours": "Odpracováno hodin",
                "rate": "Průměrná sazba",
                "rates": "Sazby",
                "shift_count": "Docházek",
            },
        },
        "residences": {
            "actions": {
                "create": "Přidat doklad",
            },
            "create": {
                "title": "Nové povolení k pobytu",
            },
            "index": {
                "title": "Povolení k pobytu",
            },
            "show": {
                "title": "Povolení k pobytu",
            },
        },
        "shifts": {
            "create": {
                "title": "Nová docházka",
            },
            "edit": {
                "cards": {
                    "general": {
                        "title": "Pozice",
                    },
                    "params": {
                        "title": "Parametry",
                    },
                },
            },
            "show": {
                "title": "Docházka",
            },
            "index": {
                "title": "Docházky",
            },
            "properties": {},
            "strings": {
                "based_on_assignment": "Docházka byla udělána na základě přidělení",
                "position_is_not_modifiable": "Nelze změnit pozici",
            },
        },
        "transactions": {
            "actions": {
                "deposit": "Připsat",
                "withdraw": "Strhnout",
            },
            "create": {
                "title": "Nová transakce",
            },
            "errors": {
                "not_editable": "Transakce není editovatelná",
            },
            "show": {
                "title": "Transakce",
            },
            "index": {
                "title": "Transakce",
                "filters": {
                    "clean": {
                        "title": "Transakce",
                        "values": {
                            "hide": "Stornované",
                            "only": "Nestornované",
                            "show": "Všechny",
                        },
                    },
                    "text": "Zaměstnanec nebo text poznámky",
                },
            },
            "properties": {
                "is_rollback": "Stornující",
                "is_rolled_back": "Stornovaná",
            },
            "values": {
                "type": {
                    "adjustment": "Odměna/pokuta",
                    "bonus": "Odměna",
                    "cash_operation": "Hotovost",
                    "envelope": "Obálka",
                    "fund_lock": "Blokace",
                    "penalty": "Pokuta",
                    "regular": "Obyčejná",
                    "rollback": "Storno",
                    "shift": "Docházka",
                },
            },
        },
        "units": {
            "create": {
                "title": "Nové středisko",
            },
            "index": {
                "title": "Střediska",
                "filters": {
                    "text": "Název střediska",
                },
            },
            "show": {
                "title": "Středisko",
            },
        },
        "users": {
            "all": "Všichni",
            "any": "Kdokoli",
            "create": {
                "title": "Nový uživatel",
            },
            "show": {
                "title": "Uživatel",
                "cards": {
                    "contacts": {
                        "title": "Kontakty",
                        "not_found": "Žádné kontaktní údaje",
                    },
                    "general": {
                        "title": "Osobní údaje",
                    },
                },
            },
            "index": {
                "title": "Uživatele",
                "filters": {
                    "text": "Jméno/příjmení/telefon",
                    "is_employee": {
                        "title": "Druh uživatelů",
                        "values": {
                            "hide": "Mimo zaměstnanci",
                            "only": "Pouze zaměstnanci",
                            "show": "Všichni",
                        },
                    },
                },
            },
            "properties": {
                "amount": "Zůstatek na účtu",
                "available_amount": "Disponibilní zůstatek",
                "is_employee": "Zaměstnanec",
                "is_temporary": "Brigádník",
                "list_name": "Plné jméno",
                "locked_amount": "Zablokováno",
                "name": "Jméno",
                "password": "Heslo",
                "password_confirmation": "Ještě jednou",
            },
            "values": {
                "flags": {
                    "is_accountant": "Fakturant",
                    "is_admin": "Správce",
                    "is_cashier": "Pokladní",
                    "is_chief_cashier": "Hlavní pokladní",
                    "is_employee": "Zaměstnanec",
                    "is_technical": "Technický uživatel",
                    "is_temporary": "Brigádník",
                    "is_hr": "Personalista",
                    "is_location_manager": "Objektový manažer",
                    "is_company_manager": "Vedoucí",
                },
            },
        },
    },
}
