<template>
    <div>
        <v-simple-table
                dense
                v-for="(envelopes, location) in protocols"
                :key="location"
                class="envelope-protocol mb-2"
        >
            <tbody>
                <tr class="text-uppercase">
                    <th colspan="2">
                        <div class="title text-center py-1">{{ l('pages.envelopes.protocol.title') }}<span v-if="location !== '-'"> - {{ location }}</span></div>
                    </th>
                </tr>
                <tr class="text-uppercase">
                    <th style="width: 90% !important">
                        {{ l('pages.envelopes.protocol.columns.envelope') }}
                    </th>
                    <th class="text-right" style="width: 10% !important">
                        {{ l('pages.envelopes.protocol.columns.received') }}
                    </th>
                </tr>
                <tr
                    v-for="(envelope, index) in envelopes"
                    :key="index"
                >
                    <td>
                        {{ envelope.employee }}
                    </td>
                    <td class="text-right">
<!--                        {{ envelope.amount | amount }}&nbsp;{{ l('global.currency') }}-->
                    </td>
                </tr>
<!--
                <tr class="text-uppercase">
                    <th>
                        {{ l('global.total') }}
                    </th>
                    <th class="text-right">
                        {{ $options.filters.amount(lodash.sumBy(envelopes, envelope => envelope.amount)) }}&nbsp;{{ l('global.currency') }}
                    </th>
                </tr>
-->
                <tr class="font-weight-bold text-uppercase">
                    <td colspan="2">
                        <div class="pt-2 pb-8">
                            {{ l('pages.envelopes.protocol.notes') }}:
                        </div>
                    </td>
                </tr>
                <tr class="font-weight-bold text-uppercase">
                    <td colspan="2">
                        <div class="py-2">
                            {{ l('pages.envelopes.protocol.signature') }}:
                        </div>
                    </td>
                </tr>
            </tbody>
        </v-simple-table>
    </div>
</template>

<script>
    import _ from 'lodash'

    export default {
        name: "EnvelopesProtocolsPage",
        data: vm => ({
            lodash: _,
            protocols: [],
        }),
        methods: {
            parseResponse(response) {
                this.protocols = _.groupBy(_.map(_.get(response.data, 'items'), function(envelope) {
                    return {
                        location: _.get(envelope, 'location_name', '-'),
                        employee: _.get(envelope, 'employee_name', ''),
                        amount: _.get(envelope, 'amount', 0),
                    }
                }), envelope => envelope.location)
            },
        },
        created() {
            this.$api.get('envelopes', {params: this.$route.query})
                .then(this.parseResponse)
        },
    }
</script>

<style>
    .v-data-table.envelope-protocol
    tbody
    tr:hover:not(.v-data-table__expanded__content) {
        background: #fff;
    }
    .envelope-protocol {
        display: block;
        overflow: visible;
        position: relative !important;
        page-break-inside: auto;
        page-break-after: always;
        -webkit-region-break-inside: auto;
        -webkit-region-break-after: always;
    }

    .envelope-protocol table th,
    .envelope-protocol table td {
        border: #000 1px solid !important;
    }

    .envelope-protocol th {
        background: #ccc;
    }

</style>