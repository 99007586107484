<template>
    <item-list-view
            :headers="headers"
            create-button
    >

        <template v-slot:item.user="_">
            <router-link
                    :to="{name: 'employees.show', params: {uuid: _.value.uuid}}">
                {{ _.value | listName }}
            </router-link>
        </template>

        <template v-slot:item.processed_at="_">
            {{ _.value | euroDateShortTime }}
        </template>

        <template v-slot:extraItemActions="_">
            <tooltip-icon
                    class="ml-1"
                    :tooltip="l('pages.fund_locks.actions.' + (_.item.is_processed ? 'revert' : 'process'))"
                    @click="_.item.is_processed ? revert(_.item) : process(_.item)"
                    :color="_.item.is_processed ? 'green' : 'red'"
            >
                {{ _.item.is_processed ? 'mdi-transfer-left' : 'mdi-transfer-right' }}
            </tooltip-icon>
        </template>

        <template v-slot:filter="_">
            <v-row dense>
                <v-col cols="12" sm="6">
                    <trim-text-field
                            class="mt-3"
                            clearable
                            dense
                            hide-details
                            v-model="_.filter.text"
                            :label="l('pages.fund_locks.index.filters.text')"
                    />
                </v-col>
                <v-col cols="12" sm="3">
                    <v-select
                            class="mt-3"
                            dense
                            hide-details
                            v-model="_.filter.processed"
                            :items="processedFilterOptions || []"
                            :label="l('pages.fund_locks.index.filters.processed.title')"
                    />
                </v-col>
                <v-col cols="12" sm="3">
                    <api-select
                            api="fund-locks/creators/list"
                            class="mt-3"
                            prepend-empty
                            :empty-text="l('global.anyPerson')"
                            dense
                            hide-details
                            v-model="_.filter.created_by_id"
                            :label="l('pages.fund_locks.index.filters.created_by')"
                    />
                </v-col>
            </v-row>
        </template>

    </item-list-view>
</template>

<script>
    import TablePageMixin from '../../../mixins/Cumulative/TablePageMixin'
    import TrimTextField from "../../Shared/Forms/TextFields/TrimTextField";
    import ApiSelect from "../../Shared/Forms/ApiSelect";
    import TooltipIcon from "../../Shared/Icons/TooltipIcon";

    export default {
        name: "FundLocksIndexPage",
        components: {TooltipIcon, ApiSelect, TrimTextField},
        mixins: [
            TablePageMixin,
        ],
        data: vm => ({
            headers: [
                {
                    value: 'id',
                    align: 'end',
                    sortable: true,
                    filterable: false,
                    width: '5%',
                },
                {
                    value: 'employee_name',
                    align: 'start',
                    sortable: true,
                    filterable: false,
                },
                {
                    value: 'note',
                    align: 'start',
                    sortable: false,
                    filterable: false,
                },
                {
                    value: 'amount',
                    align: 'end',
                    sortable: true,
                    filterable: false,
                },
                {
                    value: 'created_at',
                    align: 'end',
                    sortable: true,
                    filterable: false,
                },
                {
                    value: 'created_by',
                    align: 'start',
                    sortable: false,
                    filterable: false,
                },
/*
                {
                    value: 'updated_at',
                    align: 'end',
                    sortable: true,
                    filterable: false,
                },
*/
                {
                    value: 'processed_at',
                    align: 'end',
                    sortable: true,
                    filterable: false,
                },
            ],
            customAppTitle: 'pages.fund_locks.index.title',
            processedFilterOptions: [
                {
                    text: vm.l('pages.fund_locks.index.filters.processed.values.show'),
                    value: 'show',
                },
                {
                    text: vm.l('pages.fund_locks.index.filters.processed.values.only'),
                    value: 'only',
                },
                {
                    text: vm.l('pages.fund_locks.index.filters.processed.values.hide'),
                    value: 'hide',
                },
            ],
        }),
        methods: {
            process(item) {

                this.$api.put('fund-locks/' + item.uuid + '/process')
                    .then(function () {
                        this.$api.get('fund-locks/' + item.uuid)
                            .then(function (response) {
                                item.processed_at = response.data.processed_at
                                item.is_processed = response.data.is_processed
                                item.is_editable = response.data.is_editable
                                item.is_deletable = response.data.is_deletable
                            }.bind(this))
                            .catch(error => error)
                    }.bind(this))
                    .catch(error => error)
            },
            revert(item) {

                this.$api.put('fund-locks/' + item.uuid + '/revert')
                    .then(function () {
                        this.$api.get('fund-locks/' + item.uuid)
                            .then(function (response) {
                                item.processed_at = response.data.processed_at
                                item.is_processed = response.data.is_processed
                                item.is_editable = response.data.is_editable
                                item.is_deletable = response.data.is_deletable
                            }.bind(this))
                            .catch(error => error)
                    }.bind(this))
                    .catch(error => error)
            },
        },
    }
</script>

<style scoped>

</style>