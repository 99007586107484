import EmployeesShowDetailsPage from '../../../components/Pages/employees/show/details/index'
import EmployeesShowDetailsPayslipPage from '../../../components/Pages/employees/show/details/payslip'
import EmployeesShowDocumentsPage from '../../../components/Pages/employees/show/documents'
import auth from '../../auth'

export default [
    {
        path: '/employees/:uuid/details/payslip/:term',
        name: 'employees.show.details.payslip',
        component: EmployeesShowDetailsPayslipPage,
        beforeEnter: auth.guard.user,
    },
    {
        path: '/employees/:uuid/details',
        name: 'employees.show.details',
        component: EmployeesShowDetailsPage,
        beforeEnter: auth.guard.user,
    },
    {
        path: '/employees/:uuid/documents',
        name: 'employees.show.documents',
        component: EmployeesShowDocumentsPage,
        beforeEnter: auth.guard.user,
    },
]
