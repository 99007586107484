<template>
    <locations-show-page
            v-slot:default="{ item: location, itemDisabled }"
            content-class="pt-3"
    >
        <tunnel :value="location" @input="handleLocationTunnel"/>
        <v-row>
            <v-col>
                <subcard
                        title-color="white"
                        title-class="subtitle-1 text-uppercase black--text"
                >
                    <!--
                    <v-simple-table>
                        <tbody>
                        <tr>
                            <th>{{ l('pages.locations.properties.address') }}</th>
                            <td>{{ location.address }}</td>
                        </tr>
                        </tbody>
                    </v-simple-table>
                    -->
                    <template slot="title">
                        <v-row>
                            <v-col cols="12" sm="6" md="8" lg="9" class="py-0 d-none d-sm-flex">
                                <div class="pt-1">
                                    {{ l('pages.locations.details.cards.invoice.title') }}
                                </div>
                            </v-col>
                            <v-col cols="12" sm="6" md="4" lg="3" class="py-0">
                                <v-flex>
                                    <div class="d-flex">

                                        <v-flex class="d-inline-flex">
                                            <api-select
                                                    class="mb-2"
                                                    dense
                                                    hide-details
                                                    v-if="!!termListApiUrl"
                                                    :api="termListApiUrl"
                                                    first
                                                    v-model="invoiceTerm"
                                            />
                                        </v-flex>

                                        <v-flex class="text-right">
                                            <excel-button
                                                    class="ml-2"
                                                    square
                                                    :disabled="!invoice || !invoice.data || !invoice.data.length"
                                                    :loading="exportingInvoice"
                                                    @click="exportInvoiceToExcel"
                                            />
                                        </v-flex>

                                    </div>
                                </v-flex>
                            </v-col>
                        </v-row>
                    </template>

                    <v-simple-table
                            v-if="invoice && invoice.data && invoice.data.length"
                            dense
                            class="location-invoice"
                    >
                        <thead>
                        <th></th>
                        <th
                            class="text-right px-1"
                            v-for="(day, index) in invoiceDays"
                            :key="index"
                        >{{ day }}</th>
                        <th class="text-right px-1">{{ l('pages.shifts.properties.hours') }}</th>
                        <th v-if="!invoiceSimpleMode" class="text-right px-1">{{ l('pages.shifts.properties.rate') }}</th>
                        <th v-if="!invoiceSimpleMode" class="text-center px-1">{{ l('pages.shifts.properties.is_payable') }}</th>
                        <th v-if="!invoiceSimpleMode" class="text-right px-1">{{ l('pages.shifts.properties.amount') }}</th>
                        </thead>
                        <tbody>
                        <template v-for="positionData in invoice.data">
                            <tr class="position">
                                <td :colspan="invoiceDays.length + (invoiceSimpleMode ? 2 : 5)">{{ positionData.position.name }}</td>
                            </tr>
                            <tr
                                v-for="(userData, index) in positionData.data"
                                :key="index"
                            >
                                <td class="user">{{ userData.user.list_name }}</td>
                                <td
                                    class="day text-right px-1"
                                    v-for="(day, index) in invoiceDays"
                                    :key="index"
                                >
                                    <v-tooltip top v-if="userData && userData['data'] && userData['data'][day]">
                                        <template v-slot:activator="{ on }">
                                            <span v-on="on">
                                            {{ userData['data'][day]['hours'] | euroNumber }}
                                            </span>
                                        </template>
                                        <span>
                                            {{ userData['data'][day]['date'] | euroDate }}
                                        </span>
                                    </v-tooltip>
                                </td>
                                <td class="text-right px-1">
                                    {{ userData && userData['hours'] ? $options.filters.amount(userData['hours']) :
                                    '' }}
                                </td>
                                <td v-if="!invoiceSimpleMode" class="text-right px-1">
                                    {{ userData && userData['rate'] ? $options.filters.amount(userData['rate']) : ''
                                    }}
                                </td>
                                <td v-if="!invoiceSimpleMode" class="text-center px-1">
                                    <check-mark
                                            x-small
                                            v-if="userData && userData['is_payable']"
                                            :value="userData['is_payable']"
                                    />
                                </td>
                                <td v-if="!invoiceSimpleMode" class="text-right px-1">
                                    {{ userData && userData['amount'] ? $options.filters.amount(userData['amount']) : '' }}
                                </td>
                            </tr>
                            <tr class="position-total">
                                <td :colspan="invoiceDays.length + 1">
                                    {{ l('pages.locations.details.cards.invoice.rows.position_total') }}
                                </td>
                                <td class="text-right px-1">
                                    {{ positionData && positionData['hours'] ?
                                    $options.filters.amount(positionData['hours']) : '' }}
                                </td>
                                <td v-if="!invoiceSimpleMode" class="px-1"></td>
                                <td v-if="!invoiceSimpleMode" class="px-1"></td>
                                <td v-if="!invoiceSimpleMode" class="text-right px-1">
                                    {{ positionData && positionData['amount'] ? $options.filters.amount(positionData['amount']) : '' }}
                                </td>
                            </tr>
                        </template>
                        <tr class="grand-total">
                            <td :colspan="invoiceDays.length + 1">
                                {{ l('pages.locations.details.cards.invoice.rows.grand_total') }}
                            </td>
                            <td class="text-right px-1">
                                {{ invoice && invoice['hours'] ? $options.filters.amount(invoice['hours']) : '' }}
                            </td>
                            <td v-if="!invoiceSimpleMode" class="px-1"></td>
                            <td v-if="!invoiceSimpleMode" class="px-1"></td>
                            <td v-if="!invoiceSimpleMode" class="text-right px-1">
                                {{ invoice && invoice['amount'] ? $options.filters.amount(invoice['amount']) : '' }}
                            </td>
                        </tr>
                        </tbody>
                    </v-simple-table>

                </subcard>
            </v-col>
        </v-row>
    </locations-show-page>
</template>

<script>
    import _ from 'lodash'
    import Subcard from '../../../Shared/Cards/Subcard'
    import LocationsShowPage from '../show'
    import Tunnel from "../../../Shared/Tunnel";
    import PageMixin from "../../../../mixins/Cumulative/PageMixin";
    import ServiceFunctionsMixin from "../../../../mixins/ServiceFunctionsMixin";
    import ApiSelect from "../../../Shared/Forms/ApiSelect";
    import CheckMark from "../../../Shared/Icons/CheckMark";
    import ExcelButton from "../../../Shared/Buttons/ExcelButton";

    export default {
        name: 'LocationsShowDetailsPage',
        components: {
            ExcelButton,
            CheckMark,
            ApiSelect,
            Tunnel,
            Subcard,
            LocationsShowPage,
        },
        mixins: [
            PageMixin,
            ServiceFunctionsMixin,
        ],
        data: vm => ({
            lodash: _,
            exportingInvoice: false,
            invoiceDays: [],
            invoice: null,
            invoiceApiUrl: null,
            invoiceSimpleMode: true,
            invoiceTerm: null,
            location: null,
            locationApiUrl: null,
            termListApiUrl: null,
        }),
        watch: {
            invoiceApiUrl: {
                immediate: true,
                handler() {
                    this.refreshInvoice()
                },
            },
            invoiceTerm: {
                immediate: true,
                handler() {
                    this.updateInvoiceApiUrl()
                    this.updateDays()
                },
            },
            invoiceSimpleMode() {
                this.updateInvoiceApiUrl()
            },
            'location.uuid': {
                immediate: true,
                handler() {
                    this.updateLocationApiUrl()
                },
            },
            locationApiUrl: {
                immediate: true,
                handler() {
                    this.updateTermListApiUrl()
                    this.updateInvoiceApiUrl()
                },
            },
        },
        methods: {
            exportInvoiceToExcel() {
                this.exportingInvoice = true
                this.requestInvoiceExport()
                    .catch(error => error)
                    .finally(function () {
                        this.exportingInvoice = false
                    }.bind(this))
            },
            handleLocationTunnel(location) {
                this.location = location
            },
            fetchInvoice() {
                const invoiceApiUrl = this.invoiceApiUrl
                return new Promise(function (resolve, reject) {
                    if (!!invoiceApiUrl) {
                        this.$api.get(invoiceApiUrl)
                            .then(response => resolve(response))
                            .catch(error => reject(error))
                    } else {
                        reject()
                    }
                }.bind(this))
            },
            refreshInvoice() {
                this.invoice = null
                this.fetchInvoice()
                    .then(function (response) {
                        this.invoice = response.data
                    }.bind(this))
                    .catch(error => error)
            },
            requestInvoiceExport() {
                const invoiceApiUrl = this.invoiceApiUrl
                return new Promise(function (resolve, reject) {
                    if (!!invoiceApiUrl) {
                        this.$api.post(invoiceApiUrl + '/excel')
                            .then(response => resolve(response))
                            .catch(error => reject(error))
                    } else {
                        reject()
                    }
                }.bind(this))
            },
            updateDays() {
                const invoiceTerm = this.invoiceTerm
                if (!!invoiceTerm) {
                    const invoiceDays = parseInt(this.$moment(invoiceTerm + '01', 'YYYYMMDD').endOf('month').format('DD'))
                    this.invoiceDays = _.map(_.keys(Array(invoiceDays)), day => (parseInt(day) + 1))
                } else {
                    this.invoiceDays = []
                }
            },
            updateInvoiceApiUrl() {
                let invoiceApiUrl = null
                const locationApiUrl = this.locationApiUrl
                if (!!locationApiUrl) {
                    invoiceApiUrl = locationApiUrl + '/invoice/' + (this.invoiceSimpleMode ? 'simple/' : '')
                    const invoiceTerm = this.invoiceTerm
                    if (!!invoiceTerm) {
                        invoiceApiUrl = invoiceApiUrl + invoiceTerm
                    }
                }
                this.invoiceApiUrl = invoiceApiUrl
            },
            updateLocationApiUrl() {
                const locationUuid = _.get(this.location, 'uuid', null)
                this.locationApiUrl = !!locationUuid ? ('locations/' + locationUuid) : null
            },
            updateTermListApiUrl() {
                this.termListApiUrl = !!this.locationApiUrl ? (this.locationApiUrl + '/historical/terms/list') : null
            },
        },
    }
</script>

<style>
    .location-invoice tbody tr.position td {
        background-color: rgb(236, 239, 241);
        font-weight: bold;
        padding-top: .25em;
        padding-bottom: .25em;
    }

    .location-invoice tbody tr td.user, .location-invoice tbody tr td.day {
        border-right: rgb(216, 219, 221) 1px solid;
    }

    .location-invoice tbody tr.position-total td {
        font-weight: bold;
        padding-top: .25em;
        padding-bottom: .75em
    }

    .location-invoice tbody tr.grand-total td {
        background-color: rgb(216, 219, 221);
        font-weight: bold;
        text-transform: uppercase;
    }
</style>