import _ from 'lodash'

export default {
    methods: {

        /**
         * Clone variable
         *
         * @param value
         * @returns {*} Cloned variable
         */
        clone(value) {
            return _.isArray(value) || _.isObject(value)
                ? JSON.parse(JSON.stringify(value))
                : value
        },

        /**
         * Check if url matches the pattern {entity-slug}/{uuid} with or without leading and/or trailing slashes
         *
         * @param {string} url
         * @returns {boolean}
         */
        isApiUrlValid: url => !!url.match(/^\/?[a-z]+(?:-[a-z0-9]+)*\/[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}\/?$/),

        /**
         * Quickly check items for equality using their serialized representations
         *
         * @param item1
         * @param item2
         * @returns {boolean}
         */
        itemsEqual(item1, item2) {
            const type = typeof item1
            switch (true) {
                case type !== (typeof item2):
                    return false
                case type === 'object':
                    return !JSON.stringify(item1).localeCompare(JSON.stringify(item2))
                default:
                    return item1 === item2
            }
        },

        /**
         * Recursively check two objects for equality
         *
         * @param object1
         * @param object2
         * @returns {boolean}
         */
        objectsEqual(object1, object2) {
            if (_.isObject(object1) && _.isObject(object2)) {
                const keys = _.uniq(_.merge(_.keys(object1), _.keys(object2)))
                return _.filter(keys, function (key) {
                    if (undefined !== object1[key] && undefined !== object2[key] && (typeof object1[key]) === (typeof object2[key])) {
                        switch (true) {
                            case _.isArray(object1[key]):
                                return this.arraysEqual(object1[key], object2[key])
                            case _.isObject(object1[key]):
                                return this.objectsEqual(object1[key], object2[key])
                            case _.isString(object1[key]):
                                return this.stringsEqual(object1[key], object2[key])
                            default:
                                return (object1[key] === object2[key])
                        }
                    }
                    return false
                }.bind(this)).length === keys.length
            }
            return false
        },

        arraysEqual(array1, array2) {
            array1 = this.clone(array1)
            array2 = this.clone(array2)
            switch (true) {
                case !_.isArray(array1) || !_.isArray(array2):
                case array1.length !== array2.length:
                    return false
                default:
                    for (let offset = 0; offset < array1.length; offset++) {
                        if (_.isArray(array1[offset]) && !this.arraysEqual(array1[offset], array2[offset])
                            || _.isObject(array1[offset]) && !this.objectsEqual(array1[offset], array2[offset])
                            || _.isString(array1[offset]) && !this.stringsEqual(array1[offset], array2[offset])
                            || ((array1[offset] !== array2[offset]) && !_.isArray(array1[offset]) && _.isObject(array1[offset]) && _.isString(array1[offset]))
                        ) {
                            return false
                        }
                    }
            }
            return true
        },

        stringsEqual(string1, string2) {
            switch (true) {
                case !_.isString(string1) || !_.isString(string2):
                case string1.length !== string2.length:
                    return false
                case !string1.match(/^[\d-]*$/):
                    return (string1.localeCompare(string2) === 0)
                default:
                    return string1 === string2
            }
        },

    },
}