<template>
    <tooltip-icon
            :color="color"
            v-bind="$attrs"
            v-on="$listeners"
            :tooltip="tooltip"
    >
        mdi-close
    </tooltip-icon>
</template>

<script>
    import TooltipIcon from './TooltipIcon'

    const
        defaultColor = 'red',
        defaultTooltip = 'pages.all.actions.delete'

    export default {
        name: "DeleteIcon",
        inheritAttrs: false,
        components: {
            TooltipIcon
        },
        props: {
            color: {
                required: false,
                type: String,
                default: defaultColor,
            },
            tooltip: {
                required: false,
                default: defaultTooltip,
            },
        },
        computed: {
            className() {
                return this.$el ? this.$el.className : ''
            },
            elementStyle() {
                return this.$el ? this.$el.style.cssText : ''
            },
        },
    }
</script>
