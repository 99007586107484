<template>
    <number-field
            abs
            :autocomplete="autocomplete"
            v-bind="attrs"
            v-on="listeners"
            v-model="model"
            class="hours-field"
            :label="label || l('forms.hoursField.label')"
            :placeholder="null !== placeholder ? placeholder : l('forms.hoursField.placeholder')"
            :rules="[rules.required, rules.invalid]"
    />
</template>

<script>
    import _ from 'lodash'
    import NumberField from './NumberField'

    export default {
        name: "HoursField",
        inheritAttrs: false,
        components: {
            NumberField,
        },
        props: {
            autocomplete: {
                required: false,
                default: 'autocomplete',
            },
            label: {
                type: String,
                required: false,
                default: '',
            },
            placeholder: {
                type: String,
                required: false,
                default: null,
            },
            required: {
                type: Boolean,
                required: false,
                default: false,
            },
            value: {
                required: false,
                default: '',
            },
        },
        data: vm => ({
            model: '',
            rules: {
                invalid: value => {
                    try {
                        value = (null === value) ? '' : value.toString().trim().replace(',', '.')
                        if (!value.length) {
                            return true
                        } else {
                            value = parseFloat(value)
                            if (!isNaN(value) && value <= 24) {
                                return true
                            }
                        }
                    } catch (e) {
                        //
                    }
                    return vm.l('forms.hoursField.invalid')
                },
                required: value => (!vm.required || null !== value && '' !== value.toString().trim()) || vm.l('forms.hoursField.required'),
            },
        }),
        watch: {
            value: {
                immediate: true,
                handler(value) {
                    this.model = value
                },
            },
            model(value) {
                if (value !== this.value) {
                    this.$emit('input', isNaN(value) ? null : value)
                }
            },
        },
        computed: {
            attrs() {
                return _.omit(this.$attrs, ['abs', 'label', 'placeholder', 'required', 'value'])
            },
            listeners() {
                return _.omit(this.$listeners, 'input')
            },
        },
    }
</script>

