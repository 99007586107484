<template>
    <v-container fill-height>
        <v-layout row wrap align-center>
            <v-flex>
                <v-form @submit.prevent="login">
                    <v-card
                            max-width="320"
                            class="mx-auto"
                    >
                        <v-card-title>
                            {{ appTitle }}
                        </v-card-title>
                        <v-card-text>
                            <v-text-field
                                    v-model="user.username"
                                    name="username"
                                    :label="l('auth.form.fields.username')"
                                    required
                            ></v-text-field>
                            <v-text-field
                                    v-model="user.password"
                                    type="password"
                                    name="password"
                                    :label="l('auth.form.fields.password')"
                            ></v-text-field>
                        </v-card-text>
                        <v-card-actions class="pb-5">
                            <v-btn dark color="light-blue darken-1"
                                   class="mx-auto"
                                   type="submit"
                            >
                                {{ l('auth.actions.login') }}
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-form>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
    import {USER_REQUEST_LOGIN} from "../../store/actions/user";
    import router from "../../router";
    import PageMixin from '../../mixins/Cumulative/PageMixin'
    import {APP_WIPE_TARGET_URL} from "../../store/mutations/app";

    export default {
        name: "LoginPage",
        mixins: [
            PageMixin,
        ],
        data: () => ({
            customAppTitle: 'auth.form.heading',
            user: {
                username: '',
                password: '',
            },
        }),
        methods: {
            login() {
                const {username, password} = this.user
                this.$store.dispatch(USER_REQUEST_LOGIN, {username, password})
                    .then(() => {
                        const targetUrl = this.$store.getters.appTargetUrl
                        this.$store.commit(APP_WIPE_TARGET_URL)
                        router.push(targetUrl || '/')
                    })
                    .catch(error => error)
            }
        },
    }
</script>

<style scoped>

</style>