import auth from '../auth'
import FundLocksIndexPage from '../../components/Pages/fund_locks/index'
import FundLocksShowPage from '../../components/Pages/fund_locks/show'
import FundLocksEditPage from '../../components/Pages/fund_locks/edit'
import FundLocksCreatePage from '../../components/Pages/fund_locks/create'

export default [
    {
        path: '/fund-locks/create',
        name: 'fund_locks.create',
        component: FundLocksCreatePage,
        beforeEnter: auth.guard.user,
    },
    {
        path: '/fund-locks/:uuid/edit',
        name: 'fund_locks.edit',
        component: FundLocksEditPage,
        beforeEnter: auth.guard.user
    },
    {
        path: '/fund-locks/:uuid',
        name: 'fund_locks.show',
        component: FundLocksShowPage,
        beforeEnter: auth.guard.user,
    },
    {
        path: '/fund-locks',
        name: 'fund_locks.index',
        component: FundLocksIndexPage,
        beforeEnter: auth.guard.user,
    },
]