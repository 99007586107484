<template>
    <item-edit-view v-slot:default="_">
        <user-edit-form :disabled="_.itemDisabled" v-model="_.item"></user-edit-form>
    </item-edit-view>
</template>

<script>
    import ItemEditView from '../../Item/Views/ItemEditView'
    import UserEditForm from './Shared/UserEditForm'

    export default {
        name: 'UsersEditPage',
        components: {
            ItemEditView,
            UserEditForm,
        },
    }
</script>
