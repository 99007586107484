import auth from '../auth'
import TransactionsIndexPage from '../../components/Pages/transactions/index'
import TransactionsShowPage from '../../components/Pages/transactions/show'
import TransactionsEditPage from '../../components/Pages/transactions/edit'
import TransactionsCreatePage from '../../components/Pages/transactions/create'

export default [
    {
        path: '/transactions/create',
        name: 'transactions.create',
        component: TransactionsCreatePage,
        beforeEnter: auth.guard.user,
    },
    {
        path: '/transactions/:uuid/edit',
        name: 'transactions.edit',
        component: TransactionsEditPage,
        beforeEnter: auth.guard.user
    },
    {
        path: '/transactions/:uuid',
        name: 'transactions.show',
        component: TransactionsShowPage,
        beforeEnter: auth.guard.user,
    },
    {
        path: '/transactions',
        name: 'transactions.index',
        component: TransactionsIndexPage,
        beforeEnter: auth.guard.user,
    },
]