<template>
    <item-create-view v-slot:default="_">
        <cashbox-edit-form
                v-model="_.item"
                :disabled="_.itemDisabled"
        />
    </item-create-view>
</template>

<script>
    import ItemCreateView from '../../Item/Views/ItemCreateView'
    import CashboxEditForm from './Shared/CashboxEditForm'

    export default {
        name: 'CashboxesCreatePage',
        components: {
            ItemCreateView,
            CashboxEditForm,
        },
    }
</script>
