<template>
    <v-app>
        <vue-headful :title="appTitle || appName"/>
        <div>
            <div v-if="wrapped">
            <span v-if="userAuthenticated">
                <v-navigation-drawer
                        app
                        clipped
                        v-model="drawer"
                >
                    <sidebar/>
                </v-navigation-drawer>

                <v-app-bar
                        app clipped-left
                        dark dense
                        hide-on-scroll
                        color="light-blue darken-1"
                >
                    <v-app-bar-nav-icon @click.stop="drawer = !drawer" class="d-lg-none"></v-app-bar-nav-icon>
                    <v-toolbar-title class="headline text-uppercase">
                        <span>{{ appTitle }}</span>
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-menu>
                        <template v-slot:activator="{ on }">
                            <span v-on="on">
                                <v-btn icon transparent class="d-inline-flex d-sm-none">
                                    <v-icon>mdi-account</v-icon>
                                </v-btn>
                                <v-btn outlined class="d-none d-sm-inline-flex">
                                    <v-icon>mdi-account</v-icon>
                                    <span>{{ shortUserName }}</span>
                                </v-btn>
                            </span>
                        </template>

                        <v-list right top>
                            <v-list-item to="/my/dashboard">
                                <v-list-item-action>
                                    <v-icon>mdi-view-dashboard</v-icon>
                                </v-list-item-action>
                                <v-list-item-content>
                                    <v-list-item-title>{{ l('pages.my.dashboard.title') }}</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item @click="logout">
                                <v-list-item-action>
                                    <v-icon>mdi-logout</v-icon>
                                </v-list-item-action>
                                <v-list-item-content>
                                    <v-list-item-title>{{ l('auth.actions.logout') }}</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                      </v-menu>
                </v-app-bar>
            </span>

                <v-main class="mb-5">
                    <v-container fluid fill-height>
                        <v-layout row wrap :align="userAuthenticated ? 'start' : 'center'">
                            <v-flex pa-5 pt-2>
                                <router-view></router-view>
                            </v-flex>
                        </v-layout>
                    </v-container>
                </v-main>

                <notifications position="bottom right"></notifications>
            </div>
            <div v-else>
                <span v-if="userAuthenticated">
                    <router-view></router-view>
                </span>
            </div>
        </div>
    </v-app>
</template>

<style>
    @import 'assets/css/overrides.css?20230719';
    @import 'assets/css/notifications.css';
</style>

<script>
    import vueHeadful from 'vue-headful'
    import t from 'typy'
    import store from './store'
    import PageMixin from './mixins/Cumulative/PageMixin'
    import Sidebar from './components/Layout/Sidebar'
    import {APP_REFRESH_CONFIG} from './store/actions/app'

    export default {
        name: 'App',
        mixins: [
            PageMixin,
        ],
        components: {
            'vue-headful': vueHeadful,
            Sidebar,
        },
        data: (vm) => ({
            drawer: null,
            shortUserName: vm.getDefaultShortUserName(),
        }),
        computed: {
            appName: () => store.getters.appName,
        },
        methods: {
            getDefaultShortUserName() {
                return this.l('auth.user.guest')
            },
            getShortUserName(user) {
                const userFirstName = t(user, 'first_name').safeString
                const userLastName = t(user, 'last_name').safeString
                const shortUserName = (userFirstName + ' ' + userLastName.substr(0, 1)).trim()
                return shortUserName || this.getDefaultShortUserName()
            },
        },
        watch: {
            userProfile(user) {
                this.shortUserName = this.getShortUserName(user)
            }
        },
        beforeCreate() {
            this.$store.dispatch(APP_REFRESH_CONFIG)
        },
    };
</script>
