<template>
  <v-card>
    <v-card-title v-if="$slots.title" :class="titleClassString">
      <slot name="title"/>
    </v-card-title>
    <v-tabs v-if="!!tabs && tabs.length > 0">
      <v-tab
          v-for="(tab, index) in tabs" :to="tab.route || ''"
          :key="index"
      >
        {{ l(tab.text) }}
      </v-tab>
    </v-tabs>
    <div v-if="$slots.panel" :class="panelClass + ' ' + panelColor">
      <slot name="panel"/>
    </div>
    <v-card-text v-if="$slots.default" :class="contentClass + ' ' + contentColor">
      <slot/>
    </v-card-text>
    <v-card-actions v-if="$slots.actions" :class="actionsClass + ' ' + actionsColor">
      <slot name="actions"></slot>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: "BaseCard",
  props: {
    actionsClass: {
      required: false,
      type: String,
      default: '',
    },
    actionsColor: {
      required: false,
      type: String,
      default: '',
    },
    contentClass: {
      required: false,
      type: String,
      default: '',
    },
    contentColor: {
      required: false,
      type: String,
      default: '',
    },
    panelClass: {
      required: false,
      type: String,
      default: '',
    },
    panelColor: {
      required: false,
      type: String,
      default: '',
    },
    tabs: {
      required: false,
      type: Array,
      default: () => [],
    },
    titleClass: {
      required: false,
      type: String,
      default: '',
    },
    titleColor: {
      required: false,
      type: String,
      default: '',
    },
  },
  computed: {
    titleClassString() {
      return this.titleClass + ' ' + this.titleColor + ' ' + (!this.tabs.length ? 'mb-2' : '');
    },
  }
}
</script>

<style>

</style>
