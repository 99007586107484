import auth from '../../auth'
import LocationsIndexPage from '../../../components/Pages/locations/index'
import LocationsEditPage from '../../../components/Pages/locations/edit'
import LocationsCreatePage from '../../../components/Pages/locations/create'

export default [
    {
        path: '/locations/create',
        name: 'locations.create',
        component: LocationsCreatePage,
        beforeEnter: auth.guard.user,
    },
    {
        path: '/locations/:uuid/edit',
        name: 'locations.edit',
        component: LocationsEditPage,
        beforeEnter: auth.guard.user
    },
    {
        path: '/locations/:uuid',
        name: 'locations.show',
        redirect: 'locations/:uuid/details',
        beforeEnter: auth.guard.user,
    },
    {
        path: '/locations',
        name: 'locations.index',
        component: LocationsIndexPage,
        beforeEnter: auth.guard.user,
    },
]