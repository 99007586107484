<template>
    <tooltip-icon
            :tooltip="tooltip"
            :color="color"
            v-bind="$attrs"
            v-on="$listeners"
    >
        {{ icon }}
    </tooltip-icon>
</template>

<script>

    import TooltipIcon from './TooltipIcon'

    const genders = [
        'male',
        'female',
        'unknown',
    ]

    const colors = {
        'male': 'blue',
        'female': 'pink lighten-2',
        'unknown': 'grey',
    }

    const icons = {
        'male': 'mdi-gender-male',
        'female': 'mdi-gender-female',
        'unknown': 'mdi-minus',
    }

    export default {
        name: "GenderIcon",
        components: {
            TooltipIcon,
        },
        props: {
            value: {
                required: false,
                type: String,
                default: '',
            },
        },
        data: () => ({
            icon: '',
            color: '',
            tooltip: '',
        }),
        computed: {
            gender() {
                return this.getGender()
            },
        },
        methods: {
            getGender() {
                let gender = ''

                if (this.value) {
                    gender = this.value
                } else if (this.$slots.default) {
                    gender = this.$slots.default[0].text
                }

                gender = gender.trim().toLowerCase()

                if (!~genders.indexOf(gender)) {
                    gender = 'unknown'
                }

                return gender
            },
            getIcon() {
                return icons[this.gender]
            },
            getColor() {
                return colors[this.gender]
            },
            getTooltip() {
                return this.l('global.gender.' + this.gender)
            }
        },
        watch: {
            gender: {
                immediate: true,
                handler (value) {
                    this.icon = this.getIcon()
                    this.color = this.getColor()
                    this.tooltip = this.getTooltip()
                },
            }
        },
    }
</script>

