<template>
    <v-form>
        <v-row>
            <v-col cols="9">
                <v-row>
                    <v-col>
                        <subcard>
                            <v-row>
                                <v-col cols="12" md="4">
                                    <v-text-field
                                            v-model="user.first_name"
                                            :label="l('pages.users.properties.first_name')"
                                            required
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" md="4">
                                    <v-text-field
                                            v-model="user.last_name"
                                            :label="l('pages.users.properties.last_name')"
                                            required
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" md="4">
                                    <password-field
                                            autocomplete="off"
                                            v-model="user.password"
                                            visible
                                    />
                                </v-col>
                            </v-row>
                        </subcard>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="py-0">
                        <subcard title-class="subtitle-1 text-uppercase white--text pt-2 pb-1 blue-grey lighten-1">
                            <template slot="title">
                                {{ l('pages.users.properties.flags') }}
                            </template>
                            <v-row>
                                <v-col
                                    class="py-1" cols="12" sm="6" md="4" lg="3" xl="2"
                                    v-for="(flag, index) in supportedFlags"
                                    :key="index"
                                >
                                    <v-checkbox
                                            v-model="userFlags[flag.value]"
                                            hide-details
                                            :label="flag.text"
                                            @change="updateUserFlagValue"
                                    />
                                </v-col>
                            </v-row>
                        </subcard>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col
                            v-if="showLocationList"
                            cols="12"
                            :md="showCashboxList ? 8 : 12"
                    >
                        <subcard title-class="subtitle-1 text-uppercase white--text pt-2 pb-1 blue-grey lighten-1">
                            <template slot="title">
                                {{ l('pages.locations.index.title') }}
                            </template>
                            <v-row>
                                <v-col
                                        class="py-1"
                                        cols="12"
                                        sm="12"
                                        :md="showCashboxList ? 12 : 4"
                                        :lg="showCashboxList ? 6 : 3"
                                        :xl="showCashboxList ? 4 : 2"
                                        v-for="(location, index) in availableLocations"
                                        :key="index"
                                >
                                    <v-checkbox
                                            v-model="userLocations[location.value]"
                                            hide-details
                                            :label="location.text"
                                            @change="updateUserLocationIdsValue"
                                    />
                                </v-col>
                            </v-row>
                        </subcard>
                    </v-col>
                    <v-col
                            v-if="showCashboxList"
                            cols="12"
                            :md="showLocationList ? 4 : 12"
                    >
                        <subcard title-class="subtitle-1 text-uppercase white--text pt-2 pb-1 blue-grey lighten-1">
                            <template slot="title">
                                {{ l('pages.cashboxes.index.title') }}
                            </template>
                            <v-row>
                                <v-col
                                        class="py-1"
                                        cols="12"
                                        sm="12"
                                        :md="showLocationList ? 12 : 4"
                                        :lg="showLocationList ? 12 : 3"
                                        :xl="showLocationList ? 12 : 2"
                                        v-for="(cashbox, index) in availableCashboxes"
                                        :key="index"
                                >
                                    <v-checkbox
                                            v-model="userCashboxes[cashbox.value]"
                                            hide-details
                                            :label="cashbox.text"
                                            @change="updateUserCashboxIdsValue"
                                    />
                                </v-col>
                            </v-row>
                        </subcard>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="12" md="3">
                <subcard>
                    <template slot="title">
                        <div>
                            {{ l('forms.phoneList.title') }}
                        </div>
                    </template>
                    <phone-list dense confirmable v-model="user.phones"></phone-list>
                </subcard>
                <subcard>
                    <template slot="title">
                        <div>
                            {{ l('forms.emailList.title') }}
                        </div>
                    </template>
                    <email-list dense confirmable v-model="user.emails"></email-list>
                </subcard>
            </v-col>
        </v-row>
    </v-form>
</template>

<script>
    import PasswordField from '../../../Shared/Forms/TextFields/PasswordField'
    import PhoneList from '../../../Shared/Forms/UserRelated/PhoneList'
    import EmailList from '../../../Shared/Forms/UserRelated/EmailList'
    import Subcard from '../../../Shared/Cards/Subcard'
    import _ from 'lodash'
    import t from 'typy'
    import ServiceFunctionsMixin from "../../../../mixins/ServiceFunctionsMixin";

    const defaultUserModel = {
        first_name: '',
        last_name: '',
        is_employee: true,
        password: '',
        phones: [],
        emails: [],
    }

    export default {
        name: 'UsersEditForm',
        mixins: [
            ServiceFunctionsMixin,
        ],
        components: {
            PasswordField,
            PhoneList,
            EmailList,
            Subcard,
        },
        props: {
            value: {
                required: false,
                type: Object,
                default: () => defaultUserModel,
            },
        },
        data: vm => ({
            availableCashboxes: [],
            availableLocations: [],
            showCashboxList: false,
            showLocationList: false,
            user: {},
            userCashboxes: {},
            userFlags: {},
            userLocations: {},
            supportedFlags: [],
        }),
        watch: {
            availableCashboxes(cashboxes) {
                const currentUserCashboxes = this.clone(_.get(this.user, 'cashbox_ids', []) || [])
                _.each(cashboxes, function(cashbox) {
                    this.userCashboxes[cashbox.value] = (currentUserCashboxes.indexOf(cashbox.value) !== -1)
                }.bind(this))
            },
            supportedFlags(flags) {
                const currentUserFlags = this.clone(_.get(this.user, 'flags', []) || [])
                _.each(flags, function(flag) {
                    this.userFlags[flag.value] = (currentUserFlags.indexOf(flag.value) !== -1)
                    this.updateUserFlagValue()
                }.bind(this))
            },
            availableLocations(locations) {
                const currentUserLocations = this.clone(_.get(this.user, 'location_ids', []) || [])
                _.each(locations, function(location) {
                    this.userLocations[location.value] = (currentUserLocations.indexOf(location.value) !== -1)
                }.bind(this))
            },
            user: {
                deep: true,
                handler(user) {
                    this.$emit('input', user)
                },
            },
            'user.cashbox_ids': {
                deep: true,
                handler(cashboxes) {
                    _.each(cashboxes, function (cashbox) {
                        this.userCashboxes[cashbox] = true
                    }.bind(this))
                },
            },
            'user.flags': {
                deep: true,
                handler(flags) {
                    _.each(flags, function (flag) {
                        this.userFlags[flag] = true
                    }.bind(this))
                },
            },
            'user.location_ids': {
                deep: true,
                handler(locations) {
                    _.each(locations, function (location) {
                        this.userLocations[location] = true
                    }.bind(this))
                },
            },
            value: {
                deep: true,
                immediate: true,
                handler(user) {
                    this.user = user
                },
            },
        },
        methods: {
            updateUserCashboxIdsValue() {
                if (!t(this.user, 'id').isDefined || t(this.user, 'cashbox_ids').isDefined) {
                    let cashboxes = []
                    _.forEach(this.userCashboxes, function(value, cashbox) {
                        if (value) {
                            cashboxes.push(cashbox)
                        }
                    }.bind(this))
                    this.user['cashbox_ids'] = cashboxes
                }
            },
            updateUserFlagValue() {
                if (!t(this.user, 'id').isDefined || t(this.user, 'flags').isDefined) {
                    let flags = []
                    _.forEach(this.userFlags, function(value, flag) {

                        if (flag === 'is_location_manager') {
                            this.showLocationList = value
                        } else if (flag === 'is_cashier') {
                            this.showCashboxList = value
                        }

                        if (value) {
                            flags.push(flag)
                        }
                    }.bind(this))
                    this.user['flags'] = flags
                }
            },
            updateUserLocationIdsValue() {
                if (!t(this.user, 'id').isDefined || t(this.user, 'location_ids').isDefined) {
                    let locations = []
                    _.forEach(this.userLocations, function(value, location) {
                        if (value) {
                            locations.push(location)
                        }
                    }.bind(this))
                    this.user['location_ids'] = locations
                }
            },
        },
        beforeCreate() {
            this.$api.get('users/flags/list')
                .then(function(response) {
                    this.supportedFlags = _.sortBy(response.data, flag => flag.text)
                }.bind(this))
            this.$api.get('cashboxes/list')
                .then(function(response) {
                    this.availableCashboxes = _.sortBy(response.data, cashbox => cashbox.text)
                }.bind(this))
            this.$api.get('locations/list')
                .then(function(response) {
                    this.availableLocations = _.sortBy(response.data, location => location.text)
                }.bind(this))
        },
    }
</script>

