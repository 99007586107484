<template>
    <envelopes-show-page v-slot:default="_">
        <div v-if="lodash.get(_, 'item.uuid', false)">
            <v-row>
                <v-col>
                    <envelope-slip :envelope="_.item"/>
                </v-col>
            </v-row>
            <v-row>
                <v-col class="text-center">
                    <v-btn
                            dark
                            depressed
                            color="grey darken-2"
                            class="ml-1"
                            target="_blank"
                            :to="{name: 'envelopes.show.face', params: {uuid: lodash.get(_.item, 'uuid', '')}, query: {stripped: 1}}"
                    >
                        {{ l('pages.envelopes.actions.show_face') }}
                    </v-btn>
                    <v-btn
                            dark
                            depressed
                            color="grey darken-2"
                            class="ml-1"
                            target="_blank"
                            :to="{name: 'envelopes.show.slip', params: {uuid: lodash.get(_.item, 'uuid', '')}, query: {stripped: 1}}"
                    >
                        {{ l('pages.envelopes.actions.show_slip') }}
                    </v-btn>
                </v-col>
            </v-row>
        </div>
    </envelopes-show-page>
</template>

<script>
    import EnvelopesShowPage from "../show";
    import Amount from "../../../Shared/Text/Amount";
    import EnvelopeSlip from "../../../Shared/ModelSpecific/Envelope/EnvelopeSlip";
    import _ from 'lodash'

    export default {
        name: "EnvelopesShowDetailsPage",
        components: {
            EnvelopeSlip,
            EnvelopesShowPage,
            Amount
        },
        data: vm => ({
            lodash: _,
        }),

    }
</script>

<style scoped>

</style>