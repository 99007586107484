<template>
    <item-create-view v-slot:default="_">
        <fund-lock-edit-form :disabled="_.itemDisabled" v-model="_.item" />
    </item-create-view>
</template>

<script>
    import FundLockEditForm from './Shared/FundLockEditForm'
    import ItemCreateView from '../../Item/Views/ItemCreateView'

    export default {
        name: 'FundLockCreatePage',
        inheritAttrs: false,
        components: {
            ItemCreateView,
            FundLockEditForm,
        },
    }
</script>

<style scoped>

</style>