<template>
    <locations-show-page parent v-slot:default="_parent">
        <sub-item-show-view v-slot:default="_">
            <location-position-edit-form :disabled="_.itemDisabled" v-model="_.item" />
        </sub-item-show-view>
    </locations-show-page>
</template>

<script>
    import LocationsShowPage from '../../show'
    import SubItemShowView from '../../../../Item/Views/SubItemShowView'
    import LocationPositionEditForm from './Shared/LocationPositionEditForm'

    export default {
        name: 'LocationsShowPositionsCreatePage',
        inheritAttrs: false,
        components: {
            LocationPositionEditForm,
            LocationsShowPage,
            SubItemShowView,
        },
    }
</script>

<style scoped>

</style>