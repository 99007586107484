<template>
    <div>
        <v-data-table
                v-if="!hidden"
                v-bind="tableAttributes"
                v-on="tableListeners"
                class="item-list"
                :dense="tableIsDense"
                :headers="tableHeaders"
                :footer-props="footerProps"
                :items="tableItems"
                :loading="tableLoading"
                :options.sync="tableOptions"
                :server-items-length="tableItemCount"
        >

            <!-- TABLE DATA FILTER PLACEHOLDER -->
            <template v-slot:top="_" v-if="$scopedSlots.filter">
                <div class="item-list__filter blue-grey lighten-5 mb-5">
                    <complex-filter v-model="tableFilter" class="py-3">
                        <slot
                                name="filter"
                                :filter="tableFilter"
                                :loading="tableLoading"
                        />
                    </complex-filter>
                </div>
            </template>

            <!-- TABLE ACTION HEADER -->
            <template v-slot:header.actions="_">

                <slot
                        name="header.actions"
                        v-bind="_"
                        v-bind:params="tableParams"
                />

                <!-- EXCEL BUTTON -->
                <excel-button
                        square
                        v-if="excelButton"
                        @click="exportToExcel()"
                        :disabled="tableLoading"
                        :loading="exportDisabled"
                >
                    {{ l('pages.all.actions.excel') }}
                </excel-button>

            </template>

            <!-- ROW ACTION BUTTONS -->
            <template v-slot:item.actions="_">

                <div class="text-no-wrap">

                    <slot
                            name="extraItemActions"
                            v-bind="_"
                            v-bind:dense="tableIsDense"
                            v-bind:params="tableParams"
                    />

                    <show-icon
                            v-if="!noShow"
                            class="ml-1"
                            @click="showButtonClicked(_.item)"
                    />

                    <edit-icon
                            class="ml-1"
                            v-if="!noEdit && _.item.is_editable"
                            @click="editButtonClicked(_.item)"
                    />

                    <delete-icon
                            class="ml-1"
                            v-if="!noDelete && _.item.is_deletable"
                            @click.stop="deleteButtonClicked(_.item)"
                    />

                </div>
            </template>


            <!-- DEFAULT COLUMN TEMPLATES (MAY BE OVERRIDDEN) -->

            <template
                v-for="nameProp in ['item.name', 'item.list_name', 'item.id']"
                v-slot:[nameProp]="_"
            >
                <router-link v-if="_.item.uuid || _.item.id" :to="$route.path + '/' + (_.item.uuid || _.item.id)">
                    {{ _.value }}
                </router-link>
            </template>

            <template
                    v-for="relProp in ['item.adjustment', 'item.assignment', 'item.cash_operation', 'item.cashbox', 'item.fund_lock', 'item.location', 'item.shift', 'item.transaction', 'item.unit', 'item.user']"
                    v-slot:[relProp]="_"
            >
                <router-link
                        v-if="!!_.value"
                        :to="{name: lodash(lodash.kebabCase(relProp.substr(5))).pluralize() + '.show', params: {uuid: _.value.uuid}}"
                >
                    {{ _.value | listName }}
                </router-link>
            </template>

            <template v-slot:item.employee_name="_">
                <router-link
                        v-if="!!_.item.user"
                        :to="{name: 'employees.show', params: {uuid: lodash.get(_.item.user, 'uuid')}}"
                >
                    {{ _.value }}
                </router-link>
                <span v-else>
                    {{ _.value }}
                </span>
            </template>

            <template v-slot:item.location_name="_">
                <router-link
                        v-if="!!_.item.location"
                        :to="{name: 'locations.show', params: {uuid: lodash.get(_.item.location, 'uuid')}}"
                >
                    {{ _.value }}
                </router-link>
                <span v-else>
                    {{ _.value }}
                </span>
            </template>

            <template
                    v-for="authorProp in ['item.created_by', 'item.createdBy', 'item.deleted_by', 'item.deletedBy', 'item.updated_by', 'item.updatedBy']"
                    v-slot:[authorProp]="_"
            >{{ _.value | lastName }}</template>

            <template v-slot:item.position="_">
                <router-link
                        v-if="!!_.value"
                        :to="{name: 'positions.show', params: {uuid: _.value.uuid}}"
                >
                    {{ _.value | listName }}
                </router-link>
                <v-chip
                        v-if="_.item.unit && _.item.unit.name"
                        x-small
                        class="px-1 text-uppercase"
                >
                    {{ _.item.unit.name }}
                </v-chip>
            </template>

            <template v-slot:item.unit="_">
                {{ _.value | listName }}
            </template>

            <template v-slot:item.term="_">{{ _.value | term }}</template>

            <template
                v-for="dateTimeProp in ['item.created_at', 'item.updated_at', 'item.deleted_at']"
                v-slot:[dateTimeProp]="_"
            >{{ _.value | euroDateShortTime }}</template>

            <template
                v-for="dateProp in ['item.date', 'item.from_date', 'item.to_date']"
                v-slot:[dateProp]="_"
            >{{ _.value | euroDate }}</template>

            <template v-slot:item.time="_">{{ _.value | shortTime }}</template>

            <template v-slot:item.hours="_">{{ _.value | euroNumber }}</template>

            <template v-slot:item.rate="_">{{ _.value | amount }}</template>

            <template
                v-for="amountProp in ['item.amount', 'item.available_amount', 'item.locked_amount']"
                v-slot:[amountProp]="_"
            ><amount>{{ _.value }}</amount></template>

            <template v-slot:item.is_payable="_">
                <check-mark :value="_.value" :x-small="tableIsDense" />
            </template>

            <template
                v-for="(_, slot) of $scopedSlots"
                v-slot:[slot]="scope"
            >
                <slot :name="slot"
                      v-bind="scope"
                      v-bind:dense="tableIsDense"
                      v-bind:params="apiTableParams"
                />
            </template>

        </v-data-table>

        <delete-dialog
                :visible="deleteDialogVisible"
                :title="deleteDialogTitle"
                :text="deleteDialogText"
                @confirmed="deleteDialogConfirmed"
                @rejected="deleteDialogRejected()"
        />

    </div>

</template>

<script>
    import AppOptionsMixin from '../../mixins/AppOptionsMixin'
    import t from 'typy'
    import _ from 'lodash'

    _.mixin(require('lodash-inflection'))

    import DeleteDialog from '../Shared/Dialogs/DeleteDialog'
    import CheckMark from '../Shared/Icons/CheckMark'
    import ComplexFilter from '../Shared/Forms/ComplexFilter'
    import DeleteIcon from '../Shared/Icons/DeleteIcon'
    import EditIcon from '../Shared/Icons/EditIcon'
    import ShowIcon from '../Shared/Icons/ShowIcon'
    import Amount from '../Shared/Text/Amount'
    import ExcelButton from "../Shared/Buttons/ExcelButton";

    const ignoredAttributes = [
        'headers',
        'items',
        'serverItemsLength',
        'options',
        'footerProps',
    ]

    const noStoreTableOptions = [
        'groupBy',
        'groupDesc',
        'mustSort',
        'multiSort',
    ];

    const defaultItemsPerPageOptions = [10, 20, 25, 50, 100]
    const defaultItemsPerPage = 10
    const defaultShowFirstLastPage = true

    const defaultTableOptions = {
        groupBy: [],
        groupDesc: [],
        itemsPerPage: defaultItemsPerPage,
        multiSort: false,
        mustSort: false,
        page: 1,
        sortBy: [],
        sortDesc: [],
    };

    export default {
        name: 'ItemList',
        inheritAttrs: false,
        mixins: [
            AppOptionsMixin,
        ],
        components: {
            ExcelButton,
            Amount,
            CheckMark,
            ComplexFilter,
            DeleteDialog,
            DeleteIcon,
            EditIcon,
            ShowIcon,
        },
        props: {
            dense: {
                type: Boolean,
                required: false,
                default: false,
            },
            excelButton: {
                type: Boolean,
                required: false,
                default: false,
            },
            filter: {
                type: Object,
                required: false,
                default: () => ({}),
            },
            headers: {
                type: Array,
                required: false,
                default: () => ([]),
            },
            hidden: {
                type: Boolean,
                required: false,
                default: false,
            },
            itemsPerPageOptions: {
                type: Array,
                required: false,
                default: () => defaultItemsPerPageOptions,
            },
            itemsPerPage: {
                type: Number,
                required: false,
                default: defaultItemsPerPage,
            },
            noDelete: {
                type: Boolean,
                required: false,
                default: false,
            },
            noEdit: {
                type: Boolean,
                required: false,
                default: false,
            },
            noShow: {
                type: Boolean,
                required: false,
                default: false,
            },
            parentSlug: {
                type: String,
                required: false,
                default: '',
            },
            parentId: {
                type: String,
                required: false,
                default: '',
            },
            showFirstLastPage: {
                type: Boolean,
                required: false,
                default: defaultShowFirstLastPage,
            },
            slug: {
                type: String,
                required: false,
                default: '',
            },
        },
        data: vm => ({
            lodash: _,
            deleteDialogText: vm.l('dialogs.delete.text'),
            deleteDialogTitle: '',
            deleteDialogVisible: false,
            deletingItem: null,
            exportDisabled: false,
            l18nKey: null,
            tableItems: [],
            tableLoading: false,
            tableFilter: vm.getInitialTableFilter(),
            tableOptions: vm.getInitialTableOptions(),
            tableParams: {},
            tableItemCount: 0,
        }),
        computed: {
            apiTableParams() {
                return this.getTableParams()
            },
            footerProps() {
                return {
                    itemsPerPageOptions: this.itemsPerPageOptions,
                    showFirstLastPage: this.showFirstLastPage,
                }
            },
            tableAttributes() {
                return _.omit(this.$attrs, ignoredAttributes)
            },
            tableHeaders() {
                return _.concat(
                    !!this.headers ? this.getTableHeadersFromProp() : this.getTableHeadersFromItems(),
                    this.getTableActionsHeader()
                )
            },
            tableIsDense() {
                return this.dense || this.tableItems.length > 20
            },
            tableListeners() {
                return this.$listeners
            },
        },
        watch: {
            hidden: {
                immediate: false,
                handler(hidden) {
                    if (!hidden) {
                        this.refreshTable()
                    }
                }
            },
            deletingItem: {
                immediate: true,
                handler(item) {
                    if (item) {
                        this.deleteDialogTitle = item.name
                        if (!this.deleteDialogVisible) {
                            this.deleteDialogVisible = true
                        }
                    } else {
                        if (!item && this.deleteDialogVisible) {
                            this.deleteDialogVisible = false
                        }
                        this.deleteDialogTitle = this.l('dialogs.delete.title')
                    }
                }
            },
            itemsPerPage: {
                immediate: true,
                handler(itemsPerPage) {
                    this.tableOptions.itemsPerPage = itemsPerPage
                },
            },
            slug: {
                immediate: true,
                handler() {
                    this.l18nKey = this.getItemSlug()
                },
            },
            tableOptions: {
                handler(options) {
                    this.handleTableOptionsChange(options)
                },
                deep: true,
            },
            tableParams: {
                handler(params) {
                    this.handleTableParamsChange(params)
                },
                deep: true,
            },
        },
        methods: {

            // Service

            /**
             * Clone variable
             *
             * @param value
             * @returns {*} Cloned variable
             */
            clone(value) {
                return _.isArray(value) || _.isObject(value)
                    ? JSON.parse(JSON.stringify(value))
                    : value
            },

            /**
             * Quickly check items for equality using their serialized representations
             *
             * @param item1
             * @param item2
             * @returns {boolean}
             */
            itemsEqual(item1, item2) {
                const type = typeof item1
                switch (true) {
                    case type !== (typeof item2):
                        return false
                    case type === 'object':
                        return !JSON.stringify(item1).localeCompare(JSON.stringify(item2))
                    default:
                        return item1 === item2
                }
            },

            /**
             * Recursively check two objects for equality
             *
             * @param object1
             * @param object2
             * @returns {boolean}
             */
            objectsEqual(object1, object2) {
                if (_.isObject(object1) && _.isObject(object2)) {
                    const keys = _.uniq(_.merge(_.keys(object1), _.keys(object2)))
                    return _.filter(keys, function (key) {
                        if (undefined !== object1[key] && undefined !== object2[key] && (typeof object1[key]) === (typeof object2[key])) {
                            return _.isObject(object1[key]) ? this.objectsEqual(object1[key], object2[key]) : (object1[key] === object2[key])
                        }
                        return false
                    }.bind(this)).length === keys.length
                }
                return false
            },


            // Model related strings

            /**
             * Format plural slug for url
             *
             * @returns {String}
             */
            formatPluralSlug(slug) {
                return !!slug ? _.kebabCase(_.pluralize(slug)) : ''
            },

            /**
             * Format singular slug for url
             *
             * @returns {String}
             */
            formatSingularSlug(slug) {
                return !!slug ? _.kebabCase(_.singularize(slug)) : ''
            },

            /**
             * Get plural item model slug
             *
             * @returns {String}
             */
            getItemSlug() {
                return this.formatPluralSlug(this.slug) || t(this.getApiUrl().match(/(?<slug>[^\/]+)\/?$/), 'groups.slug').safeString
            },

            /**
             * Get singular parent model slug
             *
             * @returns {?String}
             */
            getParentSlug() {
                switch (true) {
                    case !!this.parentSlug:
                        return this.formatSingularSlug(this.parentSlug)
                    case this.$route.path.match(/^(\/[^\/]+){3}/):
                        return this.formatSingularSlug(this.$route.path.split('/', 2)[1])
                    default:
                        return null
                }
            },


            // Page and API urls

            /**
             * Get API url for item list retrieval
             *
             * @returns {string} explicitly set API url or current page url
             */
            getApiUrl() {
                return '/' + (
                    this.formatPluralSlug(_.trim(t(this.slug).safeString, '/'))
                    || _.trimStart(this.$route.path.replace(/^(?:.*\/)?([^\/]+)\/?$/, '$1'), '/')
                )
            },

            /**
             * Get specific item API url
             *
             * @param item
             * @returns {String}
             */
            getItemApiUrl(item) {
                // Prefer UUID over ID
                return this.getApiUrl() + '/' + (t(item, 'uuid').safeString || t(item, 'id').safeString)
            },

            /**
             * Get specific item page url
             *
             * @param item
             * @returns {String}
             */
            getItemPageUrl(item) {
                // Prefer UUID over ID
                return this.getPageUrl() + '/' + (t(item, 'uuid').safeString || t(item, 'id').safeString)
            },

            /**
             * Get item page url
             *
             * @returns {string} explicitly set API url or current page url
             */
            getPageUrl() {

                const routeSegments = _.trim(this.$route.path, '/').split('/', 3)
                const routeParentAvailable = routeSegments.length === 3

                const itemSlug = this.formatPluralSlug(this.slug) || routeSegments[routeParentAvailable ? 2 : 0],
                    parentSlug = this.formatPluralSlug(this.parentSlug) || (routeParentAvailable ? routeSegments[0] : null),
                    parentId = this.parentId || (routeParentAvailable ? routeSegments[1] : null)

                return '/' + ((!!parentSlug && !!parentId) ? [parentSlug, parentId, itemSlug].join('/') : itemSlug)

            },


            // Table headers

            /**
             * Get header setting for Actions column
             *
             * @returns {{text: String, sortable: boolean, align: string, value: string, searchable: boolean}}
             */
            getTableActionsHeader() {
                return {
                    value: 'actions',
                    text: this.localizeHeaderText('actions'),
                    align: 'right',
                    sortable: false,
                    searchable: false,
                }
            },

            /**
             * Get table header settings from the first item on list
             *
             * @returns {Array}
             */
            getTableHeadersFromItems() {
                return _.isArray(this.tableItems) && this.tableItems.length
                    ? _.flatten(_.map(this.tableItems, function (item) {
                        return _.map(_.keys(item), function (key) {
                            return {
                                value: key,
                                text: _.startCase(key),
                            }
                        })
                    }))
                    : []
            },

            /**
             * Get table header settings from 'header' prop passed in explicitly
             *
             * @returns {Array}
             */
            getTableHeadersFromProp() {
                return _.map(this.clone(t(this.headers).safeArray), function (header) {

                    if (t(header).isString) {

                        header = this.convertKeyToHeader(header)

                    } else {

                        header = t(header).safeObjectOrEmpty

                        if (t(header, 'value').isFalsy && t(header, 'text').isTruthy) {
                            header.value = _.snakeCase(header.text)
                        } else if (t(header, 'text').isFalsy && t(header, 'value').isTruthy) {
                            header.text = header.value
                        }

                        if (t(header, 'text').isDefined) {
                            header.text = this.localizeHeaderText(header.text)
                        }
                    }
                    return header
                }.bind(this))
            },

            /**
             * Convert key string to properly formatted header setting
             *
             * @param key
             * @returns {{text: String, value: *}}
             */
            convertKeyToHeader(key) {
                key = _.snakeCase(key)
                return {
                    value: key,
                    text: this.localizeHeaderText(key),
                }
            },

            /**
             * Localize header title.
             * Fall back to default value if necessary.
             *
             * @param text
             * @returns {String}
             */
            localizeHeaderText(text) {

                const key = 'pages.' + _.snakeCase(this.l18nKey || this.getItemSlug()) + '.properties.' + _.snakeCase(text)
                let title = this.l(key)

                if (title === key) {
                    title = _.startCase(key)
                }

                return title
            },


            // Table data updates

            /**
             * Fetch item list from server
             *
             * @returns {Promise} AxiosResponse
             */
            fetchTableData() {
                return this.$api.get(this.getApiUrl(), {params: this.getTableParams()})
            },

            /**
             * Update component data set with the new data
             *
             * @param {Object} data
             */
            updateTableData(data) {
                this.setTableItems(data.items)
                this.setTableItemCount(data.total)
                this.setTableOptions(data.options)
            },

            /**
             * Set item list
             *
             * @param {Array} items
             */
            setTableItems(items) {
                this.tableItems = items
            },

            /**
             * Set item count
             *
             * @param {Number} count
             */
            setTableItemCount(count) {
                this.tableItemCount = count
            },

            /**
             * Set table options
             *
             * @param {Object} options
             */
            setTableOptions(options) {
                this.tableOptions = _.merge(this.tableOptions, options)
            },

            /**
             * Wipe item list information from component data set
             */
            wipeTableData() {
                this.hideDeleteDialog()
                this.setTableItems([])
                this.setTableItemCount(0)
            },

            /**
             * Refresh table data
             *
             * @returns {Promise}
             */
            refreshTable() {
                this.wipeTableData()
                this.tableLoading = true
                return this.fetchTableData()
                    .then(function (resp) {
                        this.updateTableData(resp.data)
                    }.bind(this))
                    .finally(function () {
                        this.tableLoading = false
                    }.bind(this))
            },


            // Table item actions

            /**
             * Go to item show page
             *
             * @param item
             * @returns {Promise<Route>}
             */
            showTableItem(item) {
                return this.$router.push(this.getItemPageUrl(item))
            },

            /**
             * Go to item edit page
             *
             * @param item
             * @returns {Promise<Route>}
             */
            editTableItem(item) {
                return this.$router.push(this.getItemPageUrl(item) + '/edit')
            },

            /**
             * Delete item
             *
             * @param item
             * @returns {Promise}
             */
            deleteTableItem(item = null) {

                if (!item) {
                    item = this.deletingItem
                }

                return new Promise(function (resolve, reject) {
                    if (item) {
                        const itemApiUrl = this.getItemApiUrl(item)
                        if (itemApiUrl) {
                            this.$api.delete(itemApiUrl)
                                .then(function () {
                                    this.refreshTable()
                                        .then(() => resolve(item))
                                        .catch(err => reject(err))
                                }.bind(this))
                                .catch(err => reject(err))
                        } else {
                            reject()
                        }
                    } else {
                        reject()
                    }

                }.bind(this))
            },


            // Item delete dialog

            /**
             * Show item delete dialog
             * @param item
             */
            showDeleteDialog(item) {
                this.deletingItem = this.clone(item)
            },

            /**
             * Hide item delete dialog
             */
            hideDeleteDialog() {
                this.deletingItem = null
            },

            /**
             * Handle item delete confirmed
             */
            deleteDialogConfirmed() {
                this.deleteTableItem().finally(function () {
                    this.hideDeleteDialog()
                }.bind(this))
            },

            /**
             * Handle item delete rejected
             */
            deleteDialogRejected() {
                this.hideDeleteDialog()
            },


            // Table item action buttons

            showButtonClicked(item) {
                this.showTableItem(item)
            },
            editButtonClicked(item) {
                this.editTableItem(item)
            },
            deleteButtonClicked(item) {
                this.showDeleteDialog(item)
            },


            // Table params

            /**
             * Get table params for server queries
             *
             * @returns {Object}
             */
            getTableParams() {
                return _.merge(this.clone(this.tableParams), this.getForcedTableParams())
            },

            getForcedTableParams() {

                const params = {
                    filter: {}
                }

                const explicitParentSlugAvailable = !!this.parentSlug
                const explicitParentIdAvailable = !!this.parentId
                const explicitParentAvailable = explicitParentSlugAvailable && explicitParentIdAvailable

                if (explicitParentAvailable) {

                    const parentParameter = this.formatSingularSlug(this.parentSlug) + '_uuid'
                    params.filter[parentParameter] = this.parentId || routeSegments[1]

                } else {

                    const routeSegments = _.trim(this.$route.path, '/').split('/', 3)
                    const routeParentAvailable = routeSegments.length === 3
                    if (routeParentAvailable) {
                        const parentParameter = this.formatSingularSlug(routeSegments[0]) + '_uuid'
                        params.filter[parentParameter] = routeSegments[1]
                    }
                }

                params.filter = _.merge(params.filter, this.filter)

                if (_.keys(params.filter).length === 0) {
                    delete params.filter
                }

                return params
            },

            /**
             * Get table options
             *
             * @returns {Object}
             */
            getDefaultTableOptions() {
                return this.clone(defaultTableOptions)
            },

            /**
             * Get table filter
             *
             * @returns {Object}
             */
            getStoredTableFilter() {
                return this.clone(t(this.getAppRouteOption('tableFilter')).safeObjectOrEmpty)
            },

            /**
             * Get stored table options
             *
             * @returns {Object}
             */
            getStoredTableOptions() {
                return _.omit(_.pick(
                    // Get only the keys found in defaultTableOptions
                    // Except the ones listed in noStoreTableOptions
                    this.clone(t(this.getAppRouteOption('tableOptions')).safeObjectOrEmpty),
                    _.keys(this.getDefaultTableOptions())), noStoreTableOptions)
            },

            /**
             * Get table params
             *
             * @returns {Object}
             */
            getInitialTableFilter() {
                return this.getStoredTableFilter()
            },

            /**
             * Get initial table options
             *
             * @returns {Object}
             */
            getInitialTableOptions() {
                return _.merge(this.getDefaultTableOptions(), this.getStoredTableOptions())
            },

            /**
             * Store table filter
             *
             * @param {?Object} filter
             */
            storeTableFilter(filter = null) {
                this.setAppRouteOption('tableFilter', this.prepareTableFilterForStorage(filter))
            },

            /**
             * Store table options
             *
             * @param {?Object} options
             */
            storeTableOptions(options = null) {
                this.setAppRouteOption('tableOptions', this.prepareTableOptionsForStorage(options))
            },

            /**
             * Prepare table filter for storage
             *
             * @param {?Object} filter
             * @returns {Object}
             */
            prepareTableFilterForStorage(filter = null) {
                return this.clone(t(filter || this.tableFilter).safeObjectOrEmpty)
            },

            /**
             * Prepare table options for storage
             *
             * @param {?Object} options
             * @returns {Object}
             */
            prepareTableOptionsForStorage(options = null) {
                // Store only the keys found in defaultTableOptions
                // Except the ones listed in noStoreTableOptions
                return _.omit(_.pick(this.clone(t(options || this.tableOptions).safeObjectOrEmpty),
                    _.keys(this.getDefaultTableOptions())), noStoreTableOptions)
            },

            /**
             * Update table params
             */
            updateTableParams() {
                const tableParams = this.clone(this.tableOptions)
                tableParams['filter'] = this.clone(this.tableFilter)
                this.tableParams = tableParams
            },


            // Data change handlers

            handleFilterUpdate(filter) {
                this.tableFilter = filter
            },

            handleTableOptionsChange(options) {
                this.storeTableOptions(options)
                this.updateTableParams()
            },

            handleTableFilterChange(filter) {
                if (!this.itemsEqual(filter, this.getStoredTableFilter())) {
                    this.storeTableFilter(filter)
                    this.updateTableParams()
                }
            },

            handleTableParamsChange(filter = null) {
                this.refreshTable()
            },

            /**
             * Export table data to Excel
             */
            exportToExcel() {
                this.exportDisabled = true
                this.$api.post(this.getApiUrl() + '/excel', this.getTableParams())
                    .catch(error => error)
                    .finally(function() {
                        this.exportDisabled = false
                    }.bind(this))
            },

        },
        created() {

            // Put a watcher on tableFilter via debounce/throttle
            this.$watch(
                'tableFilter',
                _.throttle(this.handleTableFilterChange, 300, {leading: false, trailing: true}),
                {deep: true, immediate: true}
            )

        },
    }
</script>

<style scoped>

    .v-card .item-list .item-list__filter {
        margin-top: -16px;
        margin-left: -16px;
        margin-right: -16px;
        padding-left: 16px;
        padding-right: 8px;
    }

</style>