<template>
    <item-list-view v-bind="tableProps">

        <template v-slot:item.user="_">
            <router-link
                    :to="{name: 'employees.show', params: {uuid: _.value.uuid}}">
                {{ _.value | listName }}
            </router-link>
        </template>

    </item-list-view>
</template>

<script>
    import TablePageMixin from '../../../mixins/Cumulative/TablePageMixin'

    export default {
        name: "adjustmentsIndexPage",
        mixins: [
            TablePageMixin,
        ],
        data: () => ({
            tableProps: {
                headers: [
                    {
                        value: 'id',
                        align: 'end',
                        sortable: true,
                        filterable: false,
                    },
                    {
                        value: 'user',
                        align: 'start',
                        sortable: false,
                        filterable: false,
                    },
                    {
                        value: 'note',
                        align: 'start',
                        sortable: false,
                        filterable: false,
                    },
                    {
                        value: 'created_at',
                        align: 'end',
                        sortable: true,
                        filterable: false,
                    },
                    {
                        value: 'updated_at',
                        align: 'end',
                        sortable: true,
                        filterable: false,
                    },
                    {
                        value: 'amount',
                        align: 'end',
                        sortable: true,
                        filterable: false,
                    },
                ],
                props: {},
            },
            customAppTitle: 'pages.adjustments.index.title',
        }),
        computed: {
        },
    }
</script>

<style scoped>

</style>