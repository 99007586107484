<template>
    <v-form>
        <v-row>
            <v-col cols="12" md="8" class="pb-0">
                <subcard content-class="pt-0">
                    <template slot="title">
                        {{ l('pages.shifts.edit.cards.general.title') }}
                    </template>

                    <v-row dense>
                        <v-col cols="12">
                            <api-select
                                    api="locations/list"
                                    :disabled="disabled"
                                    :readonly="!!assignmentId && !isAuto"
                                    hide-details
                                    :label="l('pages.shifts.properties.location')"
                                    :items="locationOptions"
                                    v-model="positionScope.location_id"
                            />
                        </v-col>
                    </v-row>

                    <v-row dense>
                        <v-col cols="12" md="3">
                            <v-select
                                    :disabled="disabled || !unitOptions.length"
                                    :readonly="!!assignmentId && !isAuto"
                                    hide-details
                                    :label="l('pages.shifts.properties.unit')"
                                    :items="unitOptions"
                                    v-model="positionScope.unit_id"
                            />
                        </v-col>
                        <v-col cols="12" md="9">
                            <v-select
                                    :disabled="disabled || !positionOptions.length"
                                    :readonly="!!assignmentId && !isAuto"
                                    hide-details
                                    :label="l('pages.shifts.properties.position')"
                                    :items="positionOptions"
                                    v-model="positionId"
                            />
                        </v-col>
                    </v-row>

                    <v-row dense>
                        <v-col cols="12">
                            <user-picker
                                    employee
                                    :readonly="!!assignmentId && !isAuto"
                                    :disabled="disabled"
                                    v-model="userId"
                            />
                        </v-col>
                    </v-row>

                </subcard>
            </v-col>

            <v-col cols="12" md="4" class="pb-0">
                <subcard content-class="pt-0">

                    <template slot="title">
                        {{ l('pages.shifts.edit.cards.params.title') }}
                    </template>



                    <v-row dense>
                        <v-col cols="12" sm="7">
                            <date-field
                                    autocomplete="off"
                                    text-field-class="mt-4"
                                    :disabled="disabled"
                                    dense
                                    hide-details
                                    :max="maxDate"
                                    :min="minDate"
                                    required
                                    :label="l('pages.shifts.properties.date')"
                                    v-model="date"
                            />
                        </v-col>
                        <v-col cols="12" sm="5">
                            <time-field
                                    autocomplete="off"
                                    class="mt-4"
                                    :disabled="disabled"
                                    clearable
                                    dense
                                    hide-details
                                    :label="l('pages.shifts.properties.time')"
                                    v-model="time"
                            />
                        </v-col>
                    </v-row>



                    <v-row dense>
                        <v-col cols="12" sm="4">
                            <hours-field
                                    autocomplete="off"
                                    class="mt-4"
                                    :disabled="disabled"
                                    dense
                                    hide-details
                                    placeholder=""
                                    v-model="hours"
                            />
                        </v-col>
                        <v-col cols="12" sm="8">
                            <rate-field
                                    autocomplete="off"
                                    class="mt-4"
                                    :disabled="disabled"
                                    dense
                                    hide-details
                                    :items="rates"
                                    v-model="rate"
                                    maxlength="6"
                            />
                        </v-col>
                    </v-row>



                    <v-row dense>
                        <v-col cols="12" sm="6">
                            <v-checkbox
                                    dense
                                    :disabled="disabled"
                                    hide-details
                                    :label="l('pages.shifts.properties.is_payable')"
                                    v-model="isPayable"
                            />
                        </v-col>
                        <v-col cols="12" sm="6">
                            <v-sheet
                                    :color="isPayable ? 'green lighten-5' : 'blue-grey lighten-5'"
                                    :class="'px-1 py-2 title text-right ' + (isPayable ? 'green--text' : 'blue-grey--text text--lighten-1')"
                            >
                                {{ ((hours || 0) * (rate || 0)) | euroNumber }}{{ l('global.currency') }}/{{ l('global.day') }}
                            </v-sheet>
                        </v-col>
                    </v-row>
                </subcard>
            </v-col>
        </v-row>
    </v-form>
</template>

<script>
    import t from 'typy'
    import _ from 'lodash'
    import Subcard from '../../../Shared/Cards/Subcard'
    import DateField from '../../../Shared/Forms/TextFields/DateField'
    import NumberField from '../../../Shared/Forms/TextFields/NumberField'
    import TimeField from '../../../Shared/Forms/TextFields/TimeField'
    import HoursField from '../../../Shared/Forms/TextFields/HoursField'
    import RateField from '../../../Shared/Forms/TextFields/RateField'
    import UserPicker from '../../../Shared/Forms/UserPicker'
    import ApiSelect from "../../../Shared/Forms/ApiSelect";

    export default {
        name: "ShiftEditForm",
        inheritAttrs: false,
        components: {
            ApiSelect,
            DateField,
            HoursField,
            NumberField,
            RateField,
            Subcard,
            TimeField,
            UserPicker,
        },
        props: {
            value: {
                required: false,
                type: Object,
                default: () => {},
            },
            disabled: {
                required: false,
                type: Boolean,
                default: false,
            }
        },
        data: vm => ({
            assignment: {},
            assignmentId: null,
            isAuto: true,
            date: '',
            hours: null,
            isPayable: false,
            locationOptions: [],
            maxDate: '',
            minDate: '',
            position: {},
            positionId: null,
            positionOptions: [],
            positionScope: {
                location_id: '',
                unit_id: '',
            },
            rate: null,
            rates: [],
            rules: {
                required: value => undefined !== value && '' !== value || vm.l('pages.shifts.validation.required')
            },
            shift: {},
            time: '',
            unitOptions: [],
            userId: null,
        }),
        watch: {
            assignmentId: {
                immediate: true,
                handler(id) {
                    this.shift.assignment_id = id
                    if (id && (!_.isObject(this.assignment) || _.get(this.assignment, 'id', null) !== id)) {
                        this.$api.get('assignments', {params: {only_id: id}})
                            .then(function(response) {
                                this.assignment = (response.data.total === 1) ? this.clone(response.data.items[0]) : null
                            }.bind(this))
                    }
                },
            },
            assignment: {
                deep: true,
                immediate: true,
                handler(assignment) {
                    if (_.isObject(assignment)) {

                        const isAuto = t(assignment, 'is_auto').safeBoolean
                        if (this.isAuto !== isAuto) {
                            this.isAuto = isAuto
                        }

                        const positionId = t(assignment, 'position_id').safeNumber
                        if (positionId && this.positionId !== positionId) {
                            this.positionId = positionId
                        }

                        const userId = t(assignment, 'user_id').safeNumber
                        if (userId && this.userId !== userId) {
                            this.userId = userId
                        }

                        this.minDate = assignment.from_date || null
                        this.maxDate = assignment.to_date || this.$moment().format('YYYY-MM-DD')

                    }
                },
            },
            date: {
                immediate: true,
                handler(value) {
                    this.shift.date = value
                },
            },
            hours: {
                immediate: true,
                handler(value) {
                    this.shift.hours = value
                },
            },
            isPayable: {
                immediate: true,
                handler(value) {
                    this.shift.is_payable = value
                },
            },
            positionId: {
                immediate: true,
                handler(id) {
                    this.shift.position_id = id
                    if (id && (t(this.position, 'id').safeNumber !== id)) {
                        this.$api.get('positions', {params: {only_id: id}})
                            .then(function(response) {
                                this.position = (response.data.total === 1) ? this.clone(response.data.items[0]) : null
                            }.bind(this))
                    }
                },
            },
            position: {
                deep: true,
                immediate: true,
                handler(position) {
                    this.positionScope = {
                        unit_id: _.get(position, 'unit_id', null),
                        location_id: _.get(position, 'location_id', null),
                    }
                    this.rates = _.get(position, 'rates', [])
                },
            },
            positionOptions: {
                deep: true,
                handler(value) {
                    if (value.length && !this.isOptionValid(this.positionId, value)) {
                        this.positionId = _.first(value).value
                    }
                },
            },
            positionScope: {
                deep: true,
                immediate: true,
                handler() {
                    this.updateUnitOptions()
                        .then(function () {
                            this.updatePositionOptions()
                        }.bind(this))
                },
            },
            rate: {
                immediate: true,
                handler(value) {
                    this.shift.rate = value
                },
            },
            shift: {
                deep: true,
                immediate: true,
                handler(value) {
                    this.emitInput(value)
                },
            },
            time: {
                immediate: true,
                handler(value) {
                    this.shift.time = value
                },
            },
            unitOptions: {
                deep: true,
                handler(value) {
                    if (value.length && !this.isOptionValid(this.positionScope.unit_id, value)) {
                        this.positionScope.unit_id = _.first(value).value
                    } 
                },
            },
            userId: {
                immediate: true,
                handler(value) {
                    this.shift.user_id = value
                },
            },
            value: {
                deep: true,
                immediate: true,
                handler(value) {
                    this.shift = value
                    this.assignmentId = _.get(value, 'assignment_id', null)
                    this.isAuto = _.get(value, ['assignment', 'is_auto'], !this.assignmentId)
                    this.positionId = _.get(value, 'position_id', _.get(value, ['assignment', 'position_id'], null))
                    this.userId = _.get(value, 'user_id', _.get(value, ['assignment', 'user_id'], null))
                    this.minDate = _.get(value, ['assignment', 'from_date'], null)
                    this.maxDate = _.get(value, ['assignment', 'to_date'], this.$moment().format('YYYY-MM-DD'))
                    this.date = (undefined !== value.date)
                        ? value.date
                        : (
                            (!this.assignmentId && !this.date)
                                ? this.$moment().format('YYYY-MM-DD')
                                : ''
                        )

                    this.hours = _.get(value, 'hours', null)
                    this.isPayable = _.get(value, 'is_payable', true)
                    this.rate = _.get(value, 'rate', null)
                    this.time = _.get(value, 'time', null)
                },
            },
        },
        methods: {
            clone(value) {
                return _.isArray(value) || _.isObject(value)
                    ? JSON.parse(JSON.stringify(value))
                    : value
            },
            itemsEqual(item1, item2) {
                const type = typeof item1
                switch (true) {
                    case type !== (typeof item2):
                        return false
                    case type === 'object':
                        return !JSON.stringify(item1).localeCompare(JSON.stringify(item2))
                    default:
                        return item1 === item2
                }
            },
            isOptionValid(option, options = [], key = 'value') {
                return !!_.filter(options, value => value && value[key] && value[key] === option).length
            },


            updatePositionOptions() {
                return new Promise(function (resolve, reject) {
                    if (this.positionScope.location_id) {
                        this.$api.get('positions/list', {params: this.positionScope})
                            .then(function (response) {
                                this.positionOptions = response.data
                                resolve()
                            }.bind(this))
                            .catch(error => reject(error))
                    } else {
                        this.positionOptions = []
                        resolve()
                    }
                }.bind(this))
            },
            updateUnitOptions() {
                return new Promise(function (resolve, reject) {
                    const locationId = this.positionScope.location_id
                    if (locationId) {
                        this.$api.get('units/list', {params: {location_id: locationId}})
                            .then(function (response) {
                                this.unitOptions = response.data
                                resolve()
                            }.bind(this))
                            .catch(error => reject(error))
                    } else {
                        this.unitOptions = []
                        resolve()
                    }
                }.bind(this))
            },
            emitInput(value) {
                this.$emit('input', value)
            },
        },
    }
</script>

