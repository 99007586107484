<template>
    <span>
        <v-chip
                v-if="item.is_rollback || item.is_rolled_back"
                dark
                :color="item.is_rollback ? 'red darken-3' : 'blue-grey darken-3'"
                x-small
                class="text-uppercase font-weight-bold"
        >
            {{ l('pages.transactions.properties.is_' + (item.is_rollback ? 'rollback' : 'rolled_back')) }}
        </v-chip>
        {{ item.note }}
    </span>
</template>

<script>
    import _ from 'lodash'

    export default {
        name: "TransactionNote",
        props: {
            item: {
                default: () => ({}),
                required: true,
                type: Object,
            },
        },
        data: vm => ({
            lodash: _,
        }),
    }
</script>

<style scoped>

</style>