import auth from '../../auth'
import EmployeesShowShiftsIndexPage from '../../../components/Pages/employees/show/shifts/index'
import EmployeesShowShiftsCreatePage from '../../../components/Pages/employees/show/shifts/create'
import EmployeesShowShiftsEditPage from '../../../components/Pages/employees/show/shifts/edit'
import EmployeesShowShiftsShowPage from '../../../components/Pages/employees/show/shifts/show'

export default [
    {
        path: '/employees/:uuid/shifts/create',
        name: 'employees.show.shifts.create',
        component: EmployeesShowShiftsCreatePage,
        beforeEnter: auth.guard.user,
    },
    {
        path: '/employees/:uuid/shifts/:childUuid',
        name: 'employees.show.shifts.show',
        component: EmployeesShowShiftsShowPage,
        beforeEnter: auth.guard.user,
    },
    {
        path: '/employees/:uuid/shifts/:childUuid/edit',
        name: 'employees.show.shifts.edit',
        component: EmployeesShowShiftsEditPage,
        beforeEnter: auth.guard.user,
    },
    {
        path: '/employees/:uuid/shifts',
        name: 'employees.show.shifts',
        component: EmployeesShowShiftsIndexPage,
        beforeEnter: auth.guard.user,
    },

]
