import auth from '../auth'
import UsersIndexPage from '../../components/Pages/users/index'
import UsersShowPage from '../../components/Pages/users/show'
import UsersEditPage from '../../components/Pages/users/edit'
import UsersCreatePage from '../../components/Pages/users/create'

export default [
    {
        path: '/users/create',
        name: 'users.create',
        component: UsersCreatePage,
        beforeEnter: auth.guard.user,
    },
    {
        path: '/users/:uuid/edit',
        name: 'users.edit',
        component: UsersEditPage,
        beforeEnter: auth.guard.user
    },
    {
        path: '/users/:uuid',
        name: 'users.show',
        component: UsersShowPage,
        beforeEnter: auth.guard.user,
    },
    {
        path: '/users',
        name: 'users.index',
        component: UsersIndexPage,
        beforeEnter: auth.guard.user,
    },
]