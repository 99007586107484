import general from './locations/general'
import assignments from './locations/assignments'
import positions from './locations/positions'
import shifts from './locations/shifts'
import subpages from './locations/subpages'

export default [].concat(

    // Child entities
    assignments,
    positions,
    shifts,

    // Subpages not bound to specific entities
    subpages,

    // General route set
    general,
)