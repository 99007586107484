<template>
    <employees-show-page parent v-slot:default="_parent">
        <sub-item-show-view v-slot:default="_">
            <employee-assignment-edit-form :disabled="_.itemDisabled" v-model="_.item" :parent-model="_parent.item" />
        </sub-item-show-view>
    </employees-show-page>
</template>

<script>
    import EmployeesShowPage from '../../show'
    import SubItemShowView from '../../../../Item/Views/SubItemShowView'
    import EmployeeAssignmentEditForm from './Shared/EmployeeAssignmentEditForm'

    export default {
        name: 'EmployeesShowAssignmentsEditPage',
        inheritAttrs: false,
        components: {
            EmployeesShowPage,
            SubItemShowView,
            EmployeeAssignmentEditForm,
        },
    }
</script>

<style scoped>

</style>