<template>
    <span>
        <v-menu
            ref="datePickerVisible"
            v-model="datePickerVisible"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            @keydown.esc="hideDatePicker"
        >
            <template v-slot:activator="{ on }">
                <v-text-field
                    :autocomplete="autocomplete"
                    :class="textFieldClass"
                    v-model="date"
                    :label="textFieldLabel"
                    @blur="refreshTextField"
                    :disabled="disabled"
                    v-bind="attrs"
                    v-on="listeners"
                >
                    <template slot="prepend-inner">
                        <v-icon color="blue-grey" :disabled="disabled" v-on="on">
                            mdi-calendar
                        </v-icon>
                        <slot name="prepend-inner"/>
                    </template>
                    <template slot="append">
                        <slot name="append"/>
                        <v-icon
                            v-if="clearable && date.length"
                            :disabled="disabled"
                            @click="clearDate"
                        >
                            mdi-close
                        </v-icon>
                    </template>
                    <template v-for="(_, slot) of slots" :slot="slot">
                        <slot :name="slot"/>
                    </template>
                </v-text-field>
            </template>
            <v-date-picker
                :value="datePickerValue"
                :allowed-dates="isDateAllowed"
                @input="handleDatePickerInput"
                :locale="pickerLocale"
                :first-day-of-week="firstDayOfWeek"
                no-title
                show-current
            />
        </v-menu>
    </span>
</template>

<script>
import DisableChromeAutocompleteMixin from '../../../../mixins/Hacks/DisableChromeAutocompleteMixin'

export default {
  name: 'DateField',
  inheritAttrs: false,
  mixins: [
    DisableChromeAutocompleteMixin,
  ],
  props: {
    autocomplete: {
      required: false,
      default: 'autocomplete',
    },
    clearable: {
      required: false,
      type: Boolean,
      default: false,
    },
    disabled: {
      required: false,
      type: Boolean,
      default: false,
    },
    label: {
      required: false,
      type: String,
      default: null,
    },
    locale: {
      required: false,
      type: String,
      default: null,
    },
    max: {
      required: false,
      type: String,
      default: '',
    },
    min: {
      required: false,
      type: String,
      default: '',
    },
    textFieldClass: {
      required: false,
      type: [String],
      default: '',
    },
    value: {
      required: false,
      type: String,
      default: null,
    },
    firstDayOfWeek: {
      required: false,
      default: '1',
    },
  },
  data: vm => ({
    date: '',
    datePickerVisible: false,
    datePickerValue: '',
    maxMoment: null,
    minMoment: null,
    tail: '',
    textFieldLabel: null,
  }),
  computed: {
    pickerLocale() {
      return this.locale || this.$store.getters.appLocale
    },
    listeners() {
      return _.omit(this.$listeners, 'input')
    },
    attrs() {
      return _.omit(_.clone(this.$attrs), ['autocomplete', 'clearable', 'disabled', 'label', 'value'])
    },
    slots() {
      return _.omit(_.clone(this.$slots), ['append', 'prepend-inner'])
    },
  },
  watch: {
    date(date) {
      const parsed = this.parseDate(date)
      if (!parsed || parsed !== date || this.isDateString(parsed)) {
        console.log(parsed);
        this.$emit('input', parsed ? parsed + this.tail : null)
      }
    },
    datePickerVisible(visible) {
      if (visible) {
        this.refreshTextField()
      }
    },
    label: {
      handler(value) {
        this.textFieldLabel = (null !== this.label) ? this.label : this.l('forms.datePicker.label')
      },
      immediate: true,
    },
    max: {
      immediate: true,
      handler(value) {
        if (value) {
          const moment = this.$moment(value.substr(0, 10))
          this.maxMoment = moment.isValid() ? moment : null
        } else {
          this.maxMoment = null
        }
      },
    },
    min: {
      immediate: true,
      handler(value) {
        if (value) {
          const moment = this.$moment(value.substr(0, 10))
          this.minMoment = moment.isValid() ? moment : null
        } else {
          this.minMoment = null
        }
      },
    },
    value: {
      handler(value) {
        try {
          value = value.toString()
        } catch (e) {
          value = ''
        }

        this.date = this.formatDate(value)

        if (value && value.length > 10) {
          this.datePickerValue = value.substr(0, 10)
          this.tail = value.substr(10)
        } else {
          this.datePickerValue = value
          this.tail = ''
        }
      },
      immediate: true,
    },
  },
  methods: {
    clearDate() {
      this.date = ''
    },
    handleDatePickerInput(date) {
      this.hideDatePicker()
      this.date = this.formatDate(date)
    },
    hideDatePicker() {
      this.datePickerVisible = false
    },
    isDateAllowed(date) {
      if (
          this.minMoment && this.minMoment.isAfter(date)
          || this.maxMoment && this.maxMoment.isBefore(date)
      ) {
        return false
      }
      return true
    },
    refreshTextField() {
      this.date = this.formatDate(this.value)
    }
  },
}
</script>

<style scoped>

</style>