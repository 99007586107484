import auth from '../../auth'
import EmployeesShowCashOperationsIndexPage from '../../../components/Pages/employees/show/cash_operations/index'
import EmployeesShowCashOperationsCreatePage from '../../../components/Pages/employees/show/cash_operations/create'
import EmployeesShowCashOperationsEditPage from '../../../components/Pages/employees/show/cash_operations/edit'
import EmployeesShowCashOperationsShowPage from '../../../components/Pages/employees/show/cash_operations/show'

export default [
    {
        path: '/employees/:uuid/cash-operations/create',
        name: 'employees.show.cash_operations.create',
        component: EmployeesShowCashOperationsCreatePage,
        beforeEnter: auth.guard.user,
    },
    {
        path: '/employees/:uuid/cash-operations/:childUuid',
        name: 'employees.show.cash_operations.show',
        component: EmployeesShowCashOperationsShowPage,
        beforeEnter: auth.guard.user,
    },
    {
        path: '/employees/:uuid/cash-operations/:childUuid/edit',
        name: 'employees.show.cash_operations.edit',
        component: EmployeesShowCashOperationsEditPage,
        beforeEnter: auth.guard.user,
    },
    {
        path: '/employees/:uuid/cash-operations',
        name: 'employees.show.cash_operations',
        component: EmployeesShowCashOperationsIndexPage,
        beforeEnter: auth.guard.user,
    },

]
