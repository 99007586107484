<template>
    <item-edit-view v-slot:default="_">
        <employee-edit-form :disabled="_.itemDisabled" v-model="_.item" />
    </item-edit-view>
</template>

<script>
    import ItemEditView from '../../Item/Views/ItemEditView'
    import EmployeeEditForm from './Shared/EmployeeEditForm'

    export default {
        name: 'EmployeesEditPage',
        components: {
            ItemEditView,
            EmployeeEditForm,
        },
    }
</script>

<style scoped>

</style>