<template>
    <item-edit-view v-slot:default="_">
        <fund-lock-edit-form :disabled="_.itemDisabled" v-model="_.item" />
    </item-edit-view>
</template>

<script>
    import FundLockEditForm from './Shared/FundLockEditForm'
    import ItemEditView from '../../Item/Views/ItemEditView'

    export default {
        name: 'FundLockEditPage',
        inheritAttrs: false,
        components: {
            ItemEditView,
            FundLockEditForm,
        },
    }
</script>

<style scoped>

</style>