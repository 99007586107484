<template>
  <employees-show-page
      parent
      content-color="white"
      v-slot:default="{ item: employee, itemDisabled }"
  >
    <v-row>
      <v-col cols="12" md="12" class="pb-0">
        <sub-item-list create-button :headers="headers">

          <template v-slot:filter="_">
            <v-row dense class="pt-5">
              <v-col cols="12" sm="7">
                <v-row dense>
                  <v-col cols="12" sm="7">
                    <v-select
                        dense
                        hide-details
                        multiple
                        v-model="_.filter.location_id"
                        :items="locationList"
                        :label="l('pages.assignments.index.filters.location_id')"
                    />
                  </v-col>
                  <v-col cols="12" sm="5">
                    <v-select
                        dense
                        hide-details
                        multiple
                        v-model="_.filter.unit_id"
                        :items="unitList"
                        :label="l('pages.assignments.index.filters.unit_id')"
                    />
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" sm="5">
                <v-row dense>
                  <v-col cols="12" sm="6">
                    <date-field
                        clearable
                        dense
                        hide-details
                        v-model="_.filter.from_date"
                        :label="l('pages.assignments.index.filters.from_date')"
                    />
                  </v-col>
                  <v-col cols="12" sm="6">
                    <date-field
                        clearable
                        dense
                        hide-details
                        v-model="_.filter.to_date"
                        :label="l('pages.assignments.index.filters.to_date')"
                    />
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </template>

        </sub-item-list>
      </v-col>
    </v-row>
  </employees-show-page>
</template>

<script>
import CheckMark from '../../../../Shared/Icons/CheckMark'
import Subcard from '../../../../Shared/Cards/Subcard'
import EmployeesShowPage from '../../show'
import SubItemList from '../../../../Item/SubItemList'
import DateField from '../../../../Shared/Forms/TextFields/DateField'
import TrimTextField from '../../../../Shared/Forms/TextFields/TrimTextField'
import Tunnel from '../../../../Shared/Tunnel'

export default {
  name: 'EmployeesShowShiftsIndexPage',
  components: {
    Tunnel,
    CheckMark,
    DateField,
    EmployeesShowPage,
    SubItemList,
    Subcard,
    TrimTextField,
  },
  data: vm => ({
    locationList: [],
    unitList: [],
    headers: [
      {
        value: 'id',
        align: 'end',
        sortable: true,
        filterable: false,
      },
      {
        value: 'location',
        align: 'start',
        sortable: false,
        filterable: false,
      },
      {
        value: 'position',
        align: 'start',
        sortable: false,
        filterable: false,
      },
      {
        value: 'date',
        align: 'end',
        sortable: true,
        filterable: false,
      },
      {
        value: 'time',
        align: 'end',
        sortable: true,
        filterable: false,
      },
      {
        value: 'hours',
        align: 'end',
        sortable: true,
        filterable: false,
      },
      {
        value: 'rate',
        align: 'end',
        sortable: true,
        filterable: false,
      },
      {
        value: 'is_payable',
        align: 'center',
        sortable: true,
        filterable: false,
      },
      {
        value: 'amount',
        align: 'end',
        sortable: true,
        filterable: false,
      },
    ],
  }),
  created() {

    this.$api.get('locations/list')
        .then(function (response) {
          this.locationList = response.data
        }.bind(this))
        .catch(error => error)

    this.$api.get('units/list')
        .then(function (response) {
          this.unitList = response.data
        }.bind(this))
        .catch(error => error)

  },
}
</script>

<style scoped>

</style>
