<template>
  <v-layout class="mx-1">
    <v-row dense>
      <v-col cols="12">
        <v-simple-table dense class="payslip">

          <tbody>
          <tr>
            <th colspan="2" class="text-uppercase black--text">
              {{ envelope.user | listName }} - {{ envelope.term | term }}
            </th>
            <th colspan="2" class="text-uppercase black--text text-right">
              {{ l('pages.envelopes.properties.start') }}: {{
                lodash.get(envelope, 'payslip.amounts.start', 0) | amount
              }}&nbsp;{{ l('global.currency') }}
            </th>
          </tr>

          <!-- SHIFTS -->
          <tr class="payslip-section-title" v-if="lodash.get(envelope, 'payslip.shifts.items', []).length">
            <th colspan="3" class="text-uppercase black--text">
              {{ l('pages.shifts.index.title') }}
            </th>
            <th class="text-right text-uppercase black--text">
              {{ envelope.payslip.shifts.amount | amount }}&nbsp;{{ l('global.currency') }}
            </th>

          </tr>
          <tr
              v-for="(shift, index) in lodash.get(envelope, 'payslip.shifts.items', [])"
              :key="index"
          >
            <td colspan="2">
              {{ shift.location_name }} - {{ shift.position_name }} ({{ shift.unit_name }})
            </td>
            <td class="text-right">
              <!--                                    {{ l('global.hours', shift.hours, {count: $options.filters.amount(shift.hours)}) }}-->
              {{ shift.hours | amount }}&nbsp;{{ l('global.hrs') }}
              x {{ (shift.is_payable ? shift.rate : 0) | amount }}&nbsp;{{ l('global.currency') }}
            </td>
            <td class="text-right">
              {{ shift.amount | amount }}&nbsp;{{ l('global.currency') }}
            </td>
          </tr>

          <!-- ADJUSTMENTS -->
          <tr class="payslip-section-title" v-if="lodash.get(envelope, 'payslip.adjustments.items', []).length">
            <th colspan="3" class="text-uppercase black--text">
              {{ l('pages.adjustments.index.title') }}
            </th>
            <th class="text-right text-uppercase black--text">
              {{ envelope.payslip.adjustments.amount | amount }}&nbsp;{{ l('global.currency') }}
            </th>
          </tr>
          <tr
              v-for="(adjustment, index) in lodash.get(envelope, 'payslip.adjustments.items', [])"
              :key="index"
          >
            <td colspan="2">
              {{ adjustment.note }}
            </td>
            <td class="text-right">
              {{ adjustment.date | euroDate }}
            </td>
            <td class="text-right">
              {{ adjustment.amount | amount }}&nbsp;{{ l('global.currency') }}
            </td>
          </tr>

          <!-- CASH OPERATIONS -->
          <tr class="payslip-section-title" v-if="lodash.get(envelope, 'payslip.cash_operations.items', []).length">
            <th colspan="3" class="text-uppercase black--text">
              {{ l('pages.cash_operations.index.title') }}
            </th>
            <th class="text-right text-uppercase black--text">
              {{ envelope.payslip.cash_operations.amount | amount }}&nbsp;{{ l('global.currency') }}
            </th>
          </tr>
          <tr
              v-for="(cash_operation, index) in lodash.get(envelope, 'payslip.cash_operations.items', [])"
              :key="index"
          >
            <td colspan="2">
              {{
                cash_operation.note || l('pages.cash_operations.values.type.' + (cash_operation.amount < 0 ? 'withdrawal' : 'deposit'))
              }}
            </td>
            <td class="text-right">
              {{ cash_operation.date | euroDate }}
            </td>
            <td class="text-right">
              {{ cash_operation.amount | amount }}&nbsp;{{ l('global.currency') }}
            </td>
          </tr>

          <!-- OTHER TRANSACTIONS -->
          <tr class="payslip-section-title" v-if="lodash.get(envelope, 'payslip.other_transactions.items', []).length">
            <th colspan="3" class="text-uppercase black&#45;&#45;text">
              {{ l('pages.transactions.index.title') }}
            </th>
            <th class="text-right text-uppercase black&#45;&#45;text">
              {{ envelope.payslip.other_transactions.amount | amount }}&nbsp;{{ l('global.currency') }}
            </th>
          </tr>
          <tr
              v-for="(transaction, index) in lodash.get(envelope, 'payslip.other_transactions.items', [])"
              :key="index"
          >
            <td colspan="2">
              {{ transaction.note }}
            </td>
            <td class="text-right">
              {{ transaction.date | euroDate }}
            </td>
            <td class="text-right">
              {{ transaction.amount | amount }}&nbsp;{{ l('global.currency') }}
            </td>
          </tr>

          <!-- FUND LOCKS -->
          <tr class="payslip-section-title" v-if="lodash.get(envelope, 'payslip.fund_locks.items', []).length">
            <th colspan="3" class="text-uppercase black--text">
              {{ l('pages.fund_locks.index.title') }}
            </th>
            <th class="text-right text-uppercase black--text">
              {{ envelope.payslip.fund_locks.amount | amount }}&nbsp;{{ l('global.currency') }}
            </th>
          </tr>
          <tr
              v-for="(fund_lock, index) in lodash.get(envelope, 'payslip.fund_locks.items', [])"
              :key="index"
          >
            <td colspan="2">
              {{ fund_lock.note }}
            </td>
            <td class="text-right">
              {{ fund_lock.date | euroDate }}
            </td>
            <td class="text-right">
              {{ fund_lock.amount | amount }}&nbsp;{{ l('global.currency') }}
            </td>
          </tr>
          <tr class="payslip-section-title" v-if="lodash.get(envelope, 'payslip.amounts.end', false)">
            <th class="text-uppercase" colspan="3">
              {{ l('global.total') }}
            </th>
            <th class="text-uppercase text-right">
              {{ envelope.payslip.amounts.end | amount }}&nbsp;{{ l('global.currency') }}
            </th>
          </tr>
          </tbody>

        </v-simple-table>
      </v-col>
    </v-row>
  </v-layout>
</template>

<script>
import _ from 'lodash'

export default {
  name: "EnvelopeSlip",
  props: {
    envelope: {
      required: true,
      type: Object,
      default: () => ({}),
    }
  },
  data: () => ({
    lodash: _,
  }),

}
</script>

<style>
.payslip, .payslip div {
  display: block;
}

.payslip, .payslip * {
  overflow: visible;
}

.payslip, .payslip table, .payslip table tbody {
  position: relative !important;
}

.payslip table {
  border-collapse: collapse;
}

.payslip, .payslip table, .payslip table tbody {
  page-break-inside: avoid;
  -webkit-region-break-inside: avoid;
}

.payslip table th,
.payslip table td {
  border: #000 1px solid !important;
}

.payslip-section-title th, .payslip-section-title td {
  background: #ccc;
}

.payslip-section-heading th, .payslip-section-heading td {
  background: #eee;
}

@media print {
  .v-application {
    background-color: transparent !important;
  }

  * {
    min-height: 0;
    height: auto;
  }
}

body {
  color-adjust: exact;
  -webkit-print-color-adjust: exact;
}

</style>
