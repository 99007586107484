import axios from 'axios'
import qs from 'qs'
import store from '../store'
import t from 'typy'
import _ from 'lodash'
import {USER_REQUEST_LOGOUT} from "../store/actions/user";
import Vue from 'vue'
import router from "../router";

//const apiUrl = localStorage.getItem('apiUrl') || '//' + location.host.replace(/^(?:(?:www|is)\.)?([^:]+).*$/, 'api.$1')
const apiHost = location.host.match(/^(([^.]+\.)*localhost(:\d+)$|\d{1,3}(\.\d{1,3}){3})$/) ? 'asmis.localhost' : location.host
const apiUrl = localStorage.getItem('apiUrl') || ('//' + apiHost + '/api')

const api = axios.create({
    baseURL: apiUrl,
    headers: {
        'Content-Type': 'application/json'
    }
})

function prepareRequestConfig(config) {

    config.paramsSerializer = params => {
        return qs.stringify(params, {
            arrayFormat: 'brackets',
            encode: false
        })
    };

    // With every request send:

    // User token if set
    if (store.getters.userAuthenticated) {
        config.headers['Authorization'] = store.getters.userToken;
    }

    // Application locale if set
    const locale = store.getters.appLocale
    if (locale) {
        config.params = t(config, 'params').safeObjectOrEmpty;
        config.params['locale'] = locale
    }

    return config;
}

function downloadSuggestedFile(response) {

    let downloadUrl = _.get(response, 'data.download', null)

    if (downloadUrl) {
        if (!downloadUrl.match(/[?&]token=[A-z\d]{32}/)) {
            downloadUrl += (~downloadUrl.indexOf('?') ? '&' : '?') + 'token=' + store.getters.userToken
        }
        window.location.href = downloadUrl
    }

    return response
}

function renderResponseNotifications(response) {

    const data = t(response, 'data').safeObjectOrEmpty
    const status = t(response, 'status').safeNumber
    const success = (status >= 200 && status < 300)
    const errors = _.flatten(_.values(t(data, 'errors').safeObjectOrEmpty))
    const message = t(data, 'message').safeString
    const messageType = (!success && !errors) ? 'warn' : (success ? 'success' : 'error')

    if (!success && !!errors.length) {

        for (let i = 0; i < errors.length; i++) {
            Vue.notify({
                text: errors[i],
                type: 'error',
            })
        }

    } else if (!!message) {

        Vue.notify({
            text: message,
            type: messageType,
        })

    }

    return response
}

function handleResponse(response) {

    renderResponseNotifications(response)
    downloadSuggestedFile(response)

    return response
}

function handleError(error) {

    const response = t(error, 'response').safeObjectOrEmpty

    if (!!response) {

        renderResponseNotifications(response)

        switch (t(response, 'status').safeNumber) {
            case 401:
                if (store.getters.userTokenStored) {
                    return store.dispatch(USER_REQUEST_LOGOUT, {offline: true})
                }
                break
            case 403:
                const path = '/' + _.trim(router.currentRoute.path, '/')
                const safePath = path.replace(/^((?:\/[A-z\d\-]+)+)(?:\/[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}(?:\/[^\/]+)?)$/, '$1')
                router.push(path !== safePath ? safePath : '/')
                    .then(response => response)
                    .catch(error => error)
                break
        }
    }

    return Promise.reject(error)
}


api.interceptors.request.use(config => prepareRequestConfig(config));
api.interceptors.response.use(response => handleResponse(response), error => handleError(error));

export default api;