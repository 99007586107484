<template>
    <v-form>
        <subcard>
            <v-row>
                <v-col cols="12" md="3">
                    <v-radio-group
                            :disabled="disabled"
                            hide-details
                            row
                            v-model="action"
                    >
                        <v-radio
                                v-for="action of actions"
                                :key="action"
                                :label="l('pages.transactions.actions.' + action)"
                                :value="action"
                        />
                    </v-radio-group>
                </v-col>
                <v-col cols="12" sm="6" md="5">
                    <v-text-field
                            autocomplete="off"
                            clearable
                            :disabled="disabled"
                            hide-details
                            :label="l('pages.transactions.properties.note')"
                            v-model="item.note"
                    />
                </v-col>
                <v-col cols="12" sm="6" md="2">
                    <term-select
                            auto-select-first
                            v-if="termIsEditable"
                            :disabled="disabled"
                            hide-details
                            v-model="item.term"
                    />
                    <v-text-field
                            v-else
                            readonly
                            hide-details
                            :label="l('pages.transactions.properties.term')"
                            :value="item.term ? $options.filters.term(item.term) : ''"
                    />
                </v-col>
                <v-col cols="12" sm="6" md="2">
                    <number-field
                            abs
                            autocomplete="off"
                            :disabled="disabled"
                            hide-details
                            :label="l('pages.transactions.properties.amount')"
                            v-model="amount"
                    />
                </v-col>
            </v-row>
            <v-row>
                <v-col align="end">
                    <v-chip
                            class="font-weight-bold text-uppercase"
                            :color="employee.available_amount < 0 ? 'deep-orange lighten-4' : 'light-green lighten-3'"
                    >
                        {{ l('pages.employees.properties.available_amount') }}:
                        {{ employee.available_amount | amount }} {{ l('global.currency') }}
                    </v-chip>
                </v-col>
            </v-row>
        </subcard>
    </v-form>
</template>

<script>
    import t from 'typy'
    import DateField from '../../../../../Shared/Forms/TextFields/DateField'
    import Subcard from '../../../../../Shared/Cards/Subcard'
    import CashboxSelect from '../../../../../Shared/Forms/Selects/CashboxSelect'
    import NumberField from '../../../../../Shared/Forms/TextFields/NumberField'
    import TermSelect from '../../../../../Shared/Forms/Selects/TermSelect'

    const ACTION_DEPOSIT = 'deposit'
    const ACTION_WITHDRAW = 'withdraw'

    const multipliers = {}
    multipliers[ACTION_WITHDRAW] = -1
    multipliers[ACTION_DEPOSIT] = 1

    export default {
        name: "EmployeeTransactionEditForm",
        inheritAttrs: false,
        components: {
            TermSelect,
            NumberField,
            CashboxSelect,
            DateField,
            Subcard,
        },
        props: {
            value: {
                required: false,
                type: Object,
                default: () => ({}),
            },
            employee: {
                required: false,
                type: Object,
                default: () => ({}),
            },
            disabled: {
                required: false,
                type: Boolean,
                default: false,
            }
        },
        data: vm => ({
            amount: null,
            availableTerms: [],
            item: {},
            rules: {
                required: value => undefined !== value && '' !== value || vm.l('pages.transactions.validation.required')
            },
            cashboxIsEditable: false,
            action: ACTION_WITHDRAW,
            actions: [
                ACTION_WITHDRAW,
                ACTION_DEPOSIT,
            ],
        }),
        computed: {
            termIsEditable() {
                return !t(this.item, 'id').safeNumber || ~this.availableTerms.indexOf(t(this.item, 'term').safeString)
            },
        },
        watch: {
            value: {
                deep: true,
                immediate: true,
                handler(value) {
                    this.item = value
                    if (undefined !== value.amount) {
                        this.action = value.amount < 0 ? ACTION_WITHDRAW : ACTION_DEPOSIT
                        this.amount = Math.abs(value.amount)
                    }
                },
            },
            amount(amount) {
                this.item.amount = Math.abs(amount) * multipliers[this.action]
            },
            'item.amount': function (amount) {
                this.amount = !!amount ? Math.abs(amount) : null
            },
            action(action) {
                if (this.amount) {
                    this.item.amount = Math.abs(this.amount) * multipliers[action]
                }
            },
            item: {
                deep: true,
                immediate: true,
                handler(item) {
                    this.$emit('input', item)
                },
            },
        },
        created() {
            this.$api.get('available/terms')
                .then(function (response) {
                    this.availableTerms = response.data
                }.bind(this))
                .catch(function (err) {
                    this.availableTerms = []
                }.bind(this))
        }
    }
</script>

