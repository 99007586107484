import auth from '../auth'
import PositionsIndexPage from '../../components/Pages/positions/index'
import PositionsShowPage from '../../components/Pages/positions/show'
import PositionsEditPage from '../../components/Pages/positions/edit'
import PositionsCreatePage from '../../components/Pages/positions/create'

export default [
    {
        path: '/positions/create',
        name: 'positions.create',
        component: PositionsCreatePage,
        beforeEnter: auth.guard.user,
    },
    {
        path: '/positions/:uuid/edit',
        name: 'positions.edit',
        component: PositionsEditPage,
        beforeEnter: auth.guard.user
    },
    {
        path: '/positions/:uuid',
        name: 'positions.show',
        component: PositionsShowPage,
        beforeEnter: auth.guard.user,
    },
    {
        path: '/positions',
        name: 'positions.index',
        component: PositionsIndexPage,
        beforeEnter: auth.guard.user,
    },
]