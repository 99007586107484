<template>
    <item-create-view v-slot:default="_">
        <user-edit-form v-model="_.item" :disabled="_.itemDisabled"></user-edit-form>
    </item-create-view>
</template>

<script>
    import ItemCreateView from '../../Item/Views/ItemCreateView'
    import UserEditForm from './Shared/UserEditForm'

    export default {
        name: 'UsersCreatePage',
        components: {
            ItemCreateView,
            UserEditForm,
        },
    }
</script>

<style scoped>

</style>