<template>
    <envelopes-show-page
            v-slot:default="{ item: envelope, itemDisabled }"
    >
        <tunnel :value="envelope" @input="handleEnvelopeUpdate" />
        <envelope-slip :envelope="envelope" v-if="!!envelope" />
    </envelopes-show-page>
</template>

<script>
    import EnvelopesShowPage from '../show'
    import Tunnel from '../../../Shared/Tunnel'
    import _ from 'lodash'
    import EnvelopeSlip from '../../../Shared/ModelSpecific/Envelope/EnvelopeSlip'

    export default {
        name: 'EnvelopesShowSlipPage',
        components: {
            EnvelopeSlip,
            Tunnel,
            EnvelopesShowPage,
        },
        data: vm => ({
            envelope: {},
            isPrintable: false,
        }),
        watch: {
            envelope() {
                this.tryToPrint()
            },
            isPrintable() {
                this.tryToPrint()
            },
        },
        methods: {
            handleEnvelopeUpdate(envelope) {
                this.envelope = envelope
            },
            tryToPrint() {
                if (this.isPrintable && _.get(this.envelope, 'id', false)) {
                    setTimeout(window.print, 500)
                }
            },
        },
        mounted() {
//            this.isPrintable = true
        }
    }
</script>

<style>
</style>