<template>
    <v-text-field
            v-bind="attrs"
            v-on="listeners"
            v-model="textFieldValue"
            :placeholder="placeholder"
            :autocomplete="autocomplete"
    >
        <template slot="prepend-inner">
            <v-tooltip top>
                <template v-slot:activator="{ on }">
                    <v-icon v-on="on" :color="confirmDisabled ? 'transparent' : (confirmValue ? 'green' : 'grey')" @click="handleConfirmClick" :disabled="confirmDisabled">
                        {{ confirmIcon }}
                    </v-icon>
                </template>
                <span>{{ confirmTooltip }}</span>
            </v-tooltip>
        </template>
        <template v-for="(_, slot) of slots" :slot="slot">
            <slot :name="slot" />
        </template>
    </v-text-field>
</template>

<script>
    import _ from 'lodash'
    import DisableChromeAutocompleteMixin from '../../../../mixins/Hacks/DisableChromeAutocompleteMixin'

    const defaultModel = {
        value: '',
        is_confirmed: false,
    }

    export default {
        name: "ConfirmableTextField",
        mixins: [
            DisableChromeAutocompleteMixin,
        ],
        inheritAttrs: false,
        props: {
            value: {
                required: false,
                type: Object,
                default: () => defaultModel,
            },
            autocomplete: {
                required: false,
                type: String,
                default: 'autocomplete',
            },
            confirmDisabled: {
                required: false,
                type: Boolean,
                default: false,
            },
            placeholder: {
                required: false,
                type: String,
                default: '',
            },
            confirmIcon: {
                required: false,
                type: String,
                default: 'mdi-check-bold',
            },
            tooltipConfirmed: {
                required: false,
                type: String,
                default: 'forms.confirmableTextField.tooltip.confirmed',
            },
            tooltipUnconfirmed: {
                required: false,
                type: String,
                default: 'forms.confirmableTextField.tooltip.unconfirmed',
            },
        },
        data: (vm) => ({
            confirmValue: defaultModel.is_confirmed,
            confirmTooltip: vm.getConfirmTooltip(defaultModel.is_confirmed),
            textFieldValue: defaultModel.value,
        }),
        watch: {
            confirmValue(newValue) {
                this.confirmTooltip = this.getConfirmTooltip(newValue)
                this.emitInput()
            },
            textFieldValue() {
                this.emitInput()
            },
            value: {
                deep: true,
                immediate: true,
                handler(input) {
                    this.confirmValue = input.is_confirmed
                    this.textFieldValue = input.value
                }
            },
        },
        methods: {
            getConfirmTooltip(value) {
                return this.l(!!value ? this.tooltipConfirmed : this.tooltipUnconfirmed)
            },
            emitInput() {
                this.$emit('input', {
                    value: this.textFieldValue,
                    is_confirmed: this.confirmValue,
                })
            },
            handleConfirmClick() {
                this.confirmValue = !this.confirmValue
            },
        },
        computed: {
            listeners() {
                return _.omit(this.$listeners, 'input')
            },
            attrs() {
                return _.omit(_.clone(this.$attrs), ['autocomplete', 'placeholder', 'value'])
            },
            slots() {
                return _.omit(_.clone(this.$slots), ['prepend-inner'])
            },
        },
    }
</script>

<style scoped>

</style>