<template>
    <v-select
            v-if="items.length !== 0"
            :items="items"
            :label="inputLabel"
            v-model="selected"
            v-bind="$attrs"
            v-on="$listeners"
    />
    <api-select
            v-else
            :label="inputLabel"
            api="available/terms/list"
            v-model="selected"
            v-bind="$attrs"
            v-on="$listeners"
            @items="handleItemsUpdate"
    />
</template>

<script>
    import ApiSelect from '../ApiSelect'
    import _ from 'lodash'

    export default {
        name: "TermSelect",
        components: {
            ApiSelect,
        },
        data: vm => ({
            selected: null,
        }),
        computed: {
            inputLabel() {
                return _.isString(this.label) ? this.label : this.l('pages.all.properties.term')
            },
        },
        props: {
            autoSelectFirst: {
                default: false,
                required: false,
                type: Boolean,
            },
            items: {
                default: () => [],
                required: false,
                type: Array,
            },
            label: {
                default: null,
                required: false,
                type: String,
            },
            value: {
                default: '',
                required: false,
                type: String,
            },
        },
        watch: {
            items: {
                deep: true,
                handler(items) {
                    this.handleItemsUpdate(items)
                },
                immediate: true,
            },
            selected(selected) {
                this.emitInput(selected)
            },
            value: {
                handler(value) {
                    this.selected = value
                },
                immediate: true,
            },
        },
        methods: {
            emitInput(value = null) {
                this.$emit('input', value || this.selected)
            },
            handleItemsUpdate(items) {
                if (this.autoSelectFirst && (items.length !== 0) && (!this.selected || !~items.map(item => item.value).indexOf(this.selected))) {
                    this.selected = _.first(items).value
                }
            },
        },

    }
</script>

<style scoped>

</style>