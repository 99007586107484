<template>
    <item-show-view v-slot:default="_">
        <v-row>
            <v-col cols="12" sm="6">
                <subcard>
                    <v-row>
                        <v-col cols="3">
                            {{ l('pages.fund_locks.properties.employee') }}
                        </v-col>
                        <v-col cols="9" class="black--text">
                            <router-link v-if="!!_.item.user" :to="{name: 'employees.show', params: {uuid: _.item.user.uuid}}">{{ _.item.user | listName }}</router-link>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="3">
                            {{ l('pages.fund_locks.properties.note') }}
                        </v-col>
                        <v-col cols="9" class="black--text">
                            {{ _.item.note }}
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="3">
                            {{ l('pages.fund_locks.properties.amount') }}
                        </v-col>
                        <v-col cols="9" class="black--text">
                            <amount currency>{{ _.item.amount }}</amount>
                        </v-col>
                    </v-row>
                </subcard>
            </v-col>
            <v-col cols="12" sm="6">
                <subcard>

                    <v-row>
                        <v-col cols="3">
                            {{ l('pages.fund_locks.properties.created_at') }}
                        </v-col>
                        <v-col cols="9" class="black--text">
                            {{ _.item.created_at | euroDateTime }}<span v-if="_.item.created_by_id">, {{ _.item.created_by | listName }}</span>
                        </v-col>
                    </v-row>
                    <v-row v-if="_.item.updated_at && _.item.updated_at !== _.item.created_at">
                        <v-col cols="3">
                            {{ l('pages.fund_locks.properties.updated_at') }}
                        </v-col>
                        <v-col cols="9" class="black--text">
                            {{ _.item.updated_at | euroDateTime }}<span v-if="_.item.updated_by_id">, {{ _.item.updated_by | listName }}</span>
                        </v-col>
                    </v-row>
                    <v-row v-if="_.item.is_processed">
                        <v-col cols="3">
                            {{ l('pages.fund_locks.properties.processed_at') }}
                        </v-col>
                        <v-col cols="9" class="black--text">
                            <check-mark :value="_.item.is_processed"/>
                            <span v-if="_.item.processed_at"> {{ _.item.processed_at | euroDateTime }}</span>
                        </v-col>
                    </v-row>
                </subcard>
            </v-col>
        </v-row>
    </item-show-view>
</template>

<script>
    import ItemShowView from '../../Item/Views/ItemShowView'
    import Amount from "../../Shared/Text/Amount";
    import CheckMark from "../../Shared/Icons/CheckMark";
    import Subcard from "../../Shared/Cards/Subcard";

    export default {
        name: 'FundLocksShowPage',
        components: {
            Subcard,
            CheckMark,
            Amount,
            ItemShowView,
        },
    }
</script>

<style scoped>

</style>