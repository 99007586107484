<template>
    <item-show-view v-slot:default="{ item: shift }">
        <v-row>
            <v-col>
                <subcard>
                    <v-simple-table>
                        <tbody>
                        <tr>
                            <th>{{ l('pages.shifts.properties.user') }}</th>
                            <td>{{ shift.user | name }}</td>
                        </tr>
                        <tr>
                            <th>{{ l('pages.shifts.properties.location') }}</th>
                            <td>{{ shift.location | name }}</td>
                        </tr>
                        <tr>
                            <th>{{ l('pages.shifts.properties.position') }}</th>
                            <td>{{ shift.position | name }} ({{ shift.unit | name }})</td>
                        </tr>
                        <tr>
                            <th>{{ l('pages.shifts.properties.date') }}</th>
                            <td>{{ shift.date | euroDate }}</td>
                        </tr>
                        <tr v-if="shift.time">
                            <th>{{ l('pages.shifts.properties.time') }}</th>
                            <td>{{ shift.time | shortTime }}</td>
                        </tr>
                        <tr>
                            <th>{{ l('pages.shifts.properties.rate') }}</th>
                            <td>{{ shift.rate | amount }} {{ l('global.currency') }}</td>
                        </tr>
                        <tr>
                            <th>{{ l('pages.shifts.properties.hours') }}</th>
                            <td>{{ shift.hours | euroNumber }}</td>
                        </tr>
                        <tr>
                            <th>{{ l('pages.shifts.properties.is_payable') }}</th>
                            <td>{{ shift.is_payable | yesno }}</td>
                        </tr>
                        <tr>
                            <th>{{ l('pages.shifts.properties.amount') }}</th>
                            <td><amount currency>{{ shift.amount }}</amount></td>
                        </tr>
                        </tbody>
                    </v-simple-table>
                </subcard>
            </v-col>
        </v-row>
    </item-show-view>
</template>

<script>
    import ItemShowView from '../../Item/Views/ItemShowView'
    import Subcard from '../../Shared/Cards/Subcard'
    import Amount from "../../Shared/Text/Amount";

    export default {
        name: 'shiftsShowPage',
        components: {
            Amount,
            ItemShowView,
            Subcard,
        },
    }
</script>

<style scoped>

</style>