<template>
    <v-input
            :dense="dense"
            :disabled="disabled"
            :hide-details="hideDetails"
    >

        <v-row dense>
                    <v-col cols="12" md="2">
                        <v-select
                                :hide-details="hideDetails"
                                :dense="dense"
                                :disabled="disabled"
                                :label="l('pages.residences.properties.type')"
                                :items="modelTypes"
                                v-model="model.type_id"
                        ></v-select>
                    </v-col>
                    <v-col cols="12" md="3">
                        <date-field
                                clearable
                                :hide-details="hideDetails"
                                :dense="dense"
                                :disabled="disabled"
                                :label="l('pages.residences.properties.from_date')"
                                :autocomplete="autocomplete"
                                v-model="model.from_date"
                        />
                    </v-col>
                    <v-col cols="12" md="3">
                        <date-field
                                :hide-details="hideDetails"
                                :dense="dense"
                                :disabled="disabled"
                                :label="l('pages.residences.properties.to_date')"
                                :autocomplete="autocomplete"
                                v-model="model.to_date"
                        />
                    </v-col>
                    <v-col cols="12" md="4">
                        <v-text-field
                                :hide-details="hideDetails"
                                :dense="dense"
                                :disabled="disabled"
                                :label="l('pages.residences.properties.note')"
                                :autocomplete="autocomplete"
                                v-model="model.note"
                        ></v-text-field>
                    </v-col>
        </v-row>

        <template
            v-for="(_, slot) of $slots"
            :slot="slot"
        >
            <slot :name="slot" />
        </template>

    </v-input>
</template>

<script>
    import DateField from '../../../../Shared/Forms/TextFields/DateField'
    import DisableChromeAutocompleteMixin from '../../../../../mixins/Hacks/DisableChromeAutocompleteMixin'

    const modelTypesApiUrl = 'residence-types/list'

    const defaultModel = {
        type_id: '',
        from_date: '',
        to_date: '',
        note: '',
    } 
    
    export default {
        name: "ResidenceField",
        mixins: [
            DisableChromeAutocompleteMixin,
        ],
        components: {
            DateField,
        },
        props: {
            autocomplete: {
                required: false,
                type: String,
                default: 'autocomplete',
            },
            dense: {
                required: false,
                type: Boolean,
                default: false,
            },
            disabled: {
                type: Boolean,
                required: false,
                default: false,
            },
            hideDetails: {
                required: false,
                type: Boolean,
                default: false,
            },
            types: {
                required: false,
                type: Array,
                default: () => ([]),
            },
            value: {
                required: false,
                type: Object,
                default: () => defaultModel,
            },
        },
        data: (vm) => ({
            model: defaultModel,
            modelTypes: [],
            defaultType: {
                text: vm.l('global.undefined'),
                value: '',
            },
        }),
        watch: {
            value: {
                immediate: true,
                deep: true,
                handler(value) {
                    this.model = value
                },
            },
            types: {
                immediate: true,
                deep: true,
                handler(value) {
                    if (value.length > 0) {
                        this.modelTypes = value
                    } else {
                        this.$api.get(modelTypesApiUrl).then(function(response) {
                            this.modelTypes = response.data
//                            this.modelTypes.unshift(this.defaultType)
                        }.bind(this))
                    }
                },
            },
            model: {
                deep: true,
                handler(input) {
                    this.$emit('input', input)
                },
            }
        },
    }
</script>

<style scoped>

</style>