import store from "../store";
import {USER_REQUEST_RESTORE} from "../store/actions/user";
import {APP_SET_TARGET_URL, APP_WIPE_TARGET_URL} from "../store/mutations/app";
import Vue from "vue";

const auth = {
    guard: {
        guest: (to, from, next) => {
            if (!store.getters.userAuthenticated) {
                next()
                return
            }
            next('/')
        },
        user: (to, from, next) => {
            if (store.getters.userAuthenticated) {
                next()
                return
            }
            auth.target.remember()
            next('/login')
        },
        test(to, from, next) {
            if (store.getters.userHasFlag('is_admin')) {
                auth.guard.user(to, from, next)
                return
            }
            Vue.notify({
                text: 'Azazaz!',
                type: 'error',
            })

            const currentPath = window.location.pathname
            if (currentPath.indexOf(to.fullPath) === 0) {
                next('/')
            } else {
                next(currentPath)
            }
        },
    },
    target: {
        remember: () => store.commit(APP_SET_TARGET_URL, window.location.pathname),
        forget: () => store.commit(APP_WIPE_TARGET_URL),
    },
    validation: {
        isAvailable: () => !store.getters.userProfileLoaded,
        isNecessary: () => store.getters.userTokenStored && !store.getters.userAuthenticated,
        dispatch: async function(to, from, next) {
            if (auth.validation.isAvailable()) {
                await store.dispatch(USER_REQUEST_RESTORE)
                    .then(() => {
                        auth.target.forget()
                        next()
                    })
            }
            next()
        },
        dispatchIfNecessary: async function(to, from, next) {
            if (auth.validation.isNecessary()) {
                auth.target.remember()
                await auth.validation.dispatch(to, from, next)
            }
            next()
        }
    }
};

export default auth;