<template>
  <div :class="'d-flex ' + className">
    <v-menu
        ref="pickerVisible"
        v-model="pickerVisible"
        :close-on-content-click="false"
        :disabled="readonly"
        transition="scale-transition"
        offset-y
        @keydown.esc="hidePicker"
    >
      <template v-slot:activator="{ on }">
        <v-input
            hide-details
            dense
            v-on="on"
            :class="'v-text-field user-picker' + (readonly ? '' : ' editable')"
        >
          <div class="user-picker__line">
            <div class="user-picker__picked-user">

              <div :class="id ? 'caption' : 'placeholder'">
                {{ l('forms.userPicker.label') }}
              </div>

              <div v-if="id">
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                                    <span
                                        :class="'picked-user__user-name ' + (user.gender ? {'unknown': 'black--text', 'male': 'blue--text text--darken-2', 'female': 'pink--text text--darken-1'}[user.gender] : 'black--text')"
                                        v-on="on"
                                    >
                                        {{ user.list_name }}
                                    </span>
                  </template>
                  <span class="font-weight-bold">
                      {{ composeUserTooltip(user) }}
                  </span>
                </v-tooltip>

                <v-chip
                    v-if="!noBirthDate && !!user && !!user.birth_date"
                    small
                    color="blue-grey lighten-5"
                    class="font-weight-bold ml-1 px-2"
                >
                  {{ user.birth_date | euroDate }}
                </v-chip>

                <span v-if="!noPhones">
                                    <v-chip
                                        small
                                        color="amber lighten-3"
                                        class="font-weight-bold text-uppercase ml-1 px-2"
                                        v-for="(phone, index) in user.phones"
                                        :key="index"
                                    >
                                        {{ phone | phoneNumber }}
                                    </v-chip>
                                </span>

                <span v-if="!noDocuments">
                                    <v-tooltip
                                        top
                                        v-for="key in documentChips"
                                        :key="key"
                                    >
                                        <template v-slot:activator="{ on }">
                                            <v-chip
                                                small
                                                :color="user[key] ? 'light-green lighten-3' : 'red'"
                                                :dark="!user[key]"
                                                class="font-weight-bold text-uppercase ml-1 px-2"
                                                v-on="on"
                                            >
                                                {{ l('forms.userPicker.properties.' + key) }}
                                            </v-chip>
                                        </template>
                                        <span class="font-weight-bold">
                                            {{
                                            l('forms.userPicker.tooltips.' + key + '.' + (user[key] ? 'yes' : 'no'))
                                          }}
                                        </span>
                                    </v-tooltip>
                                </span>

                <v-chip
                    small
                    :color="user.available_amount < 0 ? 'deep-orange lighten-4' : 'green lighten-4'"
                    class="font-weight-bold ml-1 px-2"
                >
                  {{ user.available_amount | amount }} {{ l('global.currency') }}
                </v-chip>

              </div>
            </div>
          </div>
        </v-input>
      </template>
      <v-card>

        <div class="blue-grey lighten-5 py-3 px-4">

          <div class="d-none d-md-flex">
            <v-flex class="flex-grow-1 flex-shrink-0 mt-1 mb-1">
              <trim-text-field
                  clearable
                  dense
                  hide-details
                  v-model="tableFilter.text"
                  :label="l('pages.employees.index.filters.text')"
              />
            </v-flex>
            <v-flex v-if="!noListDocuments" class="flex-shrink-0 flex-grow-0 mt-1 mb-1">
              <v-flex>
                <v-checkbox
                    v-for="checkboxFilter in documentListFilters"
                    :key="checkboxFilter"
                    dense
                    hide-details
                    class="d-inline-flex mx-2 mt-0"
                    v-model="tableFilter[checkboxFilter]"
                    true-value="only"
                    false-value="show"
                    :label="l('forms.userPicker.properties.' + checkboxFilter)"
                />
              </v-flex>
            </v-flex>
          </div>
          <div class="d-block d-md-none">
            <v-row dense>
              <v-col>
                <trim-text-field
                    dense
                    hide-details
                    v-model="tableFilter.text"
                    :label="l('pages.employees.index.filters.text')"
                />
              </v-col>
            </v-row>
            <v-row dense v-if="!noListDocuments">
              <v-col>
                <v-checkbox
                    v-for="mobileCheckboxFilter in documentListFilters"
                    :key="mobileCheckboxFilter"
                    dense
                    hide-details
                    class="d-inline-flex mx-2 mt-0"
                    v-model="tableFilter[mobileCheckboxFilter]"
                    true-value="only"
                    false-value="show"
                    :label="l('forms.userPicker.properties.' + mobileCheckboxFilter)"
                />
              </v-col>
            </v-row>

          </div>
        </div>

        <v-card-text>
          <v-data-table
              class="user-picker__table"
              dense
              :headers="tableHeaders"
              :footer-props="{itemsPerPageOptions: [tableOptions.itemsPerPage], showFirstLastPage: true}"
              :items="tableItems"
              :loading="tableLoading"
              :options.sync="tableOptions"
              :server-items-length="tableItemCount"
              @click:row="handleTableRowClick"
          >

            <template slot="loading">
              <div class="text-center user-picker__table-loading">
                {{ l('$vuetify.dataIterator.loadingText') }}
              </div>
            </template>

            <template v-slot:item.list_name="_">
              <!--
                                          <gender-icon small>
                                              {{ _.item.gender }}
                                          </gender-icon>
              -->
              <span>
                                {{ _.value }}
                            </span>
            </template>

            <template v-slot:item.birth_date="_">
              {{ _.value | euroDate }}
            </template>

            <template v-slot:item.phones="_">
              <div
                  v-for="(phone, index) in _.value"
                  :key="index"
                  class="text-no-wrap"
              >
                {{ phone | phoneNumber }}
              </div>
            </template>

            <template v-slot:item.available_amount="_">
              <amount currency>{{ _.value }}</amount>
            </template>

            <template v-slot:item.gender="_">
              <gender-icon>{{ _.value }}</gender-icon>
            </template>

            <template
                v-for="checkboxSlot in documentListValues"
                v-slot:[checkboxSlot]="_">
              <v-tooltip top v-if="_.item.is_temporary">
                <template v-slot:activator="{ on }">
                  <check-mark
                      v-on="on"
                      x-small
                      :value="true"
                      icon-checked="mdi-minus"
                      color-checked="purple"
                  />
                </template>
                <span class="font-weight-bold">
                    {{ l('pages.employees.properties.is_temporary') }}
                </span>
              </v-tooltip>
              <check-mark
                  v-else
                  x-small
                  :value="_.value"
                  icon-checked="mdi-circle-medium"
                  icon-unchecked="mdi-circle-medium"
                  color-unchecked="red lighten-4"
              />
            </template>

          </v-data-table>
        </v-card-text>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import _ from 'lodash'
import GenderIcon from '../Icons/GenderIcon'
import CheckMark from '../Icons/CheckMark'
import TrimTextField from './TextFields/TrimTextField'
import Amount from '../Text/Amount'

const apiUrl = 'users'
const perPage = 10

const defaultTableOptions = {
  groupBy: [],
  groupDesc: [],
  itemsPerPage: perPage,
  multiSort: false,
  mustSort: false,
  page: 1,
  sortBy: [],
  sortDesc: [],
};

const defaultTableFilter = {
  text: '',
  except_id: null,
  is_employee: null,
  stay_permit: null,
  work_permit: null,
  valid_contract: null,
}

export default {
  name: "UserPicker",
  components: {
    Amount,
    TrimTextField,
    CheckMark,
    GenderIcon,
  },
  inheritAttrs: false,
  props: {
    value: {
      required: false,
      default: null,
    },
    employee: {
      type: Boolean,
      required: false,
      default: null,
    },
    noBirthDate: {
      type: Boolean,
      required: false,
      default: false,
    },
    noDocuments: {
      type: Boolean,
      required: false,
      default: false,
    },
    noFoodCert: {
      type: Boolean,
      required: false,
      default: false,
    },
    noListDocuments: {
      type: Boolean,
      required: false,
      default: false,
    },
    noListFoodCert: {
      type: Boolean,
      required: false,
      default: false,
    },
    noPhones: {
      type: Boolean,
      required: false,
      default: false,
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: vm => ({
    lodash: _,
    availableDocumentAttributes: ['stay_permit', 'work_permit', 'valid_contract'/*, 'food_cert'*/],
    id: null,
    user: {},
    className: '',
    pickerVisible: false,
    tableItems: [],
    tableLoading: false,
    tableFilter: vm.clone(defaultTableFilter),
    basicTableHeaders: [
      {
        value: 'list_name',
        text: vm.l('forms.userPicker.properties.list_name'),
        align: 'start',
        sortable: true,
        filterable: false,
      },
      {
        value: 'birth_date',
        text: vm.l('forms.userPicker.properties.birth_date'),
        align: 'end',
        sortable: true,
        filterable: false,
      },
      /*
      {
          value: 'age',
          text: vm.l('forms.userPicker.properties.age'),
          align: 'end',
          sortable: true,
          filterable: false,
      },
      {
          value: 'gender',
          text: vm.l('forms.userPicker.properties.gender'),
          align: 'center',
          sortable: true,
          filterable: false,
      },
      */
      {
        value: 'phones',
        text: vm.l('forms.userPicker.properties.phones'),
        align: 'end',
        sortable: false,
        filterable: false,
      },
      {
        value: 'available_amount',
        text: vm.l('forms.userPicker.properties.available_amount'),
        align: 'end',
        sortable: true,
        filterable: false,
      },
    ],
    documentTableHeaders: [
      {
        value: 'stay_permit',
        text: vm.l('forms.userPicker.properties.stay_permit'),
        align: 'center',
        sortable: false,
        filterable: false,
      },
      {
        value: 'work_permit',
        text: vm.l('forms.userPicker.properties.work_permit'),
        align: 'center',
        sortable: false,
        filterable: false,
      },
      {
        value: 'valid_contract',
        text: vm.l('forms.userPicker.properties.valid_contract'),
        align: 'center',
        sortable: false,
        filterable: false,
      },
      /*
      {
        value: 'food_cert',
        text: vm.l('forms.userPicker.properties.food_cert'),
        align: 'center',
        sortable: false,
        filterable: false,
      },
      */
    ],
    tableOptions: vm.clone(defaultTableOptions),
    tableParams: {},
    tableItemCount: 0,
    tableRefreshNeeded: true,
    handlers: {
      tableFilter: _.throttle(vm.handleTableFilterChange, 300, {leading: false, trailing: true})
    }
  }),
  computed: {
    documentListFilters() {
      let headers = this.availableDocumentAttributes
      if (this.noListFoodCert) {
        headers = _.filter(headers, header => header !== 'food_cert')
      }
      return headers
    },
    documentListValues() {
      return _.map(this.documentListFilters, header => ('item.' + header))
    },
    documentChips() {
      let attributes = this.availableDocumentAttributes
      if (this.noFoodCert) {
        attributes = _.filter(attributes, attribute => attribute !== 'food_cert')
      }
      return attributes
    },
    tableHeaders() {
      if (this.noListDocuments) {
        return this.basicTableHeaders
      } else {
        let documentTableHeaders = JSON.parse(JSON.stringify(this.documentTableHeaders))
        if (this.noListFoodCert) {
          documentTableHeaders = _.filter(documentTableHeaders, header => header.value !== 'food_cert')
        }
        return this.basicTableHeaders.concat(documentTableHeaders)
      }
    },
  },
  watch: {
    '$el.className': {
      handler(className) {
        this.className = className
      },
      immediate: true,
    },
    employee: {
      immediate: true,
      handler(value) {
        if (undefined !== value && null !== value && '' !== value) {
          this.tableFilter.is_employee = !!value
        } else if (undefined !== this.tableFilter.is_employee) {
          delete this.tableFilter.is_employee
        }
      },
    },
    id: {
      handler(id) {
        if (!this.user || this.user.id !== id) {
          this.updateUser(id)
              .then(function () {
                this.handleIdChange(id)
              }.bind(this))
              .catch(function () {
                this.handleIdChange(null)
              }.bind(this))
        } else {
          this.handleIdChange(id)
        }
      },
    },
    tableOptions: {
      deep: true,
      handler(options) {
        this.handleTableOptionsChange(options)
      },
    },
    tableParams: {
      deep: true,
      handler(params) {
        this.handleTableParamsChange(params)
      },
    },
    user: {
      handler(user) {
        this.id = (!!user && user.id) ? user.id : null
      },
    },
    value: {
      immediate: true,
      handler(value) {
        if (this.id !== value) {
          this.id = value
        }
      },
    },
    pickerVisible: {
      immediate: true,
      handler(value) {
        if (value && this.tableRefreshNeeded) {
          this.refreshTable()
        }
      },
    },
  },
  methods: {

    // Service
    clone(value) {
      return _.isArray(value) || _.isObject(value)
          ? JSON.parse(JSON.stringify(value))
          : value
    },

    itemsEqual(item1, item2) {
      const type = typeof item1
      switch (true) {
        case type !== (typeof item2):
          return false
        case type === 'object':
          return !JSON.stringify(item1).localeCompare(JSON.stringify(item2))
        default:
          return item1 === item2
      }
    },

    objectsEqual(object1, object2) {
      if (_.isObject(object1) && _.isObject(object2)) {
        const keys = _.uniq(_.merge(_.keys(object1), _.keys(object2)))
        return _.filter(keys, function (key) {
          if (undefined !== object1[key] && undefined !== object2[key] && (typeof object1[key]) === (typeof object2[key])) {
            return _.isObject(object1[key]) ? this.objectsEqual(object1[key], object2[key]) : (object1[key] === object2[key])
          }
          return false
        }.bind(this)).length === keys.length
      }
      return false
    },

    composeUserTooltip(user) {

      let tooltipElements = []
      tooltipElements.push('ID ' + user.id)
//                tooltipElements.push(user.gender && user.gender !== 'unknown' ? (this.$options.filters.gender(user.gender)) : '')
      tooltipElements.push(this.$options.filters.euroDate(user.birth_date || ''))
      tooltipElements.push(this.$options.filters.phones(user.phones || []))

      return _.filter(tooltipElements, value => !!value).join(', ')
    },

    fetchTableData() {
      return this.$api.get(apiUrl, {params: this.tableParams})
    },

    handleTableOptionsChange() {
      this.updateTableParams()
    },

    handleTableFilterChange() {
      this.updateTableParams()
    },

    handleTableParamsChange() {
      if (this.pickerVisible) {
        this.refreshTable()
      } else {
        this.tableRefreshNeeded = true
      }
    },
    handleTableRowClick(item) {
      this.hidePicker()
      this.user = this.clone(item)
    },
    handleIdChange(id) {
      this.tableFilter.except_id = id
      this.$emit('input', id)
    },

    hidePicker() {
      this.pickerVisible = false
    },

    refreshTable() {
      this.wipeTableData()
      this.tableLoading = true
      return this.fetchTableData()
          .then(function (resp) {
            this.updateTableData(resp.data)
          }.bind(this))
          .finally(function () {
            this.tableLoading = false
          }.bind(this))
    },
    updateUser(id = null) {

      if (null === id) {
        id = this.id
      }

      return new Promise(function (resolve, reject) {
        if (null !== id && !isNaN(id)) {
          this.$api.get(apiUrl, {params: {filter: {only_id: id}}})
              .then(function (response) {
                if (_.get(response, 'data.total', 0) === 1) {
                  this.user = response.data.items[0]
                  resolve()
                }
                reject()
              }.bind(this))
              .catch(err => reject(err))
        } else {
          this.user = {}
          resolve()
        }
      }.bind(this))
    },
    updateTableData(data) {
      this.tableItems = data.items
      this.tableItemCount = data.total
      this.tableOptions = _.merge(this.tableOptions, data.options)
    },
    wipeTableData() {
      this.tableItems = []
      this.tableItemCount = 0
    },

    updateTableParams() {
      const params = _.merge(this.clone(this.tableOptions), {filter: this.clone(this.tableFilter)})
      if (!this.objectsEqual(params, this.tableParams)) {
        this.tableParams = params
      }
    },

  },
  created() {
    // Put a watcher on tableFilter via debounce/throttle
    this.$watch('tableFilter', this.handlers.tableFilter, {deep: true, immediate: true})
  },
  mounted() {
    this.className = _.get(this, ['$el', 'className'], '').replace('v-menu', '').trim()
  }
}
</script>

<style>
.user-picker.editable .user-picker__line,
.user-picker.editable .user-picker__line *,
.user-picker.editable .user-picker__line,
.user-picker.editable.v-text-field > .v-input__control > .v-input__slot,
.user-picker__table tbody tr {
  cursor: pointer;
}

.user-picker__picked-user .placeholder {
  padding-top: 16px;
  padding-bottom: 2px;
}

.user-picker__picked-user .caption {
  margin-top: -1px;
}

.user-picker__picked-user .v-chip {
  margin-top: -6px;
  margin-bottom: 1px;
}

.user-picker__picked-user .picked-user__user-name {
  display: inline-block;
}

.user-picker__table-loading {
  padding: 8em 5px;
}

</style>
