<template>
    <v-dialog
            transition="none"
            max-width="290"
            v-model="isVisible"
            @click:outside="reject()"
            @keydown.esc="reject()"
    >
        <v-card>
            <v-card-title>
                {{ l(title) }}
            </v-card-title>
            <v-card-text>
                {{ l(text) }}
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="confirm()" color="red">
                    {{ l(confirmButton) }}
                </v-btn>
                <v-btn text @click="reject()">
                    {{ l(rejectButton) }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    export default {
        name: "DeleteDialog",
        data: () => ({
            isVisible: false,
        }),
        props: {
            autohide: {
                type: Boolean,
                required: false,
                default: false
            },
            title: {
                type: String,
                required: false,
                default: 'dialogs.delete.title'
            },
            text: {
                type: String,
                required: false,
                default: 'dialogs.delete.text'
            },
            confirmButton: {
                type: String,
                required: false,
                default: 'dialogs.delete.confirm'
            },
            rejectButton: {
                type: String,
                required: false,
                default: 'dialogs.delete.reject'
            },
            visible: {
                type: Boolean,
                required: false,
            },
        },
        methods: {
            confirm() {
                if (this.autohide) {
                    this.hide()
                }
                this.$emit('confirmed')
            },
            reject() {
                if (this.autohide) {
                    this.hide()
                }
                this.$emit('rejected')
            },
            hide() {
                this.setVisibility(false)
            },
            reveal() {
                this.setVisibility(true)
            },
            show() {
                this.reveal()
            },
            setVisibility(visibility) {
                visibility = !!visibility
                if (this.isVisible !== visibility) {
                    this.isVisible = visibility
                    this.$emit(visibility ? 'revealed' : 'hidden')
                }
            }
        },
        mounted() {
            if (undefined !== this.visible) {
                this.setVisibility(this.visible)
            }
        },
        watch: {
            visible() {
                this.setVisibility(this.visible)
            }
        }
    }
</script>

<style scoped>

</style>