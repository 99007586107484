<template>
    <item-list
            v-bind="$attrs"
            v-on="$listeners"
    >
        <template v-slot:header.actions="_">
            <!-- CREATE BUTTON -->
            <create-button
                    square
                    v-if="createButton"
                    :to="$route.path + '/create'" class="ml-2 success"
                    :label="l('pages.all.actions.create')"
            />

        </template>

        <template
            v-for="(_, slot) of $scopedSlots"
            v-slot:[slot]="scope"
        >
            <slot :name="slot" v-bind="scope" />
        </template>

    </item-list>
</template>

<script>
    import ItemList from './ItemList'
    import CreateButton from "../Shared/Buttons/CreateButton";
    export default {
        name: "SubItemList",
        components: {
            CreateButton,
            ItemList,
        },
        props: {
            createButton: {
                default: false,
                required: false,
                type: Boolean,
            },
        },
    }
</script>

<style scoped>

</style>