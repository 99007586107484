<template>
    <item-show-view
            :tabs="tabs"
            v-bind="$attrs"
            v-on="$listeners"
    >

        <template v-slot:header="_">
            <tunnel :value="_.item" @input="handleLocationTunnel"/>
            <v-chip
                    color="amber lighten-4"
                    v-if="closeDateMessage"
                    class="ml-2 font-weight-bold text-uppercase"
                    small
            >
                {{ closeDateMessage }}
            </v-chip>
        </template>

        <template v-slot:buttons="_">
                <v-btn
                        v-if="previousMonthButtonEnabled"
                        class="d-none d-sm-inline-flex"
                        @click="handlePreviousMonthButtonClick()"
                        :disabled="_.disabled"
                        text
                >
                    {{ previousMonthButtonText }}
                </v-btn>
                <v-btn
                        v-if="currentMonthButtonEnabled"
                        class="d-none d-sm-inline-flex"
                        @click="handleCurrentMonthButtonClick()"
                        :disabled="_.disabled"
                        text
                >
                    {{ currentMonthButtonText }}
                </v-btn>
        </template>

        <template v-slot:default="_">
            <slot
                    v-bind:item-disabled="_.itemDisabled"
                    v-bind:item="location"
            />
        </template>

    </item-show-view>
</template>

<script>
    import _ from 'lodash'
    import ItemShowView from '../../Item/Views/ItemShowView'
    import UserMixin from "../../../mixins/UserMixin";
    import Tunnel from "../../Shared/Tunnel";

    export default {
        name: 'LocationsShowPage',
        mixins: [
            UserMixin,
        ],
        components: {
            Tunnel,
            ItemShowView,
        },
        data: vm => ({
            currentMonth: vm.$moment().startOf('month'),
            previousMonth: vm.$moment().startOf('month').subtract(1, 'months'),
            closeDateMessage: null,
            hasLockDayCome: false,
            hasFinalLockDayCome: false,
            isPreviousMonthOpen: null,
            isCurrentMonthOpen: null,
            location: {},
            locationHasPositions: false,
            locationUuid: null,
            monthButtonsAvailable: false,
            currentMonthButtonText: '',
            previousMonthButtonText: '',
        }),
        computed: {
            lockDay() {return this.$store.getters.appConfigValue('lockday')},
            finalLockDay() {return this.$store.getters.appConfigValue('finallockday')},
            currentMonthName() {return this.l('global.months.' + (this.currentMonth.format('MM') || ''))},
            previousMonthName() {return this.l('global.months.' + (this.previousMonth.format('MM') || ''))},
            currentMonthTerm() {return this.currentMonth.format('YYYYMM') || ''},
            previousMonthTerm() {return this.previousMonth.format('YYYYMM') || ''},

            currentMonthButtonEnabled() {
                let enabled = this.monthButtonsAvailable

                if (enabled) {
                    enabled = this.hasLockDayCome
                }

                if (enabled) {
                    enabled = this.userWorksWithLocations
                }

                return enabled
            },
            previousMonthButtonEnabled() {
                let enabled = this.monthButtonsAvailable

                if (enabled) {
                    if (this.isPreviousMonthOpen) {
                        // Close
                        enabled = this.userWorksWithLocations
                    } else {
                        // Open
                        enabled = !this.hasFinalLockDayCome && this.userIsGodLike
                    }
                }

                return enabled
            },
            tabs() {
                return _.map(_.filter([
                    {
                        name: 'details',
                        show: true,
                    },
                    {
                        name: 'positions',
                        show: this.userIsGodLike || this.userHasFlag('is_accountant'),
                    },
                    {
                        name: 'assignments',
                        show: this.locationHasPositions,
                    },
                    {
                        name: 'shifts',
                        show: this.locationHasPositions,
                    },
                ], tab => tab.show), tab => tab.name)
            },
        },
        watch: {
            lockDay: {
                immediate: true,
                handler(value) {
                    this.hasLockDayCome = (this.$moment().date() >= value)
                }
            },
            finalLockDay: {
                immediate: true,
                handler(value) {
                    this.hasFinalLockDayCome = (this.$moment().date() >= value)
                }
            },
            isCurrentMonthOpen: {
                immediate: true,
                handler(value) {
                    if (undefined === value || null === value) {
                        this.currentMonthButtonText = ''
                    } else {
                        this.currentMonthButtonText = this.l('pages.locations.actions.' + (value ? 'close' : 'open')) + ' ' + this.currentMonthName
                    }
                }
            },
            isPreviousMonthOpen: {
                immediate: true,
                handler(value) {
                    if (null === value) {
                        this.previousMonthButtonText = ''
                    } else {
                        this.previousMonthButtonText = this.l('pages.locations.actions.' + (value ? 'close' : 'open')) + ' ' + this.previousMonthName
                    }
                }
            },
            location(location) {

                this.locationUuid = _.get(location, 'uuid', null)
                this.locationHasPositions = _.get(location, 'positions', []).length
                
                const openTerms = _.get(location, 'open_terms', [])

                const isCurrentMonthOpen = (openTerms.indexOf(this.currentMonthTerm) > -1)
                const isPreviousMonthOpen = (openTerms.indexOf(this.previousMonthTerm) > -1)
                
                this.isCurrentMonthOpen = isCurrentMonthOpen
                this.isPreviousMonthOpen = isPreviousMonthOpen

                this.monthButtonsAvailable = true


                if (!!location) {

                    const lockDay = this.lockDay
                    const lockDayHasCome = this.hasLockDayCome

                    let closeMonth = null, closeDate = null

                    switch (true) {

                        case !lockDayHasCome && this.isPreviousMonthOpen:
                            closeMonth = this.previousMonthName
                            closeDate = this.$moment().date(lockDay)
                            break
                        case lockDayHasCome && this.isCurrentMonthOpen:
                            closeMonth = this.currentMonthName
                            closeDate = this.$moment().endOf('month').add(lockDay, 'days')
                            break
                    }

                    if (null !== closeDate) {
                        closeDate = closeDate.format('DD.MM.YYYY') + ', 00:00'
                    }

                    this.closeDateMessage = (closeMonth && closeDate)
                        ? this.l('pages.locations.show.messages.close', {month: closeMonth, date: closeDate})
                        : null
                }
            }
        },
        methods: {
            handleLocationTunnel(location) {
                this.location = location
            },
            handleCurrentMonthButtonClick() {
                if (this.isCurrentMonthOpen) {
                    this.closeMonth(this.currentMonthTerm)
                } else {
                    this.openMonth(this.currentMonthTerm)
                }
            },
            handlePreviousMonthButtonClick() {
                if (this.isPreviousMonthOpen) {
                    this.closeMonth(this.previousMonthTerm)
                } else {
                    this.openMonth(this.previousMonthTerm)
                }
            },
            closeMonth(term) {
                return this.updateMonth('close', term)
            },
            openMonth(term) {
                return this.updateMonth('open', term)
            },
            updateMonth(action, term) {

                if (this.locationUuid) {
                    return this.$api.put('locations/' + this.locationUuid + '/' + action + '/' + term)
                        .then(function() {
                            this.reloadLocation()
                        }.bind(this))
                } else {
                    return Promise.reject()
                }
            },
            reloadLocation() {

                if (this.locationUuid) {
                    return this.$api.get('locations/' + this.locationUuid)
                        .then(function(response) {
                            this.location = response.data
                        }.bind(this))
                } else {
                    return Promise.reject()
                }
            },
        },
    }
</script>

<style scoped>

</style>