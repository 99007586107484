<template>
  <employees-show-page
      parent
      content-color="white"
      v-slot:default="{ item: employee, itemDisabled }"
  >
    <v-row>
      <v-col cols="12" md="12" class="pb-0">
        <sub-item-list create-button :headers="headers">

          <template v-slot:filter="_">
            <v-row dense class="pt-5">
              <v-col cols="12" lg="6">
                <v-row dense>
                  <v-col cols="12" md="5">
                    <v-row dense>
                      <v-col cols="12" sm="6">
                        <api-select
                            api="transactions/types"
                            dense
                            hide-details
                            multiple
                            order="asc"
                            v-model="_.filter.type"
                            :label="l('pages.transactions.index.filters.type')"
                        />
                      </v-col>
                      <v-col cols="12" sm="6">
                        <v-select
                            dense
                            hide-details
                            v-model="_.filter.clean"
                            :items="cleanFilterOptions"
                            :label="l('pages.transactions.index.filters.clean.title')"
                        />
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12" md="7">
                    <v-row dense>
                      <v-col cols="12" sm="6">
                        <trim-text-field
                            clearable
                            dense
                            hide-details
                            v-model="_.filter.note"
                            :label="l('pages.transactions.index.filters.note')"
                        />
                      </v-col>
                      <v-col cols="12" sm="6">
                        <api-select
                            api="transactions/creators/list"
                            prepend-empty
                            :empty-text="l('global.anyPerson')"
                            dense
                            hide-details
                            v-model="_.filter.created_by_id"
                            :label="l('pages.transactions.index.filters.created_by')"
                        />
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>

              <v-col cols="12" lg="6">
                <v-row dense>
                  <v-col cols="12" md="6">
                    <v-row dense>
                      <v-col cols="12" sm="6">
                        <term-select
                            clearable
                            dense
                            :disabled="!termFilterOptions || termFilterOptions.length === 0"
                            hide-details
                            :items="termFilterOptions"
                            v-model="_.filter.from_term"
                            :label="l('pages.transactions.index.filters.from_term')"
                        />
                      </v-col>
                      <v-col cols="12" sm="6">
                        <term-select
                            clearable
                            dense
                            :disabled="!termFilterOptions || termFilterOptions.length === 0"
                            hide-details
                            :items="termFilterOptions"
                            v-model="_.filter.to_term"
                            :label="l('pages.transactions.index.filters.to_term')"
                        />
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-row dense>
                      <v-col cols="12" sm="6">
                        <date-field
                            clearable
                            dense
                            hide-details
                            v-model="_.filter.from_date"
                            :label="l('pages.transactions.index.filters.from_date')"
                        />
                      </v-col>
                      <v-col cols="12" sm="6">
                        <date-field
                            clearable
                            dense
                            hide-details
                            v-model="_.filter.to_date"
                            :label="l('pages.transactions.index.filters.to_date')"
                        />
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </template>

          <template v-slot:item.type="_">
            <transaction-type
                :item="_.item"
                :dense="_.dense"
            />
          </template>

          <template v-slot:item.note="_">
            <transaction-note :item="_.item"/>
          </template>

        </sub-item-list>
      </v-col>
    </v-row>
  </employees-show-page>
</template>

<script>
import EmployeesShowPage from '../../show'
import SubItemList from '../../../../Item/SubItemList'
import TrimTextField from '../../../../Shared/Forms/TextFields/TrimTextField'
import DateField from '../../../../Shared/Forms/TextFields/DateField'
import TransactionType from '../../../../Shared/ModelSpecific/Transaction/TransactionType'
import TransactionNote from '../../../../Shared/ModelSpecific/Transaction/TransactionNote'
import ApiSelect from "../../../../Shared/Forms/ApiSelect";
import TermSelect from "../../../../Shared/Forms/Selects/TermSelect";

export default {
  name: 'EmployeesShowTransactionsIndexPage',
  components: {
    TermSelect,
    ApiSelect,
    TransactionNote,
    DateField,
    EmployeesShowPage,
    SubItemList,
    TransactionType,
    TrimTextField,
  },
  data: vm => ({
    cleanFilterOptions: [
      {
        text: vm.l('pages.transactions.index.filters.clean.values.show'),
        value: 'show',
      },
      {
        text: vm.l('pages.transactions.index.filters.clean.values.only'),
        value: 'only',
      },
      {
        text: vm.l('pages.transactions.index.filters.clean.values.hide'),
        value: 'hide',
      },
    ],
    termFilterOptions: [],
    headers: [
      {
        value: 'id',
        align: 'end',
        sortable: true,
        filterable: false,
      },
      {
        value: 'type',
        align: 'center',
        sortable: false,
        filterable: false,
      },
      {
        value: 'note',
        align: 'start',
        sortable: false,
        filterable: false,
      },
      {
        value: 'term',
        align: 'end',
        sortable: true,
        filterable: false,
      },
      {
        value: 'amount',
        align: 'end',
        sortable: true,
        filterable: false,
      },
      {
        value: 'created_at',
        align: 'end',
        sortable: true,
        filterable: false,
      },
      {
        value: 'created_by',
        align: 'start',
        sortable: false,
        filterable: false,
      },
    ],
  }),
  created() {
    this.$api.get('transactions/terms/list/desc')
        .then(function (response) {
          this.termFilterOptions = response.data
        }.bind(this))
        .catch(error => error)
  },
}
</script>

<style scoped>

</style>
