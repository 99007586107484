<template>
  <employees-show-page
      parent
      content-color="white"
      v-slot:default="{ item: employee, itemDisabled }"
  >
    <v-row>
      <v-col cols="12" md="12" class="pb-0">
        <sub-item-list create-button :headers="headers">
          <template v-slot:filter="_">
            <v-row dense class="pt-5">
              <v-col cols="12" sm="3">
                <date-field
                    clearable
                    dense
                    hide-details
                    v-model="_.filter.from_date"
                    :label="l('pages.adjustments.index.filters.from_date')"
                />
              </v-col>
              <v-col cols="12" sm="3">
                <date-field
                    clearable
                    dense
                    hide-details
                    v-model="_.filter.to_date"
                    :label="l('pages.adjustments.index.filters.to_date')"
                />
              </v-col>
            </v-row>
          </template>

        </sub-item-list>
      </v-col>
    </v-row>
  </employees-show-page>
</template>

<script>
import CheckMark from '../../../../Shared/Icons/CheckMark'
import Subcard from '../../../../Shared/Cards/Subcard'
import EmployeesShowPage from '../../show'
import DateField from '../../../../Shared/Forms/TextFields/DateField'
import TrimTextField from '../../../../Shared/Forms/TextFields/TrimTextField'
import SubItemList from '../../../../Item/SubItemList'

export default {
  name: 'EmployeesShowAdjustmentsIndexPage',
  components: {
    CheckMark,
    DateField,
    EmployeesShowPage,
    SubItemList,
    Subcard,
    TrimTextField,
  },
  data: vm => ({
    headers: [
      {
        value: 'id',
        align: 'end',
        sortable: true,
        filterable: false,
      },
      {
        value: 'note',
        align: 'start',
        sortable: false,
        filterable: false,
      },
      {
        value: 'created_at',
        align: 'end',
        sortable: true,
        filterable: false,
      },
      {
        value: 'updated_at',
        align: 'end',
        sortable: true,
        filterable: false,
      },
      {
        value: 'amount',
        align: 'end',
        sortable: true,
        filterable: false,
      },
    ],
  }),
}
</script>

<style scoped>

</style>
