<template>
    <base-card
            class="mt-0 mb-5"
            :actions-class="actionsClass"
            :actions-color="actionsColor"
            :content-class="contentClass"
            :content-color="contentColor"
            :panel-class="panelClass"
            :panel-color="panelColor"
            :title-class="titleClass"
            :title-color="titleColor"
            :v-on="$listeners"
            :v-bind="$attrs"
    >
        <template v-for="(_, slot) of $slots" :slot="slot">
            <slot :name="slot"/>
        </template>
    </base-card>

</template>

<script>
    import BaseCard from './BaseCard'

    export default {
        name: "Subcard",
        inheritAttrs: false,
        components: {
            BaseCard,
        },
        props: {
            actionsClass: {
                required: false,
                type: String,
                default: '',
            },
            actionsColor: {
                required: false,
                type: String,
                default: '',
            },
            contentClass: {
                required: false,
                type: String,
                default: 'pb-5',
            },
            contentColor: {
                required: false,
                type: String,
                default: '',
            },
            panelClass: {
                required: false,
                type: String,
                default: '',
            },
            panelColor: {
                required: false,
                type: String,
                default: '',
            },
            titleClass: {
                required: false,
                type: String,
                default: 'subtitle-1 text-uppercase white--text pt-2 pb-1 mb-5',
            },
            titleColor: {
                required: false,
                type: String,
                default: 'blue-grey lighten-1',
            },
        },
    }
</script>
