import PageMixin from './PageMixin'
import ItemListView from '../../components/Item/Views/ItemListView'

const ignoredAttributes = [
    'headers',
    'items',
    'serverItemsLength',
    'options',
    'footerProps',
]


const TablePageMixin = {
    components: {
        ItemListView,
    },
    mixins: [
        PageMixin,
    ],
}

export default TablePageMixin
