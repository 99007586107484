import auth from '../../auth'
/*
import CashboxesShowCashOperationsIndexPage from '../../../components/Pages/cashboxes/show/cash_operations/index'
import CashboxesShowCashOperationsCreatePage from '../../../components/Pages/cashboxes/show/cash_operations/create'
import CashboxesShowCashOperationsEditPage from '../../../components/Pages/cashboxes/show/cash_operations/edit'
import CashboxesShowCashOperationsShowPage from '../../../components/Pages/cashboxes/show/cash_operations/show'
*/

export default [
    {
        path: '/cashboxes/:uuid/cash-operations/create',
        name: 'cashboxes.show.cash_operations.create',
//        component: CashboxesShowCashOperationsCreatePage,
        redirect: 'cashboxes/:uuid',
        beforeEnter: auth.guard.user,
    },
    {
        path: '/cashboxes/:uuid/cash-operations/:childUuid',
        name: 'cashboxes.show.cash_operations.show',
//        component: CashboxesShowCashOperationsShowPage,
        redirect: 'cashboxes/:uuid',
        beforeEnter: auth.guard.user,
    },
    {
        path: '/cashboxes/:uuid/cash-operations/:childUuid/edit',
        name: 'cashboxes.show.cash_operations.edit',
//        component: CashboxesShowCashOperationsEditPage,
        redirect: 'cashboxes/:uuid',
        beforeEnter: auth.guard.user,
    },
    {
        path: '/cashboxes/:uuid/cash-operations',
        name: 'cashboxes.show.cash_operations',
//        component: CashboxesShowCashOperationsIndexPage,
        redirect: 'cashboxes/:uuid',
        beforeEnter: auth.guard.user,
    },

]
