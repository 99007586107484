<template>
  <employees-payslip
      print
      :employee="$route.params.uuid"
      :term="$route.params.term"
  />
</template>

<script>
import EmployeesShowPage from '@/components/Pages/employees/show'
import EmployeesPayslip from '@/components/Pages/employees/show/details/payslip/EmployeesPayslip'

export default {
  name: 'EmployeesShowDetailsPayslipPage',
  components: {
    EmployeesPayslip,
    EmployeesShowPage,
  },
}
</script>

<style>
</style>
