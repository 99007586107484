<template>
    <item-edit-view v-slot:default="_">
        <position-edit-form :disabled="_.itemDisabled" v-model="_.item"></position-edit-form>
    </item-edit-view>
</template>

<script>
    import ItemEditView from '../../Item/Views/ItemEditView'
    import PositionEditForm from './Shared/PositionEditForm'

    export default {
        name: 'PositionsEditPage',
        components: {
            ItemEditView,
            PositionEditForm,
        },
    }
</script>
