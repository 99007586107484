<template>
  <subcard>
    <template slot="title">
      <v-flex>
        <div class="d-block d-sm-flex">
          <v-flex class="d-block d-sm-inline-block text-center text-sm-left">
            <span>
              {{ l('pages.my.dashboard.cards.money.title') }}
            </span>
            <span v-if="term || toTerm">
              - {{ term | term }}
              <span v-if="term && toTerm > term"> {{ l('global.to') }} {{ toTerm | term }}</span>
            </span>
          </v-flex>
        </div>
      </v-flex>
    </template>
    <v-row>
      <v-col>
        <div>
          <term-select
              :items="termOptions"
              v-model="term"
              hide-details
              dense
          />
        </div>
      </v-col>
    </v-row>
    <div v-if="dataLoaded">
      <v-row>
        <v-col cols="12" sm="4" class="text-center text-sm-right">
          <h2>
            {{ start | amount }}
          </h2>
        </v-col>
        <v-col cols="12" sm="8" class="text-center text-sm-left">
        <span v-if="term && term < toTerm">
          {{ l('pages.my.dashboard.cards.money.items.startFrom', {from: $options.filters.term(term)}) }}
        </span>
          <span v-else>
          {{ l('pages.my.dashboard.cards.money.items.start') }}
        </span>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="4" class="text-center text-sm-right">
          <h2>
            {{ now | amount }}
          </h2>
        </v-col>
        <v-col cols="12" sm="8" class="text-center text-sm-left">
        <span v-if="term && term < toTerm">
          {{
            l('pages.my.dashboard.cards.money.items.nowFromTo', {
              from: $options.filters.term(term),
              to: $options.filters.term(toTerm)
            })
          }}
        </span>
          <span v-else>
          {{ l('pages.my.dashboard.cards.money.items.now') }}
        </span>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="4" class="text-center text-sm-right">
          <h2 class="black--text">
            {{ total | amount }}
          </h2>
        </v-col>
        <v-col cols="12" sm="8" class="text-center text-sm-left">
          {{ l('pages.my.dashboard.cards.money.items.total') }}
        </v-col>
      </v-row>
    </div>
    <div v-else class="text-center px-2 py-16 my-3">
      {{ l('pages.my.dashboard.cards.money.loading') }}
    </div>
  </subcard>
</template>

<script>
import Subcard from '../../../../Shared/Cards/Subcard'
import TermSelect from '../../../../Shared/Forms/Selects/TermSelect'
import _ from 'lodash'

export default {
  name: 'MyDashboardMoneyCard',
  components: {
    Subcard,
    TermSelect,
  },
  data: vm => ({
    dataLoaded: false,
    start: 0,
    now: 0,
    total: 0,
    toTerm: null,
    payday: null,
    term: null,
    termOptions: [],
  }),
  watch: {
    payday: {
      handler: 'onPayDayUpdate',
    },
    term: {
      handler: 'onTermUpdate',
    },
    termOptions: {
      deep: true,
      handler: 'onTermOptionsUpdate',
    },
  },
  methods: {
    fetchPayDay() {
      return this.$api.get('info/payday');
    },
    fetchTermOptions() {
      return this.$api.get('stats/terms/list/desc');
    },
    loadData(term) {
      this.dataLoaded = false;
      this.$api
          .get('stats/money/' + term)
          .then(function (response) {
            this.dataLoaded = true;
            this.start = Number(_.get(response.data, 'start', 0))
            this.now = Number(_.get(response.data, 'now', 0))
            this.total = Number(_.get(response.data, 'total', 0))
            this.term = _.get(response.data, 'term', this.$moment().format('YYYYMM'))
          }.bind(this))
          .catch(error => error)
    },
    onPayDayUpdate() {
      this.updateTermOptions();
    },
    onTermOptionsUpdate(termOptions) {
      if (!termOptions || termOptions.length === 0) {
        return;
      }
      const availableTerms = termOptions.map(
          function (termOption) {
            return termOption.value;
          }
      );
      if (!this.term || (availableTerms.indexOf(this.term) === -1)) {
        this.term = availableTerms[0];
      }
    },
    onTermUpdate(term) {
      this.toTerm = term ? this.$moment(term.replace(/^(\d{4})(\d{2})$/, "$1-$2-01")).add(1, 'M').format('YYYYMM') : null;
      this.loadData(term);
    },
    updatePayDay() {
      return this.fetchPayDay()
          .then(function (response) {
            this.payday = _.get(response.data, 'payday', null)
          }.bind(this))
          .catch(error => error);
    },
    updateTermOptions() {
      return this.fetchTermOptions()
          .then(function (response) {
            const termOptions = response.data
            if (termOptions.length > 0) {
              this.termOptions = (this.payday && this.$moment().isBefore(this.payday))
                  ? termOptions.slice(1)
                  : termOptions;
            }
          }.bind(this))
          .catch(error => error)
    },
  },
  created() {
    this.updatePayDay();
  },
}
</script>
