<template>
    <v-form>
        <subcard>
            <v-row>
                <v-col cols="12" md="3">
                    <v-radio-group
                            :disabled="disabled"
                            row
                            v-model="type"
                            hide-details
                    >
                        <v-radio
                                v-for="adjustmentType of types"
                                :key="adjustmentType"
                                :label="l('pages.adjustments.types.' + adjustmentType)"
                                :value="adjustmentType"
                        />
                    </v-radio-group>
                </v-col>
                <v-col cols="12" md="2">
                    <number-field
                            abs
                            autocomplete="off"
                            :disabled="disabled"
                            hide-details
                            :label="l('pages.adjustments.properties.amount')"
                            :rules="[rules.required]"
                            required
                            v-model="amount"
                    />
                </v-col>
                <v-col cols="12" md="5">
                    <trim-text-field
                            autocomplete="off"
                            :disabled="disabled"
                            hide-details
                            :label="l('pages.adjustments.properties.note')"
                            v-model="item.note"
                            :rules="[rules.required]"
                            required
                    />
                </v-col>
                <v-col cols="12" md="2">
                    <term-select
                            auto-select-first
                            :disabled="disabled"
                            hide-details
                            v-model="item.term"
                    />
                </v-col>
            </v-row>
        </subcard>
    </v-form>
</template>

<script>
    import Subcard from '../../../../../Shared/Cards/Subcard'
    import TermSelect from "../../../../../Shared/Forms/Selects/TermSelect";
    import NumberField from "../../../../../Shared/Forms/TextFields/NumberField";
    import TrimTextField from "../../../../../Shared/Forms/TextFields/TrimTextField";

    const TYPE_BONUS = 'bonus'
    const TYPE_PENALTY = 'penalty'

    const multipliers = {}
    multipliers[TYPE_PENALTY] = -1
    multipliers[TYPE_BONUS] = 1

    export default {
        name: "EmployeeAdjustmentEditForm",
        inheritAttrs: false,
        components: {
            TrimTextField,
            NumberField,
            TermSelect,
            Subcard,
        },
        props: {
            value: {
                required: false,
                type: Object,
                default: () => {},
            },
            disabled: {
                required: false,
                type: Boolean,
                default: false,
            }
        },
        data: vm => ({
            amount: null,
            item: {},
            rules: {
                required: value => undefined !== value && '' !== value || vm.l('pages.adjustments.validation.required')
            },
            type: TYPE_PENALTY,
            types: [
                TYPE_PENALTY,
                TYPE_BONUS,
            ],
        }),
        watch: {
            value: {
                deep: true,
                immediate: true,
                handler(value) {
                    this.item = value
                    if (undefined !== value.amount) {
                        this.type = value.amount < 0 ? TYPE_PENALTY : TYPE_BONUS
                        this.amount = Math.abs(value.amount)
                    }
                },
            },
            amount(amount) {
                this.item.amount = Math.abs(amount) * multipliers[this.type]
            },
            item: {
                deep: true,
                handler(item) {
                    this.$emit('input', item)
                },
            },
            'item.amount': function(amount) {
                this.amount = !!amount ? Math.abs(amount) : null
            },
            type(type) {
                if (this.amount) {
                    this.item.amount = Math.abs(this.amount) * multipliers[type]
                }
            },
        },
    }
</script>

