<template>
  <item-list-view
      :create-button="userHasFlag('is_accountant') || userIsGodLike"
      :no-edit="!userHasFlag('is_accountant') && !userIsGodLike"
      :no-delete="!userHasFlag('is_accountant') && !userIsGodLike"
      :headers="headers"
  >
    <template v-slot:filter="_">
      <v-row dense class="pt-1">
        <v-col cols="12" sm="8">
          <trim-text-field
              dense
              hide-details
              v-model="_.filter.text" :label="l('pages.locations.index.filters.text')"
          />
        </v-col>
        <v-col cols="12" sm="4">
          <api-select
              api="units/list"
              dense
              hide-details
              multiple
              v-model="_.filter.unit_id"
              :label="l('pages.locations.index.filters.unit_id')"
          />
        </v-col>
      </v-row>
    </template>
    <template v-slot:item.units="_">
      {{ _.value | names }}
    </template>
  </item-list-view>
</template>

<script>
import TablePageMixin from '../../../mixins/Cumulative/TablePageMixin'
import TrimTextField from '../../Shared/Forms/TextFields/TrimTextField'
import ApiSelect from '../../Shared/Forms/ApiSelect'

export default {
  name: "LocationsIndexPage",
  components: {ApiSelect, TrimTextField},
  mixins: [
    TablePageMixin,
  ],
  data: () => ({
    headers: [
      {
        value: 'id',
        align: 'end',
        sortable: true,
        filterable: false,
        width: '5%',
      },
      {
        value: 'name',
        align: 'start',
        sortable: true,
        filterable: false,
      },
      {
        value: 'address',
        align: 'start',
        sortable: true,
        filterable: false,
      },
      {
        value: 'units',
        align: 'start',
        sortable: false,
        filterable: false,
      },
      {
        value: 'updated_at',
        align: 'end',
        sortable: true,
        filterable: false,
      },
    ],
    customAppTitle: 'pages.locations.index.title',
  }),
}
</script>

<style scoped>

</style>
