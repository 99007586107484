<template>
  <item-list-view
      v-bind="tableProps"
      :create-button="userIsGodLike || userHasFlag('is_hr')"
      :excel-button="userIsGodLike || userHasFlag('is_hr')"
      :no-edit="!(userIsGodLike || userHasFlag('is_hr'))"
      :no-delete="!(userIsGodLike || userHasFlag('is_hr'))"
  >
    <template v-slot:filter="_">
      <v-row dense>
        <v-col
            cols="12"
            sm="2"
        >
          <trim-text-field
              dense
              hide-details
              v-model="_.filter.text"
              :label="l('pages.employees.index.filters.text')"
          />
        </v-col>
        <v-col
            cols="12"
            sm="4"
            class="py-0"
        >
          <v-row dense>
            <v-col
                v-for="checkboxFilter in ['stay_permit', 'work_permit', 'valid_contract']"
                :key="checkboxFilter"
                cols="12"
                sm="4"
            >
              <v-select
                  v-model="_.filter[checkboxFilter]"
                  :items="[
                { value: '', text: l('pages.employees.index.filters.' + checkboxFilter + '.options.show') },
                { value: 'hide', text: l('pages.employees.index.filters.' + checkboxFilter + '.options.hide') },
                { value: 'only', text: l('pages.employees.index.filters.' + checkboxFilter + '.options.only') }
              ]"
                  :label="l('pages.employees.index.filters.' + checkboxFilter + '.label')"
                  dense
                  hide-details
                  class="mt-2"
              />
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" sm="2">
          <date-field
              clearable
              dense
              hide-details
              v-model="_.filter.from_date"
              :label="l('pages.employees.index.filters.from_date')"
          />
        </v-col>
        <v-col cols="12" sm="2">
          <date-field
              clearable
              dense
              hide-details
              v-model="_.filter.to_date"
              :label="l('pages.employees.index.filters.to_date')"
          />
        </v-col>
        <v-col cols="12" sm="2">
          <date-field
              clearable
              dense
              hide-details
              v-model="_.filter.no_shift_after"
              :label="l('pages.employees.index.filters.no_shift_after')"
          />
        </v-col>
      </v-row>
      <!--
      <v-flex class="flex-grow-1 flex-shrink-0 mt-1 mb-1">
        <trim-text-field
            dense
            hide-details
            v-model="_.filter.text"
            :label="l('pages.employees.index.filters.text')"
        />
      </v-flex>
      <v-flex class="flex-shrink-0 flex-grow-0 mt-1 mb-1">
        <v-flex>
          <v-checkbox
              v-for="checkboxFilter in ['stay_permit', 'work_permit', 'valid_contract']"
              :key="checkboxFilter"
              dense
              hide-details
              class="d-inline-flex mx-2 mt-0"
              v-model="_.filter[checkboxFilter]"
              true-value="only"
              false-value="show"
              :label="l('pages.employees.index.filters.' + checkboxFilter)"
          />
        </v-flex>
      </v-flex>
      -->
    </template>

    <template
        v-for="nameProp in ['item.name', 'item.list_name']"
        v-slot:[nameProp]="_"
    >
      <router-link v-if="_.item.uuid || _.item.id" :to="$route.path + '/' + (_.item.uuid || _.item.id)">
        {{ _.value }}
      </router-link>
    </template>

    <template v-slot:item.birth_date="_">
      {{ _.value | euroDate }}
    </template>

    <template v-slot:item.created_at="_">
      {{ _.value | euroDate }}
    </template>

    <template v-slot:item.last_shift_date="_">
      {{ _.value | euroDate }}
    </template>

    <template v-slot:item.phones="_">
      <v-chip
          :href="'tel://+' + phone.number"
          v-for="(phone, index) in _.value"
          :key="index"
          :small="!_.dense"
          :x-small="_.dense"
          :class="'ml-1 px-2' + (_.dense ? ' font-weight-bold' : ' my-1')"
      >
        {{ phone | phoneNumber }}
      </v-chip>
      <!--            {{ _.value | phones }}-->
    </template>
    <template v-slot:item.emails="_">
      <v-chip
          :href="'mailto:' + email.address"
          v-for="(email, index) in _.value"
          :key="index"
          :small="!_.dense"
          :x-small="_.dense"
          :class="'mr-1 px-2' + (_.dense ? '' : ' my-1')"
      >
        {{ email.address }}
      </v-chip>
    </template>
    <template v-slot:item.documents="_">
      <v-chip
          v-if="_.item.is_temporary"
          x-small
          color="indigo"
          dark
          class="text-uppercase font-weight-bold mr-1 px-1 mb-1"
          :to="(userIsGodLike || userHasFlag('is_hr')) ? '/employees/' + _.item.uuid + '/edit' : null"
      >
        {{ l('pages.employees.properties.is_temporary') }}
      </v-chip>
      <v-chip
          v-else-if="documentOptions.filter(doc => _.item[doc.type]).length === documentOptions.length"
          x-small
          color="green"
          text-color="white"
          class="text-uppercase font-weight-bold mr-1 px-1 mb-1"
          :to="(userIsGodLike || userHasFlag('is_hr')) ? '/employees/' + _.item.uuid + '/documents' : null"
      >
        {{ l('pages.employees.values.documents.all') }}
      </v-chip>
      <span v-else>
      <v-chip
          v-for="(doc, index) in documentOptions"
          :key="index"
          x-small
          :color="_.item[doc.type] ? 'green lighten-5' : 'deep-orange lighten-5'"
          :text-color="_.item[doc.type] ? 'green darken-4' : 'deep-orange darken-4'"
          class="text-uppercase font-weight-bold mr-1 px-1 mb-1"
          :to="(userIsGodLike || userHasFlag('is_hr')) ? '/employees/' + _.item.uuid + '/documents' : null"
      >
        {{ l('pages.employees.properties.' + doc.type) }}
      </v-chip>
      </span>
    </template>
    <template v-slot:item.gender="_">
      <gender-icon>{{ _.value }}</gender-icon>
    </template>
    <template
        v-for="checkboxSlot in ['item.stay_permit', 'item.work_permit', 'item.valid_contract', 'item.food_cert']"
        v-slot:[checkboxSlot]="_"
    >
      <check-mark
          :x-small="_.dense"
          :value="_.value"
      />
    </template>
  </item-list-view>
</template>

<script>
import TablePageMixin from '../../../mixins/Cumulative/TablePageMixin'
import CheckMark from '../../Shared/Icons/CheckMark'
import GenderIcon from '../../Shared/Icons/GenderIcon'
import ComplexFilter from '../../Shared/Forms/ComplexFilter'
import TrimTextField from '../../Shared/Forms/TextFields/TrimTextField'
import ApiSelect from '../../Shared/Forms/ApiSelect'
import DateField from '../../Shared/Forms/TextFields/DateField'

export default {
  name: "EmployeesIndexPage",
  mixins: [
    TablePageMixin,
  ],
  components: {
    ApiSelect,
    TrimTextField,
    ComplexFilter,
    CheckMark,
    DateField,
    GenderIcon,
  },
  data: () => ({
    tableProps: {
      headers: [
        /*
        {
            value: 'id',
            align: 'end',
            sortable: true,
            filterable: false,
        },
        */
        {
          value: 'list_name',
          align: 'start',
          sortable: true,
          filterable: false,
        },
        {
          value: 'birth_date',
          align: 'end',
          sortable: true,
          filterable: false,
        },
        /*
        {
          value: 'age',
          align: 'end',
          sortable: true,
          filterable: false,
        },
        {
            value: 'gender',
            align: 'center',
            sortable: true,
            filterable: false,
        },
        */
        {
          value: 'phones',
          align: 'end',
          sortable: false,
          filterable: false,
        },
        /*
        {
            value: 'emails',
            align: 'start',
            sortable: false,
            filterable: false,
        },
        */
        {
          value: 'documents',
          align: 'start',
          sortable: false,
          filterable: false,
        },
        /*
        {
          value: 'stay_permit',
          align: 'center',
          sortable: false,
          filterable: false,
        },
        {
          value: 'work_permit',
          align: 'center',
          sortable: false,
          filterable: false,
        },
        {
          value: 'valid_contract',
          align: 'center',
          sortable: false,
          filterable: false,
        },
        {
          value: 'food_cert',
          align: 'center',
          sortable: false,
          filterable: false,
        },
        */
        {
          value: 'created_at',
          align: 'end',
          sortable: true,
          filterable: false,
        },
        {
          value: 'last_shift_date',
          align: 'end',
          sortable: true,
          filterable: false,
        },
        {
          value: 'available_amount',
          align: 'end',
          sortable: true,
          filterable: false,
        },
        /*
        {
            value: 'updated_at',
            align: 'end',
            sortable: true,
            filterable: false,
        },
        */
      ],
      props: {},
    },
    documentOptions: [
      {
        type: 'stay_permit',
        color: 'green',
        textColor: 'white',
      },
      {
        type: 'work_permit',
        color: 'light-blue darken-1',
        textColor: 'white',
      },
      {
        type: 'valid_contract',
        color: 'orange darken-2',
        textColor: 'white',
      },
    ],
    customAppTitle: 'pages.employees.index.title',
  }),
}
</script>

<style scoped>

</style>
