<template>
  <employees-show-page
      parent
      content-color="white"
      v-slot:default="{ item: employee, itemDisabled }"
  >
    <v-row>
      <v-col cols="12" md="12" class="pb-0">
        <sub-item-list
            :create-button="userWorksWithCashboxes"
            :headers="headers"
            :no-edit="!userWorksWithCashboxes"
            :no-delete="!userWorksWithCashboxes"
        >

          <template v-slot:extraItemActions="_">
            <tooltip-icon
                class="ml-1"
                :tooltip="l('pages.cash_operations.actions.show_slip')"
                @click="showSlip(_.item)"
                value="mdi-file-document-box-check-outline"
                color="grey"
            />
          </template>

          <template v-slot:filter="_">
            <v-row dense class="pt-5">

              <v-col cols="12" lg="5">
                <v-row dense>
                  <v-col cols="12" sm="4">
                    <api-select
                        api="cash-operations/types"
                        dense
                        hide-details
                        multiple
                        v-model="_.filter.type"
                        :label="l('pages.cash_operations.index.filters.type')"
                    />
                  </v-col>
                  <v-col cols="12" sm="8">
                    <trim-text-field
                        dense
                        hide-details
                        v-model="_.filter.note"
                        :label="l('pages.cash_operations.index.filters.note')"
                    />
                  </v-col>
                </v-row>
              </v-col>

              <v-col cols="12" lg="7">
                <v-row dense>
                  <v-col cols="12" sm="3">
                    <term-select
                        clearable
                        dense
                        :disabled="!termFilterOptions || termFilterOptions.length === 0"
                        hide-details
                        :items="termFilterOptions"
                        v-model="_.filter.from_term"
                        :label="l('pages.cash_operations.index.filters.from_term')"
                    />
                  </v-col>
                  <v-col cols="12" sm="3">
                    <term-select
                        clearable
                        dense
                        :disabled="!termFilterOptions || termFilterOptions.length === 0"
                        hide-details
                        :items="termFilterOptions"
                        v-model="_.filter.to_term"
                        :label="l('pages.cash_operations.index.filters.to_term')"
                    />
                  </v-col>
                  <v-col cols="12" sm="3">
                    <date-field
                        clearable
                        dense
                        hide-details
                        v-model="_.filter.from_date"
                        :label="l('pages.cash_operations.index.filters.from_date')"
                    />
                  </v-col>
                  <v-col cols="12" sm="3">
                    <date-field
                        clearable
                        dense
                        hide-details
                        v-model="_.filter.to_date"
                        :label="l('pages.cash_operations.index.filters.to_date')"
                    />
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </template>
        </sub-item-list>
      </v-col>
    </v-row>
  </employees-show-page>
</template>

<script>
import TablePageMixin from '../../../../../mixins/Cumulative/TablePageMixin'
import TrimTextField from '../../../../Shared/Forms/TextFields/TrimTextField'
import DateField from '../../../../Shared/Forms/TextFields/DateField'
import ApiSelect from "../../../../Shared/Forms/ApiSelect";
import EmployeesShowPage from "../../show";
import SubItemList from "../../../../Item/SubItemList";
import TooltipIcon from "../../../../Shared/Icons/TooltipIcon";
import TermSelect from "../../../../Shared/Forms/Selects/TermSelect";

export default {
  name: "CashOperationsIndexPage",
  components: {
    TermSelect,
    TooltipIcon,
    SubItemList,
    EmployeesShowPage,
    ApiSelect,
    DateField,
    TrimTextField,
  },
  mixins: [
    TablePageMixin,
  ],
  data: vm => ({
    termFilterOptions: [],
    headers: [
      {
        value: 'id',
        align: 'end',
        sortable: true,
        filterable: false,
        width: '5%',
      },
      {
        value: 'cashbox',
        align: 'start',
        sortable: false,
        filterable: false,
      },
      {
        value: 'note',
        align: 'start',
        sortable: false,
        filterable: false,
      },
      {
        value: 'term',
        align: 'end',
        sortable: true,
        filterable: false,
      },
      {
        value: 'amount',
        align: 'end',
        sortable: true,
        filterable: false,
      },
      {
        value: 'created_at',
        align: 'end',
        sortable: true,
        filterable: false,
      },
      {
        value: 'created_by',
        align: 'start',
        sortable: true,
        filterable: false,
      },
    ],
    customAppTitle: 'pages.cash_operations.index.title',
  }),
  methods: {
    showSlip(item) {
      if (undefined !== item.uuid) {
        window.open('/cash-operations/' + item.uuid + '/slip?stripped=1')
      }
    }
  },
  created() {
    this.$api.get('transactions/terms/list/desc')
        .then(function (response) {
          this.termFilterOptions = response.data
        }.bind(this))
        .catch(error => error)
  },
}
</script>

<style scoped>

</style>
