<template>
    <v-text-field
            :append-icon="appendIcon"
            :autocomplete="autocomplete"
            :type="textFieldType"
            :label="textFieldLabel"
            v-bind="attrs"
            v-on="listeners"
            :value="value"
            @click:append="switchVisibility"
            @input="handleInput"
    >
        <template v-for="(_, slot) of slots" slot="slot">
            <slot :name="slot" />
        </template>
    </v-text-field>
</template>

<script>
    import _ from 'lodash'
    import DisableChromeAutocompleteMixin from '../../../../mixins/Hacks/DisableChromeAutocompleteMixin'

    export default {
        name: "PasswordField",
        inheritAttrs: false,
        mixins: [
            DisableChromeAutocompleteMixin,
        ],
        props: {
            autocomplete: {
                required: false,
                type: String,
                default: 'autocomplete',
            },
            label: {
                required: false,
                type: String,
                default: null,
            },
            value: {
                required: false,
                type: String,
                default: '',
            },
            visible: {
                required: false,
                type: Boolean,
                default: false,
            },
        },
        data: vm => ({
            appendIcon: vm.getAppendIcon(),
            textFieldLabel: '',
            textFieldType: vm.getTextFieldType(),
            textFieldValue: '',
            valueVisible: false,
        }),
        computed: {
            listeners() {
                return _.omit(this.$listeners, 'input')
            },
            attrs() {
                return _.omit(_.clone(this.$attrs), ['append-icon', 'autocomplete', 'label', 'type', 'value', 'visible'])
            },
            slots() {
                return _.omit(_.clone(this.$slots), ['append'])
            },
        },
        methods: {
            getTextFieldType() {
                return this.valueVisible ? 'text' : 'password'
            },
            getAppendIcon() {
                return this.valueVisible ? 'mdi-eye' : 'mdi-eye-off'
            },
            handleInput(value) {
                this.$emit('input', value)
            },
            switchVisibility() {
                this.valueVisible = !this.valueVisible
            },
        },
        watch: {
            label: {
                immediate: true,
                handler(value) {
                    this.textFieldLabel = null === value ? this.l('forms.passwordField.label') : value
                },
            },
            valueVisible() {
                this.textFieldType = this.getTextFieldType()
                this.appendIcon = this.getAppendIcon()
            },
            visible: {
                immediate: true,
                handler(visible) {
                    this.valueVisible = visible
                },
            },
        },
    }
</script>
