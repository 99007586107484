import auth from '../auth'
import AdjustmentsIndexPage from '../../components/Pages/adjustments/index'
import AdjustmentsShowPage from '../../components/Pages/adjustments/show'
import AdjustmentsEditPage from '../../components/Pages/adjustments/edit'
import AdjustmentsCreatePage from '../../components/Pages/adjustments/create'

export default [
    {
        path: '/adjustments/create',
        name: 'adjustments.create',
        component: AdjustmentsCreatePage,
        beforeEnter: auth.guard.user,
    },
    {
        path: '/adjustments/:uuid/edit',
        name: 'adjustments.edit',
        component: AdjustmentsEditPage,
        beforeEnter: auth.guard.user
    },
    {
        path: '/adjustments/:uuid',
        name: 'adjustments.show',
        component: AdjustmentsShowPage,
        beforeEnter: auth.guard.user,
    },
    {
        path: '/adjustments',
        name: 'adjustments.index',
        component: AdjustmentsIndexPage,
        beforeEnter: auth.guard.user,
    },
]