import auth from '../auth'
import ManagementCashboxesPage from '../../components/Pages/management/cashboxes'
import ManagementIndexPage from '../../components/Pages/management/index'

export default [
    {
        path: '/management/cashboxes',
        name: 'pages.management.cashboxes',
        component: ManagementCashboxesPage,
        beforeEnter: auth.guard.user
    },
    {
        path: '/management',
        name: 'pages.management.index',
        component: ManagementIndexPage,
        beforeEnter: auth.guard.user
    },
]
