<template>
    <item-list-view
            create-button
            :headers="headers"
    >

        <template v-slot:item.user="_">
            <router-link
                    :to="{name: 'employees.show', params: {uuid: _.value.uuid}}">
                {{ _.value | listName }}
            </router-link>
        </template>

        <template v-slot:item.type="_">
            <v-chip
                    :color="_.value === 'longterm' ? 'green lighten-3' : (_.value === 'single' ? 'transparent' : 'light-green lighten-4')"
                    :small="!_.dense"
                    :x-small="_.dense"
                    class="text-uppercase"
            >
                {{ l('pages.assignments.values.to_date.' + _.value) }}
            </v-chip>
        </template>

        <template v-slot:item.days="_">
            <span v-if="_.value">
                {{ _.value }}
            </span>
            <v-icon
                    v-else
                    small
                    color="green darken-4"
            >
                mdi-infinity
            </v-icon>
        </template>

        <template v-slot:item.days_covered="_">
            <v-chip
                    :color="(_.item.days === 0) ? 'transparent' : (_.item.days === _.item.days_covered ? 'green lighten-4' : (_.item.days > _.item.days_covered ? 'deep-orange lighten-4' : 'yellow'))"
                    :small="!_.dense"
                    :x-small="_.dense"
            >
                {{ _.value }}
            </v-chip>
        </template>

        <template v-slot:extraItemActions="_">
            <tooltip-icon
                    v-if="_.item.is_coverable && (_.item.days > _.item.days_covered)"
                    class="ml-1"
                    :tooltip="l('pages.assignments.actions.cover')"
                    @click="cover(_.item)"
                    value="mdi-check-all"
                    color="green"
            />
        </template>

        <template v-slot:filter="_">
            <v-row dense>
                <v-col cols="12" md="9" class="py-0">
                    <v-row dense>
                        <v-col cols="12" md="3">
                            <trim-text-field
                                    class="mt-3"
                                    dense
                                    hide-details
                                    v-model="_.filter.text"
                                    :label="l('pages.employees.index.filters.text')"
                            />
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-select
                                    dense
                                    hide-details
                                    chips
                                    deletable-chips
                                    multiple
                                    v-model="_.filter.location_id"
                                    :items="locationList"
                                    :label="l('pages.assignments.index.filters.location_id')"
                            />
                        </v-col>
                        <v-col cols="12" md="3">
                            <v-select
                                    dense
                                    hide-details
                                    chips
                                    deletable-chips
                                    multiple
                                    v-model="_.filter.unit_id"
                                    :items="unitList"
                                    :label="l('pages.assignments.index.filters.unit_id')"
                            />
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="12" md="3" class="py-0">
                    <v-row dense>
                        <v-col cols="12" md="6">
                            <date-field
                                    clearable
                                    text-field-class="mt-3"
                                    dense
                                    hide-details
                                    v-model="_.filter.from_date"
                                    :label="l('pages.assignments.index.filters.from_date')"
                            />
                        </v-col>
                        <v-col cols="12" md="6">
                            <date-field
                                    clearable
                                    text-field-class="mt-3"
                                    dense
                                    hide-details
                                    v-model="_.filter.to_date"
                                    :label="l('pages.assignments.index.filters.to_date')"
                            />
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </template>

    </item-list-view>
</template>

<script>
    import TablePageMixin from '../../../mixins/Cumulative/TablePageMixin'
    import TrimTextField from '../../Shared/Forms/TextFields/TrimTextField'
    import DateField from '../../Shared/Forms/TextFields/DateField'
    import TooltipIcon from "../../Shared/Icons/TooltipIcon";

    export default {
        name: "AssignmentsIndexPage",
        components: {
            TooltipIcon,
            DateField,
            TrimTextField,
        },
        mixins: [
            TablePageMixin,
        ],
        data: () => ({
            locationList: [],
            unitList: [],
            headers: [
                {
                    value: 'id',
                    align: 'end',
                    sortable: true,
                    filterable: false,
                },
                {
                    value: 'user',
                    align: 'start',
                    sortable: false,
                    filterable: false,
                },
                {
                    value: 'location',
                    align: 'start',
                    sortable: false,
                    filterable: false,
                },
                {
                    value: 'position',
                    align: 'start',
                    sortable: false,
                    filterable: false,
                },
                {
                    value: 'from_date',
                    align: 'end',
                    sortable: true,
                    filterable: false,
                },
                {
                    value: 'to_date',
                    align: 'end',
                    sortable: true,
                    filterable: false,
                },
                {
                    value: 'days',
                    align: 'center',
                    sortable: true,
                    filterable: false,
                },
                {
                    value: 'days_covered',
                    align: 'center',
                    sortable: false,
                    filterable: false,
                },
                {
                    value: 'time',
                    align: 'end',
                    sortable: true,
                    filterable: false,
                },
                {
                    value: 'hours',
                    align: 'end',
                    sortable: true,
                    filterable: false,
                },
                {
                    value: 'rate',
                    align: 'end',
                    sortable: true,
                    filterable: false,
                },
                {
                    value: 'is_payable',
                    align: 'center',
                    sortable: true,
                    filterable: false,
                },
            ],
            customAppTitle: 'pages.assignments.index.title',
        }),
        methods: {
            cover(assignment) {
                const assignmentUuid = _.get(assignment, 'uuid', null)
                if (assignmentUuid) {
                    const assignmentUrl = 'assignments/' + assignmentUuid
                    this.$api.post(assignmentUrl + '/cover')
                        .then(function () {
                            this.$api.get(assignmentUrl).then(function (response) {
                                    assignment.days_covered = _.get(response.data, 'days_covered')
                                    assignment.is_coverable = _.get(response.data, 'is_coverable')
                                }.bind(this))
                                .catch(error => error)
                        }.bind(this))
                        .catch(error => error)
                }
            },
        },
        created() {

            this.$api.get('locations/list')
                .then(function (response) {
                    this.locationList = response.data
                }.bind(this))
                .catch(error => error)

            this.$api.get('units/list')
                .then(function (response) {
                    this.unitList = response.data
                }.bind(this))
                .catch(error => error)

        },
    }
</script>
