<template>
    <subcard>

        <!-- LIST HEADER -->
        <template v-slot:title>
            <v-flex>
                <div class="d-flex">

                    <v-flex class="d-none d-sm-inline-flex">
                        <span>
                            {{ l('pages.food_certs.index.title') }}
                        </span>
                    </v-flex>

                    <v-flex class="text-right">

                        <v-btn
                                v-if="employee.is_editable"
                                depressed
                                color="white"
                                class="ml-2 mb-1"
                                small
                                @click="handleCreateButtonClick"
                        >
                            {{ l('pages.food_certs.actions.create') }}
                        </v-btn>

                    </v-flex>

                </div>
            </v-flex>
        </template>

        <!-- LIST CONTENTS -->
        <item-list
                :items-per-page="itemsPerPage"
                :items-per-page-options="itemsPerPageOptions"
                :headers="headers"
                :hidden="listIsHidden"
                no-show
                no-edit
                :filter="{user_id: lodash.get(employee, 'id', null)}"
                slug="food_certs"
        >

            <template v-slot:item.id="_">
                {{ _.value }}
            </template>

            <template v-slot:item.type="_">
                {{ _.value | listName }}
            </template>

            <template v-slot:extraItemActions="_">
                <edit-icon
                        class="ml-1"
                        v-if="_.item.is_editable"
                        @click="handleEditButtonClick(_.item)"
                />
            </template>

        </item-list>

        <!-- EDIT WINDOW -->
        <v-dialog
                transition="none"
                max-width="290"
                v-model="editDialogIsVisible"
                @click:outside="hideEditDialog()"
                @keydown.esc="hideEditDialog()"
        >
            <v-card>
                <v-form v-if="null !== edited">
                    <v-card-title>
                        {{ undefined !== edited.id ? (l('pages.food_certs.show.title') + ' ' + edited.id) : l('pages.food_certs.create.title') }}
                    </v-card-title>
                    <v-card-text>

                        <v-row dense>
                            <v-col cols="12" sm="6">
                                <date-field
                                        autocomplete="off"
                                        text-field-class="mt-4"
                                        :disabled="editDisabled"
                                        dense
                                        hide-details
                                        :label="l('pages.food_certs.properties.from_date')"
                                        v-model="edited.from_date"
                                />
                            </v-col>
                            <v-col cols="12" sm="6">
                                <date-field
                                        autocomplete="off"
                                        text-field-class="mt-4"
                                        :disabled="editDisabled"
                                        dense
                                        hide-details
                                        :label="l('pages.food_certs.properties.to_date')"
                                        v-model="edited.to_date"
                                />
                            </v-col>
                        </v-row>

                        <v-row dense>
                            <v-col cols="12">
                                <trim-text-field
                                        autocomplete="off"
                                        maxlength="255"
                                        class="mt-4"
                                        :disabled="editDisabled"
                                        dense
                                        hide-details
                                        :label="l('pages.food_certs.properties.note')"
                                        v-model="edited.note"
                                />
                            </v-col>
                        </v-row>

                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn text @click="handleDontSaveButtonClick">
                            {{ l('pages.food_certs.actions.back') }}
                        </v-btn>
                        <v-btn text @click="handleSaveButtonClick" color="green">
                            {{ l('pages.food_certs.actions.save') }}
                        </v-btn>
                    </v-card-actions>
                </v-form>
            </v-card>
        </v-dialog>

    </subcard>
</template>

<script>
    import _ from 'lodash'
    import Subcard from '../../../../Shared/Cards/Subcard'
    import ItemList from '../../../../Item/ItemList'
    import EditIcon from "../../../../Shared/Icons/EditIcon";
    import ApiSelect from "../../../../Shared/Forms/ApiSelect";
    import ServiceFunctionsMixin from "../../../../../mixins/ServiceFunctionsMixin";
    import DateField from "../../../../Shared/Forms/TextFields/DateField";
    import TrimTextField from "../../../../Shared/Forms/TextFields/TrimTextField";

    export default {
        name: "DocumentsFoodCertList",
        components: {
            TrimTextField,
            DateField,
            ApiSelect,
            EditIcon,
            ItemList,
            Subcard,
        },
        mixins: [
            ServiceFunctionsMixin,
        ],
        props: {
            employee: {
                required: true,
                type: Object,
            },
        },
        data: vm => ({
            lodash: _,
            edited: null,
            editDialogIsVisible: false,
            editDisabled: false,
            listIsHidden: false,
            itemsPerPage: 5,
            itemsPerPageOptions: [5, 10, 20],
            headers: [
                {
                    value: 'id',
                    align: 'end',
                    sortable: true,
                    filterable: false,
                },
                {
                    value: 'note',
                    align: 'start',
                    sortable: false,
                    filterable: false,
                },
                {
                    value: 'from_date',
                    align: 'end',
                    sortable: true,
                    filterable: false,
                },
                {
                    value: 'to_date',
                    align: 'end',
                    sortable: true,
                    filterable: false,
                },
            ],
        }),
        watch: {
            editDialogIsVisible(visible) {
                if (!visible && null !== this.edited) {
                    this.wipeEdited()
                }
            },
            edited(edited) {
                if (edited !== null) {
                    this.showEditDialog()
                } else if (this.editDialogIsVisible) {
                    this.hideEditDialog()
                }
            }
        },
        methods: {
            hideEditDialog() {
                this.editDialogIsVisible = false
            },
            showEditDialog() {
                this.editDialogIsVisible = true
            },
            wipeEdited() {
                this.edited = null
            },
            reloadList() {
                this.listIsHidden = true
                setTimeout(function() {
                    this.listIsHidden = false
                }.bind(this), 500)

            },
            handleCreateButtonClick() {
                this.edited = {}
            },
            handleEditButtonClick(item) {
                this.edited = this.clone(item)
            },
            handleDontSaveButtonClick() {
                this.wipeEdited()
            },
            handleSaveButtonClick() {
                this.editDisabled = true
                const uuid = _.get(this.edited, 'uuid', null)
                const existing = !!uuid
                const apiAction = existing ? 'put' : 'post'
                const apiUrl = 'food-certs' + (existing ? ('/' + uuid) : '')
                const apiParams = existing ? this.edited : _.merge(this.edited, {user_id: _.get(this.employee, 'id', null)})
                return this.$api[apiAction](apiUrl, apiParams)
                    .then(function () {
                        this.editDisabled = false
                        this.wipeEdited()
                        this.reloadList()
                    }.bind(this))
                    .catch(function () {
                        this.editDisabled = false
                    }.bind(this))
            },
        },
    }
</script>

<style scoped>

</style>