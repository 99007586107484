<template>
    <item-view
            v-bind="$attrs"
            v-on="$listeners"
    >
        <template
            v-for="(_, slot) of $scopedSlots"
            v-slot:[slot]="scope"
        >
            <tunnel :value="scope.item" @input="handleParent" />
            <slot :name="slot" v-bind="scope"/>
        </template>
    </item-view>
</template>

<script>

    import ItemView from './ItemView'
    import PageMixin from '../../../mixins/Cumulative/PageMixin'
    import Tunnel from '../../Shared/Tunnel'

    export default {
        name: "ItemShowView",
        inheritAttrs: false,
        mixins: [
            PageMixin,
        ],
        components: {
            Tunnel,
            ItemView,
        },
        methods: {
            handleParent(parent) {
                this.customAppTitle = _.get(parent, 'name', '')
            },
        },
    }
</script>

<style scoped>

</style>