<template>
    <subcard>

        <template slot="title">
            <v-flex>
                <div class="d-block d-sm-flex">

                    <v-flex class="d-block d-sm-inline-block text-center text-sm-left">
                        <span>
                            {{ l('pages.my.dashboard.cards.payments.title') }}
                        </span>
                    </v-flex>

                    <v-flex class="d-sm-inline-block d-block text-center text-sm-right">
                        <v-btn
                                depressed
                                small
                                color="blue-grey darken-2"
                                dark
                                @click="expandAll"
                                class="text-uppercase font-weight-bold"
                        >
                            {{ l('pages.my.dashboard.cards.payments.buttons.expand.all') }}
                        </v-btn>
                        <v-btn
                                depressed
                                small
                                color="blue-grey darken-2"
                                dark
                                @click="collapseAll"
                                class="text-uppercase font-weight-bold ml-1"
                        >
                            {{ l('pages.my.dashboard.cards.payments.buttons.collapse.all') }}
                        </v-btn>
                    </v-flex>

                </div>
            </v-flex>
        </template>


        <v-flex class="mb-3">
            <div class="d-flex">

                <v-flex>
                    <term-select
                            :items="termOptions"
                            v-model="term"
                            hide-details
                            dense
                    />
                </v-flex>

                <v-flex class="text-right">
                    <excel-button
                            class="ml-1"
                            :disabled="exportDisabled"
                            :loading="exportLoading"
                            square
                            @click="exportPayments"
                    />
                </v-flex>

            </div>
        </v-flex>


        <div v-if="locations.length">

            <v-row dense class="caption text-uppercase font-weight-bold">
                <v-col cols="12" sm="5" class="text-center text-sm-left d-none d-sm-flex">
                    {{ l('pages.shifts.properties.location') }} / {{ l('pages.shifts.properties.position') }} / {{ l('pages.shifts.properties.employee') }}
                </v-col>
                <v-col class="text-right" cols="4" sm="2">
                    {{ l('pages.shifts.properties.hours') }}
                </v-col>
                <v-col class="text-right" cols="3" sm="2">
                    {{ l('pages.shifts.properties.rate') }}
                </v-col>
                <v-col class="text-right" cols="5" sm="3">
                    {{ l('pages.shifts.properties.amount') }}
                </v-col>
            </v-row>

            <div
                v-for="(location, locationIndex) in locations"
                :key="locationIndex"
            >

                <v-row 
                        dense 
                        class="font-weight-bold text-uppercase blue-grey lighten-4 black--text mb-1 my-dashboard-payments-expandable-row"
                        @click="switchLocationExpansion(locationIndex)"
                >
                    <v-col cols="12" sm="5" class="text-center text-sm-left">
                        {{ location.name }}
                    </v-col>
                    <v-col class="text-right" cols="4" sm="2">
                        {{ $options.filters.amount(lodash.sumBy(location.positions, position => lodash.sumBy(position.employees, employee => parseFloat(employee.hours)))) }}
                    </v-col>
                    <v-col class="text-right" cols="3" sm="2">

                    </v-col>
                    <v-col class="text-right" cols="5" sm="3">
                        {{ $options.filters.amount(lodash.sumBy(location.positions, position => lodash.sumBy(position.employees, employee => parseFloat(employee.amount)))) }}
                    </v-col>
                </v-row>

                <div v-if="expansionMap[locationIndex].expand">

                    <div
                        v-for="(position, positionIndex) in location.positions"
                        :key="positionIndex"
                        class="mb-1"
                    >

                        <v-row
                                dense
                                class="font-weight-bold black--text blue-grey lighten-5 my-dashboard-payments-expandable-row"
                                @click="switchPositionExpansion(locationIndex, positionIndex)"
                        >
                            <v-col cols="12" sm="5" class="text-center text-sm-left">
                                {{ position.name }}
                            </v-col>
                            <v-col class="text-right" cols="4" sm="2">
                                {{ $options.filters.amount(lodash.sumBy(position.employees, employee => parseFloat(employee.hours))) }}
                            </v-col>
                            <v-col class="text-right" cols="3" sm="2">

                            </v-col>
                            <v-col class="text-right" cols="5" sm="3">
                                {{ $options.filters.amount(lodash.sumBy(position.employees, employee => parseFloat(employee.amount))) }}
                            </v-col>
                        </v-row>

                        <div class="mb-2 black--text" v-if="expansionMap[locationIndex].positions[positionIndex]">

                            <v-row
                                v-for="(employee, employeeIndex) in position.employees"
                                :key="employeeIndex"
                                dense
                                style="border-top: #aaa 1px solid"
                            >
                                <v-col cols="12" sm="5" class="text-center text-sm-left">
                                    {{ employee.name }}
                                </v-col>
                                <v-col class="text-right" cols="4" sm="2">
                                    {{ employee.hours | amount }}
                                </v-col>
                                <v-col class="text-right" cols="3" sm="2">
                                    {{ employee.rate | amount }}
                                </v-col>
                                <v-col class="text-right" cols="5" sm="3">
                                    {{ employee.amount | amount }}
                                </v-col>
                            </v-row>

                        </div>
                    </div>

                </div>
            </div>

            <v-row dense class="font-weight-bold text-uppercase black--text mb-1">
                <v-col cols="12" sm="5" class="text-center text-sm-left">
                    {{ l('global.total') }}
                </v-col>
                <v-col class="text-right" cols="4" sm="2">
                    {{ $options.filters.amount(lodash.sumBy(locations, location => lodash.sumBy(location.positions, position => lodash.sumBy(position.employees, employee => parseFloat(employee.hours))))) }}
                </v-col>
                <v-col class="text-right" cols="3" sm="2">

                </v-col>
                <v-col class="text-right" cols="5" sm="3">
                    {{ $options.filters.amount(lodash.sumBy(locations, location => lodash.sumBy(location.positions, position => lodash.sumBy(position.employees, employee => parseFloat(employee.amount))))) }}
                </v-col>
            </v-row>

        </div>

    </subcard>
</template>

<script>
    import Subcard from '../../../../Shared/Cards/Subcard'
    import TermSelect from '../../../../Shared/Forms/Selects/TermSelect'
    import ApiSelect from '../../../../Shared/Forms/ApiSelect'
    import ExcelButton from '../../../../Shared/Buttons/ExcelButton'
    import _ from 'lodash'

    export default {
        name: 'MyDashboardPaymentsCard',
        components: {
            ExcelButton,
            ApiSelect,
            TermSelect,
            Subcard
        },
        data: vm => ({
            expansionMap: [],
            exportDisabled: true,
            exportLoading: false,
            lodash: _,
            locations: [],
            payments: {},
            term: null,
            termOptions: [],
        }),
        watch: {
            payments(payments) {
                const locations = _.get(payments, 'locations', [])
                this.exportDisabled = (locations.length === 0)
                this.locations = locations
                this.refreshExpansionMap()
            },
            term(term) {
                this.updatePayments(term)
            },
        },
        methods: {
            collapseAll() {
                this.expansionMap = _.map(JSON.parse(JSON.stringify(this.expansionMap)), function(location) {
                    return {
                        expand: false,
                        positions: _.map(_.get(location, 'positions', []), () => false),
                    }
                })
            },
            expandAll() {
                this.expansionMap = _.map(JSON.parse(JSON.stringify(this.expansionMap)), function(location) {
                    return {
                        expand: true,
                        positions: _.map(_.get(location, 'positions', []), () => true),
                    }
                })
            },
            exportPayments() {
                this.exportLoading = true
                this.$api
                    .get('stats/payments/excel/' + this.term)
                    .then(response => response)
                    .catch(error => error)
                    .finally(function() {
                        this.exportLoading = false
                    }.bind(this))
            },
            fillExpansionMap() {

                const expansionMap = JSON.parse(JSON.stringify(this.expansionMap))

                _.each(this.locations || [], function(location, locationIndex) {

                    if (!_.isArray(_.get(expansionMap, locationIndex, false))) {
                        expansionMap[locationIndex] = {
                            expand: false,
                            positions: [],
                        }
                    }

                    if (!_.isArray(_.get(expansionMap[locationIndex], 'positions', false))) {
                        expansionMap[locationIndex]['positions'] = []
                    }

                    _.each(_.get(location, 'positions', []), function(position, positionIndex) {
                        if (undefined === expansionMap[locationIndex]['positions'][positionIndex]) {
                            expansionMap[locationIndex]['positions'][positionIndex] = false
                        }
                    }.bind(this))

                }.bind(this))

                this.expansionMap = expansionMap
            },
            refreshExpansionMap() {
                this.wipeExpansionMap()
                this.fillExpansionMap()
            },
            switchLocationExpansion(locationIndex) {
                const expansionMap = JSON.parse(JSON.stringify(this.expansionMap))
                if (undefined !== expansionMap[locationIndex]) {
                    expansionMap[locationIndex].expand = !_.get(expansionMap[locationIndex], 'expand', false)
                }
                this.expansionMap = expansionMap
            },
            switchPositionExpansion(locationIndex, positionIndex) {
                const expansionMap = JSON.parse(JSON.stringify(this.expansionMap))
                if (null !== _.get(expansionMap, [locationIndex, 'positions', positionIndex], null)) {
                    expansionMap[locationIndex].positions[positionIndex] = !expansionMap[locationIndex].positions[positionIndex]
                }
                this.expansionMap = expansionMap
            },
            updateTermOptions() {
                this.$api
                    .get('shifts/terms/list/desc')
                    .then(function (response) {
                        const termOptions = response.data
                        if (termOptions.length > 0) {
                            this.termOptions = termOptions
                            if (!this.term || termOptions.indexOf(this.term) === -1) {
                                this.term = termOptions[(termOptions.length > 1) ? 1 : 0].value
                            }
                        }
                    }.bind(this))
                    .catch(error => error)
            },
            updatePayments(term = null) {
                this.wipePayments()
                this.$api
                    .get('stats/payments/' + (term || this.term))
                    .then(function(response) {
                        this.payments = response.data
                    }.bind(this))
                    .catch(error => error)
            },
            wipeExpansionMap() {
                this.expansionMap = []
            },
            wipePayments() {
                this.payments = {}
            },
        },
        created() {
            this.updateTermOptions()
        },
    }
</script>

<style>
    .my-dashboard-payments-expandable-row {
        cursor: pointer;
    }
</style>