<template>
    <v-icon
            :color="iconColor"
            v-bind="$attrs"
            v-on="$listeners"
    >
        {{ icon }}
    </v-icon>
</template>

<script>

    const CHECKMARK_ICON_STATE_CHECKED = 'checked'
    const CHECKMARK_ICON_STATE_UNCHECKED = 'unchecked'

    const defaultValues = {
        icons: {
            checked: 'mdi-check-circle',
            unchecked: 'mdi-checkbox-blank-circle-outline',
        },
        colors: {
            checked: 'green',
            unchecked: 'blue-grey lighten-4',
        },
    }

    export default {
        name: "CheckMark",
        props: {
            colorChecked: {
                required: false,
                default: '',
            },
            colorUnchecked: {
                required: false,
                default: '',
            },
            iconChecked: {
                required: false,
                type: String,
                default: '',
            },
            iconUnchecked: {
                required: false,
                type: String,
                default: '',
            },
            value: {
                required: false,
                default: false,
            },
        },
        data: vm => ({
            colors: {
                checked: defaultValues.colors.checked,
                unchecked: defaultValues.colors.unchecked,
            },
            icons: {
                checked: defaultValues.icons.checked,
                unchecked: defaultValues.icons.unchecked,
            },
            state: CHECKMARK_ICON_STATE_UNCHECKED,
        }),
        watch: {
            colorChecked: {
                immediate: true,
                handler(value) {
                    this.colors.checked = value || defaultValues.colors.checked
                },
            },
            colorUnchecked: {
                immediate: true,
                handler(value) {
                    this.colors.unchecked = value || defaultValues.colors.unchecked
                },
            },
            iconChecked: {
                immediate: true,
                handler(value) {
                    this.icons.checked = value || defaultValues.icons.checked
                },
            },
            iconUnchecked: {
                immediate: true,
                handler(value) {
                    this.icons.unchecked = value || defaultValues.icons.unchecked
                },
            },
            value: {
                immediate: true,
                handler(value) {
                    this.state = !!value ? CHECKMARK_ICON_STATE_CHECKED : CHECKMARK_ICON_STATE_UNCHECKED
                },
            },

        },
        computed: {
            icon() {
                return this.icons[this.state]
            },
            iconColor() {
                return this.colors[this.state]
            },
        },
    }
</script>

<style scoped>

</style>