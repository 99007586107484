<template>
    <item-edit-view v-slot:default="_">
        <transaction-edit-form
                :disabled="_.itemDisabled"
                v-model="_.item"
        />
    </item-edit-view>
</template>

<script>
    import ItemEditView from '../../Item/Views/ItemEditView'
    import TransactionEditForm from './Shared/TransactionEditForm'

    export default {
        name: 'TransactionsEditPage',
        components: {
            ItemEditView,
            TransactionEditForm,
        },
    }
</script>

<style scoped>

</style>