<template>
  <item-show-view
      v-bind="$attrs"
      v-on="$listeners"
  >
    <template v-slot:header="_">
      <v-chip dark :color="cashboxAmount > 0 ? 'green' : 'red'" class="ml-2 font-weight-bold ">
        {{ cashboxAmount | amount }} {{ l('global.currency') }}
      </v-chip>
    </template>

    <template v-slot:default="{ item: cashbox }">

      <tunnel :value="cashbox" @input="handleCashboxTunnel"/>

      <v-row>
        <v-col class="pb-0">
          <subcard
              title-class="pb-5 mb-2"
              title-color=""
          >

            <!-- LIST CONTENTS -->
            <item-list
                v-if="cashbox && cashbox.uuid"
                :hidden="listIsHidden"
                no-edit
                no-show
                slug="cash-operations"
                parent-slug="cashboxes"
                :parent-id="cashbox.uuid"
                :headers="headers"
            >

              <template v-slot:header.actions="_">
                <!-- CREATE BUTTON -->
                <create-button
                    square
                    class="ml-2"
                    :label="l('pages.cash_operations.actions.create')"
                    @click="handleCreateButtonClick"
                />
              </template>

              <template v-slot:item.id="_">
                {{ _.value }}
              </template>

              <template v-slot:extraItemActions="_">
                <tooltip-icon
                    v-if="_.item && undefined !== _.item.user_id && !!_.item.user_id"
                    class="ml-1"
                    :tooltip="l('pages.cash_operations.actions.show_slip')"
                    @click="showSlip(_.item)"
                    value="mdi-file-document-box-check-outline"
                    color="grey"
                />
                <edit-icon
                    class="ml-1"
                    v-if="_.item.is_editable"
                    @click="handleEditButtonClick(_.item)"
                />
              </template>

              <template v-slot:item.user="_">
                <router-link v-if="_.item.user_id"
                             :to="{name: 'employees.show', params: {uuid: _.value.uuid}}">
                  {{ _.value | listName }}
                </router-link>
              </template>

              <template v-slot:filter="_">
                <v-row dense>

                  <v-col cols="12" lg="5" class="py-0">
                    <v-row dense>
                      <v-col cols="12" sm="4">
                        <api-select
                            class="mt-3 py-2"
                            api="cash-operations/types"
                            dense
                            hide-details
                            chips
                            deletable-chips
                            multiple
                            v-model="_.filter.type"
                            :label="l('pages.cash_operations.index.filters.type')"
                        />
                      </v-col>
                      <v-col cols="12" sm="8">
                        <trim-text-field
                            class="mt-3 py-2"
                            dense
                            hide-details
                            v-model="_.filter.text"
                            :label="l('pages.cash_operations.index.filters.text')"
                        />
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col cols="12" lg="7" class="py-0">
                    <v-row dense>
                      <v-col cols="12" sm="3">
                        <term-select
                            clearable
                            class="mt-3 py-2"
                            dense
                            :disabled="!termFilterOptions || termFilterOptions.length === 0"
                            hide-details
                            :items="termFilterOptions"
                            v-model="_.filter.from_term"
                            :label="l('pages.cash_operations.index.filters.from_term')"
                        />
                      </v-col>
                      <v-col cols="12" sm="3">
                        <term-select
                            clearable
                            class="mt-3 py-2"
                            dense
                            :disabled="!termFilterOptions || termFilterOptions.length === 0"
                            hide-details
                            :items="termFilterOptions"
                            v-model="_.filter.to_term"
                            :label="l('pages.cash_operations.index.filters.to_term')"
                        />
                      </v-col>
                      <v-col cols="12" sm="3">
                        <date-field
                            clearable
                            text-field-class="mt-3 py-2"
                            dense
                            hide-details
                            v-model="_.filter.from_date"
                            :label="l('pages.cash_operations.index.filters.from_date')"
                        />
                      </v-col>
                      <v-col cols="12" sm="3">
                        <date-field
                            clearable
                            text-field-class="mt-3 py-2"
                            dense
                            hide-details
                            v-model="_.filter.to_date"
                            :label="l('pages.cash_operations.index.filters.to_date')"
                        />
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </template>

            </item-list>

            <!-- EDIT WINDOW -->
            <v-dialog
                transition="none"
                max-width="640"
                v-model="editDialogIsVisible"
                @click:outside="hideEditDialog()"
                @keydown.esc="hideEditDialog()"
            >
              <v-card>
                <v-form v-if="null !== cashOperation">
                  <v-card-title>
                    <v-flex v-if="!!cashOperation && !cashOperation.uuid">
                      <div class="d-flex justify-center">
                        <v-btn-toggle
                            class="pb-0"
                            mandatory
                            v-model="editDialogMode"
                        >
                          <v-btn
                              v-for="mode in supportedEditDialogModes"
                              :key="mode"
                              :value="mode"
                              small
                          >
                            {{ l('pages.cash_operations.create.mode.' + mode) }}
                          </v-btn>
                        </v-btn-toggle>
                      </div>
                    </v-flex>
                    <div v-else>
                      {{
                        undefined !== cashOperation.id ? (l('pages.cash_operations.show.title') + ' ' + cashOperation.id) : l('pages.cash_operations.create.title')
                      }}
                    </div>
                  </v-card-title>
                  <v-card-text>

                    <v-row>
                      <v-col cols="12">
                        <user-picker
                            v-if="editDialogMode === 'employee'"
                            employee
                            :disabled="editDisabled"
                            :readonly="!!cashOperation.id"
                            v-model="cashOperation.user_id"
                            no-birth-date
                            no-phones
                            no-food-cert
                            no-list-food-cert
                        />
                        <v-sheet
                            v-else-if="!cashOperation.id"
                            flat
                            class="red lighten-5 red--text text--darken-4 px-3 py-2 mt-1 font-weight-bold text-center"

                        >
                          {{ l('pages.cash_operations.create.no_employee_warning') }}
                        </v-sheet>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" :sm="editDialogMode === 'employee' ? 5 : 7">
                        <v-radio-group
                            :disabled="editDisabled"
                            hide-details
                            row
                            v-model="type"
                        >
                          <v-radio
                              v-for="cashOperationType of types"
                              v-if="!(cashOperationType === 'transfer' && editDialogMode === 'employee')"
                              :key="cashOperationType"
                              hide-details
                              :label="l('pages.cash_operations.values.type.' + cashOperationType)"
                              :value="cashOperationType"
                          />
                        </v-radio-group>
                      </v-col>
                      <v-col cols="12" sm="4" v-if="editDialogMode === 'employee'">
                        <term-select
                            auto-select-first
                            class="mt-3"
                            dense
                            :disabled="editDisabled"
                            hide-details
                            :label="l('pages.cash_operations.properties.term')"
                            v-model="cashOperation.term"
                            required
                        />
                      </v-col>
                      <v-col cols="12" :sm="editDialogMode === 'employee' ? 3 : 5">
                        <number-field
                            abs
                            class="mt-3"
                            dense
                            :disabled="editDisabled"
                            hide-details
                            :label="l('pages.cash_operations.properties.amount')"
                            v-model="amount"
                            :rules="[rules.required]"
                            required
                        />
                      </v-col>
                    </v-row>
                    <v-row dense>
                      <v-col cols="12" sm="3" order="2" align="end" v-if="showAvailableAmount">
                        <v-tooltip bottom v-if="!!availableAmount">
                          <template v-slot:activator="{ on, attrs }">
                            <v-chip
                                class="font-weight-bold text-uppercase px-2"
                                small
                                :color="availableAmount === null ? 'grey lighten-3' : (availableAmount <= 0 ? 'deep-orange lighten-4' : 'light-green lighten-3')"
                                @click="handleAvailableAmountClick"
                                v-bind="attrs"
                                v-on="on"
                            >
                              {{ l('global.max') }}
                              <span class="ml-1">
                                                                <span v-if="null !== availableAmount">
                                                                    {{
                                                                    availableAmount | amount
                                                                  }} {{ l('global.currency') }}
                                                                </span>
                                                                <span v-else>
                                                                    ---
                                                                </span>
                                                            </span>
                            </v-chip>
                          </template>
                          <span>{{ l('pages.employees.actions.set_max_cash_amount') }}</span>
                        </v-tooltip>
                        <v-chip
                            class="font-weight-bold text-uppercase px-2"
                            small
                            :color="availableAmount === null ? 'grey lighten-3' : (availableAmount <= 0 ? 'deep-orange lighten-4' : 'light-green lighten-3')"
                            v-else
                        >
                          {{ l('pages.employees.properties.max_cash_amount') }}:
                          <span class="ml-1">
                                                        <span v-if="null !== availableAmount">
                                                            {{ availableAmount | amount }} {{ l('global.currency') }}
                                                        </span>
                                                        <span v-else>
                                                            ---
                                                        </span>
                                                    </span>
                        </v-chip>
                      </v-col>
                      <v-col :cols="showAvailableAmount ? 9 : 12" order="1">
                        <v-select
                            v-if="type === 'transfer'"
                            class="mt-2"
                            hide-details
                            :items="transferCashboxes"
                            :label="l('pages.cash_operations.properties.cashbox')"
                            v-model="cashOperation.to"
                        />
                        <v-text-field
                            v-else
                            class="mt-2"
                            :disabled="editDisabled"
                            hide-details
                            :label="l('pages.cash_operations.properties.note')"
                            v-model="cashOperation.note"
                        />
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="handleDontSaveButtonClick">
                      {{ l('pages.cash_operations.actions.back') }}
                    </v-btn>
                    <v-btn
                        :disabled="!cashOperation.amount || editDialogMode === 'employee' && (!cashOperation.user_id) || type === 'transfer' && (!cashOperation.to)"
                        text
                        @click="handleSaveButtonClick" color="green">
                      {{ l('pages.cash_operations.actions.save') }}
                    </v-btn>
                  </v-card-actions>
                </v-form>
              </v-card>
            </v-dialog>

          </subcard>
        </v-col>
      </v-row>
    </template>

  </item-show-view>
</template>

<script>
import ItemShowView from '../../Item/Views/ItemShowView'
import PageCard from '../../Shared/Cards/PageCard'
import Subcard from '../../Shared/Cards/Subcard'
import Amount from '../../Shared/Text/Amount'
import ItemList from '../../Item/ItemList'
import TooltipIcon from '../../Shared/Icons/TooltipIcon'
import DateField from '../../Shared/Forms/TextFields/DateField'
import TermSelect from '../../Shared/Forms/Selects/TermSelect'
import TrimTextField from '../../Shared/Forms/TextFields/TrimTextField'
import ApiSelect from '../../Shared/Forms/ApiSelect'
import EditIcon from "../../Shared/Icons/EditIcon";
import UserMixin from "../../../mixins/UserMixin";
import ServiceFunctionsMixin from "../../../mixins/ServiceFunctionsMixin";
import UserPicker from "../../Shared/Forms/UserPicker";
import NumberField from "../../Shared/Forms/TextFields/NumberField";
import Tunnel from "../../Shared/Tunnel";
import SquareButton from "../../Shared/Buttons/SquareButton";
import CreateButton from "../../Shared/Buttons/CreateButton";

const multipliers = {
  withdrawal: -1,
  transfer: 1,
  deposit: 1,
}


export default {
  name: 'CashboxesShowPage',
  mixins: [
    UserMixin,
    ServiceFunctionsMixin,
  ],
  components: {
    CreateButton,
    SquareButton,
    Tunnel,
    NumberField,
    UserPicker,
    EditIcon,
    ApiSelect,
    TrimTextField,
    TermSelect,
    DateField,
    TooltipIcon,
    ItemList,
    Amount,
    PageCard,
    Subcard,
    ItemShowView,
  },
  data: vm => ({
    amount: null,
    availableAmount: null,
    availableTerms: [],
    cashbox: {},
    cashboxAmount: 0,
    cashOperation: null,
    editDialogEmployeeEnabled: true,
    editDialogIsVisible: false,
    editDialogMode: 'employee',
    editDisabled: false,
    listIsHidden: false,
    rules: {
      required: value => undefined !== value && '' !== value || vm.l('pages.cash_operations.validation.required')
    },
    supportedEditDialogModes: [
      'employee',
      'simple',
    ],
    termFilterOptions: [],
    transferCashboxes: [],
    type: 'withdrawal',
    types: [
      'withdrawal',
      'deposit',
      'transfer',
    ],
    employee: null,
    headers: [
      {
        value: 'id',
        align: 'end',
        sortable: true,
        filterable: false,
        width: '5%',
      },
      {
        value: 'user',
        align: 'start',
        sortable: false,
        filterable: false,
      },
      {
        value: 'note',
        align: 'start',
        sortable: false,
        filterable: false,
      },
      {
        value: 'term',
        align: 'end',
        sortable: true,
        filterable: false,
      },
      {
        value: 'amount',
        align: 'end',
        sortable: true,
        filterable: false,
      },
      {
        value: 'created_at',
        align: 'end',
        sortable: true,
        filterable: false,
      },
      {
        value: 'created_by',
        align: 'start',
        sortable: true,
        filterable: false,
      },
    ],
  }),
  computed: {
    amountDisabled() {
      return (null === this.availableAmount) && this.showAvailableAmount
    },
    showAvailableAmount() {
      return this.editDialogMode === 'employee' && this.type === 'withdrawal' && !!this.cashOperation.user_id
    }
  },
  watch: {
    amount(amount) {
      if (null !== this.cashOperation) {
        this.cashOperation.amount = Math.abs(amount) * multipliers[this.type]
      }
    },
    'cashbox.amount': function (amount) {
      this.cashboxAmount = amount
    },
    'cashOperation.amount': function (amount) {
      this.amount = !!amount ? Math.abs(amount) : null
    },
    'cashOperation.term': function () {
      this.refreshAvailableAmount()
    },
    'cashOperation.user_id': function (userId) {
      this.employee = null
      if (!!userId) {
        this.$api.get('employees', {params: {filter: {'only_id': userId}}})
            .then(function (response) {
              if (_.get(response.data, 'total', 0) === 1) {
                this.employee = _.get(response.data, 'items.0', null)
              }
            }.bind(this))
      }
    },
    editDialogIsVisible(visible) {
      if (!visible && null !== this.cashOperation) {
        this.wipeCashOperation()
      }
    },
    editDialogMode(mode) {
      this.editDialogEmployeeEnabled = (mode === 'employee')
      if (!this.editDialogEmployeeEnabled) {

        if (undefined !== this.cashOperation.user_id) {
          delete this.cashOperation.user_id
        }

        this.cashOperation.term = _.last(this.availableTerms)

      } else {

        if (this.type === 'transfer') {
          this.type = 'withdrawal'
        }

        this.cashOperation.term = _.first(this.availableTerms)
      }
    },
    employee() {
      this.refreshAvailableAmount()
    },
    cashOperation(cashOperation) {
      if (cashOperation !== null) {

        if (undefined !== cashOperation.amount && this.type !== 'transfer') {
          this.type = cashOperation.amount < 0 ? 'withdrawal' : 'deposit'
          this.amount = Math.abs(cashOperation.amount)
        }

        if (!this.editDialogIsVisible) {
          this.showEditDialog()
        }

      } else {

        this.type = 'withdrawal'
        this.amount = null
        if (this.editDialogIsVisible) {
          this.hideEditDialog()
        }
      }
    },
    type(type) {
      if (null !== this.cashOperation) {

        if (type === 'transfer' && undefined !== this.cashOperation.note && !!this.cashOperation.note) {
          delete this.cashOperation.note
        }

        if (!!this.amount) {
          this.cashOperation.amount = Math.abs(this.amount) * multipliers[type]
        }

      }
    },
  },
  methods: {
    detectEditDialogMode() {
      return (!!this.cashOperation && this.cashOperation.uuid && !this.cashOperation.user_id) ? 'simple' : 'employee'
    },
    handleAvailableAmountClick() {
      if (null !== this.availableAmount) {
        this.amount = this.availableAmount
      }
    },
    handleCashboxTunnel(cashbox) {
      this.updateCashbox(cashbox)
    },
    handleCreateButtonClick() {
      this.cashOperation = {}
    },
    handleDontSaveButtonClick() {
      this.wipeCashOperation()
    },
    handleEditButtonClick(item) {
      this.cashOperation = this.clone(item)
    },
    handleSaveButtonClick() {
      this.editDisabled = true
      const uuid = _.get(this.cashOperation, 'uuid', null)
      const existing = !!uuid
      const apiAction = existing ? 'put' : 'post'
      const apiUrl = (this.type === 'transfer') ? ('cashboxes/' + this.cashbox.uuid + '/transfer') : ('cash-operations' + (existing ? ('/' + uuid) : ''))
      const apiParams = existing ? this.cashOperation : _.merge(this.cashOperation, {cashbox_id: _.get(this.cashbox, 'id', null)})
      return this.$api[apiAction](apiUrl, apiParams)
          .then(function () {
            this.editDisabled = false
            this.wipeCashOperation()
            this.reloadList()
            this.refreshCashbox()
          }.bind(this))
          .catch(function () {
            this.editDisabled = false
          }.bind(this))
    },
    hideEditDialog() {
      this.editDialogIsVisible = false
    },
    refreshAvailableAmount() {
      this.availableAmount = null
      const term = this.cashOperation.term,
          uuid = this.employee.uuid;
      if (!!term && !!uuid) {
        this.$api.get('employees/' + uuid + '/amount/' + term)
            .then(function (response) {
              this.availableAmount = response.data.amount
            }.bind(this))
            .catch(error => error)
      }
    },
    refreshCashbox() {
      if (!!this.cashbox && this.cashbox.uuid) {
        this.$api.get('cashboxes/' + this.cashbox.uuid)
            .then(function (response) {
              this.updateCashbox(response.data)
            }.bind(this))
            .catch(error => error)
      }
    },
    reloadList() {
      this.listIsHidden = true
      setTimeout(function () {
        this.listIsHidden = false
      }.bind(this), 500)

    },
    showEditDialog() {
      this.editDialogMode = this.detectEditDialogMode()
      this.editDialogIsVisible = true
    },
    showSlip(item) {
      if (undefined !== item.uuid) {
        window.open('/cash-operations/' + item.uuid + '/slip?stripped=1')
      }
    },
    updateCashbox(cashbox) {
      this.cashbox = cashbox
      this.transferCashboxes = []
      this.$api.get('cashboxes/list')
          .then(function (response) {
            this.transferCashboxes = _.filter(response.data, item => (item.value !== cashbox.id))
          }.bind(this))
          .catch(error => error)
    },
    wipeCashOperation() {
      this.cashOperation = null
    },
  },
  created() {
    this.$api.get('transactions/terms/list/desc')
        .then(function (response) {
          this.termFilterOptions = response.data
        }.bind(this))
        .catch(function () {
          this.termFilterOptions = []
        }.bind(this))

    this.$api.get('available/terms')
        .then(function (response) {
          this.availableTerms = response.data
        }.bind(this))
        .catch(function () {
          this.availableTerms = []
        }.bind(this))

  },
}
</script>

<style scoped>

</style>