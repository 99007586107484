<template>
    <div>
        <envelope-face
            v-for="(envelope, index) in envelopes"
            :key="index"
            :envelope="envelope"
            class="envelope-face"
        />
    </div>
</template>

<script>
    import EnvelopeFace from "../../Shared/ModelSpecific/Envelope/EnvelopeFace";
    export default {
        name: "EnvelopesFacesPage",
        components: {
            EnvelopeFace
        },
        data: vm => ({
            envelopes: [],
        }),
        created() {
            this.$api.get('envelopes', {params: this.$route.query})
                .then(function(response) {
                    this.envelopes = response.data.items
                }.bind(this))
        },
    }
</script>

<style scoped>

</style>