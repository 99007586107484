<template>
  <item-list-view
      :headers="headers"
      :no-delete="!(userIsGodLike || userHasFlag('is_chief_cashier'))"
  >

    <template slot="headerChips">
      <v-chip
          color="green lighten-4"
          v-if="estimationMessage"
          class="ml-2 font-weight-bold text-uppercase"
          small
      >
        {{ estimationMessage }}
      </v-chip>
      <v-chip
          color="amber lighten-4"
          v-if="packDateMessage"
          class="ml-2 font-weight-bold text-uppercase"
          small
      >
        {{ packDateMessage }}
      </v-chip>
    </template>

    <template v-slot:item.user="_">
      <router-link :to="{name: 'employees.show', params: {uuid: _.value.uuid}}">
        {{ _.value | listName }}
      </router-link>
    </template>

    <template v-slot:item.packed_at="_">
      {{ _.value | euroDateShortTime }}
    </template>

    <template v-slot:header.actions="_">
      <tunnel :value="_.params" @input="handleParamsTunnel"/>
      <square-button
          icon="mdi-contact-mail-outline"
          button-class="grey darken-2 white--text"
          depressed
          @click="showFaces()"
          :tooltip-text="l('pages.envelopes.actions.show_faces')"
      />
      <square-button
          icon="mdi-card-text-outline"
          button-class="blue darken-2 white--text ml-2"
          depressed
          @click="showSlips()"
          :tooltip-text="l('pages.envelopes.actions.show_slips')"
      />
      <square-button
          icon="mdi-file-document-box-check-outline"
          button-class="amber darken-4 white--text ml-2"
          depressed
          @click="showProtocols()"
          :tooltip-text="l('pages.envelopes.actions.show_protocols')"
      />
    </template>

    <template v-slot:extraItemActions="_">
      <tooltip-icon
          :tooltip="l('pages.envelopes.actions.show_face')"
          @click="showFace(_.item)"
          value="mdi-contact-mail-outline"
          color="black"
      />
      <tooltip-icon
          class="ml-2"
          :tooltip="l('pages.envelopes.actions.show_slip')"
          @click="showSlip(_.item)"
          value="mdi-card-text-outline"
          color="black"
      />
      <tooltip-icon
          class="ml-3 mr-2"
          :tooltip="l('pages.envelopes.actions.' + (_.item.is_packed ? 'unpack' : 'pack'))"
          @click="_.item.is_packed ? unpack(_.item) : pack(_.item)"
          :color="_.item.is_packed ? 'red' : 'green'"
      >
        {{ _.item.is_packed ? 'mdi-email-open-outline' : 'mdi-email-arrow-right-outline' }}
      </tooltip-icon>
    </template>

    <template v-slot:filter="_">
      <v-row dense class="pt-1">
        <v-col cols="12" sm="4" md="3">
          <trim-text-field
              clearable
              dense
              hide-details
              v-model="_.filter.text"
              :label="l('pages.envelopes.index.filters.text')"
          />
        </v-col>
        <v-col cols="12" sm="4" md="3">
          <api-select
              api="locations/list"
              dense
              hide-details
              multiple
              order="asc"
              v-model="_.filter.location_id"
              :label="l('pages.envelopes.index.filters.location_id')"
          />
        </v-col>
        <v-col cols="12" sm="4" md="2">
          <v-select
              dense
              hide-details
              v-model="_.filter.packed"
              :items="packedFilterOptions"
              :label="l('pages.envelopes.index.filters.packed.title')"
          />
        </v-col>
        <v-col cols="12" sm="6" md="2">
          <term-select
              clearable
              dense
              :disabled="!termFilterOptions || termFilterOptions.length === 0"
              hide-details
              :items="termFilterOptions"
              v-model="_.filter.from_term"
              :label="l('pages.envelopes.index.filters.from_term')"
          />
        </v-col>
        <v-col cols="12" sm="6" md="2">
          <term-select
              clearable
              dense
              :disabled="!termFilterOptions || termFilterOptions.length === 0"
              hide-details
              :items="termFilterOptions"
              v-model="_.filter.to_term"
              :label="l('pages.envelopes.index.filters.to_term')"
          />
        </v-col>
      </v-row>
    </template>

  </item-list-view>
</template>

<script>
import TablePageMixin from '../../../mixins/Cumulative/TablePageMixin'
import TooltipIcon from "../../Shared/Icons/TooltipIcon";
import TermSelect from "../../Shared/Forms/Selects/TermSelect";
import TrimTextField from "../../Shared/Forms/TextFields/TrimTextField";
import SquareButton from "../../Shared/Buttons/SquareButton";
import qs from "qs";
import Tunnel from "../../Shared/Tunnel";
import _ from 'lodash'
import ApiSelect from "../../Shared/Forms/ApiSelect";

export default {
  name: "EnvelopesIndexPage",
  components: {ApiSelect, Tunnel, SquareButton, TrimTextField, TermSelect, TooltipIcon},
  mixins: [
    TablePageMixin,
  ],
  data: vm => ({
    estimation: null,
    estimationMessage: null,
    headers: [
      {
        value: 'id',
        align: 'end',
        sortable: true,
        filterable: false,
        width: '5%',
      },
      {
        value: 'employee_name',
        align: 'start',
        sortable: true,
        filterable: false,
      },
      {
        value: 'location_name',
        align: 'start',
        sortable: true,
        filterable: false,
      },
      {
        value: 'term',
        align: 'end',
        sortable: true,
        filterable: false,
      },
      {
        value: 'amount',
        align: 'end',
        sortable: true,
        filterable: false,
      },
      {
        value: 'created_at',
        align: 'end',
        sortable: true,
        filterable: false,
      },
      {
        value: 'packed_at',
        align: 'end',
        sortable: true,
        filterable: false,
      },
    ],
    nearestPayday: null,
    packDateMessage: null,
    packedFilterOptions: [
      {
        text: vm.l('pages.envelopes.index.filters.packed.values.show'),
        value: 'show',
      },
      {
        text: vm.l('pages.envelopes.index.filters.packed.values.only'),
        value: 'only',
      },
      {
        text: vm.l('pages.envelopes.index.filters.packed.values.hide'),
        value: 'hide',
      },
    ],
    tableParams: {},
    termFilterOptions: [],
    customAppTitle: 'pages.envelopes.index.title',
  }),
  watch: {
    estimation(estimation) {
      const amount = _.get(estimation, 'amount', null),
          term = _.get(estimation, 'term', null)

      this.estimationMessage = this.composeEstimationMessage(amount, term)
    },
    nearestPayday(payday) {
      if (!!payday) {
        const date = this.$moment(payday).format('DD.MM.YYYY') + ', 00:00'
        this.packDateMessage = this.l('pages.envelopes.index.messages.pack', {date: date})
      } else {
        this.packDateMessage = null
      }
    }
  },
  methods: {
    composeEstimationMessage(amount, term) {
      return (amount && term)
          ? this.$options.filters.term(term) + ': ' + this.$options.filters.amount(amount) + ' ' + this.l('global.currency')
          : null
    },
    handleParamsTunnel(params) {
      this.tableParams = params
    },
    pack(item) {

      this.$api.put('envelopes/' + item.uuid + '/pack')
          .then(function () {
            this.$api.get('envelopes/' + item.uuid)
                .then(function (response) {
                  item.packed_at = response.data.packed_at
                  item.is_packed = response.data.is_packed
                  item.is_editable = response.data.is_editable
                  item.is_deletable = response.data.is_deletable
                }.bind(this))
                .catch(error => error)
          }.bind(this))
          .catch(error => error)
    },
    showSlip(item) {
      if (undefined !== item.uuid) {
        window.open('/envelopes/' + item.uuid + '/slip?stripped=1')
      }
    },
    fetchEstimation() {
      return this.$api.get('envelopes/estimate')
    },
    getPrintParams() {
      return _.merge(this.tableParams, {stripped: 1, itemsPerPage: 10000, page: 1})
    },
    getPrintParamString() {
      return qs.stringify(this.getPrintParams(), {
        arrayFormat: 'brackets',
        encode: false
      })
    },
    refreshEstimation() {
      this.estimation = null
      this.fetchEstimation()
          .then(function (response) {
            this.estimation = response.data
          }.bind(this))
    },
    showFace(item) {
      if (undefined !== item.uuid) {
        window.open('/envelopes/' + item.uuid + '/face?stripped=1')
      }
    },
    showProtocols() {
      window.open('/envelopes/protocols/?' + this.getPrintParamString())
    },
    showSlips() {
      window.open('/envelopes/slips/?' + this.getPrintParamString())
    },
    showFaces() {
      window.open('/envelopes/faces/?' + this.getPrintParamString())
    },
    unpack(item) {

      this.$api.put('envelopes/' + item.uuid + '/unpack')
          .then(function () {
            this.$api.get('envelopes/' + item.uuid)
                .then(function (response) {
                  item.packed_at = response.data.packed_at
                  item.is_packed = response.data.is_packed
                  item.is_editable = response.data.is_editable
                  item.is_deletable = response.data.is_deletable
                }.bind(this))
                .catch(error => error)
          }.bind(this))
          .catch(error => error)
    },
  },
  created() {

    this.$api.get('envelopes/terms/list')
        .then(function (response) {
          this.termFilterOptions = response.data
        }.bind(this))
        .catch(error => error)

    this.$api.get('info/payday')
        .then(function (response) {
          this.nearestPayday = _.get(response.data, 'payday', null)
        }.bind(this))
        .catch(error => error)

    this.refreshEstimation()

  },
}
</script>

<style scoped>

</style>
