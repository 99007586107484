<template />
<script>
    export default {
        name: "Tunnel",
        props: {
            value: {
                default: null,
                required: true,
            },
        },
        watch: {
            value: {
                deep: true,
                handler(value) {
                    this.$emit('input', value)
                },
                immediate: true,
            },
        },
    }
</script>

