<template>
    <v-select
            :disabled="disabled || (listItems || []).length === 0"
            :items="selectItems"
            :loading="loading"
            v-bind="$attrs"
            v-model="selected"
            v-on="$listeners"
    />
</template>

<script>
    import _ from 'lodash'

    export default {
        name: "ApiSelect",
        inheritAttrs: false,
        props: {
            api: {
                required: true,
                type: String,
            },
            disabled: {
                default: false,
                required: false,
                type: Boolean,
            },
            emptyText: {
                default: '---',
                required: false,
                type: String,
            },
            emptyValue: {
                default: null,
                required: false,
                type: String,
            },
            params: {
                default: null,
                required: false,
                type: Object,
            },
            first: {
                default: false,
                required: false,
                type: Boolean,
            },
            prependEmpty: {
                default: false,
                required: false,
                type: Boolean,
            },
            items: {
                default: null,
                required: false,
                type: Array,
            },
            order: {
                default: null,
                required: false,
                type: String,
            },
            value: {
                default: null,
                required: false,
            },
        },
        data: vm => ({
            apiLoadFailed: false,
            loading: false,
            listItems: null,
            selected: null,
        }),
        computed: {
            selectItems() {
                return this.prependEmpty ? [{text: this.emptyText, value: this.emptyValue}].concat(this.listItems) : this.listItems
            },
        },
        watch: {
            api() {
                this.apiLoadFailed = false
                this.updateListIfItemsNotSetExplicitly()
            },
            params: {
                deep: true,
                handler(params) {
                    this.apiLoadFailed = false
                    this.updateListIfItemsNotSetExplicitly()
                },
            },
            selected(item) {
                this.$emit('input', item)
            },
            items: {
                deep: true,
                handler(items) {
                    this.listItems = items || []
                },
                immediate: true,
            },
            listItems: {
                deep: true,
                handler(listItems) {
                    if ((listItems || []).length !== 0) {
                        if (!!this.selected && !this.isSelectedItemListed()) {
                            this.selected = null
                        } else if (!this.selected && this.first) {
                            this.selected = listItems[0].value
                        }
                    }
                    this.$emit('items', listItems)
                },
                immediate: true,
            },
            value: {
                handler(value) {
                    this.selected = value || null
                },
                immediate: true,
            },
        },
        methods: {
            isSelectedItemListed() {
                return _.filter(this.listItems || [], function (item) {
                    return item.value === this.selected
                }.bind(this)).length !== 0
            },
            updateList() {
                if (!this.apiLoadFailed) {
                    this.loading = true
                    this.$api.get(this.api, {params: this.params})
                        .then(function (response) {
                            let listItems = response.data || []
                            if (!!this.order && listItems.length) {
                                listItems = _.sortBy(listItems, item => item.text)
                                if (this.order.toLowerCase() === 'desc') {
                                    _.reverse(listItems)
                                }
                            }
                            this.listItems = listItems
                        }.bind(this))
                        .catch(function () {
                            this.apiLoadFailed = true
                            this.listItems = []
                        }.bind(this))
                        .finally(function () {
                            this.loading = false
                        }.bind(this))
                }
            },
            updateListIfItemsNotSetExplicitly() {
                if ((this.items || []).length === 0) {
                    this.updateList()
                }
            }
        },
        created() {
            this.updateListIfItemsNotSetExplicitly()
        }
    }
</script>

<style scoped>

</style>
