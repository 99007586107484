<template>
    <page-card title-class="pb-6">

        <template slot="title">
            <v-flex>
                <div class="d-flex">

                    <!-- HEADER TITLE -->
                    <v-flex class="d-none d-sm-inline-flex">
                        <span>
                            {{ appTitle }}
                        </span>
                        <span>
                            <slot name="headerChips" />
                        </span>
                    </v-flex>

                    <v-flex class="text-right">

                        <!-- HEADER ACTION BUTTONS -->
                        <slot name="headerButtons" />

                        <!-- CREATE BUTTON -->
                        <create-button
                                :label="l('pages.all.actions.create')"
                                v-if="createButton"
                                :to="$route.path + '/create'" class="ml-2 success"
                        />

                    </v-flex>

                </div>
            </v-flex>
        </template>

        <!-- TABLE ITSELF -->
        <item-list
                v-bind="$attrs"
                v-on="$listeners"
        >
            <template
                v-for="(_, slot) of $scopedSlots"
                v-slot:[slot]="scope"
            >
                <slot :name="slot" v-bind="scope"/>
            </template>
        </item-list>

    </page-card>
</template>

<script>

    import PageMixin from '../../../mixins/Cumulative/PageMixin'
    import PageCard from '../../Shared/Cards/PageCard'
    import ItemList from '../ItemList'
    import CreateButton from "../../Shared/Buttons/CreateButton";

    const ItemListView = {
        name: "ItemListView",
        mixins: [
            PageMixin,
        ],
        components: {
            CreateButton,
            ItemList,
            PageCard,
        },
        inheritAttrs: false,
        props: {
            createButton: {
                type: Boolean,
                required: false,
                default: false,
            },
        },
    }

    export default ItemListView


</script>

<style>

</style>