<template>
    <page-card
            :tabs="tabs"
            v-bind="$attrs"
            v-on="$listeners"
    >
        <template v-for="(_, slot) of $slots" :slot="slot">
            <slot :name="slot" />
        </template>
    </page-card>
</template>

<script>
    import PageCard from '../../../Shared/Cards/PageCard'

    export default {
        name: "ManagementPage",
        components: {
            PageCard
        },
        data: vm => ({
            tabs: [
                {
                    text: vm.l('pages.management.index.title'),
                    route: {
                        name: 'pages.management.index',
                    },
                },
                {
                    text: vm.l('pages.management.cashboxes.title'),
                    route: {
                        name: 'pages.management.cashboxes',
                    },
                },
            ],
        }),
    }
</script>

<style scoped>

</style>