import auth from '../../auth'
import EmployeesShowAssignmentsIndexPage from '../../../components/Pages/locations/show/shifts/index'
import EmployeesShowAssignmentsCreatePage from '../../../components/Pages/locations/show/shifts/create'
import EmployeesShowAssignmentsEditPage from '../../../components/Pages/locations/show/shifts/edit'
import EmployeesShowAssignmentsShowPage from '../../../components/Pages/locations/show/shifts/show'

export default [
    {
        path: '/locations/:uuid/shifts/create',
        name: 'locations.show.shifts.create',
        component: EmployeesShowAssignmentsCreatePage,
        beforeEnter: auth.guard.user,
    },
    {
        path: '/locations/:uuid/shifts/:childUuid',
        name: 'locations.show.shifts.show',
        component: EmployeesShowAssignmentsShowPage,
        beforeEnter: auth.guard.user,
    },
    {
        path: '/locations/:uuid/shifts/:childUuid/edit',
        name: 'locations.show.shifts.edit',
        component: EmployeesShowAssignmentsEditPage,
        beforeEnter: auth.guard.user,
    },
    {
        path: '/locations/:uuid/shifts',
        name: 'locations.show.shifts',
        component: EmployeesShowAssignmentsIndexPage,
        beforeEnter: auth.guard.user,
    },

]
