<template>
    <v-form>
        <v-row>
            <v-col cols="12" md="6" class="pb-0">
                <subcard content-class="pt-0">
                    <template slot="title">
                        {{ l('pages.assignments.edit.cards.general.title') }}
                    </template>

                    <v-row dense>
                        <v-col cols="12">
                            <api-select
                                    api="locations/list/available"
                                    :disabled="disabled"
                                    hide-details
                                    :label="l('pages.assignments.properties.location')"
                                    v-model="positionScope.location_id"
                            />
                        </v-col>
                    </v-row>

                    <v-row dense>
                        <v-col cols="12" md="3">
                            <v-select
                                    :disabled="disabled || !unitOptions.length"
                                    hide-details
                                    :label="l('pages.assignments.properties.unit')"
                                    :items="unitOptions"
                                    v-model="positionScope.unit_id"
                            />
                        </v-col>
                        <v-col cols="12" md="9">
                            <v-select
                                    :disabled="disabled || !positionOptions.length"
                                    hide-details
                                    :label="l('pages.assignments.properties.position')"
                                    :items="positionOptions"
                                    v-model="positionId"
                            />
                        </v-col>
                    </v-row>

                </subcard>
            </v-col>

            <v-col cols="12" md="6" class="pb-0">
                <subcard content-class="pt-0">

                    <template slot="title">
                        {{ l('pages.assignments.edit.cards.params.title') }}
                    </template>


                    <v-row dense>
                        <v-col cols="12" md="4" sm="6">
                            <date-field
                                    autocomplete="off"
                                    text-field-class="mt-4"
                                    :disabled="disabled"
                                    dense
                                    hide-details
                                    required
                                    :label="l('pages.assignments.properties.from_date')"
                                    v-model="fromDate"
                            >
                                <template slot="append">
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <v-icon
                                                    @click="handleSingleDateSwitchClicked"
                                                    :disabled="disabled"
                                                    v-on="on"
                                            >
                                                {{ single ? 'mdi-equal-box' : 'mdi-equal' }}
                                            </v-icon>
                                        </template>
                                        <span>
                                            {{ l('pages.assignments.edit.form.from_date.single') }}
                                        </span>
                                    </v-tooltip>

                                </template>
                            </date-field>
                        </v-col>
                        <v-col cols="12" md="4" sm="6">
                            <date-field
                                    autocomplete="off"
                                    clearable
                                    text-field-class="mt-4"
                                    :disabled="disabled"
                                    dense
                                    hide-details
                                    :label="l('pages.assignments.properties.to_date')"
                                    v-model="toDate"
                            />
                        </v-col>
                        <v-col cols="12" md="4" sm="12">
                            <time-field
                                    autocomplete="off"
                                    class="mt-4"
                                    :disabled="disabled"
                                    clearable
                                    dense
                                    hide-details
                                    :label="l('pages.assignments.properties.time')"
                                    v-model="time"
                            />
                        </v-col>
                    </v-row>


                    <v-row dense>
                        <v-col cols="12" md="3" sm="4">
                            <hours-field
                                    autocomplete="off"
                                    class="mt-4"
                                    :disabled="disabled"
                                    dense
                                    hide-details
                                    placeholder=""
                                    v-model="hours"
                            />
                        </v-col>
                        <v-col cols="12" md="5" sm="8">
                            <rate-field
                                    autocomplete="off"
                                    class="mt-4"
                                    :disabled="disabled"
                                    dense
                                    hide-details
                                    :items="rates"
                                    v-model="rate"
                                    maxlength="6"
                            />
                        </v-col>
                        <v-col cols="12" md="4" sm="12">
                            <v-checkbox
                                    dense
                                    :disabled="disabled"
                                    hide-details
                                    :label="l('pages.assignments.properties.is_payable')"
                                    v-model="isPayable"
                            />
                        </v-col>
                    </v-row>


                </subcard>
            </v-col>
        </v-row>
    </v-form>
</template>

<script>
    import _ from 'lodash'
    import t from 'typy'
    import Subcard from '../../../../../Shared/Cards/Subcard'
    import DateField from '../../../../../Shared/Forms/TextFields/DateField'
    import NumberField from '../../../../../Shared/Forms/TextFields/NumberField'
    import TimeField from '../../../../../Shared/Forms/TextFields/TimeField'
    import HoursField from '../../../../../Shared/Forms/TextFields/HoursField'
    import RateField from '../../../../../Shared/Forms/TextFields/RateField'
    import ApiSelect from '../../../../../Shared/Forms/ApiSelect'

    export default {
        name: "EmployeeAssignmentEditForm",
        inheritAttrs: false,
        components: {
            ApiSelect,
            DateField,
            HoursField,
            NumberField,
            RateField,
            Subcard,
            TimeField,
        },
        props: {
            value: {
                required: false,
                type: Object,
                default: () => ({}),
            },
            disabled: {
                required: false,
                type: Boolean,
                default: false,
            }
        },
        data: vm => ({
            assignment: {},
            fromDate: '',
            hours: null,
            isPayable: false,
            locationOptions: [],
            position: {},
            positionId: null,
            positionOptions: [],
            positionScope: {
                location_id: '',
                unit_id: '',
            },
            rate: null,
            rates: [],
            rules: {
                required: value => undefined !== value && '' !== value || vm.l('pages.assignments.validation.required')
            },
            single: false,
            toDate: '',
            unitOptions: [],
        }),
        watch: {
            value: {
                deep: true,
                immediate: true,
                handler(value) {
                    this.assignment = value
                    this.fromDate = (undefined !== value.from_date) ? value.from_date : ''
                    this.hours = (undefined !== value.hours) ? value.hours : null
                    this.isPayable = (undefined !== value.is_payable) ? !!value.is_payable : true
                    this.positionId = (undefined !== value.position_id) ? value.position_id : null
                    this.rate = (undefined !== value.rate) ? value.rate : null
                    this.time = (undefined !== value.time) ? value.time : ''
                    this.toDate = (undefined !== value.to_date) ? value.to_date : ''
                },
            },
            assignment: {
                deep: true,
                immediate: true,
                handler(value) {
                    this.emitInput(value)
                },
            },
            fromDate: {
                immediate: true,
                handler(value) {
                    this.assignment.from_date = value
                    if (this.single) {
                        this.assignment.to_date = value
                    }
                    this.refreshSingleDateFlag()
                },
            },
            hours: {
                immediate: true,
                handler(value) {
                    this.assignment.hours = value
                },
            },
            isPayable: {
                immediate: true,
                handler(value) {
                    this.assignment.is_payable = value
                },
            },
            rate: {
                immediate: true,
                handler(value) {
                    this.assignment.rate = value
                },
            },
            time: {
                immediate: true,
                handler(value) {
                    this.assignment.time = value
                },
            },
            toDate: {
                immediate: true,
                handler(value) {
                    this.assignment.to_date = value
                    this.refreshSingleDateFlag()
                },
            },
            positionId: {
                immediate: true,
                handler(id) {
                    this.assignment.position_id = id
                    if (id && (!_.isObject(this.position) || _.get(this.position, 'id', null) !== id)) {
                        this.$api.get('positions', {params: {only_id: id}})
                            .then(function (response) {
                                if (response.data.total === 1) {
                                    this.position = this.clone(response.data.items[0])
                                } else {
                                    this.position = null
                                }
                            }.bind(this))
                    }
                },
            },
            position: {
                immediate: true,
                deep: true,
                handler(position) {
                    this.positionScope = {
                        unit_id: _.get(position, 'unit_id', null),
                        location_id: _.get(position, 'location_id', null),
                    }
                    this.rates = _.get(position, 'rates', [])
                },
            },
            positionOptions: {
                deep: true,
                handler(value) {
                    if (value.length && !this.isOptionValid(this.positionId, value)) {
                        this.positionId = _.first(value).value
                    }
                },
            },
            positionScope: {
                deep: true,
                immediate: true,
                handler() {
                    this.updateUnitOptions()
                        .then(function () {
                            this.updatePositionOptions()
                        }.bind(this))
                },
            },
            single: {
                immediate: true,
                handler(value) {
                    if (!!value) {
                        this.toDate = this.fromDate
                    }
                },
            },
            unitOptions: {
                deep: true,
                handler(value) {
                    if (value.length && !this.isOptionValid(this.positionScope.unit_id, value)) {
                        this.positionScope.unit_id = _.first(value).value
                    }
                },
            },

        },
        methods: {
            clone(value) {
                return _.isArray(value) || _.isObject(value)
                    ? JSON.parse(JSON.stringify(value))
                    : value
            },
            itemsEqual(item1, item2) {
                const type = typeof item1
                switch (true) {
                    case type !== (typeof item2):
                        return false
                    case type === 'object':
                        return !JSON.stringify(item1).localeCompare(JSON.stringify(item2))
                    default:
                        return item1 === item2
                }
            },
            isOptionValid(option, options = [], key = 'value') {
                return !!_.filter(options, value => value && value[key] && value[key] === option).length
            },


            updatePositionOptions() {
                return new Promise(function (resolve, reject) {
                    if (this.positionScope.location_id) {
                        this.$api.get('positions/list', {params: this.positionScope})
                            .then(function (response) {
                                this.positionOptions = response.data
                                resolve()
                            }.bind(this))
                            .catch(error => reject(error))
                    } else {
                        this.positionOptions = []
                        resolve()
                    }
                }.bind(this))
            },
            updateUnitOptions() {
                return new Promise(function (resolve, reject) {
                    const locationId = this.positionScope.location_id
                    if (locationId) {
                        this.$api.get('units/list', {params: {location_id: locationId}})
                            .then(function (response) {
                                this.unitOptions = response.data
                                resolve()
                            }.bind(this))
                            .catch(error => reject(error))
                    } else {
                        this.unitOptions = []
                        resolve()
                    }
                }.bind(this))
            },
            emitInput(value) {
                this.$emit('input', value)
            },

            handleSingleDateSwitchClicked() {
                this.single = !this.single
            },
            refreshSingleDateFlag() {
                this.single = !!this.toDate && !!this.fromDate
                    && (t(this.fromDate).safeString.substr(0, 10) === t(this.toDate).safeString.substr(0, 10))
            },

        },
    }
</script>

