import auth from '../auth'
import EnvelopesIndexPage from '../../components/Pages/envelopes/index'
import EnvelopesShowDetailsPage from '../../components/Pages/envelopes/show/details'
import EnvelopesShowSlipPage from '../../components/Pages/envelopes/show/slip'
import EnvelopesShowFacePage from '../../components/Pages/envelopes/show/face'
import EnvelopesFacesPage from '../../components/Pages/envelopes/faces'
import EnvelopesProtocolsPage from '../../components/Pages/envelopes/protocols'
import EnvelopesSlipsPage from '../../components/Pages/envelopes/slips'

export default [
    {
        path: '/envelopes/protocols',
        name: 'envelopes.protocols',
        component: EnvelopesProtocolsPage,
        beforeEnter: auth.guard.user,
    },
    {
        path: '/envelopes/slips',
        name: 'envelopes.slips',
        component: EnvelopesSlipsPage,
        beforeEnter: auth.guard.user,
    },
    {
        path: '/envelopes/faces',
        name: 'envelopes.faces',
        component: EnvelopesFacesPage,
        beforeEnter: auth.guard.user,
    },
    {
        path: '/envelopes/:uuid/slip',
        name: 'envelopes.show.slip',
        component: EnvelopesShowSlipPage,
        beforeEnter: auth.guard.user,
    },
    {
        path: '/envelopes/:uuid/face',
        name: 'envelopes.show.face',
        component: EnvelopesShowFacePage,
        beforeEnter: auth.guard.user,
    },
    {
        path: '/envelopes/:uuid',
        name: 'envelopes.show',
        component: EnvelopesShowDetailsPage,
        beforeEnter: auth.guard.user,
    },
    {
        path: '/envelopes',
        name: 'envelopes.index',
        component: EnvelopesIndexPage,
        beforeEnter: auth.guard.user,
    },
]