<template>
    <item-edit-view v-slot:default="_">
        <location-edit-form
                :disabled="_.itemDisabled"
                v-model="_.item"
        />
    </item-edit-view>
</template>

<script>
    import ItemEditView from '../../Item/Views/ItemEditView'
    import LocationEditForm from './Shared/LocationEditForm'

    export default {
        name: 'LocationsEditPage',
        components: {
            ItemEditView,
            LocationEditForm,
        },
    }
</script>
