import Vue from 'vue'
import VueI18n from 'vue-i18n'
import store from '../store'
import lang from '../lang'

const defaultImpl = VueI18n.prototype.getChoiceIndex
VueI18n.prototype.getChoiceIndex = function(choice, choicesLength) {

    // this === VueI18n instance, so the locale property also exists here

    choice = Math.abs(choice)

    switch (true) {
        case choice === 0:
        case choicesLength < 2:
            return 0
        case choice >= 100:
            choice = choice % 100 || 100
            break;
    }

    const tail = choice % 10
    const isFraction = (choice > Math.floor(choice))

    console.log(choice, Math.floor(choice), isFraction)

    switch(this.locale) {

        case 'cs':

            // "žádná věc | jedna věc | {count} věci | {count} věcí | {count} věci"
            //     0      |     1     |     2-4      |      5+      |    zlomky

            switch (true) {
                case isFraction && choicesLength > 4:
                    return 4
                case choice === 1:
                case choicesLength === 2:
                    return 1;
                case choice < 5:
                case choicesLength === 3:
                    return 2
                default:
                    return 3
            }

        case 'ru':

            // "нет вещей | {count} вещь | {count} вещи | {count} věcí"
            //     0      |  (^|[^1])1$  |   см. ниже   |   остальные
            // -----------+--------------+ vvvvvvvvvvvv +---------------
            // Дроби и числа с последней цифрой от 2 до 4, исключая 12-14.
            // Иначе говоря: (^|[^1])[2-4](.\d+)?$

            switch (true) {
                case choicesLength === 2:
                    return 1
                case isFraction:
                    return 2
                case (choice < 5 || choice > 20) && tail > 0 && tail < 5:
                    return (tail === 1) ? 1 : 2
                default:
                    return 3
            }

        default:
            return defaultImpl.apply(this, arguments) // default implementation
    }

}

Vue.use(VueI18n);

const i18n = new VueI18n({
    locale: store.getters.appLocale,
    fallbackLocale: 'cs',
    messages: lang,
});

export default i18n