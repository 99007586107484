<template>
    <item-show-view v-slot:default="{ item: adjustment }">
        <v-simple-table>
            <tbody>
            <tr>
                <th>{{ l('pages.adjustments.properties.name') }}</th>
                <td>{{ adjustment | name }}</td>
            </tr>
            </tbody>
        </v-simple-table>

    </item-show-view>
</template>

<script>
    import ItemShowView from '../../Item/Views/ItemShowView'

    export default {
        name: 'adjustmentsShowPage',
        components: {
            ItemShowView,
        },
    }
</script>

<style scoped>

</style>