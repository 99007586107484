<template>
    <item-create-view v-slot:default="_">
        <transaction-edit-form
                :disabled="_.itemDisabled"
                v-model="_.item"
        />
    </item-create-view>
</template>

<script>
    import ItemCreateView from '../../Item/Views/ItemCreateView'
    import TransactionEditForm from './Shared/TransactionEditForm'

    export default {
        name: 'TransactionsCreatePage',
        components: {
            ItemCreateView,
            TransactionEditForm,
        },
    }
</script>
