<template>
    <employees-show-page
            parent
            content-color="white"
            v-slot:default="{ item: employee, itemDisabled }"
    >
        <v-row>
            <v-col cols="12" md="12" class="pb-0">
                <sub-item-list create-button :headers="headers">

                    <template v-slot:item.processed_at="_">
                        {{ _.value | euroDateShortTime }}
                    </template>

                    <template v-slot:extraItemActions="_">
                        <tooltip-icon
                                class="ml-1"
                                :tooltip="l('pages.fund_locks.actions.' + (_.item.is_processed ? 'revert' : 'process'))"
                                @click="_.item.is_processed ? revert(_.item) : process(_.item)"
                                :color="_.item.is_processed ? 'green' : 'red'"
                        >
                            {{ _.item.is_processed ? 'mdi-transfer-left' : 'mdi-transfer-right' }}
                        </tooltip-icon>
                    </template>

                </sub-item-list>
            </v-col>
        </v-row>
    </employees-show-page>
</template>

<script>
    import CheckMark from '../../../../Shared/Icons/CheckMark'
    import Subcard from '../../../../Shared/Cards/Subcard'
    import EmployeesShowPage from '../../show'
    import DateField from '../../../../Shared/Forms/TextFields/DateField'
    import TrimTextField from '../../../../Shared/Forms/TextFields/TrimTextField'
    import SubItemList from '../../../../Item/SubItemList'
    import TooltipIcon from "../../../../Shared/Icons/TooltipIcon";

    export default {
        name: 'EmployeesShowAdjustmentsIndexPage',
        components: {
            TooltipIcon,
            CheckMark,
            DateField,
            EmployeesShowPage,
            SubItemList,
            Subcard,
            TrimTextField,
        },
        data: vm => ({
            headers: [
                {
                    value: 'id',
                    align: 'end',
                    sortable: true,
                    filterable: false,
                },
                {
                    value: 'note',
                    align: 'start',
                    sortable: false,
                    filterable: false,
                },
                {
                    value: 'amount',
                    align: 'end',
                    sortable: true,
                    filterable: false,
                },
                {
                    value: 'created_at',
                    align: 'end',
                    sortable: true,
                    filterable: false,
                },
                {
                    value: 'created_by',
                    align: 'start',
                    sortable: false,
                    filterable: false,
                },
/*
                {
                    value: 'updated_at',
                    align: 'end',
                    sortable: true,
                    filterable: false,
                },
*/
                {
                    value: 'processed_at',
                    align: 'end',
                    sortable: true,
                    filterable: false,
                },
            ],
        }),
        methods: {
            process(item) {

                this.$api.put('fund-locks/' + item.uuid + '/process')
                    .then(function() {
                        this.$api.get('fund-locks/' + item.uuid)
                        .then(function(response) {
                            item.processed_at = response.data.processed_at
                            item.is_processed = response.data.is_processed
                            item.is_editable = response.data.is_editable
                            item.is_deletable = response.data.is_deletable
                        }.bind(this))
                        .catch(error => error)
                    }.bind(this))
                    .catch(error => error)
            },
            revert(item) {

                this.$api.put('fund-locks/' + item.uuid + '/revert')
                    .then(function() {
                        this.$api.get('fund-locks/' + item.uuid)
                        .then(function(response) {
                            item.processed_at = response.data.processed_at
                            item.is_processed = response.data.is_processed
                            item.is_editable = response.data.is_editable
                            item.is_deletable = response.data.is_deletable
                        }.bind(this))
                        .catch(error => error)
                    }.bind(this))
                    .catch(error => error)
            },
        },
    }
</script>

<style scoped>

</style>