<template>
    <employees-show-page
            parent
            v-slot:default="_parent"
    >
        <sub-item-show-view
                v-slot:default="_"
        >
            <v-row>
                <v-col cols="12" sm="6">
                    <subcard>
                        <v-row>
                            <v-col cols="3">
                                {{ l('pages.fund_locks.properties.note') }}
                            </v-col>
                            <v-col cols="9" class="black--text">
                                {{ _.item.note }}
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="3">
                                {{ l('pages.fund_locks.properties.amount') }}
                            </v-col>
                            <v-col cols="9" class="black--text">
                                <amount currency>{{ _.item.amount }}</amount>
                            </v-col>
                        </v-row>
                    </subcard>
                </v-col>
                <v-col cols="12" sm="6">
                    <subcard>

                        <v-row>
                            <v-col cols="3">
                                {{ l('pages.fund_locks.properties.created_at') }}
                            </v-col>
                            <v-col cols="9" class="black--text">
                                {{ _.item.created_at | euroDateTime }}<span v-if="_.item.created_by_id">, {{ _.item.created_by | listName }}</span>
                            </v-col>
                        </v-row>
                        <v-row v-if="_.item.updated_at && _.item.updated_at !== _.item.created_at">
                            <v-col cols="3">
                                {{ l('pages.fund_locks.properties.updated_at') }}
                            </v-col>
                            <v-col cols="9" class="black--text">
                                {{ _.item.updated_at | euroDateTime }}<span v-if="_.item.updated_by_id">, {{ _.item.updated_by | listName }}</span>
                            </v-col>
                        </v-row>
                        <v-row v-if="_.item.is_processed">
                            <v-col cols="3">
                                {{ l('pages.fund_locks.properties.processed_at') }}
                            </v-col>
                            <v-col cols="9" class="black--text">
                                <check-mark :value="_.item.is_processed"/>
                                <span v-if="_.item.processed_at"> {{ _.item.processed_at | euroDateTime }}</span>
                            </v-col>
                        </v-row>
                    </subcard>
                </v-col>
            </v-row>
        </sub-item-show-view>
    </employees-show-page>
</template>

<script>
    import EmployeesShowPage from '../../show'
    import Subcard from '../../../../Shared/Cards/Subcard'
    import SubItemShowView from '../../../../Item/Views/SubItemShowView'
    import Amount from "../../../../Shared/Text/Amount";
    import CheckMark from "../../../../Shared/Icons/CheckMark";

    export default {
        name: 'EmployeesShowAdjustmentsShowPage',
        inheritAttrs: false,
        components: {
            CheckMark,
            Amount,
            EmployeesShowPage,
            Subcard,
            SubItemShowView,
        },
        data: vm => ({
        }),
    }
</script>

<style scoped>

</style>