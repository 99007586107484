import 'es6-promise/auto'
// import 'roboto-fontface/css/roboto/roboto-fontface.css'
import '@mdi/font/css/materialdesignicons.css'
import Vue from 'vue'
import VueMoment from 'vue-moment'
import i18n from './internals/i18n'
import store from './store'
import api from './internals/api'
import router from './router'
//import './registerServiceWorker'
import vuetify from './plugins/vuetify'
import Notifications from 'vue-notification'
import GlobalMixin from './mixins/Cumulative/GlobalMixin'
import App from './App.vue'

require('./internals/filters')
// require('./internals/globalComponents')

Vue.config.productionTip = false
Vue.use(VueMoment)
Vue.use(Notifications)
Vue.prototype.$api = api
Vue.prototype.$debug = (process.env.NODE_ENV === 'development')
Vue.mixin(GlobalMixin)

new Vue({
    i18n,
    store,
    router,
    vuetify,
    render: h => h(App)
}).$mount('#app')

