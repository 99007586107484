<template>
    <item-list-view v-bind="tableProps">
        <template v-slot:filter="_">
            <v-row dense>
                <v-col cols="12" md="9">
                    <trim-text-field
                            dense
                            hide-details
                            v-model="_.filter.text"
                            :label="l('pages.users.index.filters.text')"
                    />
                </v-col>
                <v-col cols="12" md="3">
                    <v-select
                            dense
                            hide-details
                            v-model="_.filter.is_employee"
                            :items="isEmployeeFilterOptions"
                            :label="l('pages.users.index.filters.is_employee.title')"
                    />
                </v-col>
            </v-row>
        </template>
        <template v-slot:item.birth_date="_">
            {{ _.value | euroDate }}
        </template>
        <template v-slot:item.flags="_">
            <v-chip
                v-for="flag in _.value"
                :key="flag"
                class="mr-2 px-2 font-weight-bold text-uppercase"
                x-small
            >
                {{ l('pages.users.values.flags.' + flag) }}
            </v-chip>
        </template>
        <template v-slot:item.emails="_">
            <span
                v-for="(email, index) in _.value"
                :key="index"
            >{{ index !== 0 ? ', ' : '' }}{{ email.address }}</span>
<!--            <span
                  v-for="(email, index) in _.value"
                  :key="index"
                >{{ index !== 0 ? ', ' : '' }}<a :href="'mailto:' + email.address">{{ email.address }}</a></span>-->
        </template>
        <template v-slot:item.phones="_">
            {{ _.value | phones }}
        </template>
    </item-list-view>
</template>

<script>
    import TablePageMixin from '../../../mixins/Cumulative/TablePageMixin'
    import TrimTextField from '../../Shared/Forms/TextFields/TrimTextField'
    import CheckMark from '../../Shared/Icons/CheckMark'

    export default {
        name: "UsersIndexPage",
        components: {
            CheckMark,
            TrimTextField,
        },
        mixins: [
            TablePageMixin,
        ],
        data: vm => ({
            isEmployeeFilterOptions: [
                {
                    text: vm.l('pages.users.index.filters.is_employee.values.show'),
                    value: 'show',
                },
                {
                    text: vm.l('pages.users.index.filters.is_employee.values.only'),
                    value: 'only',
                },
                {
                    text: vm.l('pages.users.index.filters.is_employee.values.hide'),
                    value: 'hide',
                },
            ],
            tableProps: {
                createButton: true,
                headers: [
                    {
                        value: 'id',
                        align: 'end',
                        width: '12ex',
                        sortable: true,
                        filterable: false,
                    },
                    {
                        value: 'list_name',
                        align: 'start',
                        sortable: true,
                        filterable: false,
                    },
                    {
                        value: 'emails',
                        align: 'start',
                        sortable: false,
                        filterable: false,
                    },
                    {
                        value: 'phones',
                        align: 'end',
                        sortable: false,
                        filterable: false,
                    },
                    {
                        value: 'flags',
                        align: 'start',
                        sortable: false,
                        filterable: false,
                    },
                    {
                        value: 'updated_at',
                        align: 'end',
                        sortable: true,
                        filterable: false,
                    },
                ],
                props: {},
            },
            customAppTitle: 'pages.users.index.title',
        }),
    }
</script>

<style scoped>

</style>