<template>
  <div>
    <v-row>
      <v-col class="pt-5">
        <v-flex>
          <v-skeleton-loader v-if="!itemLoaded" type="card-heading"></v-skeleton-loader>
          <div class="d-flex" v-if="itemLoaded">

            <v-flex class="d-none d-sm-inline-flex headline black--text">
              <span>{{ pageTitle }}</span>
              <span v-if="itemModified">
                                <v-chip
                                    class="text-uppercase font-weight-bold ml-3"
                                    color="red"
                                    dark
                                    small
                                >
                                    {{ l('pages.all.indicators.modified') }}
                                </v-chip>
                            </span>
            </v-flex>

            <v-flex class="text-right">
              <slot
                  name="buttons"
                  v-bind:item="item"
                  v-bind:item-disabled="itemDisabled"
                  v-bind:item-loaded="itemLoaded"
                  v-bind:item-loading="itemLoading"
                  v-bind:item-modified="itemModified"
                  v-bind:server-item="serverItem"
              ></slot>
              <div class="d-inline-flex" v-if="editButtonEnabled">
                <v-btn
                    class="d-none d-sm-inline-flex"
                    @click="handleEditButtonClick()"
                    :disabled="itemDisabled"
                    text
                >
                  {{ l('pages.all.actions.edit') }}
                </v-btn>
                <v-btn
                    class="d-inline-flex d-sm-none"
                    @click="handleEditButtonClick()"
                    :disabled="itemDisabled"
                    icon
                    transparent
                >
                  <v-icon>
                    mdi-pencil-outline
                  </v-icon>
                </v-btn>
              </div>
              <div class="d-inline-flex" v-if="showButtonEnabled">
                <v-btn
                    class="d-none d-sm-inline-flex"
                    @click="handleShowButtonClick()"
                    :disabled="itemDisabled"
                    text
                >
                  {{ l('pages.all.actions.show') }}
                </v-btn>
                <v-btn
                    class="d-inline-flex d-sm-none"
                    @click="handleShowButtonClick()"
                    :disabled="itemDisabled"
                    icon
                    transparent
                >
                  <v-icon>
                    mdi-eye-outline
                  </v-icon>
                </v-btn>
              </div>
              <div class="d-inline-flex" v-if="deleteButtonEnabled">
                <v-btn
                    class="d-none d-sm-inline-flex"
                    @click="handleDeleteButtonClick()"
                    color="red"
                    :disabled="itemDisabled"
                    text
                >
                  {{ l('pages.all.actions.delete') }}
                </v-btn>
                <v-btn
                    class="d-inline-flex d-sm-none"
                    @click="handleDeleteButtonClick()"
                    color="red"
                    :disabled="itemDisabled"
                    icon
                    transparent
                >
                  <v-icon>
                    mdi-close
                  </v-icon>
                </v-btn>
              </div>
              <div class="d-inline-flex">
                <v-btn
                    class="d-none d-sm-inline-flex"
                    @click="handleIndexButtonClick()"
                    :disabled="itemDisabled"
                    text
                >
                  {{ l('pages.all.actions.back_to_index') }}
                </v-btn>
                <v-btn
                    class="d-inline-flex d-sm-none"
                    @click="handleIndexButtonClick()"
                    :disabled="itemDisabled"
                    icon
                    transparent
                >
                  <v-icon>
                    mdi-format-list-bulleted-square
                  </v-icon>
                </v-btn>
              </div>
            </v-flex>
          </div>
        </v-flex>
      </v-col>
    </v-row>

    <v-row>
      <v-col class="pt-0">
        <!-- ITEM DETAILS -->
        <slot
            v-bind:item="item"
            v-bind:item-disabled="itemDisabled"
            v-bind:item-loaded="itemLoaded"
            v-bind:item-loading="itemLoading"
            v-bind:item-modified="itemModified"
            v-bind:server-item="serverItem"
        />
      </v-col>
    </v-row>

    <!-- ITEM ACTIONS -->
    <v-row>
      <v-col>
        <v-flex class="text-right">
          <v-skeleton-loader v-if="saveButtonEnabled && !itemLoaded" type="actions"/>
          <slot
              v-if="itemLoaded"
              name="actions"
              v-bind:item="item"
              v-bind:item-disabled="itemDisabled"
              v-bind:item-loaded="itemLoaded"
              v-bind:item-loading="itemLoading"
              v-bind:item-modified="itemModified"
              v-bind:server-item="serverItem"
          />
          <div class="d-inline-flex" v-if="saveButtonEnabled">
            <div class="d-inline-flex">
              <v-btn
                  class="d-none d-sm-inline-flex ml-2"
                  @click="handleSaveButtonClick()"
                  :disabled="itemDisabled"
                  text
              >
                {{ l('pages.all.actions.save') }}
              </v-btn>
              <v-btn
                  class="d-inline-flex d-sm-none ml-2"
                  @click="handleSaveButtonClick()"
                  depressed
                  :disabled="itemDisabled"
                  fab
                  transparent
              >
                <v-icon>
                  mdi-check-bold
                </v-icon>
              </v-btn>
            </div>
            <div class="d-inline-flex">
              <v-btn
                  class="d-none d-sm-inline-flex ml-2"
                  @click="handleSaveAndIndexButtonClick()"
                  color="green"
                  :dark="!itemDisabled"
                  depressed
                  :disabled="itemDisabled"
              >
                {{ l('pages.all.actions.save_and_index') }}
              </v-btn>
              <v-btn
                  class="d-inline-flex d-sm-none ml-2"
                  @click="handleSaveAndIndexButtonClick()"
                  color="green"
                  :dark="!itemDisabled"
                  depressed
                  :disabled="itemDisabled"
                  fab
              >
                <v-icon>
                  mdi-playlist-check
                </v-icon>
              </v-btn>
            </div>
          </div>
        </v-flex>
      </v-col>
    </v-row>

    <!-- ITEM DELETE DIALOG WINDOW -->
    <delete-dialog
        v-if="deleteButtonEnabled"
        :visible="deleteDialogVisible"
        :title="l('pages.all.actions.delete')"
        @confirmed="deleteDialogConfirmed()"
        @rejected="deleteDialogRejected()"
    />

  </div>
</template>

<script>

import ItemViewMixin from './ItemViewMixin'
import Subcard from '../../Shared/Cards/Subcard'
import DeleteDialog from '../../Shared/Dialogs/DeleteDialog'

export default {
  name: "SubItemView",
  mixins: [
    ItemViewMixin,
  ],
  components: {
    DeleteDialog,
    Subcard,
  },
}

</script>

<style scoped>

</style>