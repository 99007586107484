<template>
    <v-text-field
            :autocomplete="autocomplete"
            v-model="textFieldValue"
            v-bind="$attrs"
            v-on="$listeners"
    >
        <template v-for="(_, slot) of $slots" slot="slot">
            <slot :name="slot"/>
        </template>
    </v-text-field>
</template>

<script>
    import DisableChromeAutocompleteMixin from '../../../../mixins/Hacks/DisableChromeAutocompleteMixin'

    export default {
        name: "TrimTextField",
        mixins: [
            DisableChromeAutocompleteMixin,
        ],
        props: {
            autocomplete: {
                required: false,
                default: 'autocomplete',
            },
            value: {
                type: String,
                required: false,
                default: null,
            },
        },
        data: vm => ({
            textFieldValue: vm.value,
        }),
        watch: {
            value: {
                immediate: true,
                handler(value) {
                    if (!!value || (this.textFieldValue || '').trim() !== value) {
                        this.textFieldValue = value
                    }
                }
            },
            textFieldValue(value) {
                this.$emit('input', (value || '').trim())
            }
        },
    }
</script>

