<template>
    <item-view
            content-class="pt-2 pb-0"
            v-bind="$attrs"
            v-on="$listeners"
            v-slot:default="_"
    >
        <slot v-bind="_" />
    </item-view>
</template>

<script>
    import ItemView from './ItemView'

    export default {
        name: "ItemCreateView",
        components: {
            ItemView,
        },
    }
</script>

<style scoped>

</style>