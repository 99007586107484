import auth from '../../auth'
import CashboxesIndexPage from '../../../components/Pages/cashboxes/index'
import CashboxesShowPage from '../../../components/Pages/cashboxes/show'
import CashboxesEditPage from '../../../components/Pages/cashboxes/edit'
import CashboxesCreatePage from '../../../components/Pages/cashboxes/create'

export default [
    {
        path: '/cashboxes/create',
        name: 'cashboxes.create',
        component: CashboxesCreatePage,
        beforeEnter: auth.guard.user,
    },
    {
        path: '/cashboxes/:uuid/edit',
        name: 'cashboxes.edit',
        component: CashboxesEditPage,
        beforeEnter: auth.guard.user
    },
    {
        path: '/cashboxes/:uuid',
        name: 'cashboxes.show',
//        redirect: 'cashboxes/:uuid/details',
        component: CashboxesShowPage,
        beforeEnter: auth.guard.user,
    },
    {
        path: '/cashboxes',
        name: 'cashboxes.index',
        component: CashboxesIndexPage,
        beforeEnter: auth.guard.user,
    },
]