<template>
    <square-button
            v-if="square"
            button-class="success"
            dark
            depressed
            icon="mdi-plus"
            :tooltip-text="label || l('pages.all.actions.create')"
            v-bind="$attrs"
            v-on="$listeners"
    />
    <v-btn
            v-else
            class="success"
            dark
            depressed
            v-bind="$attrs"
            v-on="$listeners"
    >
        {{ label || l('pages.all.actions.create') }}
    </v-btn>
</template>

<script>
    import SquareButton from "./SquareButton";
    export default {
        name: "CreateButton",
        inheritAttrs: false,
        components: {SquareButton},
        props: {
            square: {
                default: false,
                required: false,
                type: Boolean,
            },
            label: {
                default: null,
                required: false,
                type: String,
            },
        },
    }
</script>

<style scoped>

</style>