import AppOptionsMixin from '../AppOptionsMixin'
import AppTitleMixin from '../AppTitleMixin'
import UserMixin from '../UserMixin'

const PageMixin = {
    mixins: [
        AppOptionsMixin,
        AppTitleMixin,
        UserMixin,
    ]
}

export default PageMixin
