<template>
    <base-card
            class="mb-5"
            :actions-class="actionsClass"
            :actions-color="actionsColor"
            :content-class="contentClass"
            :content-color="contentColor"
            :panel-class="panelClass"
            :panel-color="panelColor"
            :tabs="tabs"
            :title-class="titleClass"
            :title-color="titleColor"
            :v-on="$listeners"
            :v-bind="$attrs"
    >
        <template v-for="(_, slot) of $slots" :slot="slot">
            <slot :name="slot" />
        </template>
    </base-card>

</template>

<script>
    import BaseCard from './BaseCard'

    export default {
        name: "PageCard",
        inheritAttrs: false,
        components: {
            BaseCard,
        },
        props: {
            actionsClass: {
                required: false,
                type: String,
                default: 'py-4',
            },
            actionsColor: {
                required: false,
                type: String,
                default: '',
            },
            contentClass: {
                required: false,
                type: String,
                default: 'pt-0',
            },
            contentColor: {
                required: false,
                type: String,
                default: '',
            },
            panelClass: {
                required: false,
                type: String,
                default: 'my-1 px-4 py-3',
            },
            panelColor: {
                required: false,
                type: String,
                default: 'blue-grey lighten-5',
            },
            tabs: {
                required: false,
                type: Array,
                default: () => [],
            },
            titleClass: {
                required: false,
                type: String,
                default: 'pt-3 pb-2',
            },
            titleColor: {
                required: false,
                type: String,
                default: '',
            },
        },
    }
</script>
