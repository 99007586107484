<template>
    <span>
        <v-tooltip v-bind="tooltipProps" :disabled="disabled">
            <template v-slot:activator="{ on }">
                <span v-on="on">
                    <v-icon
                            :class="className"
                            v-bind="$attrs"
                            v-on="$listeners"
                    >
                        {{ icon }}
                    </v-icon>
                </span>
            </template>
            <span>
                {{ tooltipText }}
            </span>
        </v-tooltip>
    </span>
</template>

<script>

    import _ from "lodash";

    const
        defaultIcon = 'mdi-file-question-outline',
        defaultTooltipText = "Set tooltip attribute (string or object containing 'text' prop)",
        defaultTooltipProps = {
            top: true
        }

    export default {
        name: "TooltipIcon",
        inheritAttrs: false,
        props: {
            value: {
                default: null,
                required: false,
                type: String,
            },
            tooltip: {
                required: false,
                default: () => ({}),
            },
            disabled: {
                required: false,
                type: Boolean,
                default: false,
            },
        },
        data: (vm) => ({
            className: '',
            icon: defaultIcon,
            slotText: null,
            tooltipProps: {},
            tooltipText: '',
        }),
        computed: {
            source() {
                return this.value || this.slotText
            },
        },
        watch: {
            '$el.className': {
                handler(value) {
                    this.className = value
                }
            },
            source: {
                immediate: true,
                handler(source) {
                    this.icon = source || defaultIcon
                },
            },
            tooltip: {
                immediate: true,
                deep: false,
                handler(tooltip) {

                    let tooltipProps,
                        tooltipText = defaultTooltipText

                    switch (typeof tooltip) {
                        case 'object':
                            if (undefined !== tooltip.text) {
                                tooltipText = tooltip.text
                                delete tooltip.text
                            }
                            tooltipProps = tooltip
                            break
                        case 'string':
                            tooltipText = tooltip
                            // Intentional fallthrough!!!
                        default:
                            tooltipProps = this.getDefaultTooltipProps()
                    }

                    this.tooltipProps = tooltipProps
                    this.tooltipText = this.l(tooltipText)
                }
            },
        },
        methods: {
            getDefaultTooltipProps() {
                return JSON.parse(JSON.stringify(defaultTooltipProps))
            },
            refreshClassName() {
                this.className = _.get(this, '$el.className', '')
            },
            refreshSlotText() {
                this.slotText = _.get(this.$slots, 'default.0.text', '')
            },
        },
        updated() {
            this.refreshClassName()
            this.refreshSlotText()
        },
        created() {
            this.refreshSlotText()
        },
    }
</script>
