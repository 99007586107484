<template>
    <item-list-view
            :create-button="userHasFlag('is_chief_cashier') || userHasFlag('is_company_manager') || userHasFlag('is_admin')"
            :no-edit="!userHasFlag('is_chief_cashier') && !userHasFlag('is_company_manager') && !userHasFlag('is_admin')"
            :no-delete="!userHasFlag('is_chief_cashier') && !userHasFlag('is_company_manager') && !userHasFlag('is_admin')"
            :headers="headers"
    >
        <template v-slot:item.packs_envelopes="_">
            <check-mark :value="_.value" :x-small="_.dense" />
        </template>
    </item-list-view>
</template>

<script>
    import TablePageMixin from '../../../mixins/Cumulative/TablePageMixin'
    import CheckMark from '../../Shared/Icons/CheckMark'

    export default {
        name: "cashboxesIndexPage",
        components: {
            CheckMark,
        },
        mixins: [
            TablePageMixin,
        ],
        data: vm => ({
            headers: [
                {
                    value: 'id',
                    align: 'end',
                    sortable: true,
                    filterable: false,
                    width: '5%',
                },
                {
                    value: 'name',
                    align: 'start',
                    sortable: true,
                    filterable: false,
                },
                {
                    value: 'packs_envelopes',
                    align: 'center',
                    sortable: true,
                    filterable: false,
                },
                {
                    value: 'amount',
                    align: 'end',
                    sortable: true,
                    filterable: false,
                },
            ],
            customAppTitle: 'pages.cashboxes.index.title',
        }),
    }
</script>

<style scoped>

</style>