<template>
    <number-field
            v-else
            abs
            :autocomplete="autocomplete"
            v-bind="attrs"
            v-on="listeners"
            v-model="model"
            class="rate-field"
            :label="label || l('forms.rateField.label')"
            :rules="[rules.required]"
    />
</template>

<script>
    import _ from 'lodash'
    import NumberField from './NumberField'

    export default {
        name: "RateField",
        inheritAttrs: false,
        components: {
            NumberField,
        },
        props: {
            autocomplete: {
                required: false,
                default: 'autocomplete',
            },
            label: {
                type: String,
                required: false,
                default: '',
            },
            required: {
                type: Boolean,
                required: false,
                default: false,
            },
            value: {
                required: false,
                default: '',
            },
        },
        data: vm => ({
            model: '',
            rules: {
                required: value => (!vm.required || null !== value && '' !== value.toString().trim()) || vm.l('forms.rateField.required'),
            },
        }),
        watch: {
            value: {
                immediate: true,
                handler(value) {
                    this.model = value
                },
            },
            model(value) {
                if (value !== this.value) {
                    this.$emit('input', isNaN(value) ? null : value)
                }
            },
        },
        computed: {
            attrs() {
                return _.omit(this.$attrs, ['abs', 'label', 'required', 'value'])
            },
            listeners() {
                return _.omit(this.$listeners, 'input')
            },
        },
    }
</script>

