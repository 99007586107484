<template>
    <employees-show-page
            parent
            v-slot:default="_parent"
    >
        <sub-item-show-view
                v-slot:default="_"
        >
            <subcard>
                <v-simple-table>
                    <thead>
                    <tr>
                        <th>{{ l('pages.fund_locks.properties.type') }}</th>
                        <th>{{ l('pages.fund_locks.properties.note') }}</th>
                        <th>{{ l('pages.fund_locks.properties.amount') }}</th>
                        <th>{{ l('pages.fund_locks.properties.term') }}</th>
                        <th>{{ l('pages.fund_locks.properties.created_at') }}</th>
                        <th>{{ l('pages.fund_locks.properties.updated_at') }}</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td><v-chip small dark :color="_.item.type === 'penalty' ? 'red' : 'green'" class="font-weight-bold text-uppercase">{{ l('pages.adjustments.types.' + _.item.type) }}</v-chip></td>
                        <td>{{ _.item.note }}</td>
                        <td><amount currency>{{ _.item.amount }}</amount></td>
                        <td>{{ _.item.term | term }}</td>
                        <td>{{ _.item.created_at | euroDateShortTime }}</td>
                        <td>{{ _.item.updated_at | euroDateShortTime }}</td>
                    </tr>
                    </tbody>
                </v-simple-table>
            </subcard>
        </sub-item-show-view>
    </employees-show-page>
</template>

<script>
    import EmployeesShowPage from '../../show'
    import Subcard from '../../../../Shared/Cards/Subcard'
    import SubItemShowView from '../../../../Item/Views/SubItemShowView'
    import Amount from "../../../../Shared/Text/Amount";

    export default {
        name: 'EmployeesShowAdjustmentsShowPage',
        inheritAttrs: false,
        components: {
            Amount,
            EmployeesShowPage,
            Subcard,
            SubItemShowView,
        },
        data: vm => ({
        }),
    }
</script>

<style scoped>

</style>