<template>
    <api-select
            :label="l('pages.all.properties.cashbox')"
            api="cashboxes/list"
            v-bind="$attrs"
            v-on="$listeners"
    />
</template>

<script>
    import ApiSelect from '../ApiSelect'

    export default {
        name: "CashboxSelect",
        components: {
            ApiSelect,
        },
    }
</script>

<style scoped>

</style>