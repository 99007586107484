<template>
    <item-create-view v-slot:default="_">
        <location-edit-form
                v-model="_.item"
                :disabled="_.itemDisabled"
        />
    </item-create-view>
</template>

<script>
    import ItemCreateView from '../../Item/Views/ItemCreateView'
    import LocationEditForm from './Shared/LocationEditForm'

    export default {
        name: 'LocationsCreatePage',
        components: {
            ItemCreateView,
            LocationEditForm,
        },
    }
</script>
