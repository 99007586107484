<template>
    <item-list-view
            create-button
            :headers="headers"
    >

        <template v-slot:filter="_">
            <v-row dense>
                <v-col cols="12" md="4">
                    <trim-text-field
                            class="mt-3"
                            dense
                            hide-details
                            v-model="_.filter.text"
                            :label="l('pages.positions.index.filters.text')"
                    />
                </v-col>
                <v-col cols="12" sm="6" md="4">
                    <api-select
                            api="units/list"
                            dense
                            hide-details
                            chips
                            deletable-chips
                            multiple
                            v-model="_.filter.unit_id"
                            :label="l('pages.positions.index.filters.unit_id')"
                    />
                </v-col>
                <v-col cols="12" sm="6" md="4">
                    <api-select
                            api="locations/list"
                            dense
                            hide-details
                            chips
                            deletable-chips
                            multiple
                            v-model="_.filter.location_id"
                            :label="l('pages.positions.index.filters.location_id')"
                    />
                </v-col>
            </v-row>
        </template>

        <template v-slot:item.rates="_">
            <span
                v-for="(rate, index) in _.value"
                :key="index"
            >{{ index !== 0 ? ', ' : ''}}{{ rate | euroNumber }}</span>
        </template>

    </item-list-view>
</template>

<script>
    import TablePageMixin from '../../../mixins/Cumulative/TablePageMixin'
    import TrimTextField from '../../Shared/Forms/TextFields/TrimTextField'
    import ApiSelect from '../../Shared/Forms/ApiSelect'

    export default {
        name: "PositionsIndexPage",
        components: {
            ApiSelect,
            TrimTextField,
        },
        mixins: [
            TablePageMixin,
        ],
        data: () => ({
            headers: [
                {
                    value: 'id',
                    align: 'end',
                    sortable: true,
                    filterable: false,
                },
                {
                    value: 'name',
                    align: 'start',
                    sortable: true,
                    filterable: false,
                },
                {
                    value: 'unit',
                    align: 'start',
                    sortable: false,
                    filterable: false,
                },
                {
                    value: 'location',
                    align: 'start',
                    sortable: false,
                    filterable: false,
                },
                {
                    value: 'rates',
                    align: 'start',
                    sortable: false,
                    filterable: false,
                },
                {
                    value: 'assignment_count',
                    align: 'end',
                    sortable: true,
                    filterable: false,
                },
                {
                    value: 'shift_count',
                    align: 'end',
                    sortable: true,
                    filterable: false,
                },
                {
                    value: 'hours',
                    align: 'end',
                    sortable: true,
                    filterable: false,
                },
                {
                    value: 'rate',
                    align: 'end',
                    sortable: true,
                    filterable: false,
                },
                {
                    value: 'amount',
                    align: 'end',
                    sortable: true,
                    filterable: false,
                },
            ],
            customAppTitle: 'pages.positions.index.title',
        }),

    }
</script>

<style scoped>

</style>