<template>
    <locations-show-page
            parent
            v-slot:default="_parent"
    >
        <sub-item-show-view
                v-slot:default="{item: parent}"
        >
            <tunnel :value="parent" @input="handleAssignmentUpdate"/>
            <v-row>
                <v-col class="py-0">
                    <subcard>
                        <v-simple-table>
                            <thead>
                            <tr>
                                <th>{{ l('pages.assignments.properties.employee') }}</th>
                                <th>{{ l('pages.assignments.properties.position') }}</th>
                                <th>{{ l('pages.assignments.properties.unit') }}</th>
                                <th>{{ l('pages.assignments.properties.location') }}</th>
                                <th>{{ l('pages.assignments.properties.from_date') }}</th>
                                <th>{{ l('pages.assignments.properties.to_date') }}</th>
                                <th>{{ l('pages.assignments.properties.time') }}</th>
                                <th>{{ l('pages.assignments.properties.rate') }}</th>
                                <th>{{ l('pages.assignments.properties.hours') }}</th>
                                <th>{{ l('pages.assignments.properties.is_payable') }}</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>{{ assignment.user | listName }}</td>
                                <td>{{ assignment.position | name }}</td>
                                <td>{{ assignment.unit | name }}</td>
                                <td>{{ assignment.location | name }}</td>
                                <td>{{ assignment.from_date | euroDate }}</td>
                                <td>{{ assignment.to_date | euroDate }}</td>
                                <td>{{ assignment.time | shortTime }}</td>
                                <td>{{ assignment.rate | amount }}</td>
                                <td>{{ assignment.hours | euroNumber }}</td>
                                <td>{{ assignment.is_payable | yesno }}</td>
                            </tr>
                            </tbody>
                        </v-simple-table>
                    </subcard>
                </v-col>
            </v-row>
            <v-row>
                <v-col class="py-0">
                    <subcard>

                        <template slot="title">
                            <v-flex>
                                <div class="d-flex">

                                    <v-flex class="d-none d-sm-inline-flex">
                                    <span>
                                        {{ l('pages.shifts.index.title') }}
                                    </span>
                                    </v-flex>

                                    <v-flex class="text-right">

                                        <v-btn
                                                v-if="assignment.is_editable"
                                                depressed
                                                color="white"
                                                class="ml-2"
                                                small
                                                @click="handleCreateShiftButtonClick"
                                        >
                                            {{ l('pages.shifts.create.title') }}
                                        </v-btn>

                                    </v-flex>
                                </div>
                            </v-flex>
                        </template>

                        <item-list
                                v-if="assignment && assignment.uuid"
                                v-bind="shiftListProps"
                                :hidden="shiftListHidden"
                        >
                            <template v-slot:extraItemActions="_">
                                <edit-icon
                                        class="ml-1"
                                        v-if="_.item.is_editable"
                                        @click="handleEditShiftButtonClick(_.item)"
                                />
                            </template>

                        </item-list>

                        <v-dialog
                                transition="none"
                                max-width="290"
                                v-model="shiftEditDialogIsVisible"
                                @click:outside="hideShiftEditDialog()"
                                @keydown.esc="hideShiftEditDialog()"
                        >
                            <v-card>
                                <v-form v-if="null !== editedShift">
                                    <v-card-title>
                                        {{ undefined !== editedShift.id ? (l('pages.shifts.show.title') + ' ' + editedShift.id) : l('pages.shifts.create.title') }}
                                    </v-card-title>
                                    <v-card-text>
                                        <v-row>
                                            <v-col cols="12" sm="6">
                                                <date-field
                                                        autocomplete="off"
                                                        text-field-class="mt-4"
                                                        :disabled="shiftEditDisabled"
                                                        dense
                                                        hide-details
                                                        :max="assignment.to_date || $moment().format('YYYY-MM-DD')"
                                                        :min="assignment.from_date"
                                                        required
                                                        :label="l('pages.shifts.properties.date')"
                                                        v-model="editedShift.date"
                                                />
                                            </v-col>
                                            <v-col cols="12" sm="6">
                                                <time-field
                                                        autocomplete="off"
                                                        class="mt-4"
                                                        :disabled="shiftEditDisabled"
                                                        clearable
                                                        dense
                                                        hide-details
                                                        :label="l('pages.shifts.properties.time')"
                                                        v-model="editedShift.time"
                                                />
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col cols="12" sm="6">
                                                <hours-field
                                                        autocomplete="off"
                                                        class="mt-4"
                                                        :disabled="shiftEditDisabled"
                                                        dense
                                                        hide-details
                                                        placeholder=""
                                                        v-model="editedShift.hours"
                                                />
                                            </v-col>
                                            <v-col cols="12" sm="6">
                                                <rate-field
                                                        autocomplete="off"
                                                        class="mt-4"
                                                        :disabled="shiftEditDisabled"
                                                        dense
                                                        hide-details
                                                        :items="lodash.get(assignment, ['position', 'rates'], [])"
                                                        v-model="editedShift.rate"
                                                        maxlength="6"
                                                />
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col cols="12">
                                                <v-checkbox
                                                        dense
                                                        :disabled="shiftEditDisabled"
                                                        hide-details
                                                        :label="l('pages.shifts.properties.is_payable')"
                                                        v-model="editedShift.is_payable"
                                                />
                                            </v-col>
                                        </v-row>
                                    </v-card-text>
                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn text @click="handleSaveShiftButtonClick" color="green">
                                            {{ l('pages.shifts.actions.save') }}
                                        </v-btn>
                                        <v-btn text @click="handleDontSaveShiftButtonClick">
                                            {{ l('pages.shifts.actions.back') }}
                                        </v-btn>
                                    </v-card-actions>
                                </v-form>
                            </v-card>
                        </v-dialog>
                    </subcard>
                </v-col>
            </v-row>
        </sub-item-show-view>
    </locations-show-page>
</template>

<script>
    import _ from 'lodash'
    import LocationsShowPage from '../../show'
    import Subcard from '../../../../Shared/Cards/Subcard'
    import SubItemShowView from '../../../../Item/Views/SubItemShowView'
    import Amount from "../../../../Shared/Text/Amount";
    import ItemList from "../../../../Item/ItemList";
    import EditIcon from "../../../../Shared/Icons/EditIcon";
    import ServiceFunctionsMixin from "../../../../../mixins/ServiceFunctionsMixin";
    import Tunnel from "../../../../Shared/Tunnel";
    import DateField from "../../../../Shared/Forms/TextFields/DateField";
    import TimeField from "../../../../Shared/Forms/TextFields/TimeField";
    import HoursField from "../../../../Shared/Forms/TextFields/HoursField";
    import RateField from "../../../../Shared/Forms/TextFields/RateField";

    export default {
        name: 'LocationsShowAssignmentsShowPage',
        inheritAttrs: false,
        mixins: [
            ServiceFunctionsMixin,
        ],
        components: {
            RateField,
            HoursField,
            TimeField,
            DateField,
            Tunnel,
            EditIcon,
            ItemList,
            Amount,
            LocationsShowPage,
            Subcard,
            SubItemShowView,
        },
        data: vm => ({
            lodash: _,
            assignment: {},
            editedShift: null,
            shiftEditDialogIsVisible: false,
            shiftEditDisabled: false,
            shiftListHidden: false,
        }),
        computed: {
            shiftListProps() {
                return {
                    noEdit: true,
                    noShow: true,
                    headers: [
                        {
                            value: 'id',
                            align: 'end',
                            sortable: true,
                            filterable: false,
                            width: '5%',
                        },
                        {
                            value: 'date',
                            align: 'end',
                            sortable: true,
                            filterable: false,
                        },
                        {
                            value: 'time',
                            align: 'end',
                            sortable: true,
                            filterable: false,
                        },
                        {
                            value: 'hours',
                            align: 'end',
                            sortable: true,
                            filterable: false,
                        },
                        {
                            value: 'rate',
                            align: 'end',
                            sortable: true,
                            filterable: false,
                        },
                        {
                            value: 'is_payable',
                            align: 'center',
                            sortable: true,
                            filterable: false,
                        },
                        {
                            value: 'amount',
                            align: 'end',
                            sortable: true,
                            filterable: false,
                        },
                    ],
                    parentSlug: 'assignments',
                    parentId: _.get(this.assignment, 'uuid'),
                    slug: 'shifts',
                }
            },
        },
        watch: {
            shiftEditDialogIsVisible(visible) {
                if (!visible && null !== this.editedShift) {
                    this.wipeEditedShift()
                }
            },
            editedShift(shift) {
                if (shift !== null) {
                    this.showShiftEditDialog()
                } else if (this.shiftEditDialogIsVisible) {
                    this.hideShiftEditDialog()
                }
            }
        },
        methods: {
            hideShiftEditDialog() {
                this.shiftEditDialogIsVisible = false
            },
            showShiftEditDialog() {
                this.shiftEditDialogIsVisible = true
            },
            wipeEditedShift() {
                this.editedShift = null
            },
            reloadShiftList() {
                this.shiftListHidden = true
                setTimeout(function() {
                    this.shiftListHidden = false
                }.bind(this), 500)

            },
            handleAssignmentUpdate(assignment) {
                this.assignment = assignment
            },
            handleCreateShiftButtonClick() {
                this.editedShift = {
                    date: null,
                    is_payable: _.get(this.assignment, 'is_payable', true),
                    rate: _.get(this.assignment, 'rate', null),
                    hours: _.get(this.assignment, 'hours', null),
                    time: _.get(this.assignment, 'time', null),
                }
            },
            handleEditShiftButtonClick(item) {
                this.editedShift = this.clone(item)
            },
            handleDontSaveShiftButtonClick() {
                this.wipeEditedShift()
            },
            handleSaveShiftButtonClick() {
                this.shiftEditDisabled = true
                const shiftUuid = _.get(this.editedShift, 'uuid', null)
                const existingShift = !!shiftUuid
                const apiAction = existingShift ? 'put' : 'post'
                const apiUrl = 'shifts' + (existingShift ? ('/' + shiftUuid) : '')
                const apiParams = existingShift ? this.editedShift : _.merge(this.editedShift, {assignment_id: _.get(this.assignment, 'id', null)})
                return this.$api[apiAction](apiUrl, apiParams)
                    .then(function () {
                        this.shiftEditDisabled = false
                        this.wipeEditedShift()
                        this.reloadShiftList()
                    }.bind(this))
                    .catch(function () {
                        this.shiftEditDisabled = false
                    })
            },
        },
    }
</script>

<style scoped>

</style>