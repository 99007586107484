import i18n from '../internals/i18n'

const GenderMixin = {
    data: (vm) => ({
        availableGenders: [
            'male',
            'female',
            'unknown',
        ],
        defaultGender: 'unknown',
        genderSelect: [],
    }),
    methods: {
        resolveGender(value) {
            try {
                value = value.toString().trim().toLowerCase()
            } catch (e) {
                value = ''
            }
            return ~this.availableGenders.indexOf(value) ? value : this.defaultGender
        },
        getGenderSelectValues() {
            return this.availableGenders.map(function(gender) {
                return {
                    text: i18n.t('global.gender.' + gender),
                    value: gender,
                }
            })
        },
    },
    beforeMount() {
        this.genderSelect = this.getGenderSelectValues()
    },
}

export default GenderMixin
