<template>
  <subcard v-if="employee && termOptions && termOptions.length !== 0">
    <v-flex class="mb-3">
      <div class="d-flex">
        <v-flex>
          <v-select
              :items="termOptions"
              v-model="term"
              hide-details
              dense
              :label="l('pages.all.properties.term')"
          />
        </v-flex>
        <v-flex class="text-right">
          <square-button
              v-if="term"
              icon="mdi-printer"
              depressed
              :tooltip-text="l('global.print')"
              @click="handlePrintButtonClick"
          />
        </v-flex>
      </div>
    </v-flex>
    <employees-payslip
        :employee="employee"
        :term="term"
        class="mt-5"
    />
  </subcard>
</template>

<script>
import _ from 'lodash'
import EmployeesPayslip from '@/components/Pages/employees/show/details/payslip/EmployeesPayslip'
import SquareButton from '@/components/Shared/Buttons/SquareButton'
import Subcard from '../../../../../Shared/Cards/Subcard'

export default {
  name: 'EmployeesPayslipCard',
  components: {
    EmployeesPayslip,
    SquareButton,
    Subcard
  },
  props: {
    employee: {
      type: String,
      required: true
    }
  },
  data: () => ({
    isLoading: false,
    shouldReload: false,
    term: null,
    termOptions: [],
    terms: []
  }),
  watch: {
    employee: {
      immediate: true,
      handler: 'loadTerms'
    },
    termOptions: {
      immediate: true,
      deep: true,
      handler: 'handleTermOptionsChange'
    },
    terms: {
      immediate: true,
      handler: 'handleTermsChange'
    }
  },
  methods: {
    handlePrintButtonClick () {
      if (this.employee && this.term) {
        const redirect = this.$router.resolve(
          {
            name: 'employees.show.details.payslip',
            params: { uuid: this.employee, term: this.term },
            query: { stripped: 1 }
          }
        )
        window.open(redirect.resolved.fullPath, '_new')
      }
    },
    handleTermOptionsChange (termOptions) {
      if (!Array.isArray(termOptions)) {
        termOptions = []
      }
      switch (true) {
        case termOptions.length === 0:
          this.term = null
          break
        case !termOptions.map(term => _.get(term, 'value', null)).includes(this.term):
          this.term = _.get(termOptions[0], 'value', null)
          break
      }
    },
    handleTermsChange (terms) {
      if (!Array.isArray(terms)) {
        terms = []
      }
      this.termOptions = terms.map(
        function (term) {
          return { value: term, text: this.$options.filters.term(term) }
        }.bind(this)
      )
    },
    loadTerms (employee = null) {
      if (this.isLoading) {
        this.shouldReload = true
        return
      }

      this.shouldReload = false
      if (employee === null) {
        employee = this.employee
      }
      if (typeof employee !== 'string' || employee.length === 0) {
        this.terms = []
        return
      }

      this.isLoading = true
      this.$api.get('employees/' + employee + '/payslip/terms')
        .then(function (response) {
          this.terms = _.get(response, 'data', [])
        }.bind(this))
        .catch(err => err)
        .finally(function () {
          this.isLoading = false
          if (this.shouldReload) {
            this.loadTerms()
          }
        }.bind(this))
    }
  }
}
</script>
