<template>
    <div>
        <envelope-slip
            v-for="(envelope, index) in envelopes" :envelope="envelope" class="envelope-slip"
            :key="index"
        />
    </div>
</template>

<script>
    import EnvelopeSlip from "../../Shared/ModelSpecific/Envelope/EnvelopeSlip";
    export default {
        name: "EnvelopesSlipsPage",
        components: {EnvelopeSlip},
        data: vm => ({
            envelopes: [],
        }),
        created() {
            this.$api.get('envelopes', {params: this.$route.query})
                .then(function(response) {
                    this.envelopes = response.data.items
                }.bind(this))
        },
    }
</script>

<style>
    .envelope-slip {
        display: block;
        overflow: visible;
        position: relative !important;
        page-break-inside: avoid;
        -webkit-region-break-inside: avoid;
    }
</style>