<template>
    <div>
        <confirmable-text-field
                v-bind="$attrs"
                v-if="confirmable"
                :autocomplete="autocomplete"
                confirm-icon="mdi-email-check-outline"
                :confirm-disabled="disabled || confirmDisabled"
                :disabled="disabled"
                :value="textFieldModel"
                @input="handleInput"
                :placeholder="textFieldPlaceholder"
        >
            <template v-for="(_, slot) of $slots" :slot="slot">
                <slot :name="slot" />
            </template>
        </confirmable-text-field>
        <v-text-field
                v-bind="$attrs"
                v-if="!confirmable"
                :autocomplete="autocomplete"
                :disabled="disabled"
                :value="textFieldModel.value"
                @input="handleInput"
                :placeholder="textFieldPlaceholder"
        >
            <template v-for="(_, slot) of $slots" :slot="slot">
                <slot :name="slot" />
            </template>
        </v-text-field>
    </div>
</template>

<script>
    import _ from 'lodash'
    import ConfirmableTextField from './ConfirmableTextField'

    const defaultModel = {
        address: '',
        is_confirmed: false,
    }

    export default {
        name: "EmailTextField",
        components: {
            ConfirmableTextField,
        },
        inheritAttrs: false,
        props: {
            value: {
                type: Object,
                required: false,
                default: defaultModel,
            },
            autocomplete: {
                required: false,
                type: String,
                default: 'autocomplete',
            },
            confirmable: {
                type: Boolean,
                required: false,
                default: false,
            },
            disabled: {
                type: Boolean,
                required: false,
                default: false,
            },
        },
        data: (vm) => ({
            textFieldModel: vm.convertEmailToTextField(vm.value),
            textFieldPlaceholder: vm.l('forms.emailTextField.placeholder'),
            confirmDisabled: false,
            shouldBeConfirmed: vm.value.is_confirmed || defaultModel.is_confirmed,
        }),
        methods: {
            convertEmailToTextField(value) {

                const converted = {
                    value: '',
                    is_confirmed: false,
                }

                if (_.isString(value) || _.isSafeInteger(value)) {
                    converted.value = value
                } else if (_.isObject(value)) {
                    if (_.has(value, 'address')) {
                        converted.value = value.address
                    }
                    if (_.has(value, 'is_confirmed')) {
                        converted.is_confirmed = !!value.is_confirmed
                    }
                }

                return converted
            },
            shouldDisableConfirm: email => !_.toString(email).match(/^[A-z](?:[\-._]*[A-z\d]+)*@(?:[A-z\d]+(?:-*[A-z\d]+)*\.)+[A-z]{2,}$/),
            handleInput(textField) {
                if (_.isObject(textField)) {
                    this.handleConfirmableInput(textField)
                } else {
                    this.handleUnconfirmableInput(textField)
                }
            },
            handleConfirmableInput(textField) {
                if (this.textFieldModel.address !== textField.value || this.textFieldModel.is_confirmed !== textField.is_confirmed) {
                    this.emitInput({
                        value: textField.value,
                        is_confirmed: textField.is_confirmed,
                    })
                }
                this.textFieldModel = textField
            },
            handleUnconfirmableInput(textField) {
                if (this.textFieldModel.number !== textField.value || this.textFieldModel.is_confirmed !== textField.is_confirmed) {
                    this.emitInput({
                        value: textField,
                        is_confirmed: this.textFieldModel.is_confirmed,
                    })
                }
                this.textFieldModel.value = textField
            },
            emitInput(input = null) {

                if (!input) {
                    input = _.clone(this.textFieldModel)
                }

                let data = _.clone(this.value)

                if (_.has(input, 'is_confirmed')) {
                    data.is_confirmed = input.is_confirmed
                }

                if (_.has(input, 'value')) {
                    data.address = input.value
                }
                this.$emit('input', data)
            },
        },
        watch: {
            value: {
                deep: true,
                immediate: true,
                handler(model) {
                    this.textFieldModel = this.convertEmailToTextField(model)
                },
            },

            textFieldModel: {
                deep: true,
                immediate: true,
                handler(textField) {
                    if (textField.is_confirmed) {
                        this.shouldBeConfirmed = true
                    }
                    this.confirmDisabled = this.shouldDisableConfirm(textField.value)
                }
            },
            confirmDisabled: {
                handler(disabled, wasAlreadyDisabled) {
                    if (disabled !== wasAlreadyDisabled) {
                        const textField = _.clone(this.textFieldModel)
                        if (disabled) {
                            this.shouldBeConfirmed = textField.is_confirmed
                            textField.is_confirmed = false
                        } else {
                            textField.is_confirmed = this.shouldBeConfirmed
                        }
                        this.textFieldModel = textField
                        if (!this.confirmable) {
                            this.emitInput()
                        }
                    }
                },
                immediate: true,
            },

        },

    }
</script>

<style scoped>

</style>