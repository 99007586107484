import auth from '../../auth'
import LocationsShowPositionsIndexPage from '../../../components/Pages/locations/show/positions/index'
import LocationsShowPositionsCreatePage from '../../../components/Pages/locations/show/positions/create'
import LocationsShowPositionsEditPage from '../../../components/Pages/locations/show/positions/edit'
import LocationsShowPositionsShowPage from '../../../components/Pages/locations/show/positions/show'

export default [
    {
        path: '/locations/:uuid/positions/create',
        name: 'locations.show.positions.create',
        component: LocationsShowPositionsCreatePage,
        beforeEnter: auth.guard.user,
    },
    {
        path: '/locations/:uuid/positions/:childUuid',
        name: 'locations.show.positions.show',
        component: LocationsShowPositionsShowPage,
        beforeEnter: auth.guard.user,
    },
    {
        path: '/locations/:uuid/positions/:childUuid/edit',
        name: 'locations.show.positions.edit',
        component: LocationsShowPositionsEditPage,
        beforeEnter: auth.guard.user,
    },
    {
        path: '/locations/:uuid/positions',
        name: 'locations.show.positions',
        component: LocationsShowPositionsIndexPage,
        beforeEnter: auth.guard.user,
    },

]
