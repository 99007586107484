import auth from '../auth'
import CashOperationsIndexPage from '../../components/Pages/cash_operations/index'
import CashOperationsShowDetailsPage from '../../components/Pages/cash_operations/show/details'
import CashOperationsShowSlipPage from '../../components/Pages/cash_operations/show/slip'
import CashOperationsEditPage from '../../components/Pages/cash_operations/edit'
import CashOperationsCreatePage from '../../components/Pages/cash_operations/create'

export default [
    {
        path: '/cash-operations/:uuid/slip',
        name: 'cash_operations.show.slip',
        component: CashOperationsShowSlipPage,
        beforeEnter: auth.guard.user,
    },
    {
        path: '/cash-operations/create',
        name: 'cash_operations.create',
        component: CashOperationsCreatePage,
        beforeEnter: auth.guard.user,
    },
    {
        path: '/cash-operations/:uuid/edit',
        name: 'cash_operations.edit',
        component: CashOperationsEditPage,
        beforeEnter: auth.guard.user
    },
    {
        path: '/cash-operations/:uuid',
        name: 'cash_operations.show',
        component: CashOperationsShowDetailsPage,
        beforeEnter: auth.guard.user,
    },
    {
        path: '/cash-operations',
        name: 'cash_operations.index',
        component: CashOperationsIndexPage,
        beforeEnter: auth.guard.user,
    },
]