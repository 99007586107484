<template>
    <employees-show-page
            parent
            v-slot:default="_parent"
    >
        <sub-item-show-view
                v-slot:default="_"
        >
            <subcard>
                <v-simple-table>
                    <thead>
                    <tr>
                        <th>{{ l('pages.residences.properties.type') }}</th>
                        <th>{{ l('pages.residences.properties.from_date') }}</th>
                        <th>{{ l('pages.residences.properties.to_date') }}</th>
                        <th>{{ l('pages.residences.properties.note') }}</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>{{ t(_.item, 'type.name').safeString }}</td>
                        <td>{{ _.item.from_date | euroDate }}</td>
                        <td>{{ _.item.to_date | euroDate }}</td>
                        <td>{{ _.item.note }}</td>
                    </tr>
                    </tbody>
                </v-simple-table>
            </subcard>
        </sub-item-show-view>
    </employees-show-page>
</template>

<script>
    import t from 'typy'
    import EmployeesShowPage from '../../show'
    import Subcard from '../../../../Shared/Cards/Subcard'
    import SubItemShowView from '../../../../Item/Views/SubItemShowView'

    export default {
        name: 'EmployeesShowResidencesShowPage',
        inheritAttrs: false,
        components: {
            EmployeesShowPage,
            Subcard,
            SubItemShowView,
        },
        data: vm => ({
            t: t,
        }),
    }
</script>

<style scoped>

</style>