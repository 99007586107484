<template>
    <locations-show-page
            parent
            v-slot:default="_parent"
    >
        <sub-item-show-view
                v-slot:default="{item: position}"
        >
            <v-row>
                <v-col>
                    <subcard>
                        <v-simple-table>
                            <tbody>
                            <tr>
                                <th>{{ l('pages.positions.properties.name') }}</th>
                                <td>{{ position.name }}</td>
                            </tr>
                            <tr>
                                <th>{{ l('pages.positions.properties.unit') }}</th>
                                <td>{{ t(position, 'unit.name').safeString }}</td>
                            </tr>
                            <tr>
                                <th>{{ l('pages.positions.properties.rates') }}</th>
                                <td>
                                    <span
                                        v-for="(rate, index) in position.rates"
                                        :key="index"
                                    >{{ index !== 0 ? ', ' : ''}}{{ rate | euroNumber }}</span>
                                </td>
                            </tr>
                            </tbody>
                        </v-simple-table>
                    </subcard>
                </v-col>
            </v-row>
        </sub-item-show-view>
    </locations-show-page>
</template>

<script>
    import t from 'typy'
    import LocationsShowPage from '../../show'
    import Subcard from '../../../../Shared/Cards/Subcard'
    import SubItemShowView from '../../../../Item/Views/SubItemShowView'

    export default {
        name: 'LocationsShowPositionsShowPage',
        inheritAttrs: false,
        components: {
            LocationsShowPage,
            Subcard,
            SubItemShowView,
        },
        data: vm => ({
           t: t,
        }),
    }
</script>

<style scoped>

</style>