import store from '../store'
import {USER_REQUEST_LOGOUT} from '../store/actions/user'

const UserMixin = {
    data: () => ({
        customAppTitle: '',
    }),
    methods: {
        logout: () => store.dispatch(USER_REQUEST_LOGOUT),
        userHasFlag: (flag) => store.getters.userHasFlag(flag),
        userHasAllFlags: (flags) => store.getters.userHasAllFlags(flags),
        userHasAnyFlag: (flags) => store.getters.userHasAnyFlag(flags),
    },
    computed: {
        userAuthenticated: () => store.getters.userAuthenticated,
        userFlags: () => store.getters.userFlags,
        userIsGodLike: () => store.getters.userIsGodLike,
        userProfile: () => store.getters.userProfile,
        userViewsLocations: () => store.getters.userViewsLocations,
        userWorksWithCashboxes: () => store.getters.userWorksWithCashboxes,
        userWorksWithLocations: () => store.getters.userWorksWithLocations,
    },
}

export default UserMixin
