import store from "../store";
import {APP_SET_TITLE} from "../store/mutations/app";

const AppTitleMixin = {
    data: () => ({
        customAppTitle: '',
    }),
    methods: {
        setAppTitle: (title) => store.commit(APP_SET_TITLE, title),
        initAppTitle() {
            if (this.customAppTitleValid) {
                this.setAppTitle(this.customAppTitle)
            }
        },
    },
    computed: {
        appTitle: function() {return this.l(store.getters.appTitle)},
        customAppTitleValid() {
            return !!this.customAppTitle;
        }
    },
    watch: {
        customAppTitle(value) {
            this.setAppTitle(value)
        }
    },
    mounted() {
        this.initAppTitle()
    }
}

export default AppTitleMixin
