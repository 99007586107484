import Vue from 'vue'
import VueRouter from 'vue-router'
import auth from './auth'
import users from './routes/users'
import employees from './routes/employees'
import envelopes from './routes/envelopes'
import fund_locks from './routes/fund-locks'
import locations from './routes/locations'
import positions from './routes/positions'
import assignments from './routes/assignments'
import shifts from './routes/shifts'
import adjustments from './routes/adjustments'
import cash_operations from './routes/cash-operations'
import cashboxes from './routes/cashboxes'
import transactions from './routes/transactions'
import general from './routes/general'
import my from './routes/my'
import management from './routes/management'

Vue.use(VueRouter)

const router = new VueRouter({
    mode: 'history',
    routes: [].concat(

        users,
        employees,
        envelopes,
        fund_locks,
        locations,
        positions,
        assignments,
        shifts,
        adjustments,
        cash_operations,
        cashboxes,
        transactions,

        // Role-specific administative functions
        management,

        // Logged in user's personal stuff
        my,

        // General purpose routes must go the last
        general
    )
})

router.beforeEach(auth.validation.dispatchIfNecessary)

export default router