<template>
  <sub-item-view
      v-bind="$attrs"
      v-on="$listeners"
      v-slot:default="_"
  >
    <slot v-bind="_"/>
  </sub-item-view>
</template>

<script>

import SubItemView from './SubItemView'

export default {
  name: "SubItemShowView",
  inheritAttrs: false,
  components: {
    SubItemView,
  },
}
</script>

<style scoped>

</style>