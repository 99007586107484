<template>
    <envelopes-show-page
            v-slot:default="{ item: envelope, itemDisabled }"
    >
        <tunnel :value="envelope" @input="handleEnvelopeUpdate"/>
        <envelope-face :envelope="envelope" />
    </envelopes-show-page>
</template>

<script>
    import EnvelopesShowPage from '../show'
    import Tunnel from '../../../Shared/Tunnel'
    import _ from 'lodash'
    import EnvelopeFace from "../../../Shared/ModelSpecific/Envelope/EnvelopeFace";

    export default {
        name: 'EnvelopesShowFacePage',
        components: {
            EnvelopeFace,
            Tunnel,
            EnvelopesShowPage,
        },
        data: vm => ({
            lodash: _,
            envelope: {},
            isPrintable: false,
        }),
        watch: {
            envelope() {
                this.tryToPrint()
            },
            isPrintable() {
                this.tryToPrint()
            },
        },
        methods: {
            handleEnvelopeUpdate(envelope) {
                this.envelope = envelope
            },
            tryToPrint() {
                if (this.isPrintable && _.get(this.envelope, 'id', false)) {
                    setTimeout(window.print, 500)
                }
            },
        },
        mounted() {
//            this.isPrintable = true
        }
    }
</script>

<style>

</style>