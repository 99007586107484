<template>
    <v-combobox
            multiple
            clearable
            hide-details
            item-color="blue"
            :label="l('pages.positions.properties.rates')"
            v-model="displayModel"
            :search-input.sync="searchInput"
            class="rates-field"
    >
        <template v-slot:selection="_">
            <v-chip
                    small
                    close
                    color="light-green lighten-4"
                    class="px-2 font-weight-bold"
                    @click:close="removeItem(_.item)">
                {{ _.item }}
            </v-chip>
        </template>
    </v-combobox>
</template>

<script>
    import _ from 'lodash'

    export default {
        name: "RatesField",
        props: {
            value: {
                type: Array,
                required: false,
                default: () => [],
            },
        },
        data: vm => ({
            displayModel: [],
            dataModel: [],
            searchInput: '',
        }),
        watch: {
            value: {
                immediate: true,
                deep: true,
                handler(value) {
                    if (_.isArray(value)) {
                        this.handleValueUpdate(value)
                    }
                },
            },
            displayModel: {
                immediate: true,
                deep: true,
                handler(value) {
                    if (_.isArray(value)) {
                        this.handleDisplayModelUpdate(value)
                    }
                },
            },
            dataModel: {
                immediate: true,
                deep: true,
                handler(value) {
                    if (_.isArray(value)) {
                        this.handleDataModelUpdate(value)
                    }
                },
            },
            searchInput(value) {
                this.handleSearchInputUpdate(value)
            },
        },
        methods: {

            clone(value) {
                return _.isArray(value) || _.isObject(value)
                    ? JSON.parse(JSON.stringify(value))
                    : value
            },
            emitInput(value) {
                this.$emit('input', ('object' === typeof value) ? this.clone(value) : value)
            },
            itemsEqual(item1, item2) {
                const type = typeof item1
                switch (true) {
                    case type !== (typeof item2):
                        return false
                    case type === 'object':
                        return !JSON.stringify(item1).localeCompare(JSON.stringify(item2))
                    default:
                        return item1 === item2
                }
            },
            sortNumber: (a, b) => a - b,
            formatForDisplay: rate => rate.toString().replace('.', ','),
            formatForStorage: rate => parseFloat(_.trimEnd(rate.replace(/\s\t/, '').replace(/^(?:(?:[^\d]|0)*([1-9]\d*)(?:[,.](\d+))?)?([^\d]+.*)?$/, '$1.$2'), '.')),

            getAsStrings(values) {
                return _.map(this.clone(values), value => _.isString(value) ? value : (_.isNumber(value) ? value.toString() : ''))
            },
            getPotentiallyValid(values) {
                return _.filter(this.getAsStrings(values), value => value.match(/[1-9]/))
            },
            getRefinedValues(values) {
                return _.sortedUniq(_.filter(_.map(this.getPotentiallyValid(values), function (value) {
                    return this.formatForStorage(value)
                }.bind(this)), value => !!value).sort(this.sortNumber))
            },

            handleDataModelUpdate(value) {
                if (!this.itemsEqual(value, this.value)) {
                    this.emitInput(value)
                }
            },
            handleDisplayModelUpdate(values) {
                this.updateDataModel(values)
            },
            handleSearchInputUpdate(value) {
                if (_.endsWith(value, ' ')) {
                    this.insertValue(value)
                    this.resetSearchInput()
                }
            },
            handleValueUpdate(values) {
                this.updateDisplayModel(values)
            },
            insertValue(value) {
                this.displayModel.push(value)
            },
            resetSearchInput() {
                this.searchInput = ''
            },

            removeItem(item) {
                this.displayModel = _.filter(this.displayModel, function (value) { return value !== item })
            },

            updateDataModel(values) {
                const updatedValues = this.getRefinedValues(values)
                if (!this.itemsEqual(updatedValues, this.dataModel)) {
                    this.dataModel = updatedValues
                }
                if (this.displayModel.length !== updatedValues.length) {
                    this.updateDisplayModel(updatedValues)
                }
            },
            updateDisplayModel(values) {
                const updatedValues = _.map(this.getRefinedValues(values), function (value) {
                    return this.formatForDisplay(value)
                }.bind(this))
                if (!this.itemsEqual(updatedValues, this.displayModel)) {
                    this.displayModel = updatedValues
                }
            },

        },
    }
</script>

<style type="text/css">
    .v-text-field.rates-field .v-select__slot .v-input__append-inner:last-child {
        display: none;
    }
</style>