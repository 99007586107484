<template>
    <item-edit-view v-slot:default="{ item: adjustment }">
        <v-form>
        </v-form>
    </item-edit-view>
</template>

<script>
    import ItemEditView from '../../Item/Views/ItemEditView'

    export default {
        name: 'adjustmentsEditPage',
        components: {
            ItemEditView,
        },
    }
</script>

<style scoped>

</style>