<template>
    <item-create-view v-slot:default="_">
        <position-edit-form v-model="_.item" :disabled="_.itemDisabled"></position-edit-form>
    </item-create-view>
</template>

<script>
    import ItemCreateView from '../../Item/Views/ItemCreateView'
    import PositionEditForm from './Shared/PositionEditForm'

    export default {
        name: 'PositionsCreatePage',
        components: {
            ItemCreateView,
            PositionEditForm,
        },
    }
</script>
