<template>
    <item-create-view v-slot:default="_">
        <shift-edit-form
                :disabled="_.itemDisabled"
                v-model="_.item"
        />
    </item-create-view>
</template>

<script>
    import ItemCreateView from '../../Item/Views/ItemCreateView'
    import ShiftEditForm from './Shared/ShiftEditForm'

    export default {
        name: 'ShiftsCreatePage',
        components: {
            ItemCreateView,
            ShiftEditForm,
        },
    }
</script>
