<template>
    <employees-show-page parent v-slot:default="_parent">
        <sub-item-show-view v-slot:default="_">
            <employee-residence-edit-form :disabled="_.itemDisabled" v-model="_.item" />
        </sub-item-show-view>
    </employees-show-page>
</template>

<script>
    import EmployeesShowPage from '../../show'
    import SubItemShowView from '../../../../Item/Views/SubItemShowView'
    import EmployeeResidenceEditForm from './Shared/EmployeeResidenceEditForm'

    export default {
        name: 'EmployeesShowResidencesEditPage',
        inheritAttrs: false,
        components: {
            EmployeesShowPage,
            SubItemShowView,
            EmployeeResidenceEditForm,
        },
    }
</script>

<style scoped>

</style>