<template>
    <div
            v-bind="$attrs"
    >
        <slot
                v-bind:item="item"
                v-bind:item-disabled="itemDisabled"
                v-bind:item-loaded="itemLoaded"
                v-bind:item-loading="itemLoading"
                v-bind:item-modified="itemModified"
                v-bind:server-item="serverItem"
        />
    </div>
</template>

<script>

    import ServiceFunctionsMixin from '../../mixins/ServiceFunctionsMixin'

    const
        // Any change made on item
        EVENT_INPUT = 'input',
        // Item loading (loading may be unsuccessful)
        EVENT_LOADING = 'loading',
        // Item loading failed
        EVENT_LOAD_FAILED = 'loadFailed',
        // Item successfully loaded (not equal to finished loading)
        EVENT_LOADED = 'loaded',
        // Item now has local unsaved changes
        EVENT_MODIFIED = 'modified',
        // Item has been reset to initial state (no local changes)
        EVENT_RESET = 'reset'

    export default {
        name: "Item",
        mixins: [
            ServiceFunctionsMixin,
        ],
        props: {
            api: {
                default: '',
                required: false,
                type: String,
            },
            disabled: {
                default: false,
                required: false,
                type: Boolean,
            },
            value: {
                default: () => ({}),
                required: false,
                type: Object,
            },
        },
        data: vm => ({
            item: {},
            itemDisabled: false,
            itemLoaded: false,
            itemLoading: false,
            itemModified: false,
            serverItem: {},
        }),
        watch: {
            api: {
                handler(url) {
                    if (!this.isApiUrlValid(url)) {
                        this.setItemLoaded(true)
                    } else {
                        this.fetchItem(url)
                            .catch(err => err)
                            .then(function (resp) {
                                this.updateServerItem(resp.data)
                            }.bind(this))
                    }
                },
                immediate: true,
            },
            disabled: {
                handler(disabled) {
                    if (!this.itemLoading) {
                        this.itemDisabled = !!disabled
                    }
                },
                immediate: true,
            },
            item: {
                deep: true,
                handler(item) {
                    this.updateItemModified()
                    this.$emit(EVENT_INPUT, item)
                },
                immediate: false,
            },
            itemLoaded(loaded, wasAlreadyLoaded) {
                if (!!loaded) {
                    this.itemLoading = false
                    if (!wasAlreadyLoaded) {
                        this.$emit(EVENT_LOADED, this.item)
                    }
                }
            },
            itemLoading(loading, wasAlreadyLoading) {
                loading = !!loading
                if (!this.disabled) {
                    this.itemDisabled = loading
                }
                if (loading) {
                    this.itemLoaded = false
                    if (!wasAlreadyLoading) {
                        this.$emit(EVENT_LOADING)
                    }
                }
            },
            itemModified(modified, wasAlreadyModified) {
                modified = !!modified
                if (modified !== !!wasAlreadyModified) {
                    this.$emit(modified ? EVENT_MODIFIED : EVENT_RESET, this.item)
                }
            },
            serverItem(serverItem) {
                this.updateItemIfNecessary(serverItem)
            },
            value: {
                deep: true,
                handler(value) {
                    this.updateItemIfNecessary(value)
                },
                immediate: true,
            },
        },
        methods: {

            // Item

            /**
             * Fetch item data from the provided api url
             *
             * @param {string} url
             * @returns {Promise}
             */
            fetchItem(url) {
                return new Promise(function (resolve, reject) {
                    // Start loading
                    this.setItemLoading(true)
                    this.$api.get(url)
                        .then(function (resp) {
                            this.setItemLoaded(true)
                            resolve(resp)
                        }.bind(this))
                        .catch(function (err) {
                            this.$emit(EVENT_LOAD_FAILED, err)
                            this.setItemLoading(false)
                            reject(err)
                        }.bind(this))
                })
            },

            /**
             * Set itemLoaded flag
             *
             * @param {*} value
             */
            setItemLoaded(value) {
                this.itemLoaded = !!value
            },

            /**
             * Set itemLoading flag
             *
             * @param {*} value
             */
            setItemLoading(value) {
                this.itemLoading = !!value
            },

            /**
             * Update item data
             *
             * @param {object} item
             */
            updateItem(item) {
                this.item = this.clone(item)
            },

            /**
             * Update item data if it is different from what was passed in
             *
             * @param {object} item
             */
            updateItemIfNecessary(item) {
                if (!this.objectsEqual(item, this.item)) {
                    this.updateItem(item)
                }
            },

            /**
             * Update itemModified flag
             *
             */
            updateItemModified() {
                this.itemModified = !this.objectsEqual(this.item, this.serverItem)
            },

            /**
             * Update serverItem data
             *
             * @param {object} item
             */
            updateServerItem(item) {
                this.serverItem = this.clone(item)
            },


        },

    }

</script>

<style>

</style>