<template>
    <cash-operations-show-page
            v-slot:default="{ item: cashOperation, itemDisabled }"
    >
        <tunnel :value="cashOperation" @input="handleCashOperationUpdate"/>
        <v-layout class="mx-1">
            <v-row dense>
                <v-col
                    class="px-0"
                    cols="6"
                    v-for="col in 2"
                    :key="col"
                >
                    <v-simple-table dense class="payslip">
                        <tbody>
                        <!--
                        <tr>
                            <th>
                                {{ l('pages.cash_operations.show.title') }}
                            </th>
                            <td>
                                {{ cashOperation.uuid }}
                            </td>
                        </tr>
                        -->
                        <tr>
                            <th>
                                {{ l('pages.cash_operations.properties.employee') }}
                            </th>
                            <td class="font-weight-bold text-uppercase">
                                {{ cashOperation.user | listName }}
                            </td>
                        </tr>
                        <tr>
                            <th>
                                {{ l('pages.cash_operations.properties.term') }}
                            </th>
                            <td class="font-weight-bold text-uppercase">
                                {{ cashOperation.term | term }}
                            </td>
                        </tr>
                        <tr>
                            <th>
                                {{ l('pages.cash_operations.properties.type') }}
                            </th>
                            <td class="font-weight-bold text-uppercase">
                                {{ l('pages.cash_operations.values.type.' + cashOperation.type) }}
                            </td>
                        </tr>
                        <tr>
                            <th>
                                {{ l('pages.cash_operations.properties.amount') }}
                            </th>
                            <td class="font-weight-bold">
                                <amount currency no-color>
                                    {{ Math.abs(cashOperation.amount) }}
                                </amount>
                            </td>
                        </tr>
                        <tr>
                            <th>
                                {{ l('pages.cash_operations.properties.cashbox') }}
                            </th>
                            <td>
                                {{ cashOperation.cashbox | name }}
                            </td>
                        </tr>
                        <tr>
                            <th>
                                {{ l('pages.cash_operations.properties.created_at') }}
                            </th>
                            <td>
                                {{ cashOperation.created_at | euroDateShortTime }}
                            </td>
                        </tr>
                        <!--
                        <tr v-if="!!cashOperation.updated_at && cashOperation.updated_at !== cashOperation.created_at">
                            <th>
                                {{ l('pages.cash_operations.properties.updated_at') }}
                            </th>
                            <td>
                                {{ cashOperation.updated_at | euroDateShortTime }}
                            </td>
                        </tr>
                        -->
                        <tr>
                            <th>
                                {{ l('pages.cash_operations.properties.note') }}
                            </th>
                            <td>
                                {{ cashOperation.note }}
                            </td>
                        </tr>
                        <tr>
                            <th>
                                {{ l('pages.cash_operations.properties.signature') }}
                            </th>
                            <td class="py-8">
                            </td>
                        </tr>
                        </tbody>
                    </v-simple-table>
                </v-col>
            </v-row>
        </v-layout>
    </cash-operations-show-page>
</template>

<script>
    import CashOperationsShowPage from '../show'
    import Tunnel from '../../../Shared/Tunnel'
    import _ from 'lodash'
    import Amount from "../../../Shared/Text/Amount";

    export default {
        name: 'CashOperationsShowSlipPage',
        components: {
            Amount,
            Tunnel,
            CashOperationsShowPage,
        },
        data: vm => ({
            cashOperation: {},
            isPrintable: false,
        }),
        watch: {
            cashOperation() {
                this.tryToPrint()
            },
            isPrintable() {
                this.tryToPrint()
            },
        },
        methods: {
            handleCashOperationUpdate(cashOperation) {
                this.cashOperation = cashOperation
            },
            tryToPrint() {
                if (this.isPrintable && _.get(this.cashOperation, 'id', false)) {
                    setTimeout(window.print, 500)
                }
            },
        },
        mounted() {
            this.isPrintable = true
        }
    }
</script>

<style>
    .payslip table {
        border-collapse: collapse;
    }

    .payslip table th,
    .payslip table td {
        border: #000 1px solid !important;
    }
</style>
