<template>
    <v-list dense v-if="userAuthenticated">
        <v-list-item
                v-for="(item, index) in items"
                :key="index"
                v-if="(undefined === item.show || item.show)"
                :to="item.url"
                exact-active-class="blue-grey lighten-5"
                :ripple="false"
        >
            <v-list-item-action>
                <v-icon>
                    {{ item.icon }}
                </v-icon>
            </v-list-item-action>
            <v-list-item-content>
                <v-list-item-title>
                    {{ l(item.text) }}
                </v-list-item-title>
            </v-list-item-content>
        </v-list-item>
    </v-list>
</template>

<script>
    import UserMixin from "../../mixins/UserMixin";

    export default {
        name: "Sidebar",
        mixins: [
            UserMixin,
        ],
        data: vm => ({
            items: [],
        }),
        watch: {
            'userFlags': {
                deep: true,
                handler() {
                    this.items = this.generateItems()
                },
            },
        },
        methods: {
            // production: process.env.NODE_ENV === 'production',
            generateItems() {
                return [
                    {
                        url: '/my/dashboard',
                        icon: 'mdi-view-dashboard',
                        text: 'pages.my.dashboard.title',
                        show: true,
                    },
                    {
                        url: '/management',
                        icon: 'mdi-crown-outline',
                        text: 'pages.management.title',
                        show: false, //this.userIsGodLike,
                    },
                    {
                        url: '/users',
                        icon: 'mdi-account-box-outline',
                        text: 'pages.users.index.title',
                        show: this.userIsGodLike,
                    },
                    {
                        url: '/employees',
                        icon: 'mdi-account-badge-outline',
                        text: 'pages.employees.index.title',
                        show: this.userWorksWithCashboxes || this.userHasAnyFlag(['is_location_manager', 'is_hr']),
                    },
                    {
                        url: '/locations',
                        icon: 'mdi-city-variant-outline',
                        text: 'pages.locations.index.title',
                        show: this.userViewsLocations,
                    },
                    {
                        url: '/positions',
                        icon: 'mdi-target',
                        text: 'pages.positions.index.title',
                        show: false,
                    },
                    {
                        url: '/assignments',
                        icon: 'mdi-account-arrow-right-outline',
                        text: 'pages.assignments.index.title',
                        show: false,
                    },
                    {
                        url: '/shifts',
                        icon: 'mdi-account-check-outline',
                        text: 'pages.shifts.index.title',
                        show: false,
                    },
                    {
                        url: '/adjustments',
                        icon: 'mdi-account-cash-outline',
                        text: 'pages.adjustments.index.title',
                        show: false,
                    },
                    {
                        url: '/cashboxes',
                        icon: 'mdi-cash-register',
                        text: 'pages.cashboxes.index.title',
                        show: this.userWorksWithCashboxes,
                    },
                    {
                        url: '/cash-operations',
                        icon: 'mdi-cash-multiple',
                        text: 'pages.cash_operations.index.title',
                        show: false,
                    },
                    {
                        url: '/fund-locks',
                        icon: 'mdi-lock-outline',
                        text: 'pages.fund_locks.index.title',
                        show: this.userIsGodLike || this.userHasFlag('is_chief_cashier'),
                    },
                    {
                        url: '/transactions',
                        icon: 'mdi-transfer-right',
                        text: 'pages.transactions.index.title',
                        show: this.userIsGodLike || this.userHasFlag('is_chief_cashier'),
                    },
                    {
                        url: '/envelopes',
                        icon: 'mdi-email-multiple-outline',
                        text: 'pages.envelopes.index.title',
                        show: this.userIsGodLike || this.userHasFlag('is_chief_cashier'),
                    },
                ]
            },
        },
        mounted() {
            this.items = this.generateItems()
        }
    }
</script>

<style scoped>

</style>