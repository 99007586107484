import auth from '../auth'
import LoginPage from '../../components/Pages/login'
import Error404Page from '../../components/Pages/error/404'

export default [
    {
        path: '/',
        name: 'root',
        redirect: '/my'
    },
    {
        path: '/login',
        name: 'login',
        component: LoginPage,
        beforeEnter: auth.guard.guest
    },
    // This one must go the last
    {
        path: '*',
        name: 'error404',
        component: Error404Page
    },
]