import auth from '../auth'
import MyDashboardPage from '../../components/Pages/my/dashboard'

export default [
    {
        path: '/my/dashboard',
        name: 'pages.my.dashboard',
        component: MyDashboardPage,
        beforeEnter: auth.guard.user
    },
    {
        path: '/my',
        name: 'pages.my',
        redirect: '/my/dashboard',
    },
]
