<template>
  <v-simple-table
      dense
      v-if="payslip !== null"
  >
    <tbody>
    <tr v-if="print">
      <td colspan="2" class="text-uppercase font-weight-bold">
        {{ payslip.employee.list_name }}
      </td>
      <td colspan="2" class="text-right text-uppercase font-weight-bold">
        {{ term | term }}
      </td>
    </tr>

    <!-- SHIFTS -->
    <tr class="blue-grey lighten-5" v-if="lodash.get(payslip, 'shifts.items', []).length">
      <td colspan="3" class="text-uppercase font-weight-bold">
        {{ l('pages.shifts.index.title') }}
      </td>
      <td class="text-right font-weight-bold text-no-wrap">
        {{ payslip.shifts.amount | amount }}&nbsp;{{ l('global.currency') }}
      </td>

    </tr>
    <tr
        v-for="(shift, index) in lodash.get(payslip, 'shifts.items', [])"
        :key="'sh' + index"
        class="white"
    >
      <td colspan="2">
        {{ shift.location_name }} - {{ shift.position_name }} ({{ shift.unit_name }})
      </td>
      <td class="text-right text-no-wrap">
        <!--                                    {{ l('global.hours', shift.hours, {count: $options.filters.amount(shift.hours)}) }}-->
        {{ shift.hours | amount }}&nbsp;{{ l('global.hrs') }}
        x {{ (shift.is_payable ? shift.rate : 0) | amount }}&nbsp;{{ l('global.currency') }}
      </td>
      <td class="text-right text-no-wrap">
        {{ shift.amount | amount }}&nbsp;{{ l('global.currency') }}
      </td>
    </tr>

    <!-- ADJUSTMENTS -->
    <tr class="blue-grey lighten-5" v-if="lodash.get(payslip, 'adjustments.items', []).length">
      <td colspan="3" class="text-uppercase font-weight-bold">
        {{ l('pages.adjustments.index.title') }}
      </td>
      <td class="text-right font-weight-bold text-no-wrap">
        {{ payslip.adjustments.amount | amount }}&nbsp;{{ l('global.currency') }}
      </td>
    </tr>
    <tr
        v-for="(adjustment, index) in lodash.get(payslip, 'adjustments.items', [])"
        :key="'ad' + index"
        class="white"
    >
      <td colspan="2">
        {{ adjustment.note }}
      </td>
      <td class="text-right">
        {{ adjustment.date | euroDate }}
      </td>
      <td class="text-right text-no-wrap">
        {{ adjustment.amount | amount }}&nbsp;{{ l('global.currency') }}
      </td>
    </tr>

    <!-- CASH OPERATIONS -->
    <tr class="blue-grey lighten-5" v-if="lodash.get(payslip, 'cash_operations.items', []).length">
      <td colspan="3" class="text-uppercase font-weight-bold">
        {{ l('pages.cash_operations.index.title') }}
      </td>
      <td class="text-right font-weight-bold text-no-wrap">
        {{ payslip.cash_operations.amount | amount }}&nbsp;{{ l('global.currency') }}
      </td>
    </tr>
    <tr
        v-for="(cash_operation, index) in lodash.get(payslip, 'cash_operations.items', [])"
        :key="'co' + index"
        class="white"
    >
      <td colspan="2">
        {{
          cash_operation.note || l('pages.cash_operations.values.type.' + (cash_operation.amount < 0 ? 'withdrawal' : 'deposit'))
        }}
      </td>
      <td class="text-right">
        {{ cash_operation.date | euroDate }}
      </td>
      <td class="text-right text-no-wrap">
        {{ cash_operation.amount | amount }}&nbsp;{{ l('global.currency') }}
      </td>
    </tr>

    <!-- OTHER TRANSACTIONS -->
    <tr class="blue-grey lighten-5" v-if="lodash.get(payslip, 'other_transactions.items', []).length">
      <td colspan="3" class="text-uppercase font-weight-bold">
        {{ l('pages.transactions.index.title') }}
      </td>
      <td class="text-right font-weight-bold text-no-wrap">
        {{ payslip.other_transactions.amount | amount }}&nbsp;{{ l('global.currency') }}
      </td>
    </tr>
    <tr
        v-for="(transaction, index) in lodash.get(payslip, 'other_transactions.items', [])"
        :key="'ot' + index"
        class="white"
    >
      <td colspan="2">
        {{ transaction.note }}
      </td>
      <td class="text-right">
        {{ transaction.date | euroDate }}
      </td>
      <td class="text-right text-no-wrap">
        {{ transaction.amount | amount }}&nbsp;{{ l('global.currency') }}
      </td>
    </tr>

    <!-- FUND LOCKS -->
    <tr class="blue-grey lighten-5" v-if="lodash.get(payslip, 'fund_locks.items', []).length">
      <td colspan="3" class="text-uppercase font-weight-bold">
        {{ l('pages.fund_locks.index.title') }}
      </td>
      <td class="text-right font-weight-bold text-no-wrap">
        {{ payslip.fund_locks.amount | amount }}&nbsp;{{ l('global.currency') }}
      </td>
    </tr>
    <tr
        v-for="(fund_lock, index) in lodash.get(payslip, 'fund_locks.items', [])"
        :key="'fl' + index"
        class="white"
    >
      <td colspan="2">
        {{ fund_lock.note }}
      </td>
      <td class="text-right">
        {{ fund_lock.date | euroDate }}
      </td>
      <td class="text-no-wrap">
        {{ fund_lock.amount | amount }}&nbsp;{{ l('global.currency') }}
      </td>
    </tr>
    <tr class="blue-grey lighten-4" v-if="lodash.get(payslip, 'amounts.end', false) !== false">
      <td class="text-uppercase font-weight-bold" colspan="3">
        {{ l('global.total') }}
      </td>
      <td class="font-weight-bold text-right text-no-wrap">
        {{ payslip.amounts.end | amount }}&nbsp;{{ l('global.currency') }}
      </td>
    </tr>
    <!-- ENVELOPES -->
    <tr
        v-for="(envelope, index) in lodash.get(payslip, 'envelopes.items', [])"
        :key="'en' + index"
        class="white"
    >
      <td colspan="2">
        {{ l('pages.employees.show.cards.finance.envelope') }}
        {{ -envelope.amount | amount }}&nbsp;{{ l('global.currency') }}
        <span v-if="envelope.pack_date">
          ({{ l('pages.employees.show.cards.finance.envelope_packed') }} {{ envelope.date | euroDate }})
        </span>
      </td>
      <td class="text-right">
        {{ envelope.date | euroDate }}
      </td>
      <td class="text-right text-no-wrap">
        {{ envelope.amount | amount }}&nbsp;{{ l('global.currency') }}
      </td>
    </tr>
    <tr
        class="blue-grey lighten-1 white--text"
        v-if="lodash.get(payslip, 'envelopes.items', []).length">
      <td class="text-uppercase font-weight-bold" colspan="3">
        {{ l('pages.employees.show.cards.finance.after_envelopes') }}
      </td>
      <td class="font-weight-bold text-right text-no-wrap">
        {{ payslip.amounts.total | amount }}&nbsp;{{ l('global.currency') }}
      </td>
    </tr>
    </tbody>

  </v-simple-table>
</template>

<script>
import _ from "lodash";

export default {
  name: 'EmployeesPayslip',
  props: {
    employee: {
      type: String,
      required: true
    },
    print: {
      type: [Boolean, Number, Object, String],
      required: false,
      default: false
    },
    term: {
      type: [Number, String],
      required: false,
      default: null
    },
  },
  data: () => ({
    lodash: _,
    isLoading: false,
    lastEmployee: null,
    lastTerm: null,
    payslip: null,
    shouldReload: false
  }),
  watch: {
    employee: {
      immediate: true,
      handler: 'handleEmployeeChange'
    },
    payslip: {
      deep: true,
      immediate: true,
      handler: 'handlePayslipChange'
    },
    print: {
      immediate: true,
      handler: 'handlePrintChange'
    },
    term: {
      immediate: true,
      handler: 'handleTermChange'
    }
  },
  methods: {
    handleEmployeeChange (employee) {
      this.loadPayslip(employee, this.term)
    },
    handlePayslipChange (payslip) {
      if (payslip) {
        this.tryToPrint()
      }
    },
    handlePrintChange (print) {
      if (print) {
        this.tryToPrint()
      }
    },
    handleTermChange (term) {
      this.loadPayslip(this.employee, term)
    },
    loadPayslip (employee = null, term = null) {
      if (this.isLoading) {
        this.shouldReload = true
        return
      }

      this.shouldReload = false
      if (employee === null) {
        employee = this.employee
      }
      if (term === null) {
        term = this.term
      }

      if (this.lastEmployee === employee && this.lastTerm === term) {
        return
      }

      this.lastEmployee = employee
      this.lastTerm = term

      if (
          typeof employee !== 'string' || employee.length === 0 ||
          !['string', 'number'].includes(typeof term) || term.toString().length === 0
      ) {
        this.payslip = null
        return
      }

      this.isLoading = true
      this.$api.get('employees/' + employee + '/payslip/' + term)
          .then(function (response) {
            this.payslip = _.get(response, 'data', null)
          }.bind(this))
          .catch(err => err)
          .finally(function () {
            this.isLoading = false
            if (this.shouldReload) {
              this.loadPayslip()
            }
          }.bind(this))
    },
    tryToPrint () {
      if (this.print && this.term && this.payslip) {
        setTimeout(window.print, 500)
      }
    },
  }
}
</script>
