import _ from 'lodash'

_.mixin(require('lodash-inflection'))
import t from 'typy'
import ServiceFunctionsMixin from '../../../mixins/ServiceFunctionsMixin'
import PageMixin from '../../../mixins/Cumulative/PageMixin'

const supportedPageTypes = [
    'create',
    'edit',
    'show',
]

const defaultPageType = 'show'

const
    // Any change made on item
    EVENT_INPUT = 'input',
    // Item loading (loading may be unsuccessful)
    EVENT_LOADING = 'loading',
    // Item loading failed
    EVENT_LOAD_FAILED = 'loadFailed',
    // Item successfully loaded (not equal to finished loading)
    EVENT_LOADED = 'loaded',
    // Item now has local unsaved changes
    EVENT_MODIFIED = 'modified',
    // Item has been reset to initial state (no local changes)
    EVENT_RESET = 'reset'

export default {
    inheritAttrs: false,
    mixins: [
        PageMixin,
        ServiceFunctionsMixin,
    ],
    props: {
        createButton: {
            default: false,
            required: false,
            type: Boolean,
        },
        deleteButton: {
            default: null,
            required: false,
            type: Boolean,
        },
        disabled: {
            default: null,
            required: false,
            type: Boolean,
        },
        editButton: {
            default: null,
            required: false,
            type: Boolean,
        },
        parent: {
            default: null,
            required: false,
            type: Boolean,
        },
        showButton: {
            default: null,
            required: false,
            type: Boolean,
        },
        saveButton: {
            default: null,
            required: false,
            type: Boolean,
        },
        tabs: {
            default: () => [],
            required: false,
            type: Array,
        },
        type: {
            default: '',
            required: false,
            type: String,
        },
        value: {
            default: () => ({}),
            required: false,
            type: Object,
        },
    },
    data: vm => ({

        t: t,

        pageType: '',
        pageTitle: '',

        itemApiUrl: '',
        itemPageUrl: '',

        itemIsChild: null,
        itemIsIndependent: null,
        itemIsParent: null,

        deleteDialogVisible: false,

        item: {},
        itemDisabled: true,
        itemLoaded: false,
        itemLoading: false,
        itemModified: false,
        itemOverrides: {},
        itemTitle: '',
        serverItem: {},

    }),
    computed: {
        createButtonEnabled() {
            return !!this.createButton
        },
        deleteButtonEnabled() {
            return !this.isItemParent()
                && this.itemLoaded
                && t(this.item, 'is_deletable').safeBoolean
                && (this.deleteButton || this.deleteButton === null && this.pageType !== 'create')
        },
        editButtonEnabled() {
            return !this.isItemParent()
                && this.itemLoaded
                && t(this.item, 'is_editable').safeBoolean
                && (this.editButton || this.editButton === null && this.pageType === 'show')
        },
        itemPluralSlug() {
            switch (this.pageType) {
                case 'create':
                    return _.last(this.$route.path.replace(/\/create\/?$/, '').split('/'))
                case 'edit':
                    return _.last(this.$route.path.replace(/(?:\/[^\/]+){2}\/?$/, '').split('/'))
                default:
                    return this.isItemChild()
                        ? _.last(this.$route.path.replace(/\/[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}(\/([a-z]+(-[a-z\d])*)?)*$/, '').split('/'))
                        : this.$route.path.replace(/^\/?([^\/]+).*$/, '$1')
            }
        },
        saveButtonEnabled() {
            return !this.isItemParent() && this.itemLoaded && (
                this.saveButton || this.saveButton === null && (
                    this.pageType === 'create'
                    || this.pageType === 'edit' && t(this.item, 'is_editable').safeBoolean
                )
            )
        },
        showButtonEnabled() {
            return this.itemLoaded && (this.showButton || this.showButton === null && this.pageType === 'edit')
        },
        tabSettings() {
            return (this.pageType === 'show' && this.itemLoaded && undefined !== this.item.uuid)
                ? _.map(this.tabs, function (tab) {
                    const slug = _.snakeCase(this.itemPluralSlug)
                    tab = _.snakeCase(tab)
                    return {
                        text: this.l('pages.' + slug + '.show.tabs.' + tab),
                        route: {
                            name: slug + '.show.' + tab,
                            params: {uuid: this.item.uuid},
                        },
                    }
                }.bind(this))
                : []
        },
    },
    watch: {
        disabled: {
            handler(disabled) {
                if (!this.itemLoading) {
                    this.itemDisabled = !!disabled
                }
            },
            immediate: true,
        },
        item: {
            deep: true,
            handler(item) {
                if (this.pageType === 'edit') {
                    this.refreshItemModified()
                }
                this.$emit(EVENT_INPUT, item)
            },
            immediate: false,
        },
        itemLoaded(loaded, wasAlreadyLoaded) {
            if (!!loaded) {
                this.itemLoading = false
                if (!wasAlreadyLoaded) {
                    this.$emit(EVENT_LOADED, this.item)
                }
            }
        },
        itemLoading(loading, wasAlreadyLoading) {
            loading = !!loading
            if (!this.disabled) {
                this.itemDisabled = loading
            }
            if (loading) {
                this.itemLoaded = false
                if (!wasAlreadyLoading) {
                    this.$emit(EVENT_LOADING)
                }
            }
        },
        itemModified(modified, wasAlreadyModified) {
            modified = !!modified
            if (modified !== !!wasAlreadyModified) {
                this.$emit(modified ? EVENT_MODIFIED : EVENT_RESET, this.item)
            }
        },
        itemPluralSlug() {
            this.pageTitle = this.getPageTitle()
        },
        /*
                pageType: {
                    handler(type) {
                        switch (type) {
                            case 'create':
                                this.itemPluralSlug = _.last(this.$route.path.replace(/\/create\/?$/, '').split('/'))
                                break
                            case 'edit':
                                this.itemPluralSlug = _.last(this.$route.path.replace(/(?:\/[^\/]+){2}\/?$/, '').split('/'))
                                break
                            default:
                                console.log(_.last(this.$route.path.replace(/\/[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}(\/([a-z]+(-[a-z\d])*)?)*$/, '').split('/')))
                                this.itemPluralSlug = _.last(this.$route.path.replace(/\/[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}(\/([a-z]+(-[a-z\d])*)?)*$/, '').split('/'))
                        }
                    },
                    immediate: true,
                },
        */
        parent: {
            handler() {
                this.itemIsIndependent = this.isItemIndependent(true)
                this.itemIsChild = this.isItemChild(true)
                this.itemIsParent = this.isItemParent(true)
            },
            immediate: false,
        },
        serverItem(serverItem) {
            if (this.pageType === 'edit' && !!serverItem && !_.get(serverItem, 'is_editable', false)) {
                this.$notify({
                    text: this.l('pages.' + _.snakeCase(this.itemPluralSlug) + '.errors.not_editable'),
                    type: 'error',
                })
                this.$router.push(this.$route.path.replace(/\/edit$/, '')).catch(err => err)
            }
            this.setItemIfNecessary(serverItem)
            this.itemModified = false
            this.refreshAppTitle()
        },
        type: {
            handler(type) {
                this.handleTypeChange(type)
            },
            immediate: true,
        },
        value: {
            deep: true,
            handler(value) {
                this.setItemIfNecessary(value)
            },
            immediate: true,
        },
    },
    methods: {

        // Page

        extractPageTypeFromRoute() {

            const path = _.trim(this.$route.path, '/')
            const segments = path.split('/')
            const segmentCount = segments.length
            const lastSegment = segments.length > 1 ? _.last(segments) : ''

            switch (segmentCount) {
                case 0:
                case 1:
                    return null
                case 2:
                case 4:
                    return (lastSegment === 'create' && (segmentCount === 2 || !this.isItemParent(true)))
                        ? 'create'
                        : (!!lastSegment.match(/^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/)
                                ? defaultPageType
                                : null
                        )
                default:
                    return this.parent
                        ? 'show'
                        : (
                            ~supportedPageTypes.indexOf(lastSegment)
                                ? lastSegment
                                : defaultPageType
                        )
            }
        },

        getItemPageUrl(force = false) {

            switch (true) {
                case this.parent:
                    return this.$route.path.replace(/^(\/[a-z]+(?:-[a-z0-9]+)*\/[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}).*$/, '$1')
                case !force && !!this.itemPageUrl:
                    return _.startsWith(this.itemPageUrl, '/') ? this.itemPageUrl : ('/' + this.itemPageUrl)
                case !!this.$route.path.match(/\/create\/?$/):
                    return ''
                default:
                    // Chop off all segments following the last uuid
                    return _.trimEnd(this.$route.path, '/')
                        .replace(/([0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12})(?:\/[a-z]+(?:-[a-z0-9]+)*)+$/, '$1')
            }

        },


        // Item

        /**
         * Get child item flag from stored variable or detect automatically
         *
         * @param force {boolean} Ignore stored value
         * @returns {boolean|boolean}
         */
        isItemChild(force = false) {
            switch (true) {
                case !force && this.itemIsChild !== null:
                    return !!this.itemIsChild
                case this.parent !== null:
                    return !this.parent
                default:
                    return _.trim(this.$route.path, '/').split('/', 3).length > 2
            }
        },

        /**
         * Get independent item flag from stored variable or detect automatically
         *
         * @param force {boolean} Ignore stored value
         * @returns {boolean}
         */
        isItemIndependent(force = false) {
            switch (true) {
                case !force && this.itemIsIndependent !== null:
                    return !!this.itemIsIndependent
                case this.parent !== null:
                    return false
                default:
                    return _.trim(this.$route.path, '/').split('/', 3).length <= 2
            }
        },

        /**
         * Get parent item flag from stored variable or detect automatically
         *
         * @param force {boolean} Ignore stored value
         * @returns {boolean}
         */
        isItemParent(force = false) {
            switch (true) {
                case !force && this.itemIsParent !== null:
                    return !!this.itemIsParent
                case this.parent !== null:
                    return this.parent
                default:
                    return false
            }
        },

        /**
         * Get item API url from stored variable or compose one
         *
         * @param force {boolean} Ignore stored value
         * @returns {string}
         */
        getItemApiUrl(force = false) {

            switch (true) {
                case !force && !!this.itemApiUrl:
                    return _.startsWith(this.itemApiUrl, '/') ? this.itemApiUrl : ('/' + this.itemApiUrl)
                case this.parent !== null:
                    const url = this.getItemPageUrl() || this.$route.path
                    return this.parent
                        ? url.split('/', 3).join('/')
                        : ('/' + url.split('/').splice(3, 2).join('/'))
                default:

                    // Chop off all segments following the last uuid
                    const path = _.trim(this.itemPageUrl || this.getItemPageUrl(force) || this.$route.path, '/')
                    const pathSegments = path.split('/', 4)

                    if (_.last(pathSegments) === 'create') {
                        return ('/' + pathSegments[pathSegments.length - 2])
                    }

                    switch (pathSegments.length) {

                        // Parent item page
                        case 2:
                            return '/' + path

                        // Child item page
                        case 4:
                            return '/' + pathSegments.splice(2, 2).join('/')

                        // No valid API url available
                        default:
                            return ''
                    }

            }
        },


        // Item page urls

        getItemCreateUrl() {
            return this.getItemIndexUrl() + '/create'
        },
        getItemEditUrl() {
            return this.getItemPageUrl() + '/edit'
        },
        getItemIndexUrl() {
            return (this.pageType === 'create')
                ? this.$route.path.replace(/\/create\/?$/, '')
                : this.getItemPageUrl().replace(/\/[^\/]+$/, '')
        },
        getItemShowUrl() {
            return this.getItemPageUrl()
        },


        // Item API resources

        getItemDestroyUrl() {
            return this.getItemApiUrl()
        },
        getItemUpdateUrl() {
            return this.getItemApiUrl()
        },
        getItemStoreUrl() {
            return this.getItemApiUrl().replace(/\/(?:create|[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12})$/, '')
        },


        // Item actions

        createItem() {
            this.$router.push(this.getItemCreateUrl())
        },
        editItem() {
            this.$router.push(this.getItemEditUrl())
        },
        destroyItem() {
            this.disableItem()
            return new Promise(function (resolve, reject) {
                if (this.pageType === 'create') {
                    reject()
                } else {
                    this.$api.delete(this.getItemDestroyUrl())
                        .then(function (response) {
                            resolve(response)
                            this.goToItemIndex()
                        }.bind(this))
                        .catch(function (err) {
                            this.enableItem()
                            reject(err)
                        }.bind(this))
                }
            }.bind(this))
        },
        goToItemIndex() {
            this.$router.push(this.getItemIndexUrl())
        },
        saveItem(thenGoToIndex = false) {
            return this.pageType === 'create' ? this.storeItem(thenGoToIndex) : this.updateItem(thenGoToIndex)
        },
        showItem() {
            this.$router.push(this.getItemShowUrl())
        },
        storeItem(index = false) {
            this.disableItem()
            return new Promise(function (resolve, reject) {
                this.$api.post(this.getItemStoreUrl(), _.merge(this.item, this.itemOverrides))
                    .then(function (response) {
                        resolve(response)
                        if (index) {
                            this.goToItemIndex()
                        } else {
                            const uuid = _.get(response.data, 'item.uuid', null)
                            if (uuid) {
                                this.$router.push(this.getItemIndexUrl() + '/' + uuid + '/edit')
                            }
                        }
                    }.bind(this))
                    .catch(function (err) {
                        this.enableItem()
                        reject(err)
                    }.bind(this))
            }.bind(this))
        },
        updateItem(index = false) {
            this.disableItem()
            return new Promise(function (resolve, reject) {
                this.$api.put(this.getItemUpdateUrl(), this.item)
                    .then(function (response) {
                        resolve(response)
                        if (!!index) {
                            this.goToItemIndex()
                        } else {
                            this.refreshItem()
                        }
                    }.bind(this))
                    .catch(function (err) {
                        this.enableItem()
                        reject(err)
                    }.bind(this))
            }.bind(this))
        },


        // Item delete functionality

        showDeleteDialog() {
            this.deleteDialogVisible = true
        },
        hideDeleteDialog() {
            this.deleteDialogVisible = false
        },
        deleteDialogConfirmed() {
            this
                .destroyItem()
                .then(function () {
                    this.hideDeleteDialog()
                }.bind(this))
                .catch(err => err)
        },
        deleteDialogRejected() {
            this.hideDeleteDialog()
        },


        // Item data manipulations

        refreshItem() {
            this.disableItem()
            return new Promise(function (resolve, reject) {
                this.fetchItem()
                    .then(function (resp) {
                        this.setServerItem(resp.data)
                        resolve(resp)
                    }.bind(this))
                    .catch(err => reject(err))
                    .finally(function () {
                        this.enableItem()
                    }.bind(this))
            }.bind(this))
        },

        /**
         * Fetch item data from the provided api url
         *
         * @returns {Promise}
         */
        fetchItem() {
            return new Promise(function (resolve, reject) {
                // Start loading
                this.setItemLoading(true)
                this.$api.get(this.getItemApiUrl())
                    .then(function (resp) {
                        this.setItemLoaded(true)
                        resolve(resp)
                    }.bind(this))
                    .catch(function (err) {
                        this.$emit(EVENT_LOAD_FAILED, err)
                        this.setItemLoading(false)
                        reject(err)
                    }.bind(this))
            }.bind(this))
        },

        /**
         * Set itemLoaded flag
         *
         * @param {*} value
         */
        setItemLoaded(value) {
            this.itemLoaded = !!value
        },

        /**
         * Set itemLoading flag
         *
         * @param {*} value
         */
        setItemLoading(value) {
            this.itemLoading = !!value
        },

        /**
         * Update item data
         *
         * @param {object} item
         */
        setItem(item) {
            this.item = this.clone(item)
        },

        /**
         * Update item data if it is different from what was passed in
         *
         * @param {object} item
         */
        setItemIfNecessary(item) {
            if (!this.objectsEqual(this.item, item)) {
                this.setItem(item)
            }
        },

        /**
         * Update itemModified flag
         *
         */
        refreshItemModified() {
            this.itemModified = !this.objectsEqual(this.serverItem, this.item)
        },

        /**
         * Update serverItem data
         *
         * @param {object} item
         */
        setServerItem(item) {
            this.serverItem = this.clone(item)
        },


        // Handle page type change

        handleTypeChange(type) {
            type = type.toLowerCase()
            this.pageType = !!type && ~supportedPageTypes.indexOf(type)
                ? type
                : (this.extractPageTypeFromRoute() || defaultPageType)
        },


        // Button click handlers

        handleEditButtonClick() {
            this.editItem()
        },
        handleIndexButtonClick() {
            this.goToItemIndex()
        },
        handleSaveButtonClick() {
            this.saveItem().catch(err => err)
        },
        handleSaveAndIndexButtonClick() {
            this.saveItem(true).catch(err => err)
        },
        handleShowButtonClick() {
            this.showItem()
        },
        handleDeleteButtonClick() {
            this.showDeleteDialog()
        },


        // Item disable/enable
        disableItem() {
            this.itemDisabled = true
        },
        enableItem() {
            this.itemDisabled = false
        },

        overrideParentId() {
            const path = this.$route.path
            const pathSegments = _.trim(path, '/').split('/', 4)
            if (pathSegments.length === 4) {
                const key = (pathSegments[0] === 'employees' ? 'user' : _(pathSegments[0]).singularize()) + '_id'
                return this.$api.get('/' + pathSegments.splice(0, 2).join('/'))
                    .then(function (resp) {
                        this.itemOverrides[key] = resp.data.id
                    }.bind(this))
            }

            return Promise.reject()
        },

        // App title
        refreshAppTitle() {
            const pageTitle = this.getPageTitle()
            this.pageTitle = pageTitle
            if (!this.isItemParent()) {
                this.customAppTitle = pageTitle
            }
        },

        getPageTitle() {
            return (this.pageType === 'create')
                ? this.l('pages.' + _.snakeCase(this.itemPluralSlug) + '.create.title')
                : _.get(this.item, 'name', '')
        },

    },
    created() {

        this.itemPageUrl = this.getItemPageUrl()
        this.itemApiUrl = this.getItemApiUrl()
        this.itemIsChild = this.isItemChild()
        this.itemIsParent = this.isItemParent()

        if (this.pageType === 'create') {
            if (this.itemIsChild) {
                this.disableItem()
                this.overrideParentId()
                    .then(function () {
                        this.enableItem()
                    }.bind(this))
                    .catch(err => err)
            }
            this.itemLoaded = true
            this.pageTitle = this.getPageTitle()
        } else {
            this.refreshItem().catch(err => err)
        }

    },

}