<template>
    <square-button
            v-if="square"
            :button-class="buttonClass"
            depressed
            :disabled="disabled || loading"
            :icon="icon"
            :icon-class="iconClass"
            large-icon-size="36"
            x-large-icon-size="44"
            :tooltip-text="tooltipText"
            v-bind="$attrs"
            v-on="$listeners"
    />
    <v-btn
            v-else
            :class="buttonClass"
            depressed
            :disabled="disabled || loading"
            v-bind="$attrs"
            v-on="$listeners"
    >
        {{ loading ? 'global.wait' : (label || l('pages.all.actions.excel')) }}
    </v-btn>
</template>

<script>
    import SquareButton from "./SquareButton";

    const EXCEL_BUTTON_NORMAL = 'green white--text'
    const EXCEL_BUTTON_LOCKED = 'grey lighten-2 grey--text'

    export default {
        name: "ExcelButton",
        inheritAttrs: false,
        components: {SquareButton},
        props: {
            disabled: {
                default: false,
                required: false,
                type: Boolean,
            },
            label: {
                default: null,
                required: false,
                type: String,
            },
            loading: {
                default: false,
                required: false,
                type: Boolean,
            },
            square: {
                default: false,
                required: false,
                type: Boolean,
            },
        },
        data: vm => ({
            buttonClass: EXCEL_BUTTON_NORMAL,
        }),
        computed: {
            icon() {
                return this.loading ? 'mdi-loading' : 'mdi-file-excel-outline'
            },
            iconClass() {
                return this.loading ? 'mdi-spin' : 'mb-2'
            },
            locked() {
                return (this.disabled || this.loading)
            },
            tooltipText() {
                return this.loading ? this.l('global.wait') : (this.label || this.l('pages.all.actions.excel'))
            }
        },
        watch: {
            locked: {
                immediate: true,
                handler(locked) {
                    this.buttonClass = locked ? EXCEL_BUTTON_LOCKED : EXCEL_BUTTON_NORMAL
                },
            },
        },
    }
</script>

<style scoped>

</style>