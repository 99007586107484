<template>
    <item-edit-view v-slot:default="{ item: adjustment }">
        Create
    </item-edit-view>
</template>

<script>
    import ItemCreateView from '../../Item/Views/ItemCreateView'

    export default {
        name: 'AdjustmentsCreatePage',
        components: {
            ItemCreateView,
        },
    }
</script>

<style scoped>

</style>