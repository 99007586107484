<template>
    <div>
        <email-text-field
                :dense="dense"
                :disabled="disabled"
                :hide-details="hideDetails"
                v-for="(_, index) in items"
                :key="index"
                v-model="items[index]"
                :confirmable="confirmable"
                autocomplete="off"
        >
            <template slot="append">
                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-icon
                                v-on="on"
                                :color="index === lastIndex ? 'transparent' : 'red'"
                                @click="deleteItem(index)"
                                :disabled="disabled || index === lastIndex"
                        >
                            mdi-close
                        </v-icon>
                    </template>
                    <span>{{ deleteTooltip }}</span>
                </v-tooltip>
            </template>
        </email-text-field>
    </div>
</template>

<script>
    import t from 'typy'
    import _ from 'lodash'
    import EmailTextField from '../TextFields/EmailTextField'

    const defaultModel = {
        address: '',
        is_confirmed: false,
    }

    export default {
        name: "EmailList",
        components: {
            EmailTextField,
        },
        props: {
            value: {
                type: Array,
                required: false,
                default: () => [],
            },
            confirmable: {
                type: Boolean,
                required: false,
                default: false,
            },
            dense: {
                type: Boolean,
                required: false,
                default: false,
            },
            disabled: {
                type: Boolean,
                required: false,
                default: false,
            },
            hideDetails: {
                type: Boolean,
                required: false,
                default: false,
            },
        },
        data: (vm) => ({
            deleteTooltip: vm.l('pages.all.actions.delete'),
            items: [],
            lastIndex: 0,
        }),
        watch: {
            value: {
                deep: true,
                immediate: true,
                handler(value) {
                    this.handleValue(value)
                }
            },
            items: {
                deep: true,
                handler(input) {
                    this.handleInput(input)
                }
            },
        },
        methods: {
            itemArraysEqual(items1, items2) {
                return items1.length === items2.length
                    && items1.filter(function (value, offset) {
                        return !this.itemsEqual(value, items2[offset])
                    }.bind(this)).length === 0
            },
            itemsEqual(item1, item2) {
                const keys = _.merge(_.keys(item1), _.keys(item2))
                return keys.map(key => undefined !== item1[key] && undefined !== item2[key] && item1[key] === item2[key]).filter(result => result).length === keys.length
            },
            assureArrangedItems() {
                const newItems = this.arrangeItems(this.items)
                if (newItems.length !== this.items.length) {
                    this.items = newItems
                    this.updateLastIndex()
                }
            },
            assureLastDefault(items) {
                const newItems = _.values(items)
                const lastItem = newItems.length ? _.last(newItems) : null

                if (!lastItem || !this.itemsEqual(defaultModel, lastItem)) {
                    newItems.push(_.clone(defaultModel))
                }

                return newItems
            },
            deleteItem(index) {
                this.items = _.values(_.omit(this.items, index))
            },
            arrangeItems(items) {
                return this.assureLastDefault(_.filter(items.slice(0).reverse(), function(item, index) {
                    return index === 0 || !this.itemsEqual(defaultModel, item);
                }.bind(this)).reverse())
            },
            cleanItems(items) {
                return items.slice(0, items.length - 1)
            },
            updateLastIndex() {
                this.lastIndex = this.items.length ? (this.items.length - 1) : 0
            },
            handleInput(input) {
                this.$emit('input', this.cleanItems(this.arrangeItems(input)))
            },
            handleValue(value) {
                const items = this.assureLastDefault(t(value).safeArray)
                if (!this.itemArraysEqual(items, this.items)) {
                    this.items = items.length ? items : [_.clone(defaultModel)]
                    this.updateLastIndex()
                }
            }
        },
        mounted() {
            this.assureArrangedItems()
        },
    }
</script>

<style scoped>

</style>