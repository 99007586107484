import auth from '../../auth'
import EmployeesShowAssignmentsIndexPage from '../../../components/Pages/locations/show/assignments/index'
import EmployeesShowAssignmentsCreatePage from '../../../components/Pages/locations/show/assignments/create'
import EmployeesShowAssignmentsEditPage from '../../../components/Pages/locations/show/assignments/edit'
import EmployeesShowAssignmentsShowPage from '../../../components/Pages/locations/show/assignments/show'

export default [
    {
        path: '/locations/:uuid/assignments/create',
        name: 'locations.show.assignments.create',
        component: EmployeesShowAssignmentsCreatePage,
        beforeEnter: auth.guard.user,
    },
    {
        path: '/locations/:uuid/assignments/:childUuid',
        name: 'locations.show.assignments.show',
        component: EmployeesShowAssignmentsShowPage,
        beforeEnter: auth.guard.user,
    },
    {
        path: '/locations/:uuid/assignments/:childUuid/edit',
        name: 'locations.show.assignments.edit',
        component: EmployeesShowAssignmentsEditPage,
        beforeEnter: auth.guard.user,
    },
    {
        path: '/locations/:uuid/assignments',
        name: 'locations.show.assignments',
        component: EmployeesShowAssignmentsIndexPage,
        beforeEnter: auth.guard.user,
    },

]
