import auth from '../../auth'
import EmployeesShowAdjustmentsIndexPage from '../../../components/Pages/employees/show/adjustments/index'
import EmployeesShowAdjustmentsCreatePage from '../../../components/Pages/employees/show/adjustments/create'
import EmployeesShowAdjustmentsEditPage from '../../../components/Pages/employees/show/adjustments/edit'
import EmployeesShowAdjustmentsShowPage from '../../../components/Pages/employees/show/adjustments/show'

export default [
    {
        path: '/employees/:uuid/adjustments/create',
        name: 'employees.show.adjustments.create',
        component: EmployeesShowAdjustmentsCreatePage,
        beforeEnter: auth.guard.user,
    },
    {
        path: '/employees/:uuid/adjustments/:childUuid',
        name: 'employees.show.adjustments.show',
        component: EmployeesShowAdjustmentsShowPage,
        beforeEnter: auth.guard.user,
    },
    {
        path: '/employees/:uuid/adjustments/:childUuid/edit',
        name: 'employees.show.adjustments.edit',
        component: EmployeesShowAdjustmentsEditPage,
        beforeEnter: auth.guard.user,
    },
    {
        path: '/employees/:uuid/adjustments',
        name: 'employees.show.adjustments',
        component: EmployeesShowAdjustmentsIndexPage,
        beforeEnter: auth.guard.user,
    },

]
