<template>
    <cash-operations-show-page v-slot:default="_">
        <v-simple-table>
            <tbody>
            <tr>
                <th>{{ l('pages.cash_operations.properties.user') }}</th>
                <td>{{ _.item.user | name }}</td>
            </tr>
            <tr>
                <th>{{ l('pages.cash_operations.properties.cashbox') }}</th>
                <td>{{ _.item.cashbox | name }}</td>
            </tr>
            <tr>
                <th>{{ l('pages.cash_operations.properties.note') }}</th>
                <td>{{ _.item.note }}</td>
            </tr>
            <tr v-if="_.item.type">
                <th>{{ l('pages.cash_operations.properties.type') }}</th>
                <td>{{ l('pages.cash_operations.values.type.' + _.item.type) }}</td>
            </tr>
            <tr>
                <th>{{ l('pages.cash_operations.properties.amount') }}</th>
                <td><amount currency>{{ _.item.amount }}</amount></td>
            </tr>
            <tr>
                <th>{{ l('pages.cash_operations.properties.created_at') }}</th>
                <td>{{ _.item.created_at | euroDateShortTime }}</td>
            </tr>
            <tr>
                <th>{{ l('pages.cash_operations.properties.updated_at') }}</th>
                <td>{{ _.item.updated_at | euroDateShortTime }}</td>
            </tr>
            </tbody>
        </v-simple-table>

    </cash-operations-show-page>
</template>

<script>
    import CashOperationsShowPage from "../show";
    import Amount from "../../../Shared/Text/Amount";
    export default {
        name: "CashOperationShowDetailsPage",
        components: {Amount, CashOperationsShowPage}
    }
</script>

<style scoped>

</style>