<template>
    <v-form>
        <subcard>
            <v-row>
                <v-col cols="12" sm="6" md="2">
                    <v-select
                            :disabled="disabled"
                            hide-details
                            :label="l('pages.residences.properties.type')"
                            :items="typeList"
                            v-model="item.type_id"
                            :rules="[rules.required]"
                            required
                    />
                </v-col>
                <v-col cols="12" sm="6" md="3">
                    <date-field
                            clearable
                            :disabled="disabled"
                            hide-details
                            :label="l('pages.residences.properties.from_date')"
                            v-model="item.from_date"
                    />
                </v-col>
                <v-col cols="12" sm="6" md="3">
                    <date-field
                            clearable
                            :disabled="disabled"
                            hide-details
                            :label="l('pages.residences.properties.to_date')"
                            v-model="item.to_date"
                    />
                </v-col>
                <v-col cols="12" sm="6" md="4">
                    <v-text-field
                            :disabled="disabled"
                            hide-details
                            :label="l('pages.residences.properties.note')"
                            v-model="item.note"
                    ></v-text-field>
                </v-col>
            </v-row>
        </subcard>
    </v-form>
</template>

<script>
    import DateField from '../../../../../Shared/Forms/TextFields/DateField'
    import Subcard from '../../../../../Shared/Cards/Subcard'

    const defaultResidenceModel = {
        type_id: null,
        from_date: '',
        to_date: '',
        note: '',
    }

    export default {
        name: "EmployeeResidenceEditForm",
        inheritAttrs: false,
        components: {
            DateField,
            Subcard,
        },
        props: {
            value: {
                required: false,
                type: Object,
                default: () => defaultResidenceModel,
            },
            disabled: {
                required: false,
                type: Boolean,
                default: false,
            }
        },
        data: vm => ({
            typeList: [],
            item: {},
            rules: {
                required: value => undefined !== value && '' !== value || vm.l('pages.residences.validation.required')
            },
        }),
        watch: {
            value: {
                deep: true,
                immediate: true,
                handler(value) {
                    this.item = value
                },
            },
            item: {
                deep: true,
                handler(value) {
                    this.$emit('input', value)
                },
            },
        },
        created() {
            this.$api.get('residence-types/list', {params: {favorite: 1}}).then(function (response) {
                this.typeList = response.data
            }.bind(this))
        },
    }
</script>

