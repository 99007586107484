<template>
    <item-edit-view v-slot:default="_">
        <cash-operation-edit-form
                :disabled="_.itemDisabled"
                v-model="_.item"
        />
    </item-edit-view>
</template>

<script>
    import ItemEditView from '../../Item/Views/ItemEditView'
    import CashOperationEditForm from './Shared/CashOperationEditForm'

    export default {
        name: 'CashOperationsEditPage',
        components: {
            ItemEditView,
            CashOperationEditForm,
        },
    }
</script>

<style scoped>

</style>