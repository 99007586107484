import auth from '../../auth'
import EmployeesShowContractsIndexPage from '../../../components/Pages/employees/show/contracts/index'
import EmployeesShowContractsCreatePage from '../../../components/Pages/employees/show/contracts/create'
import EmployeesShowContractsEditPage from '../../../components/Pages/employees/show/contracts/edit'
import EmployeesShowContractsShowPage from '../../../components/Pages/employees/show/contracts/show'

export default [
    {
        path: '/employees/:uuid/contracts/create',
        name: 'employees.show.contracts.create',
        component: EmployeesShowContractsCreatePage,
        beforeEnter: auth.guard.user,
    },
    {
        path: '/employees/:uuid/contracts/:childUuid',
        name: 'employees.show.contracts.show',
        component: EmployeesShowContractsShowPage,
        beforeEnter: auth.guard.user,
    },
    {
        path: '/employees/:uuid/contracts/:childUuid/edit',
        name: 'employees.show.contracts.edit',
        component: EmployeesShowContractsEditPage,
        beforeEnter: auth.guard.user,
    },
    {
        path: '/employees/:uuid/contracts',
        name: 'employees.show.contracts',
        component: EmployeesShowContractsIndexPage,
        beforeEnter: auth.guard.user,
    },

]
