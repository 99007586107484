<template>
    <employees-show-page parent v-slot:default="_parent">
        <sub-item-show-view v-slot:default="_">
            <employee-shift-edit-form :disabled="_.itemDisabled" v-model="_.item" :employee="_parent.item" />
        </sub-item-show-view>
    </employees-show-page>
</template>

<script>
    import EmployeesShowPage from '../../show'
    import SubItemShowView from '../../../../Item/Views/SubItemShowView'
    import EmployeeShiftEditForm from './Shared/EmployeeShiftEditForm'

    export default {
        name: 'EmployeesShowShiftsCreatePage',
        inheritAttrs: false,
        components: {
            EmployeeShiftEditForm,
            EmployeesShowPage,
            SubItemShowView,
        },
    }
</script>

<style scoped>

</style>