<template>
    <div>
        <v-input
                v-for="(_, index) in items"
                :key="index"
                :dense="dense"
                :disabled="disabled"
                :hide-details="hideDetails"
                :class="dense && index !== 0 ? 'mt-3' : ''"
        >
            <slot
                    :index="index"
                    :item="items[index]"
                    :item-disabled="disabled"
                    :item-dense="dense"
                    :item-hide-details="hideDetails"
            ></slot>
            <template slot="append">
                <delete-icon
                        :class="dense ? 'mt-1' : 'mt-3'"
                        :color="index === lastIndex ? 'transparent' : 'red'"
                        @click="deleteItem(index)"
                        :disabled="disabled || index === lastIndex"
                ></delete-icon>
            </template>
            <template v-for="(_, slot) of $slots" slot="slot">
                <slot :name="slot"/>
            </template>
        </v-input>
    </div>
</template>

<script>
    import _ from 'lodash'
    import DeleteIcon from "../Icons/DeleteIcon";

    export default {
        name: "InputList",
        components: {
            DeleteIcon,
        },
        props: {
            modelTemplate: {
                required: false,
                default: () => ({}),
            },
            dense: {
                type: Boolean,
                required: false,
                default: false,
            },
            disabled: {
                type: Boolean,
                required: false,
                default: false,
            },
            hideDetails: {
                type: Boolean,
                required: false,
                default: false,
            },
            value: {
                type: Array,
                required: false,
                default: () => [],
            },
        },
        data: (vm) => ({
            items: [],
            lastIndex: 0,
        }),
        watch: {
            value: {
                deep: true,
                immediate: true,
                handler(value) {
                    this.handleValueChange(value)
                }
            },
            items: {
                deep: true,
                immediate: true,
                handler(input) {
                    this.handleItemsChange(input)
                }
            },
        },
        methods: {
            addLast(items) {
                const newItems = this.clone(items)
                newItems.push(this.getModelTemplate())
                return newItems
            },
            addLastIfNecessary(items) {
                const count = items.length
                return (!count || !this.itemsEqual(this.modelTemplate, items[count - 1]))
                    ? this.addLast(items)
                    : items
            },
            arrangeItems(items) {
                const nonEmptyItems = _.filter(this.clone(items), function (item, index) {
                    return !this.itemsEqual(this.modelTemplate, item);
                }.bind(this))
                return this.addLastIfNecessary(nonEmptyItems)
            },
            trimItems(items) {
                const count = items.length
                const lastItemIndex = (count > 0) ? (count - 1) : 0
                return (count > 0 && this.itemsEqual(items[lastItemIndex], this.modelTemplate))
                    ? items.slice(0, lastItemIndex)
                    : items
            },
            clone(value) {
                return _.isArray(value) || _.isObject(value)
                    ? JSON.parse(JSON.stringify(value))
                    : value
            },
            deleteItem(index) {
                this.items = _.values(_.omit(this.clone(this.items), index))
            },
            getModelTemplate() {
                return this.clone(this.modelTemplate)
            },
            handleItemsChange(items) {
                const arrangedItems = this.arrangeItems(items)
                if (!this.itemArraysEqual(this.items, arrangedItems)) {
                    this.items = arrangedItems
                    this.lastIndex = arrangedItems.length - 1
                } else {
                    const trimmedItems = this.trimItems(arrangedItems)
                    if (!this.itemArraysEqual(this.value, trimmedItems)) {
                        this.$emit('input', trimmedItems)
                    }
                }
            },
            handleValueChange(value) {
                const trimItems = this.trimItems(this.items)
                if (!this.itemArraysEqual(value, trimItems)) {
                    this.items = this.clone(value)
                }
            },
            itemArraysEqual(items1, items2) {
                return _.isArray(items1) && _.isArray(items2)
                    && items1.length === items2.length
                    && _.filter(items1, function (value, index) {
                        return !this.itemsEqual(value, items2[index])
                    }.bind(this)).length === 0
            },
            itemsEqual(item1, item2) {
                const type = typeof item1
                switch (true) {
                    case type !== (typeof item2):
                        return false
                    case type === 'object':
                        return !JSON.stringify(item1).localeCompare(JSON.stringify(item2))
                    default:
                        return item1 === item2
                }
            },
        },
    }
</script>

<style scoped>

</style>