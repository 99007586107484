<template>
    <div>
        <page-card
                v-if="wrapped"
                v-on="$listeners"
                v-bind="$attrs"
                :tabs="tabSettings"
                :content-color="contentColor"
        >

            <!-- ITEM TITLE -->
            <template slot="title">
                <v-flex>
                    <v-skeleton-loader v-if="!itemLoaded" type="card-heading"></v-skeleton-loader>
                    <div class="d-flex" v-if="itemLoaded">

                        <v-flex class="d-none d-sm-inline-flex">
                            <span>{{ pageTitle || appTitle }}</span>
                            <span v-if="itemModified">
                                <v-chip
                                        class="text-uppercase font-weight-bold ml-3"
                                        color="red"
                                        dark
                                        small
                                >
                                    {{ l('pages.all.indicators.modified') }}
                                </v-chip>
                            </span>
                            <slot
                                    name="header"
                                    v-bind:item="item"
                                    v-bind:item-disabled="itemDisabled"
                                    v-bind:item-loaded="itemLoaded"
                                    v-bind:item-loading="itemLoading"
                                    v-bind:item-modified="itemModified"
                                    v-bind:server-item="serverItem"
                            />
                        </v-flex>

                        <v-flex class="text-right">

                            <!-- ITEM BUTTONS -->
                            <slot
                                    name="buttons"
                                    v-bind:item="item"
                                    v-bind:item-disabled="itemDisabled"
                                    v-bind:item-loaded="itemLoaded"
                                    v-bind:item-loading="itemLoading"
                                    v-bind:item-modified="itemModified"
                                    v-bind:server-item="serverItem"
                            />

                            <div class="d-inline-flex" v-if="editButtonEnabled">
<!--
                                <v-btn
                                        class="d-none d-sm-inline-flex"
                                        @click="handleEditButtonClick()"
                                        :disabled="itemDisabled"
                                        text
                                >
                                    {{ l('pages.all.actions.edit') }}
                                </v-btn>
                                <v-btn
                                        class="d-inline-flex d-sm-none"
                                        @click="handleEditButtonClick()"
                                        :disabled="itemDisabled"
                                        icon
                                        transparent
                                >
                                    <v-icon>
                                        mdi-pencil-outline
                                    </v-icon>
                                </v-btn>
-->
                                <edit-icon
                                        class="ml-1"
                                        @click="handleEditButtonClick()"
                                        :disabled="itemDisabled"
                                />
                            </div>
                            <div class="d-inline-flex" v-if="showButtonEnabled">
<!--
                                <v-btn
                                        class="d-none d-sm-inline-flex"
                                        @click="handleShowButtonClick()"
                                        :disabled="itemDisabled"
                                        text
                                >
                                    {{ l('pages.all.actions.show') }}
                                </v-btn>
                                <v-btn
                                        class="d-inline-flex d-sm-none"
                                        @click="handleShowButtonClick()"
                                        :disabled="itemDisabled"
                                        icon
                                        transparent
                                >
                                    <v-icon>
                                        mdi-eye-outline
                                    </v-icon>
                                </v-btn>
-->
                                <show-icon
                                        class="ml-1"
                                        @click="handleShowButtonClick()"
                                        :disabled="itemDisabled"
                                />
                            </div>
                            <div class="d-inline-flex" v-if="deleteButtonEnabled">
<!--
                                <v-btn
                                        class="d-none d-sm-inline-flex"
                                        @click="handleDeleteButtonClick()"
                                        color="red"
                                        :disabled="itemDisabled"
                                        text
                                >
                                    {{ l('pages.all.actions.delete') }}
                                </v-btn>
                                <v-btn
                                        class="d-inline-flex d-sm-none"
                                        color="red"
                                        @click="handleDeleteButtonClick()"
                                        :disabled="itemDisabled"
                                        icon
                                        transparent
                                >
                                    <v-icon>
                                        mdi-close
                                    </v-icon>
                                </v-btn>
-->
                                <delete-icon
                                        class="ml-1"
                                        @click="handleDeleteButtonClick()"
                                        :disabled="itemDisabled"
                                />
                            </div>
                            <div class="d-inline-flex">
<!--
                                <v-btn
                                        class="d-none d-sm-inline-flex"
                                        @click="handleIndexButtonClick()"
                                        :disabled="itemDisabled"
                                        text
                                >
                                    {{ l('pages.all.actions.back_to_index') }}
                                </v-btn>
                                <v-btn
                                        class="d-inline-flex d-sm-none"
                                        @click="handleIndexButtonClick()"
                                        :disabled="itemDisabled"
                                        icon
                                        transparent
                                >
                                    <v-icon>
                                        mdi-format-list-bulleted-square
                                    </v-icon>
                                </v-btn>
-->
                                <tooltip-icon
                                        class="ml-1"
                                        @click="handleIndexButtonClick()"
                                        :disabled="itemDisabled"
                                        :tooltip="l('pages.all.actions.back_to_index')"
                                >
                                    mdi-menu-open
                                </tooltip-icon>
                            </div>
                        </v-flex>
                    </div>
                </v-flex>
            </template>


            <!-- ITEM CONTENT -->
            <template slot="default">
                <div>
                    <v-skeleton-loader v-if="!itemLoaded" type="card"></v-skeleton-loader>
                    <div v-if="itemLoaded">
                        <slot
                                v-bind:item="item"
                                v-bind:item-disabled="itemDisabled"
                                v-bind:item-loaded="itemLoaded"
                                v-bind:item-loading="itemLoading"
                                v-bind:item-modified="itemModified"
                                v-bind:server-item="serverItem"
                        />
                    </div>
                </div>
            </template>


            <!-- ITEM ACTIONS -->
            <template slot="actions" v-if="saveButtonEnabled || $slots.actions">
                <v-flex class="text-right">
                    <v-skeleton-loader v-if="saveButtonEnabled && !itemLoaded" type="actions"/>
                    <slot
                            v-if="itemLoaded"
                            name="actions"
                            v-bind:item="item"
                            v-bind:item-disabled="itemDisabled"
                            v-bind:item-loaded="itemLoaded"
                            v-bind:item-loading="itemLoading"
                            v-bind:item-modified="itemModified"
                            v-bind:server-item="serverItem"
                    />
                    <div class="d-inline-flex" v-if="saveButtonEnabled">
                        <div class="d-inline-flex">
                            <v-btn
                                    class="d-none d-sm-inline-flex ml-2"
                                    @click="handleSaveButtonClick()"
                                    :disabled="itemDisabled"
                                    text
                            >
                                {{ l('pages.all.actions.save') }}
                            </v-btn>
                            <v-btn
                                    class="d-inline-flex d-sm-none ml-2"
                                    @click="handleSaveButtonClick()"
                                    depressed
                                    :disabled="itemDisabled"
                                    fab
                                    transparent
                            >
                                <v-icon>
                                    mdi-check-bold
                                </v-icon>
                            </v-btn>
                        </div>
                        <div class="d-inline-flex">
                            <v-btn
                                    class="d-none d-sm-inline-flex ml-2"
                                    @click="handleSaveAndIndexButtonClick()"
                                    color="green"
                                    :dark="!itemDisabled"
                                    depressed
                                    :disabled="itemDisabled"
                            >
                                {{ l('pages.all.actions.save_and_index') }}
                            </v-btn>
                            <v-btn
                                    class="d-inline-flex d-sm-none ml-2"
                                    @click="handleSaveAndIndexButtonClick()"
                                    color="green"
                                    :dark="!itemDisabled"
                                    depressed
                                    :disabled="itemDisabled"
                                    fab
                            >
                                <v-icon>
                                    mdi-playlist-check
                                </v-icon>
                            </v-btn>
                        </div>
                    </div>
                </v-flex>
            </template>

            <!-- ITEM DELETE DIALOG WINDOW -->
            <delete-dialog
                    v-if="deleteButtonEnabled"
                    :visible="deleteDialogVisible"
                    :title="l('pages.all.actions.delete')"
                    @confirmed="deleteDialogConfirmed()"
                    @rejected="deleteDialogRejected()"
            />

        </page-card>

        <div v-else>
            <!-- ITEM CONTENT -->
            <div v-if="itemLoaded">
                <slot
                        v-bind:item="item"
                        v-bind:item-disabled="itemDisabled"
                        v-bind:item-loaded="itemLoaded"
                        v-bind:item-loading="itemLoading"
                        v-bind:item-modified="itemModified"
                        v-bind:server-item="serverItem"
                />
            </div>
        </div>

    </div>
</template>

<script>

    import ItemViewMixin from './ItemViewMixin'
    import PageCard from '../../Shared/Cards/PageCard'
    import DeleteDialog from '../../Shared/Dialogs/DeleteDialog'
    import Item from '../Item'
    import TooltipIcon from "../../Shared/Icons/TooltipIcon";
    import EditIcon from "../../Shared/Icons/EditIcon";
    import ShowIcon from "../../Shared/Icons/ShowIcon";
    import DeleteIcon from "../../Shared/Icons/DeleteIcon";

    export default {
        name: "ItemView",
        inheritAttrs: false,
        mixins: [
            ItemViewMixin,
        ],
        components: {
            DeleteIcon,
            ShowIcon,
            EditIcon,
            TooltipIcon,
            Item,
            PageCard,
            DeleteDialog,
        },
        props: {
            contentColor: {
                default: 'blue-grey lighten-5',
                required: false,
                type: String,
            },
        },
    }
</script>
