<template>
    <locations-show-page parent v-slot:default="_parent">
        <sub-item-show-view v-slot:default="_">
            <location-assignment-edit-form
                    :disabled="_.itemDisabled"
                    v-model="_.item"
                    :parent-model="_parent.item"
                    @position="handlePosition"
            />
        </sub-item-show-view>
    </locations-show-page>
</template>

<script>
    import _ from 'lodash'
    import LocationsShowPage from '../../show'
    import SubItemShowView from '../../../../Item/Views/SubItemShowView'
    import LocationAssignmentEditForm from './Shared/LocationAssignmentEditForm'

    export default {
        name: 'LocationsShowAssignmentsCreatePage',
        inheritAttrs: false,
        components: {
            LocationAssignmentEditForm,
            LocationsShowPage,
            SubItemShowView,
        },
        data: vm => ({
            positionId: null,
            unitId: null,
        }),
        methods: {
            handlePosition(position) {
                this.positionId = _.get(position, 'id', null)
                this.unitId = _.get(position, 'unit_id', null)
            },
        },
    }
</script>

<style scoped>

</style>