<template>
    <employees-show-page
            parent
            v-slot:default="_parent"
    >
        <sub-item-show-view
                v-slot:default="{item: cash_operation}"
        >
            <subcard>
                <v-simple-table>
                    <tbody>
                    <tr>
                        <th>{{ l('pages.cash_operations.properties.user') }}</th>
                        <td>{{ cash_operation.user | name }}</td>
                    </tr>
                    <tr>
                        <th>{{ l('pages.cash_operations.properties.cashbox') }}</th>
                        <td>{{ cash_operation.cashbox | name }}</td>
                    </tr>
                    <tr>
                        <th>{{ l('pages.cash_operations.properties.note') }}</th>
                        <td>{{ cash_operation.note }}</td>
                    </tr>
                    <tr v-if="cash_operation.type">
                        <th>{{ l('pages.cash_operations.properties.type') }}</th>
                        <td>{{ l('pages.cash_operations.values.type.' + cash_operation.type) }}</td>
                    </tr>
                    <tr>
                        <th>{{ l('pages.cash_operations.properties.amount') }}</th>
                        <td><amount currency>{{ cash_operation.amount }}</amount></td>
                    </tr>
                    <tr>
                        <th>{{ l('pages.cash_operations.properties.created_at') }}</th>
                        <td>{{ cash_operation.created_at | euroDateShortTime }}</td>
                    </tr>
                    <tr>
                        <th>{{ l('pages.cash_operations.properties.updated_at') }}</th>
                        <td>{{ cash_operation.updated_at | euroDateShortTime }}</td>
                    </tr>
                    </tbody>
                </v-simple-table>
            </subcard>
        </sub-item-show-view>
    </employees-show-page>
</template>

<script>
    import EmployeesShowPage from '../../show'
    import Subcard from '../../../../Shared/Cards/Subcard'
    import SubItemShowView from '../../../../Item/Views/SubItemShowView'
    import Amount from "../../../../Shared/Text/Amount";

    export default {
        name: 'EmployeesShowCashOperationsShowPage',
        inheritAttrs: false,
        components: {
            Amount,
            EmployeesShowPage,
            Subcard,
            SubItemShowView,
        },
        data: vm => ({
        }),
    }
</script>

<style scoped>

</style>