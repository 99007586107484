<template>
  <subcard>
    <template slot="title">
      <v-flex>
        <div class="d-block d-sm-flex">
          <v-flex class="d-block d-sm-inline-block text-center text-sm-left">
            <span>
                {{ l('pages.my.dashboard.cards.balances.title') }}
            </span>
          </v-flex>
          <v-flex class="d-sm-inline-block d-block text-center text-sm-right"/>
        </div>
      </v-flex>
    </template>
    <v-flex class="mb-3">
      <div class="d-flex">
        <v-flex>
          <v-row dense>
            <v-col cols="6">
              <term-select
                  :items="termOptions"
                  v-model="term"
                  hide-details
                  dense
              />
            </v-col>
            <v-col cols="6">
              <v-select
                  :items="debtOptions"
                  v-model="debt"
                  hide-details
                  dense
              />
            </v-col>
          </v-row>
        </v-flex>
        <v-flex class="text-right">
          <excel-button
              class="ml-2"
              :disabled="exportDisabled"
              :loading="exportLoading"
              square
              @click="exportBalances"
          />
        </v-flex>
      </div>
    </v-flex>
    <v-data-table
        dense
        v-if="!!items && items.length"
        :footer-props="footerProps"
        :headers="headers"
        :items="items"
        :items-per-page="itemsPerPage"
    >
      <template v-slot:item.list_name="{ item }">
        <router-link
            :to="{name: 'employees.show', params: {uuid: item.uuid}}"
        >
          {{ item.list_name }}
        </router-link>
      </template>
      <template v-slot:item.locked="{ item }">
        {{ item.locked | amount }}
      </template>
      <template v-slot:item.account="{ item }">
        {{ item.account | amount }}
      </template>
      <template v-slot:item.total="{ item }">
        {{ item.total | amount }}
      </template>
    </v-data-table>
  </subcard>
</template>

<script>
import Subcard from '../../../../Shared/Cards/Subcard'
import TermSelect from '../../../../Shared/Forms/Selects/TermSelect'
import ApiSelect from '../../../../Shared/Forms/ApiSelect'
import ExcelButton from '../../../../Shared/Buttons/ExcelButton'
import _ from 'lodash'

export default {
  name: 'MyDashboardBalancesCard',
  components: {
    ExcelButton,
    ApiSelect,
    TermSelect,
    Subcard
  },
  data: vm => ({
    expansionMap: [],
    exportDisabled: true,
    exportLoading: false,
    lodash: _,
    balances: {},
    items: [],
    debt: 'show',
    debtOptions: [
      {
        value: 'show',
        text: vm.l('pages.my.dashboard.cards.balances.debt.options.show')
      },
      {
        value: 'only',
        text: vm.l('pages.my.dashboard.cards.balances.debt.options.only')
      },
      {
        value: 'hide',
        text: vm.l('pages.my.dashboard.cards.balances.debt.options.hide')
      },
    ],
    footerProps: {
      itemsPerPageOptions: [10, 20, 50],
    },
    headers: [
      {
        align: 'left',
        sortable: true,
        text: vm.l('pages.my.dashboard.cards.balances.headers.list_name'),
        value: 'list_name',
      },
      {
        align: 'right',
        sortable: true,
        text: vm.l('pages.my.dashboard.cards.balances.headers.locked'),
        value: 'locked',
      },
      {
        align: 'right',
        sortable: true,
        text: vm.l('pages.my.dashboard.cards.balances.headers.account'),
        value: 'account',
      },
      {
        align: 'right',
        sortable: true,
        text: vm.l('pages.my.dashboard.cards.balances.headers.total'),
        value: 'total',
      },
    ],
    itemsPerPage: 20,
    term: null,
    termOptions: [],
  }),
  watch: {
    balances(balances) {
      this.items = _.get(balances, 'items', [])
    },
    debt: 'updateBalances',
    items(items) {
      this.exportDisabled = !items || items.length === 0
    },
    term: 'updateBalances',
  },
  methods: {
    exportBalances() {
      this.exportLoading = true
      this.$api
          .get('stats/balances/excel/' + this.term, {params: {debt: this.debt}})
          .then(response => response)
          .catch(error => error)
          .finally(function () {
            this.exportLoading = false
          }.bind(this))
    },
    updateTermOptions() {
      this.$api
          .get('stats/terms/list/desc')
          .then(function (response) {
            const termOptions = response.data
            if (termOptions.length > 0) {
              this.termOptions = termOptions
              if (!this.term || termOptions.indexOf(this.term) === -1) {
                this.term = termOptions[(termOptions.length > 1) ? 1 : 0].value
              }
            }
          }.bind(this))
          .catch(error => error)
    },
    updateBalances() {
      this.wipeBalances()
      this.$api
          .get('stats/balances/' + this.term, {params: {debt: this.debt}})
          .then(function (response) {
            this.balances = response.data
          }.bind(this))
          .catch(error => error)
    },
    wipeBalances() {
      this.balances = {}
    },
  },
  created() {
    this.updateTermOptions()
  },
}
</script>
